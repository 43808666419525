import React from 'react';
import logo from 'assets/img/logo/logo.png';

const AuthLayout = ({title, description, headingChild, children, className}) => {
  return (
    <div className={`pi-auth-container ${className}`}>
      <div className='pi-logo pi-text-center'>
        <img src={logo} alt='Pi Variables' width={90} className='m-auto' />
      </div>
      <div className='pi-heading pi-text-center'>
        {title && <h2 className='pi-color-gray pi-uppercase pi-bold-700'>{title}</h2>}
        {description && <p>{description}</p>}
        {headingChild && headingChild}
      </div>
      <div className='pi-content'>{children}</div>
    </div>
  );
};

export default AuthLayout;
