import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';

import {
  TextField,
  Button,
  RadioButton,
  Checkbox,
  TextArea,
  DashedTitle,
  ReactSelect,
  PopOver,
  PhoneNumber,
} from 'elements';

import {phoneFieldValidator} from 'core/helpers/form.helper';
import {
  getInitialValues,
  validationSchemaStationary,
  validationSchemaMoving,
  unitOptions,
  checkOnlyNumber,
} from './driver.form.utils';
import ToggleSwitch from '../../../../../elements/ToggleSwitch';

export default function DriverForm({initialValues, onSubmit, disabled, fromViewMode}) {
  const [movingEvent, setMovingEvent] = useState(false);

  const {
    dirty,
    status,
    values,
    errors,
    touched,
    isValid,
    setValues,
    setStatus,
    handleBlur,
    handleChange,
    handleSubmit,
    validateForm,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    onSubmit,
    validationSchema: movingEvent ? validationSchemaMoving : validationSchemaStationary,
    enableReinitialize: true,
    initialValues: getInitialValues(initialValues),
  });

  const [popover, setPopover] = useState(!values.trackMovements && !fromViewMode);
  const [updatePopover, setUpdatePopover] = useState(false);

  function onConstructionChecked() {
    // setValues({...values, construction: !values.construction, maintenance: false});
    setValues({...values, trackMovements: false, iconType: 'construction' || values.iconType});
  }

  function onMaintenanceChecked() {
    // setValues({...values, maintenance: !values.maintenance, construction: false});
    setValues({...values, trackMovements: true, iconType: 'snow' || values.iconType});
  }

  function onUnitChange(val) {
    setValues({...values, geofenceUnit: val});
  }

  function onIconTypeChange(icon) {
    setValues({...values, iconType: icon});
  }

  function onUpdateStationaryEvents(state) {
    setValues({...values, updateStationaryEvents: state});
  }

  function onGeofenceEdit(value) {
    value =
      value.indexOf('.') >= 0
        ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
        : value;
    setValues({...values, geofenceDistance: value});
  }

  function validatePhone(isValid) {
    setStatus(isValid ? null : {phone: 'The phone number is not valid. '});
  }

  function onPhoneNumberBlur() {
    setFieldTouched('phoneNumber', true);
  }

  function onPhoneNumberChange(v) {
    setFieldValue('phoneNumber', v);
  }

  useEffect(() => {
    !values.iconType && values.iconType === '' && setValues({...values, iconType: 'construction'});
    if (values.trackMovements === false && !fromViewMode) {
      setTimeout(() => setPopover(false), 10000);
    }
    setMovingEvent(values.trackMovements);
  }, [values]);

  useEffect(() => {
    validateForm();
  }, [movingEvent]);

  return (
    <form onSubmit={handleSubmit}>
      <p className='pi-color-text fl-info-text'>
        <i className='icon-info' />
        Check the
        <a
          target='_blank'
          href='https://pi-lit.com/wp-content/uploads/2022/10/Pi-Link%E2%84%A2-Cloud-Connectivity-for-Pi-Lit%C2%AE-Sequential-Flares.pdf'
        >
          link
        </a>{' '}
        for more information
      </p>
      <DashedTitle title='Driver Information' stepNumber='1' indicator={true} position='left' />
      <div className='fl-form'>
        <TextField
          shadow
          name='firstName'
          label='First Name *'
          placeholder='First Name'
          value={values.firstName}
          errorMessage={touched.firstName && errors.firstName}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
        />
        <TextField
          shadow
          name='lastName'
          label='Last Name *'
          placeholder='Last Name'
          value={values.lastName}
          errorMessage={touched.lastName && errors.lastName}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={disabled}
        />
        <PhoneNumber
          name='phoneNumber'
          label='Phone Number *'
          hint='Enter number with your country code.'
          value={values.phoneNumber}
          errorMessage={touched.phoneNumber && (errors.phoneNumber || status?.phone)}
          onValidate={validatePhone}
          onBlur={onPhoneNumberBlur}
          onChange={onPhoneNumberChange}
          disabled={disabled}
        />
        <TextField
          shadow
          name='vehicleId'
          label='Vehicle Name/ID *'
          placeholder='Vehicle Name/ID'
          value={values.vehicleId}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={touched.vehicleId && errors.vehicleId}
          disabled={disabled}
        />
        <div className='fl-layout-full'>
          <DashedTitle
            title='Road assistance vehicle onscene'
            stepNumber='2'
            indicator={true}
            position='left'
          />
          <TextArea
            className='mb-30'
            name='alertMessage'
            label='Custom Notification'
            placeholder={values.defaultMessage || 'Custom Notification'}
            value={values.alertMessage}
            errorMessage={errors.alertMessage}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        {!values.trackMovements && (
          <div className='fl-layout-full fl-layout-distance'>
            <div className='pi-field'>
              <div className='fl-popover-container'>
                <label htmlFor='lorem' className='pi-field-label'>
                  <span>Geofence distance *</span>
                  <i
                    className='icon-info pi-font-size-xl'
                    onClick={() => !disabled && setPopover(true)}
                  />
                </label>
                {popover && (
                  <PopOver
                    size='sm'
                    position='custom'
                    title='Note'
                    description='When the Pi-Link is outside this geofence distance, it will automatically deactivate the alert.'
                  >
                    <Button
                      icon
                      size='sm'
                      color='secondary'
                      styleType='filled'
                      className='pi-width-xs'
                      onClick={() => setPopover(false)}
                    >
                      <span>OK</span>
                    </Button>
                  </PopOver>
                )}
              </div>
              <TextField
                type='number'
                step='0.01'
                inputmode='decimal'
                id='geofence_distance'
                className='mb-30'
                name='geofenceDistance'
                placeholder='0.00'
                onChange={e => onGeofenceEdit(e.target.value)}
                onBlur={handleBlur}
                value={values.geofenceDistance}
                disabled={disabled}
                errorMessage={touched.geofenceDistance && errors.geofenceDistance}
                onKeyUp={e => checkOnlyNumber(e)}
              />
            </div>
            <ReactSelect
              placeholder=''
              label='Unit'
              options={unitOptions}
              onChange={opt => onUnitChange(opt.value)}
              value={unitOptions.find(x =>
                values.geofenceUnit && values.geofenceUnit !== ''
                  ? x.value === values.geofenceUnit
                  : x.value === values.defaultGeofenceUnit,
              )}
              isDisabled={disabled}
            />
          </div>
        )}
        <div className='fl-layout-full'>
          <DashedTitle title='Choose event Type' stepNumber='3' indicator={true} position='left' />
          {!values.trackMovements && (
            <div className='fl-popover-container mb-3'>
              <div className='pi-flex pi-items-center'>
                <div className='fl-switch-outlined mr-10'>
                  <span className='pi-color-text pi-bold-500'>Periodically Update Location</span>
                  <ToggleSwitch
                    onOff={values.updateStationaryEvents}
                    onChange={onUpdateStationaryEvents}
                    checked={values.updateStationaryEvents}
                  />
                </div>
                <i
                  className='icon-info pi-color-text pi-font-size-xl'
                  onClick={() => setUpdatePopover(!updatePopover)}
                />
              </div>
              {updatePopover && (
                <PopOver
                  size='sm'
                  position='custom'
                  title='Note'
                  description="Selecting this option will disable the geofence feature. Instead, the stationary event will periodically update the location based on the pi-link's GPS. This is useful for users that want a moving alert while maintaining the Waze icons. NOTE: the automatic geofence deactivation will be disabled. Users must manually deactivate the alert by holding down the activate button on the pi-link."
                >
                  <Button
                    icon
                    size='sm'
                    color='secondary'
                    styleType='filled'
                    className='pi-width-xs'
                    onClick={() => setUpdatePopover(!updatePopover)}
                  >
                    <span>OK</span>
                  </Button>
                </PopOver>
              )}
            </div>
          )}
          <ul className='fl-notifs'>
            <li>
              <RadioButton
                size='sm'
                type='checkbox'
                name='construction'
                labelId='construction'
                label='Stationary Event'
                icon='radio-button-unchecked'
                value={values.trackMovements}
                selectedValue={values.trackMovements}
                className={`pi-padding-xs ${!values.trackMovements ? 'pi-active-dark' : ''}`}
                onChange={onConstructionChecked}
                disabled={disabled}
              />
            </li>
            <li>
              <RadioButton
                size='sm'
                type='checkbox'
                name='maintenance'
                labelId='maintenance'
                label='Moving Event'
                icon='radio-button-unchecked'
                value={values.trackMovements}
                selectedValue={values.trackMovements}
                className={`pi-padding-xs ${values.trackMovements ? 'pi-active-dark' : ''}`}
                onChange={onMaintenanceChecked}
                disabled={disabled}
              />
            </li>
          </ul>
          {!values.trackMovements ? (
            <ul className='fl-notifs'>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='construction'
                  value='construction'
                  labelId='construction-hard-hat'
                  label='Construction Hard Hat'
                  icon='construction-hard-hat'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${
                    values.iconType === 'construction' ? 'pi-active-dark' : ''
                  }`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='Vehicle stopped'
                  labelId='vehicle_stopped'
                  value='maintenance'
                  label='Vehicle stopped'
                  icon='vehicle-stopped'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${
                    values.iconType === 'maintenance' ? 'pi-active-dark' : ''
                  }`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='flood'
                  value='flood'
                  labelId='flood'
                  label='Flood'
                  icon='flood'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${values.iconType === 'flood' ? 'pi-active-dark' : ''}`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='lane-closure'
                  value='lane_closure'
                  labelId='lane-closure'
                  label='Lane Closure'
                  icon='lane-closure'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${
                    values.iconType === 'lane_closure' ? 'pi-active-dark' : ''
                  }`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='hazard'
                  value='hazard'
                  labelId='hazard'
                  label='Hazard'
                  icon='warning-alt'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${
                    values.iconType === 'hazard' ? 'pi-active-dark' : ''
                  }`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='emergency'
                  value='emergency'
                  labelId='emergency'
                  label='Emergency Resposne'
                  icon='emergency'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${
                    values.iconType === 'emergency' ? 'pi-active-dark' : ''
                  }`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
            </ul>
          ) : (
            <ul className='fl-notifs'>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='snow-plow'
                  value='snow'
                  labelId='snow-plow'
                  label='Snow Plow'
                  icon='snow-plow'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${values.iconType === 'snow' ? 'pi-active-dark' : ''}`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='waste-vehicle'
                  value='waste'
                  labelId='waste-vehicle'
                  label='Waste Vehicle'
                  icon='waste-vehicle'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${values.iconType === 'waste' ? 'pi-active-dark' : ''}`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
              <li>
                <RadioButton
                  size='sm'
                  iconSize='md'
                  type='checkbox'
                  name='street_sweeper'
                  value='street_sweeper'
                  labelId='street_sweeper'
                  label='Hazard'
                  icon='warning-alt'
                  selectedValue={values.iconType}
                  className={`pi-padding-xs ${
                    values.iconType === 'street_sweeper' ? 'pi-active-dark' : ''
                  }`}
                  onChange={e => onIconTypeChange(e.target.value)}
                  disabled={disabled}
                />
              </li>
            </ul>
          )}

          <Checkbox
            name='sendSms'
            label='Enable Text Messages'
            value={values.sendSms}
            checked={values.sendSms}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        <div className='fl-action mt-45'>
          <Button
            color='gray'
            type='submit'
            styleType='filled'
            className='pi-width-sm'
            disabled={isSubmitting || !isValid || !dirty}
          >
            <span>Submit</span>
          </Button>
        </div>
      </div>
    </form>
  );
}
