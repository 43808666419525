import React from 'react';

const FilterTag = ({
    tag,
    removeFilter
}) => {
    return (
        <>
        {tag.label && <span>{tag.label}:</span>}
        {tag.value && <strong>{tag.value}</strong>}
            <button
                className="pi-remove"
                onClick={()=>removeFilter(tag)}
            >
                <i className="icon-close"/>
            </button>
        </>
    );
};

export default FilterTag;