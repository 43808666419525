import {orderBy} from 'lodash';
import {
  ASYNC_STATUS,
  GET_ALL_LOCATIONS,
  GET_LOCATIONS_STATISTICS,
  CLEAR_CURSOR,
  GET_ONE_LOCATION,
  CLEAR_ONE_LOCATION,
  CLEAR_LOCATIONS,
  GET_TEAM_DATA,
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_PHONE_NUMBER,
  GET_USER_GROUPS_LOCATIONS,
  GET_ONE_LOCATION_HISTORY,
  GET_ONE_LOCATION_STATISTIC,
  GET_DEVICES_MODELS_LOCATIONS,
  LOCATIONS_MANUFACTURERS,
  SELECTED_TEAMS_OPTIONS,
  GET_USERS_LIST,
  UPDATE_ONE_LOCATION_HISTORY,
  GET_TYPES_FOR_HISTORY_FILTER,
  GET_USERS_FOR_HISTORY_FILTER,
  SET_TEAM_TABLE_DATA,
  CHECK_EMAIL_EXISTS,
  DELETE_LOCATION_IMAGE,
  SET_JOB_TITLE,
  SET_NOTIFICATION_TYPES,
  SET_LOCATIONS,
} from '../constants';

const initialState = {
  message: null,
  status: ASYNC_STATUS.FAILURE,
  userGroupStatus: ASYNC_STATUS.FAILURE,
  deleteImageStatus: null,
  deleteImageMessage: '',
  getOneStatus: null,
  getOneMessage: '',
  statisticStatus: ASYNC_STATUS.FAILURE,
  historyStatus: ASYNC_STATUS.FAILURE,
  loadedLocations: [],
  locationStatistics: [],
  teamData: [],
  userGroups: [],
  cursorNext: null,
  currentLocation: null,
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  jobTitle: '',
  notificationTypes: ['EMAIL'],
  currentLocationHistory: [],
  currentLocationHistoryFreeze: [],
  currentLocationStatistic: [],
  models: [],
  manufacturers: [],
  selectedOptions: null,
  usersList: null,
  typesForHistoryFilter: [],
  usersForHistoryFilter: [],
  teamTableData: [],
  isEmailExists: null,
  loader: false,
};

const updateLocation = ({loadedLocations}, action) => {
  let updatedLocation = action.data;
  for (const location of loadedLocations) {
    if (location.location_id === updatedLocation.location_id) {
      location.name = updatedLocation.name;
      location.model = updatedLocation.model;

      return loadedLocations;
    }
  }

  return loadedLocations;
};

const changeDeviceToLocation = item => {
  if (item.log_data?.detail) {
    const changeableTypes = ['ASSET TYPE', 'MODEL', 'NAME', 'IMAGE', 'USER'];

    item.log_data.detail = changeableTypes.includes(item.log_data.summary)
      ? item.log_data.detail.replace('device', 'asset')
      : item.log_data.detail;
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LOCATIONS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
            loading: true,
          };
        case ASYNC_STATUS.SUCCEED:
          const data = action.data;
          const loadedLocations =
            action.isScroll === true
              ? [...state.loadedLocations, ...data.results]
              : [...data.results];

          return {
            ...state,
            loadedLocations,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the devices.',
            cursorNext: data.cursor,
            currentPage: 1,
            loading: false,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            loading: false,
          };
        default:
          return state;
      }
    case SET_LOCATIONS:
      return {
        ...state,
        loadedLocations: action.payload.locations,
      };
    case GET_LOCATIONS_STATISTICS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            locationStatistics: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_ONE_LOCATION:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            getOneStatus: ASYNC_STATUS.REQUEST,
            getOneMessage: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          const newState = {
            ...state,
            getOneStatus: ASYNC_STATUS.SUCCEED,
            currentLocation: action.data,
            getOneMessage: action.message,
          };

          if (action.updateList) {
            Object.assign(newState, {loadedLocations: updateLocation(state, action)});
          }

          return newState;
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            getOneStatus: ASYNC_STATUS.FAILURE,
            getOneMessage: action.message,
          };
        default:
          return state;
      }
    case CLEAR_ONE_LOCATION:
      return {
        ...state,
        currentLocation: null,
        currentLocationHistory: [],
      };
    case DELETE_LOCATION_IMAGE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            deleteImageStatus: ASYNC_STATUS.REQUEST,
            deleteImageMessage: action.message,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            deleteImageStatus: ASYNC_STATUS.SUCCEED,
            deleteImageMessage: action.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            deleteImageStatus: ASYNC_STATUS.FAILURE,
            deleteImageMessage: action.message,
          };
        default:
          return state;
      }

    case GET_ONE_LOCATION_HISTORY:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            currentLocationHistoryFreeze: [],
            historyStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          const history = orderBy(action.data || [], item => {
            changeDeviceToLocation(item);
            return [['created'], ['desc']];
          });

          return {
            ...state,
            historyStatus: ASYNC_STATUS.SUCCEED,
            currentLocationHistory: history,
            currentLocationHistoryFreeze: history,
            message: action.message,
          };

        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            historyStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case UPDATE_ONE_LOCATION_HISTORY:
      return {
        ...state,
        currentLocationHistory: action.payload,
      };
    case GET_ONE_LOCATION_STATISTIC:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            statisticStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            statisticStatus: ASYNC_STATUS.SUCCEED,
            currentLocationStatistic: action.data,
            message: action.message,
          };

        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            statisticStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_TEAM_DATA: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            teamData: action.data,
            message: action.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            teamData: [],
            message: action.message,
          };
        default:
          return state;
      }
    }
    case GET_USER_GROUPS_LOCATIONS: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            userGroupStatus: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            userGroupStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all data',
            userGroups: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            userGroupStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    }
    case GET_DEVICES_MODELS_LOCATIONS: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all data',
            models: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    }
    case GET_TYPES_FOR_HISTORY_FILTER: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching teams list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the teams.',
            typesForHistoryFilter: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }
    case GET_USERS_FOR_HISTORY_FILTER: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching teams list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the teams.',
            usersForHistoryFilter: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }
    case LOCATIONS_MANUFACTURERS: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all data',
            manufacturers: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    }
    case SELECTED_TEAMS_OPTIONS: {
      return {
        ...state,
        selectedOptions: action.options,
      };
    }
    case GET_USERS_LIST: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: action.message,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            usersList: action.data,
            message: action.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    }
    case CHECK_EMAIL_EXISTS: {
      return {
        ...state,
        isEmailExists: action.data,
      };
    }
    case CLEAR_LOCATIONS: {
      return {
        ...state,
        message: null,
        loadedLocations: [],
        locationStatistics: [],
      };
    }
    case CLEAR_CURSOR: {
      return {
        ...state,
        cursorNext: null,
      };
    }
    case SET_EMAIL: {
      return {
        ...state,
        email: action.email,
      };
    }
    case SET_FIRST_NAME: {
      return {
        ...state,
        firstName: action.name,
      };
    }
    case SET_JOB_TITLE: {
      return {
        ...state,
        jobTitle: action.name,
      };
    }
    case SET_NOTIFICATION_TYPES: {
      return {
        ...state,
        notificationTypes: action.name,
      };
    }
    case SET_LAST_NAME: {
      return {
        ...state,
        lastName: action.name,
      };
    }
    case SET_PHONE_NUMBER: {
      return {
        ...state,
        phone: action.phone,
      };
    }
    case SET_TEAM_TABLE_DATA: {
      return {
        ...state,
        teamTableData: action.data,
      };
    }
    default:
      return state;
  }
}
