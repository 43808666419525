import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import DownArrow from '../../../../../assets/img/svg/down-arrow.svg';
import {mobileReportFormChange} from '../../../../impact-detection/redux/actions/mobile/reportsActions';

const ReportMobileStep2 = () => {
  const dispatch = useDispatch();

  const {locationUsers} = useSelector(state => state.locationLandingPage);
  const {form} = useSelector(state => state.reportMobile);

  const changeFiled = (field, value) => {
    dispatch(mobileReportFormChange({field, step: 'step2', value}));
  };

  return (
    <div className='rm-step-content'>
      <h4>* Who performed the inspection?</h4>
      <label className='rm-select'>
        <select
          value={form.step2.user.value}
          onChange={e => changeFiled('user', e.target.value)}
        >
          <option value=''>Select</option>
          {locationUsers.map(item => (
            <option key={item.email} value={item.email}>
              {item.first_name + ' ' + item.last_name}
            </option>
          ))}
        </select>
        <img src={DownArrow} alt='' />
      </label>
      {form.step2.user.error && (
        <div className='invalid-feedback d-block'>
          <span>{form.step2.user.error}</span>
        </div>
      )}
    </div>
  );
};

export default ReportMobileStep2;
