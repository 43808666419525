import React from 'react';

const Radio = ({
    className,
    label,
    name,
    value,
    onChange,
    color,
    checked
}) => {
    return (
        <label className={`pi-radio ${className?className:''} ${color?'pi-'+color:'pi-text'}`}>
            <input type="radio" name={name} onChange={onChange} value={value} checked={checked}/>
            <i className="pi-check-icon"/>
            <span className="pi-check-text">{label}</span>
        </label>
    );
};

export default Radio;