import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import {mobileReportFormChange} from '../../../../impact-detection/redux/actions/mobile/reportsActions';
import {
  formatReportDate,
  formatReportTime,
} from '../../../../impact-detection/redux/reducers/mobile/reportReducer';
import {Button, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faClock} from '@fortawesome/free-solid-svg-icons';

const ReportMobileStep1 = () => {
  const dispatch = useDispatch();
  const {form} = useSelector(state => state.reportMobile);

  const [dateOpen, setDateOpen] = useState();
  const [timeOpen, setTimeOpen] = useState();

  const changeFiled = (field, value) => {
    dispatch(mobileReportFormChange({field, step: 'step1', value}));
  };

  return (
    <div className='rm-step-content'>
      <h4>* Enter date and time when the inspection was done?</h4>
      <div className={`rm-customDatePicker`}>
        <Datetime
          closeOnSelect={true}
          value={form.step1.date.value}
          timeFormat={false}
          onOpen={() => {
            setDateOpen(true);
          }}
          onClose={() => setDateOpen(false)}
          dateFormat={'MM/DD/YYYY'}
          onChange={e => changeFiled('date', formatReportDate(e))}
          renderInput={(props, openCalendar, closeCalender) => {
            return (
              <InputGroup className='rm-input-group'>
                <Input
                  placeholder='Date'
                  className=''
                  value={props.value}
                  onChange={props.onChange}
                />
                <InputGroupAddon addonType='append'>
                  <Button
                    onClick={() => (dateOpen ? closeCalender() : openCalendar())}
                    className='inputAddon'
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            );
          }}
        />
        {form.step1.date.error && (
          <div className='rm-error invalid-feedback d-block'>
            <span>{form.step1.date.error}</span>
          </div>
        )}
      </div>
      <div className={`rm-customDatePicker`}>
        <Datetime
          closeOnSelect={true}
          closeOnTab={true}
          value={form.step1.time.value}
          dateFormat={false}
          timeFormat={'HH:mm A'}
          onOpen={() => {
            setTimeOpen(true);
          }}
          onClose={() => setTimeOpen(false)}
          onChange={e => changeFiled('time', formatReportTime(e))}
          renderInput={(props, openCalendar, closeCalendar) => {
            return (
              <InputGroup className='rm-input-group'>
                <Input
                  placeholder='--:--'
                  className=''
                  value={props.value}
                  onChange={props.onChange}
                />
                <InputGroupAddon addonType='append'>
                  <Button
                    onClick={() => (timeOpen ? closeCalendar() : openCalendar())}
                    className='inputAddon'
                  >
                    <FontAwesomeIcon icon={faClock} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            );
          }}
        />
        {form.step1.time.error && (
          <div className='rm-error invalid-feedback d-block'>
            <span>{form.step1.time.error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportMobileStep1;
