import React from 'react';
import moment from "moment";

const DateCell = (props) => {
    const dateProps = props.data;
    return (
        <>
            <td className={`pi-type-date ${dateProps.has_report === 'YES' ? 'pi-date-impact' : 'pi-date-missing'}`}>
                <div className="pi-td">
                    <div className="pi-date-status">
                        {
                            dateProps.has_report === 'YES' ?
                                <i className="pi-date-icon icon-alert-octagon"/>
                                :
                                <i className="pi-date-icon icon-alert-octagon-o"/>
                        }
                        {dateProps.has_report === 'YES' ? "Impact":"Missing Report"}
                    </div>
                    <span>
                        {moment.unix(dateProps.impact_date).format("DD")} {moment.unix(dateProps.impact_date).format("MMM")} {moment.unix(dateProps.impact_date).format("hh:mm")}
                    </span>
                </div>
            </td>
        </>
    )
}

export default DateCell;