import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Toast from 'react-redux-toastr';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import Routes from './routes/Routes';
import AuthRouter from './modules/auth/Router';
import WorkZoneRoutes from 'modules/workzone/routes/wzRoutes';
import {
  logoutAsyncAction,
  authCheckAsyncAction,
} from './modules/impact-detection/redux/actions/authActions';

import {WORKSPACES} from 'modules/impact-detection/redux/constants';
import './assets/scss/redesign.scss';
import {LogoLoader} from 'elements';
import {isSupportedBrowser} from './core/helpers/external.helper';
import {
  switchDashboard,
  switchWorkspace,
} from 'modules/shared/pages/workspaceSwitcher/store/workspace.actions';
import moment from 'moment';
import {setNavigateToAfterAuth} from 'modules/shared/redux/slices/globalSettings.slice';

require('moment-timezone');

const VALID_BASE_ROUTE = [
  '/locations',
  '/maps',
  '/teams',
  '/reports',
  '/activity',
  '/impact/devices',
  '/workzone',
  '/mobile/report-flow',
  '/pi-link',
  '/pi-link-records',
  '/mobile/report-flow',
];

const OPEN_AS_IT_IS = ['/profile', '/account-settings', '/workspace-switcher'];
function getRoutes(name, props) {
  const ROUTES = {
    [WORKSPACES.impactDetection]: <Routes {...props} />,
    [WORKSPACES.workzone]: <WorkZoneRoutes {...props} />,
  };

  return ROUTES[name];
}

export default function App(props) {
  const dispatch = useDispatch();
  const {currentWorkspace} = useSelector(state => state.workspaces);
  const navigateToAfterAuth = useSelector(state => state.globalSettings.navigateToAfterAuth);
  const {status, message, data, isAuthenticated, loading} = useSelector(state => state.auth);
  const isAdmin = useSelector(
    state => state.auth?.data?.is_pivar_admin || state.auth?.data?.is_account_admin,
  );

  const checkUserAuthentication = () => {
    dispatch(authCheckAsyncAction());
  };

  function setDefaultTimeZone() {
    const token = localStorage.getItem('accessToken');

    if (message === 'User not authenticated' && token) {
      return dispatch(logoutAsyncAction());
    }

    if (data?.account_timezone) {
      moment.tz.setDefault(data.account_timezone);
    }
  }

  // there is a mobile view port issue due to difference mobile browsers ui that the only way to fix is using js
  // to calculate 1% of the view port height and use as the unit in css
  const handleResize = useCallback(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  function init() {
    handleResize();
    //listen to view port resize and recalculate 1% of the view port
    window.addEventListener('resize', handleResize);
    isSupportedBrowser();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }

  useEffect(init, []);
  useEffect(setDefaultTimeZone, [status]);
  useEffect(checkUserAuthentication, [dispatch]);

  const initialPage = useMemo(() => {
    let screen = window.location.pathname;
    let isWorkzone = screen?.includes('/workzone');

    const isScreenWorkspaceIsAccessible = data?.account_types?.includes(
      isWorkzone ? WORKSPACES.workzone : WORKSPACES.impactDetection,
    );

    // if the user is not admin the user should not redirected to accounts settings or the user dosn't have access to the workspace
    if (
      (!isAdmin && isAuthenticated && (navigateToAfterAuth || screen) === '/account-settings') ||
      (data?.account_types && !isScreenWorkspaceIsAccessible)
    ) {
      const redirectProp = {
        isWorkzone: false,
        from: screen,
        screen: '/maps',
      };

      if (isWorkzone && !isScreenWorkspaceIsAccessible) {
        redirectProp.screen = '/maps';
      }
      if (!isWorkzone && !isScreenWorkspaceIsAccessible) {
        redirectProp.screen = '/workzone/map';
      }

      return redirectProp;
    }

    if (navigateToAfterAuth && isAuthenticated) {
      setTimeout(() => {
        if (window.location.href.replace(window.location.origin, '') === navigateToAfterAuth) {
          dispatch(setNavigateToAfterAuth());
        }
      }, 1000);

      return {
        isWorkzone: navigateToAfterAuth.includes('/workzone'),
        from: '*',
        screen: navigateToAfterAuth,
      };
    }

    const isValidRoute = VALID_BASE_ROUTE.filter(v =>
      `${window.location.host}${screen}`.includes(`${window.location.host}${v}`),
    ).length;

    if (screen === '/' || !isValidRoute) {
      const data = {
        isWorkzone: currentWorkspace.name === WORKSPACES.workzone,
        screen: currentWorkspace.name === WORKSPACES.workzone ? '/workzone/map' : '/maps',
        from: screen,
      };

      if (OPEN_AS_IT_IS.includes(screen)) {
        data.from = '/';
        return data;
      }

      return data;
    }

    return {
      isWorkzone,
      screen,
    };
  }, [currentWorkspace.name, navigateToAfterAuth, isAuthenticated, isAdmin]);

  useEffect(() => {
    if (loading) return;
    const workspaceFromRoute = WORKSPACES[initialPage.isWorkzone ? 'workzone' : 'impactDetection'];

    if (data?.account_types?.includes(workspaceFromRoute)) {
      dispatch(switchWorkspace(workspaceFromRoute));
    } else if (data?.account_types) {
      dispatch(switchWorkspace(data.account_types[0]));
    } else {
      dispatch(switchDashboard());
    }
  }, [loading, dispatch]);

  return (
    <div className={`h-100 overflow-hidden ${loading ? 'pi-loader-screen' : ''}`}>
      {loading ? (
        <LogoLoader />
      ) : isAuthenticated ? (
        getRoutes(currentWorkspace.name, {
          user: data,
          initialPage,
        })
      ) : (
        <AuthRouter />
      )}
      <Toast progressBar closeOnToastrClick transitionIn='fadeIn' transitionOut='fadeOut' />
    </div>
  );
}
