import {createSlice} from '@reduxjs/toolkit';
import {getAllAssetTypesAsyncAction} from './asyncActions';

const INITIAL_STATE = {
  message: null,
  status: null,
  data: [],
  loading: false,
};

const AssetTypeSlice = createSlice({
  initialState: INITIAL_STATE,
  name: 'assetType',
  reducers: {
    appendNewAsset: (state, action) => {
      if (!action.payload) return state;

      const item = state.data.find(v => v.value === action.payload);

      if (!item) {
        state.data.push({value: action.payload, label: action.payload});
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllAssetTypesAsyncAction.fulfilled, (state, action) => {
      state.data = action.payload.data.map(action_data => ({
        value: action_data,
        label: action_data,
      }));
      state.loading = false;
      return state;
    });

    builder.addCase(getAllAssetTypesAsyncAction.pending, state => {
      state.loading = true;
    });
    builder.addCase(getAllAssetTypesAsyncAction.rejected, state => {
      state.loading = false;
      return state;
    });
  },
});

export {getAllAssetTypesAsyncAction};
export const AssetTypeActions = AssetTypeSlice.actions;
export default AssetTypeSlice.reducer;
