import React from 'react';
import { Modal, UncontrolledCarousel } from 'reactstrap';

const ImageGallery = ({
    isOpened,
    data,
    active,
    closeHandler,
    galleryNext,
    galleryPrev
}) => {
    return (
        <Modal className="pi-modal pi-image-gallery"
               isOpen={isOpened}
               backdrop={true}
               onClose={closeHandler}
               toggle={closeHandler}
               style={{ maxWidth: "800px" }}
        >
            <div className="pi-modal-header">
                <h4>Impact Images</h4>
                <button className="pi-close" onClick={closeHandler}>
                    <i className="icon-close"/>
                </button>
            </div>
            <div className="pi-modal-body">
                <UncontrolledCarousel
                    className="pi-image-slider"
                    activeIndex={active}
                    next={galleryNext}
                    previous={galleryPrev}
                    items={data}
                />
            </div>
            <div className="pi-modal-footer"/>
        </Modal>
    );
};

export default ImageGallery;