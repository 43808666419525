import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {mobileReportFormChange} from '../../../../impact-detection/redux/actions/mobile/reportsActions';

const ReportMobileStep4 = () => {
  const dispatch = useDispatch();

  const {form} = useSelector(state => state.reportMobile);

  const changeFiled = (field, value) => {
    dispatch(mobileReportFormChange({field, step: 'step4', value}));
  };

  return (
    <div className='rm-step-content'>
      <h4>Did any other agencies report this impact?</h4>
      <textarea
        value={form.step4.other_agencies.value}
        placeholder='Enter other agencies'
        onChange={e => changeFiled('other_agencies', e.target.value)}
      />
    </div>
  );
};

export default ReportMobileStep4;
