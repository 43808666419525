import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {apiRequest} from 'core/api/apiRequest';
import {useHistory} from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { authCheckAsyncAction } from 'modules/impact-detection/redux/actions/authActions';

export function SignInCallback() {
    const history = useHistory();
    const dispatch = useDispatch();

    let params = new URLSearchParams(history.location.search);
    let code = params.get("code");
       
    useEffect(() => {
        async function sendCallback() {
            const response = await apiRequest('user/login/auth0/callback', 'get', {
                code
              });

            if(response.success) {
                toastr.success('Authentication', 'Welcome to our dashboard!');
                localStorage.setItem('accessToken', response.data.token);
                localStorage.setItem('loginWithAuth0', true);
                dispatch(authCheckAsyncAction());
            } else {
                toastr.error('Authentication', 'Something went wrong!');
                const getUrl = await apiRequest('/user/logout/auth0/redirect-url');
                if(getUrl.success) {
                  window.location.replace(getUrl.data.url)
                }
                history.push('/auth/sign-in')
            }
        }
        code !== "" && sendCallback()
    }, [code])

  return (
    <></>
  );
}
