import React from 'react';
import moment from 'moment';
import {maxBy} from 'lodash';
import {store} from '../store';
import momentTimezone from 'moment-timezone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';

const lettersRegExp = /[a-zA-Z]/g;
const costRegex = /^\d*(?:[.]\d*)?$/;

const OFFSET = new Date().getTimezoneOffset() * 60 * 1000;

const timeFormats = {
  1: 'MM/DD/YY',
  2: 'YYYY-MM-DD',
  4: 'HH:mm A',
  5: 'DD MMM YYYY[,] hh:mm',
  6: 'MM/DD/YY h:mm A',
  7: 'HH:mm',
};

export const getAccountTZ = () =>
  store.getState().auth.data?.account_timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

export const validateDate = date => moment(date, 'MM/DD/YYYY').isValid();

export const tableSortCaret = order => {
  if (order) {
    return (
      <span
        style={{
          color: '#FAA528',
          position: 'absolute',
          right: 0,
          top: '50%',
          marginTop: -6,
        }}
      >
        {order === 'asc' ? (
          <FontAwesomeIcon icon={faAngleUp} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} />
        )}
      </span>
    );
  }
  return null;
};

export const sortFunc = (
  trigger,
  state,
  type,
  column,
  header,
  data,
  setNewSortedHeader,
  setNewSortedData,
) => {
  if (type === 'asc') {
    let justData = data.sort((a, b) =>
      a[trigger.value].toLowerCase() > b[trigger.value].toLowerCase() ? 1 : -1,
    );
    setNewSortedData(justData);

    trigger.type = trigger.type === 'desc' ? 'asc' : 'desc';
    let newHeadings = header.map(item => (item.title === trigger.title ? trigger : item));
    setNewSortedHeader(newHeadings);
  } else if (type === 'desc') {
    let justData = data.sort((a, b) =>
      b[trigger.value].toLowerCase() > a[trigger.value].toLowerCase() ? 1 : -1,
    );
    setNewSortedData(justData);

    trigger.active = true;
    trigger.type = 'desc';
    let newHeadings = header.map(item => {
      if (item.title !== trigger.title) {
        item.active = false;
        delete item.type;
        return item;
      } else {
        return trigger;
      }
    });
    setNewSortedHeader(newHeadings);
  }
};

export const getDeviceType = name => {
  let type = 1;
  if (name === 'ATTENUATOR') {
    type = 1;
  } else if (name === 'GATEWAY') {
    type = 2;
  } else if (name === 'NODE') {
    type = 3;
  } else if (name === 'TAPER_GATEWAY') {
    type = 4;
  }
  return type;
};

export const dateToUnix = date => {
  const simpleStartDate = date.getTime();
  return simpleStartDate / 1000;
};
export const dateToUnixWithTimeZone = date => {
  const dateWithTimeZone = getAccountMomentDate(date);

  const offset = dateWithTimeZone.utcOffset() * 60 * 1000;

  return Math.floor((dateWithTimeZone.valueOf() - offset) / 1000);
};

export const convertWithTimezone = date => {
  if (!date) throw new Error('date and timezone are required.');
  let converted = date;

  if (typeof date !== 'object') {
    converted = dateUnixToObject(date);
  }

  return converted.toLocaleString('en-US', {timeZone: getAccountTZ()});
};

export const convertFormatWithTimezone = (date, format = 1) => {
  let _format = timeFormats[format];
  let converted = date;
  if (typeof date !== 'object' && typeof date !== 'string') {
    converted = dateUnixToObject(date);
  }

  return getAccountTZ()
    ? moment(converted).tz(getAccountTZ()).format(_format).toLocaleString()
    : moment(converted).format(_format).toLocaleString();
};

export const dateUnixToObject = date => new Date(date * 1000);

export const containLetters = string => lettersRegExp.test(string);

export const isAmount = evt => costRegex.test(evt);

export const validateAmount = (val, def) => {
  let value = val;
  if (!isAmount(val)) {
    value = def || '';
  }

  return value;
};

export const buildCompareDate = (date, compare) => {
  if (!date) return date;

  const compareDate = dateUnixToObject(compare);
  const _date = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    compareDate.getHours(),
    compareDate.getMinutes(),
    compareDate.getSeconds(),
    compareDate.getMilliseconds(),
  );

  return dateToUnix(_date);
};

export const setItemAsync = async ({key, value}) => {
  localStorage.setItem(key, value);
  await null;
};

export const rmItemAsync = async key => {
  localStorage.removeItem(key);
  await null;
};

export const formatDate = (date, format = 1) =>
  moment(date, timeFormats[1]).format(timeFormats[format]);

export const formatDateToNumeric = (date, format = 1) => {
  const simpleStartDate = new Date(moment(date).format(timeFormats[format])).getTime();
  return simpleStartDate / 1000;
};

export const formatDateWithOffset = (
  date,
  {offset = OFFSET, format = 1} = {offset: OFFSET, format: 1},
) => {
  const dateFormattedForBackend = moment(date, timeFormats[1]).format(timeFormats[format]);
  const dateFormattedForUi = moment(date, timeFormats[1]).format(timeFormats[1]);

  const simpleStartDate = new Date(dateFormattedForBackend).getTime();

  return {
    dateFormatted: dateFormattedForUi,
    date: simpleStartDate / 1000,
    dateOffset: simpleStartDate + offset,
  };
};

export const formatOnlyOffset = (date, offset = OFFSET) => {
  const simpleStartDate = new Date(date).getTime();
  return offset > 0 ? simpleStartDate + offset : simpleStartDate;
};

export const downloadFile = downloadURL => {
  if (!downloadURL) return;

  const link = document.createElement('a');
  link.setAttribute('href', downloadURL);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getYMinMax = (data, sensitivity = null) => {
  let max = maxBy(data, i => Math.abs(i));

  max = sensitivity && sensitivity > Math.abs(max) ? sensitivity : max;

  return {
    min: max < 0 ? max : !max ? -4000 : -max,
    max: Math.abs(max || 4000),
  };
};

export const buildSensitivityLine = (data, sensitivity) => Array(data.length).fill(sensitivity);

export const getAccountMomentDate = (date = new Date()) =>
  moment.unix(momentTimezone.tz(date, getAccountTZ()).unix()).tz(getAccountTZ());

export const dateAndTimeCombiner = (date, time) => {
  const timeDate = date + ' ' + time.replace('PM', '').replace('AM', '').trim();

  const dateWithTimeZone = getAccountMomentDate(timeDate);

  const offset = dateWithTimeZone.utcOffset() * 60 * 1000;

  return Math.floor((dateWithTimeZone.valueOf() - offset) / 1000);
};

export const timer = ms => new Promise(res => setTimeout(res, ms));

export const toFixedWithoutRound = (num, rate = 7) => {
  const calcDec = Math.pow(10, rate);
  return Math.trunc(num * calcDec) / calcDec;
};

export const combineNotificationChannels = channels => {
  return channels
    ?.map(item => {
      if (item !== 'SMS') {
        return `${item.slice(0, 1)}${item.slice(1).toLowerCase()}`;
      } else {
        return item;
      }
    })
    .join(', ');
};

export function getUsedDevice() {
  const ua = navigator.userAgent;
  const device = {};
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    device.type = 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    device.type = 'mobile';
  } else {
    device.type = 'desktop';
  }
  return device;
}
