import {isRejectedWithValue} from '@reduxjs/toolkit';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_API_URL
  ? process.env.REACT_APP_BASE_API_URL
  : window.location.origin + '/';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      let accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});

export const rtkQueryAuthrizationMiddleWare = api => next => action => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 404 && action.payload.data?.message === 'Account not found.') {
      window.history.replaceState(null, null, '/not-found');
      window.location.reload(); //made to prevent canceled toaster messages
    }
    if (action.payload.status === 401) {
      localStorage.removeItem('accessToken');

      localStorage.setItem(
        'pageToLoadAfterAuthentication',
        window.location.href.replace(window.location.origin, ''),
      );

      if (
        !action.meta.baseQueryMeta.request?.url.includes('user/login') ||
        !window.location.pathname.startsWith('/auth/')
      ) {
        //made for workspaces change, need to change localStorage.clear in future
        localStorage.removeItem('persist:root');
        api.dispatch({type: 'CLEAR_STORE'});
      }
    }
  }

  return next(action);
};
