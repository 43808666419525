import moment from 'moment';
import UrlCell from '../../../../elements/TableCells/UrlCell';
import TextCell from '../../../../elements/TableCells/TextCell';
import StatusCell from '../../../../elements/TableCells/StatusCell';
import {getAccountMomentDate, getAccountTZ} from '../../../../helpers/functions';
import momentTimezone from 'moment-timezone';
export const colors = ['primary', 'green', 'gray', 'secondary', 'red', 'purple'];

export const statusButtons = [
  {
    label: 'All',
    name: 'all',
    labelId: 'all',
    value: 'all',
  },
  {
    label: 'Pending Inspection',
    icon: 'warning',
    iconColor: 'red',
    name: 'status',
    labelId: 'inspection',
    value: 'PENDING_INSPECTION',
  },
  {
    label: 'Closed',
    icon: 'task-black',
    iconColor: 'gray',
    name: 'status',
    labelId: 'closed',
    value: 'CLOSED',
  },
];

export const tooltips = [
  '<p><h5>NOTE:</h5>Filter by All Impacts or those that are Pending Inspection or Closed.</p>',
];

export const tooltipAddReport = [
  '<p>No Impact - No damage observed <br> Minor Impact - Minor scaring. Some evidence of an event. <br/> Major Impact - Device damaged and needs repair.</p>',
];

export const deafaultTableHeading = [
  {title: 'Name', value: 'name', sortAction: true, active: false},
  {title: 'Impact ID', value: 'impact_id', sortAction: true, active: false},
  {title: 'Status', value: 'status', sortAction: true, active: false},
  {title: 'Model', value: 'model', sortAction: true, active: false, classes: 'pi-visibility'},
  {title: 'Serial Number', value: 'serial_number', sortAction: true, active: false},
  {title: 'Team', value: 'team', sortAction: true, active: false, classes: 'pi-visibility'},
  {title: 'Impact Date', value: 'impact_date', sortAction: true, active: true, type: 'desc'},
];

export const chartColors = {magnitude: '#000000', x: '#CB2727', y: '#00B051', z: '#0047BA'};

export const activityTableOptions = [
  {
    label: 'name',
    element: UrlCell,
    getUrl: data => `/activity/${data.event_id}`,
  },
  {
    label: 'impact_id',
    element: TextCell,
  },
  {
    label: 'event_status',
    element: StatusCell,
    labelResolve: value => statusFormater(value),
    itemResolve: item => getStatusIcon(item),
  },
  {
    label: 'model',
    element: TextCell,
    className: 'pi-visibility',
  },
  {
    label: 'serial_number',
    element: TextCell,
  },
  {
    label: 'team',
    element: TextCell,
    labelResolve: value => value?.name,
    className: 'pi-visibility',
  },
  {
    label: 'event_date',
    type: 'date',
    element: TextCell,
    labelResolve: value => (value !== 0 ? moment.unix(value) : ''),
  },
];

export const getStatusIcon = status => {
  switch (status) {
    case 'PENDING_INSPECTION':
      return {
        icon: 'warning',
        color: 'red',
      };
    case 'CLOSED':
      return {
        icon: 'task-black',
        color: 'green',
      };
    default:
  }
};

export const statusFormater = status => {
  return (
    status &&
    status
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join(' ')
  );
};

export const timeFormater = time => {
  return moment.unix(time).format('MM/DD/YY h:mm A');
};

export const getDeviceOrientation = orientation => {
  if (orientation && Object.keys(orientation)?.length > 0) {
    let x = Math.abs(orientation.x);
    let y = Math.abs(orientation.y);
    let z = Math.abs(orientation.z);

    if (z > x && z > y) {
      return 'z';
    } else if (y > z && y > x) {
      return 'y';
    } else if (x > z && x > y) {
      return 'x';
    }
  } else {
    return 'x';
  }
};

export const teamActionsBtns = {
  button1Color: 'text',
  button1Icon: 'account-arrow',
  button2Color: 'red',
  button2Icon: 'delete',
};

export const alphabeticSort = (data, type, field) => {
  if (type === 'asc') {
    return data.sort((a, b) => {
      if (field === 'group' || field === 'subgroup') {
        return a[field].name.toLowerCase() > b[field].name.toLowerCase()
          ? -1
          : a[field].name.toLowerCase() < b[field].name.toLowerCase()
          ? 1
          : 0;
      } else {
        return a[field].toLowerCase() > b[field].toLowerCase()
          ? -1
          : a[field].toLowerCase() < b[field].toLowerCase()
          ? 1
          : 0;
      }
    });
  }
  if (type === 'desc') {
    return data.sort((a, b) => {
      if (field === 'group' || field === 'subgroup') {
        return a[field].name.toLowerCase() < b[field].name.toLowerCase()
          ? -1
          : a[field].name.toLowerCase() > b[field].name.toLowerCase()
          ? 1
          : 0;
      } else {
        return a[field].toLowerCase() < b[field].toLowerCase()
          ? -1
          : a[field].toLowerCase() > b[field].toLowerCase()
          ? 1
          : 0;
      }
    });
  }
};

export const searchFunction = (data, searchTerm) => {
  return data.filter(item => {
    const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      item.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subgroup.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
};

export const numberFieldValidation = e =>
  ['e', 'E', '.', '+', '-'].includes(e.key) && e.preventDefault();

export const reformatValue = value => {
  let sensValue = value.split(' ')[0];
  return sensValue !== '' ? sensValue : '';
};

export const required = 'This field is required.';
export const isExists = 'This email address is already registered.';

export const formatSelectOptions = data => {
  let formatedOptions = [];
  data.map(option => formatedOptions.push({value: option, label: option}));
  return formatedOptions;
};

export const formattedBatteryLevel = level => {
  if (parseInt(level) > 100) {
    return 100;
  }
  if (level.split('.')[1] === '0') {
    return (Math.round(level * 100) / 100).toFixed(0);
  } else {
    return level;
  }
};

export const calculateCost = values => {
  let overallCost = values.custom_reports.reduce((acc, {cost}) => {
    if (!isNaN(parseInt(cost))) {
      return acc + parseFloat(cost);
    } else {
      return acc;
    }
  }, 0);
  return overallCost === 0 ? '' : overallCost.toFixed(2);
};

export const dateAndTimeCombiner = (date, time) => {
  const timeDate = date + ' ' + time.replace('PM', '').replace('AM', '').trim();

  const dateWithTimeZone = getAccountMomentDate(timeDate);

  const offset = dateWithTimeZone.utcOffset() * 60 * 1000;

  return Math.floor((dateWithTimeZone.valueOf() - offset) / 1000);
};
