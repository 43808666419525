import {Route, Switch} from 'react-router-dom';
import React from 'react';

export const ChildRoutes = ({layout: Layout, routes}) => (
  <Layout>
    <Switch>
      {routes.map((category, index) => {
        if (category.children) {
          const rst = category.children.map(route => (
            <Route
              key={route.path + route.name}
              path={route.path}
              exact
              render={routeProps => {
                const RouteComponent = route.component;
                return <RouteComponent {...routeProps} initialize={route.initialize} />;
              }}
            />
          ));
          rst.push(
            <Route
              key={index * 10}
              path={category.path}
              exact
              component={category.component}
            />,
          );
          return rst;
        }
        return (
          // Route item without children
          <Route key={index * 10} path={category.path} exact component={category.component} />
        );
      })}
    </Switch>
  </Layout>
);
