import React from 'react';

import UserAvatar from './UserAvatar';
import {InfoString} from 'elements';

const GroupCard = props => {
  const highlightLabel = (text, highlight) => {
    if (highlight && highlight.length !== '') {
      const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
      return (
        <strong>
          {parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <mark key={i} style={{backgroundColor: '#ff980069'}}>
                {part.trim()}
              </mark>
            ) : (
              part.trim()
            ),
          )}
        </strong>
      );
    } else {
      return text;
    }
  };

  return (
    <div className={`pi-group-card ${props.isNew ? 'pi-add-new' : ''}`} onClick={props.action}>
      {!props.isNew ? (
        <>
          <div className='pi-card-header'>
            {props.data.title && props.data.icon && (
              <UserAvatar
                username={props.data.title}
                icon={props.data.icon}
                searchMode={props.searchMode}
                component={highlightLabel(props.data.title, props.searchValue)}
              />
            )}
          </div>
          <ul className='pi-card-meta'>
            {props.data.stats.map((item, index) => (
              <li key={index}>
                <InfoString isRow={true} label={item?.label} link={item?.link} text={item?.text} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className={'pi-new'}>
          <i className='icon-circle-plus' />
          <strong>Add Group</strong>
        </div>
      )}
    </div>
  );
};

export default GroupCard;
