import React, {useEffect, useState} from 'react';
import {ImageGallery} from 'components';

const ImageUploader = ({className = '', values, onChange, onDelete, disable, showError}) => {
  const [imageGalleryOpened, setImageGalleryOpened] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const emptyImages = 5 - values.length;

  const prepareImageValues = () => {
    let images = [];
    values.map((item, index) => {
      if (typeof item === 'string') {
        images.push({src: item, key: index});
      } else {
        images.push({src: URL.createObjectURL(item), key: index});
      }
      return images;
    });
    return images;
  };

  const handleImageGalleryOpen = index => {
    setImageGalleryOpened(true);
    setActiveIndex(index);
  };

  const handleImageGalleryClose = () => setImageGalleryOpened(false);

  const galleryNext = () => {
    activeIndex === prepareImageValues().length - 1
      ? setActiveIndex(0)
      : setActiveIndex(activeIndex + 1);
  };

  const galleryPrev = () => {
    activeIndex === 0
      ? setActiveIndex(prepareImageValues().length - 1)
      : setActiveIndex(activeIndex - 1);
  };

  return (
    <div className={`pi-image-uploader ${className}`}>
      <ul className='pi-image-gallery'>
        <li className='pi-upload-new'>
          <label htmlFor='pi-upload'>
            <i className='icon-circle-plus' />
          </label>
          <input
            type='file'
            id='pi-upload'
            onChange={e => onChange(e)}
            multiple
            accept='image/*'
            disabled={disable}
          />
        </li>
        {values.map((image, index) => {
          let imageURL = typeof image === 'string' ? image : URL.createObjectURL(image);
          return (
            <li key={`image-upload-${index}`}>
              <div
                className='pi-image'
                style={{backgroundImage: `url(${imageURL})`}}
                onClick={() => handleImageGalleryOpen(index)}
              />
              <button className='pi-delete' onClick={() => onDelete(image, index)}>
                <i className='icon-close' />
              </button>
            </li>
          );
        })}
        {[...Array(emptyImages)].map((item, index) => {
          return (
            <li className='pi-placeholder' key={`empty-${index}`}>
              <i className='icon-image' />
            </li>
          );
        })}
      </ul>
      {showError && <div className='error-message'>Please upload a maximum of up to 5 images.</div>}
      <ImageGallery
        data={prepareImageValues()}
        active={activeIndex}
        isOpened={imageGalleryOpened}
        closeHandler={handleImageGalleryClose}
        galleryNext={galleryNext}
        galleryPrev={galleryPrev}
      />
    </div>
  );
};

export default ImageUploader;

