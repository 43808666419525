import React, {useEffect, useState} from 'react';

//components
import {PasswordField, Button} from 'elements';
import AuthLayout from './AuthLayout';

//utils
import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import {toastr} from 'react-redux-toastr';
import {useDispatch, useSelector} from 'react-redux';
import {ASYNC_STATUS} from '../impact-detection/redux/constants';
import {resetPasswordSchema} from './auth.schema';
import {signupAsyncAction} from '../impact-detection/redux/actions/authActions';

export default function SignUp({match}) {
  const auth = useSelector(store => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [validationRules, setValidationRules] = useState({min: false, matches: false});
  const [focused, setFocused] = useState({password: false, confirmPassword: false, field: ''});

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: values => {
      const token = match.params.token;
      if (!token) {
        return;
      }

      dispatch(
        signupAsyncAction(token, {
          password: values.password,
          confirm_password: values.confirmPassword,
        }),
      );
    },
    validateOnBlur: true,
  });

  useEffect(() => {
    if (auth.status === ASYNC_STATUS.SUCCEED) {
      onSignupSucceed();
    } else if (auth.status === ASYNC_STATUS.FAILURE && auth.statusSource === 'signupAsyncAction') {
      toastr.error('Something went wrong! Please try again.');
    }
  }, [auth.status]);

  function onFocus({target}) {
    setFocused(focused => ({...focused, [target.name]: true, field: target.name}));
  }
  function onBlur(ec) {
    formik.handleBlur(ec);
    const target = ec.target;
    setFocused(focused => ({...focused, [target.name]: false, field: target.name}));
  }

  async function getValidationStateOfForm() {
    let yupValidationState = {min: false, matches: false};
    await resetPasswordSchema
      .validate(formik.values, {abortEarly: false})
      .then(a => (yupValidationState = {min: true, matches: true}))
      .catch(err => {
        if (focused.password || focused.confirmPassword) {
          yupValidationState = {min: true, matches: true};
        }
        err.inner.forEach(err => {
          if (focused.field === err.path && (err.type === 'min' || err.type === 'matches')) {
            yupValidationState[err.type] = false;
          }
        });
      });
    setValidationRules(yupValidationState);
  }

  useEffect(() => {
    getValidationStateOfForm();
  }, [formik.values, focused]);

  const onSignupSucceed = () => {
    toastr.success('Authentication', 'Welcome to our service!');
    history.push('/maps');
  };

  return (
    <>
      <div className='text-center mt-4'>
        <p className='lead'></p>
      </div>
      <AuthLayout
        title='Welcome to the Pi-Lit Dashboard!'
        headingChild={
          <div className='flex flex-col'>
            <p>Please create a password to continue</p>
            <ul className='pi-validation-rules'>
              <li className={`${validationRules.min ? 'pi-active' : ''}`}>
                <i className='icon-check' />
                <span>min 8 characters long</span>
              </li>
              <li className={`${validationRules.matches ? 'pi-active' : ''}`}>
                <i className='icon-check' />
                <span>min 1 number, 1 upper case and 1 special character</span>
              </li>
            </ul>
          </div>
        }
      >
        <div className='m-sm-4'>
          <form onSubmit={formik.handleSubmit}>
            <PasswordField
              name='password'
              label='Password *'
              helpMessage='Must contain a minimum of 8 characters, one number and one special character.'
              onChange={formik.handleChange}
              value={formik.values.password}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {!!formik.errors.password && formik.touched.password ? (
              <p className='text-red-700 text-sm font-bold'>{formik.errors.password}</p>
            ) : null}
            <PasswordField
              name='confirmPassword'
              label='Confirm Password *'
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              className='!mt-3'
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {!!formik.errors.confirmPassword && formik.touched.confirmPassword ? (
              <p className='text-red-700 text-xs font-bold'>{formik.errors.confirmPassword}</p>
            ) : null}
            <div className='text-center mt-3'>
              <Button
                color='gray'
                type='submit'
                styleType='filled'
                className='pi-width-sm'
                disabled={
                  !formik.isValid || !formik.values.password || !formik.values.confirmPassword
                }
              >
                Sign up
              </Button>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  );
}
