import React from 'react';

const ReportMobileStep10 = () => {
    return (
        <div className="rm-step-content rm-step-success">
            <h4>Your report has been created successfully!</h4>
        </div>
    );
}

export default ReportMobileStep10;