import React from 'react';
import {Checkbox} from 'elements';

const CheckBoxTextCell = ({data, color, label, onClick, className}) => {
  return (
    <td className={`pi-type-checkbox-text ${className ? className : ''}`}>
      <div className='pi-td'>
        <Checkbox color={color} checked={data.checked} onChange={() => onClick(data)} />
        <span onClick={() => onClick(data, true)}>{label}</span>
      </div>
    </td>
  );
};

export default CheckBoxTextCell;
