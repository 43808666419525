import React from 'react';

import classes from './NotFoundItemModal.module.scss';

export const NotFoundItemModal = ({title = 'company', value, onCreate}) => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>
        There are no {title} with this name, press create to add a new {title}
      </p>
      <p className={classes.createLabel}>
        <span className={classes.create} onClick={onCreate.bind(null, value)}>
          Create
        </span>{' '}
        “{value}” new {title}
      </p>
    </div>
  );
};
