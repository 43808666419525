import React from 'react';
import {Route, Switch, BrowserRouter as Router, Redirect} from 'react-router-dom';

// import SignIn from './SignIn';
import SignIn from './signin/Index';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';
import Welcome from './Welcome';

import {ResetPassword} from './ResetPassword.jsx';
import {SignInCallback} from './signin/SignInCallback';

import Auth from '../../layouts/Auth';
import NotFound from '../../components/NotFound';
import ReportMobile from 'modules/shared/pages/landingPage';
import DriverDevice from 'modules/shared/pages/driver-device/Index';
import PiLinkRecords from 'modules/shared/pages/pilink-records';

const publicRoutes = [
  {path: '/not-found', Component: NotFound, landing: true},
  {path: '/pi-link/:serialNumber', Component: DriverDevice, landing: true},
  {path: '/mobile/report-flow/:impact_id', Component: ReportMobile, landing: true},
  {path: '/auth/welcome', Component: Welcome},
  {path: '/auth/sign-in', Component: SignIn},
  {path: '/auth/callback', Component: SignInCallback},
  // {path: '/auth/sign-in-new', Component: SignInNew},
  {path: '/auth/sign-up/:token', Component: SignUp},
  {path: '/auth/forgot-password', Component: ForgotPassword},
  {path: '/auth/reset-password/:token', Component: ResetPassword},
  {path: '/auth/*', Component: NotFound},
  {path: '/*', Component: () => <Redirect to='/auth/sign-in' />},
  {path: '/pi-link-records', Component: PiLinkRecords, landing: true},
];

export default function AuthRouter() {
  return (
    <Router>
      <Switch>
        {publicRoutes.map(({Component, path, landing}) => (
          <Route
            exact
            key={path}
            path={path}
            render={routeProperties =>
              landing ? (
                <Component {...routeProperties} />
              ) : (
                <Auth>
                  <Component {...routeProperties} />
                </Auth>
              )
            }
          />
        ))}
      </Switch>
    </Router>
  );
}
