import moment from 'moment';
import {severityFormatter} from '../../reports/ReportUtils';

export const formatHistoryDate = date => `${moment.unix(date).format('DD MMM YYYY[,] hh:mm')}`;
// export const getNotes = notesArray => {
//     let notes = '';
//     notesArray.forEach(note => {
//         if (note){
//             notes += `${note}, `;
//         }
//     });
//     return notes;
// }

export const buildReportHistory = parentData => {
  const {log_data: data} = parentData;
  let body = [];
  let images = [];

  let customReports = [];
  // let costs = [];
  let total = 0;

  const generalInformation = [
    {
      icon: 'engineering',
      label: 'Performed by',
      text: parentData.user.first_name
        ? `${parentData.user.first_name} ${parentData.user.last_name}`
        : '',
    },
    {
      icon: 'event',
      label: 'Impact Date',
      text: formatHistoryDate(data.report.event_date),
    },
    {
      icon: 'calendar',
      label: 'Report Date',
      text: formatHistoryDate(data.report.report_date),
    },
    {
      icon: 'speed',
      label: 'Severity',
      // text: parentData?.log_data?.summary === 'MAINTENANCE' ? '' : severityFormatter(data.report.severity)
      text: severityFormatter(data.report.severity),
    },
    {
      icon: 'speed',
      label: 'Other agencies (optional)',
      text: data.report.other_agencies,
    },
    {
      icon: 'notes',
      label: 'Notes',
      isNote: true,
      // text: getNotes(data.report.notes),
      text: data.report.notes,
    },
  ];

  customReports = data.report.custom_reports?.flatMap(item => {
    const sectionData = [
      {label: 'Title', text: item.title},
      {label: 'value', text: item.value},
      {label: 'Comments', text: item.comment, isNote: true},
      {label: 'Cost', text: item.cost > 0 ? item.cost : ''},
      {
        show:
          item.title || item.value || item.comment || (item.cost && parseInt(item.cost) > 0)
            ? true
            : false,
      },
    ];
    if (item.cost) {
      sectionData.push({label: 'Total', text: `$${total}`});
    }
    // item.show = (item.report_title || item.report_reference_number || item.report_comment) ? true : false ;
    return sectionData;
  });

  images = data.report.image_urls.map((item, index) => {
    const imagesData = {src: item, key: index};
    return imagesData;
  });

  // costs = data.report?.associated_costs ? data.report.associated_costs.map(item => {
  //     total += Number(item.cost);
  //     return {label: item.description, text: `$${item.cost}`}
  // }) : [];
  //
  // costs.push({label: 'Total', text: `$${total}`, isTotal: true});

  const showState = {
    costs: false,
    images: false,
    customReports: false,
    generalInformation: false,
  };

  showState.costs = !!total;
  showState.images = !!images.length;
  customReports.forEach(report => {
    if (report.show) {
      showState.customReports = true;
    }
  });
  showState.generalInformation = images.length;
  generalInformation.forEach(info => {
    if (info.text) {
      showState.generalInformation = true;
    }
  });

  body = [
    {
      sectionTitle:
        parentData?.log_data?.summary === 'MAINTENANCE'
          ? 'Maintenance General Information'
          : 'Inspection General information',
      show: showState.generalInformation,
      infoStrings: generalInformation,
    },
    // {
    //     sectionTitle: parentData?.log_data?.summary === 'MAINTENANCE' ? 'Maintenance Cost Information' : 'Inspection Cost Information',
    //     infoStrings: costs,
    //     show: showState.costs,
    // },
    {
      sectionTitle:
        parentData?.log_data?.summary === 'MAINTENANCE'
          ? 'Maintenance images'
          : 'Inspection Images',
      imageGallery: images,
      show: showState.images,
    },
    {
      sectionTitle:
        parentData?.log_data?.summary === 'MAINTENANCE'
          ? 'Maintenance Additional Information'
          : 'Inspection Additional Information',
      infoStrings: customReports,
      show: showState.customReports,
    },
  ];

  return {
    body,
    images,
  };
};
