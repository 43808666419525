import {formatDateWithOffset} from 'helpers/functions';
import React, {useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {convertTimeRange} from '../pages/reports/ReportUtils';

const DashboardNavigation = ({handleSidebarResponsive}) => {
  const history = useHistory();
  const [time, setTime] = useState({start: '', end: ''});

  const setTimeByRange = range => {
    let times = convertTimeRange(range);
    if (times) {
      const startDate = formatDateWithOffset(times.start);
      const endDate = formatDateWithOffset(times.end);
      setTime({
        start: startDate.dateFormatted,
        end: endDate.dateFormatted,
      });
    }
  };

  useEffect(() => {
    setTimeByRange('week');
  }, []);

  const checkIsActive = path => {
    return history.location.pathname.includes(path);
  };

  return (
    <>
      <ul className='pi-nav-items'>
        <li onClick={handleSidebarResponsive}>
          <NavLink to='/maps' className='pi-sidebar-menu' activeClassName='pi-active'>
            <i className='icon-map' />
            <span>Map</span>
          </NavLink>
        </li>
        <li onClick={handleSidebarResponsive}>
          <NavLink
            to='/activity?status=pending_inspection'
            className='pi-sidebar-menu'
            activeClassName='pi-active'
          >
            <i className='icon-desktop' />
            <span>Activity</span>
          </NavLink>
        </li>
        <li onClick={handleSidebarResponsive}>
          <NavLink
            to='/impact/devices?assigned=NO'
            className='pi-sidebar-menu'
            activeClassName='pi-active'
          >
            <i className='icon-devices' />
            <span>Devices</span>
          </NavLink>
        </li>
        <li onClick={handleSidebarResponsive}>
          <NavLink to='/locations' className='pi-sidebar-menu' activeClassName='pi-active'>
            <i className='icon-place' />
            <span>Assets</span>
          </NavLink>
        </li>
        <li onClick={handleSidebarResponsive}>
          <NavLink
            to='/teams'
            className='pi-sidebar-menu'
            activeClassName='pi-active'
            onClick={e => checkIsActive('teams') && e.preventDefault()}
          >
            <i className='icon-groups' />
            <span>Teams</span>
          </NavLink>
        </li>
        {/*        <li onClick={handleSidebarResponsive}>
          <NavLink to='/analytics' className='pi-sidebar-menu' activeClassName='pi-active'>
            <i className='icon-analytics' />
            <span>Analytics</span>
          </NavLink>
        </li>*/}
        <li onClick={handleSidebarResponsive}>
          <NavLink
            to={`/reports?start_date=${time.start}&end_date=${time.end}&severity=all`}
            className='pi-sidebar-menu'
            activeClassName='pi-active'
          >
            <i className='icon-assessment' />
            <span>Reports</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default DashboardNavigation;
