export const W_GET_USER_GROUPS = 'W_GET_USER_GROUPS';
export const W_GET_USER_GROUP = 'W_GET_USER_GROUP';
export const W_CREATE_USER_GROUP = 'W_CREATE_USER_GROUP';
export const W_ADD_USER_GROUP_DATA = 'W_ADD_USER_GROUP_DATA';
export const W_EDIT_USER_GROUP_NAME = 'W_EDIT_USER_GROUP_NAME';
export const W_MOVE_USER_GROUP_DATA = 'W_MOVE_USER_GROUP_DATA';
export const W_DELETE_USER_GROUP_DATA = 'W_DELETE_USER_GROUP_DATA';
export const W_DELETE_USER_GROUP = 'W_DELETE_USER_GROUP';
export const GET_TEAMS_WORKZONES = 'GET_TEAMS_WORKZONES';
export const W_LOAD_TEAMS = 'W_LOAD_TEAMS';
export const W_SEARCH_TEAMS = 'W_SEARCH_TEAMS';
export const W_CLEAR_TEAMS = 'W_CLEAR_TEAMS';
export const W_DISABLE_ADD_BUTTON = 'W_DISABLE_ADD_BUTTON';
export const W_CLEAR_USER_GROUPS = 'W_CLEAR_USER_GROUPS';
export const W_SET_USER_GROUP_CREATED = 'W_SET_USER_GROUP_CREATED';
export const W_SET_ACTIVE_TEAM = 'W_SET_ACTIVE_TEAM';
export const W_SET_ACTIVE_GROUP = 'W_SET_ACTIVE_GROUP';
export const W_SET_ACTIVE_SUBGROUPS = 'W_SET_ACTIVE_SUBGROUPS';
export const W_SET_USER_GROUP_DATA_ADDED = 'W_SET_USER_GROUP_DATA_ADDED';
export const W_GET_USER_GROUPS_NEW = 'W_GET_USER_GROUPS_NEW';
export const W_GET_TEAMS = 'W_GET_TEAMS';
export const W_GET_ONE_TEAM = 'W_GET_ONE_TEAM';

export const CREATE_WORKZONE = 'CREATE_WORKZONE';
export const GET_ALL_WORKZONES = 'GET_ALL_WORKZONES';
export const GET_ONE_WORKZONE = 'GET_ONE_WORKZONE';
export const CLEAR_ONE_WORKZONE = 'CLEAR_ONE_WORKZONE';
export const CLEAR_WORKZONE_CURSOR = 'CLEAR_WORKZONE_CURSOR';
export const UPDATE_ONE_WORKZONE = 'UPDATE_ONE_WORKZONE';
export const DELETE_ONE_WORKZONE = 'DELETE_ONE_WORKZONE';
export const UPDATE_WORKZONE_LIST = 'UPDATE_WORKZONE_LIST';
export const GET_WORKZONE_DEVICES = 'GET_WORKZONE_DEVICES';
export const GET_WORKZONE_ONE_DEVICE = 'GET_WORKZONE_ONE_DEVICE';
export const UPDATE_WORKZONE_ONE_DEVICE = 'UPDATE_WORKZONE_ONE_DEVICE';
export const UPDATE_WORKZONE_DEVICE_LIST = 'UPDATE_WORKZONE_DEVICE_LIST';
export const SET_WORKZONE_DEVICE_TABLE = 'SET_WORKZONE_DEVICE_TABLE';
export const CLEAR_WORKZONE_DEVICE_LIST = 'CLEAR_WORKZONE_DEVICE_LIST';
export const GET_WORKZONE_MAP_DEVICES = 'GET_WORKZONE_MAP_DEVICES';
export const GET_WORKZONE_MAP_WORKZONES = 'GET_WORKZONE_MAP_WORKZONES';
export const CREATE_TAPER_SCHEDULE = 'CREATE_TAPER_SCHEDULE';
export const UPDATE_TAPER_SCHEDULE = 'UPDATE_TAPER_SCHEDULE';
export const DELETE_TAPER_SCHEDULE = 'DELETE_TAPER_SCHEDULE';
export const UPDATE_WORKZONE_DEVICE_SETTINGS = 'UPDATE_WORKZONE_DEVICE_SETTINGS';
export const SET_WORKZONE_DEVICE_LAMP_TABLE = 'SET_WORKZONE_DEVICE_LAMP_TABLE';
export const UPDATE_WORKZONE_DEVICE_LAMP_TABLE = 'UPDATE_WORKZONE_DEVICE_LAMP_TABLE';
export const DELETE_WORKZONE_DEVICE_LAMP_TABLE = 'DELETE_WORKZONE_DEVICE_LAMP_TABLE';
export const UPDATE_LAMP_LIST = 'UPDATE_LAMP_LIST';
export const GET_WORKZONE_TEAM_USERS = 'GET_WORKZONE_TEAM_USERS';
export const SET_WORKZONE_TEAM_TABLE_DATA = 'SET_WORKZONE_TEAM_TABLE_DATA';
export const GET_NOT_CONNECTED_WORZONE_DEVICES = 'GET_NOT_CONNECTED_WORZONE_DEVICES';
export const GET_ALL_WORKZONE_DEVICES = 'GET_ALL_WORKZONE_DEVICES';
export const DELETE_WORKZONE_DEVICE = 'DELETE_WORKZONE_DEVICE';
export const SET_REMOVE_DEVICE = 'SET_REMOVE_DEVICE';
export const GET_ASSET_TYPES_WZ = 'GET_ASSET_TYPES_WZ';
export const CLEAR_WORKZONE_TEAM_USERS = 'CLEAR_WORKZONE_TEAM_USERS';
export const CLEAR_ONE_WORKZONE_DEVICE = 'CLEAR_ONE_WORKZONE_DEVICE';
export const GET_DEVICE_MANUFACTURERS = 'GET_DEVICE_MANUFACTURERS';
export const GET_DEVICE_MODELS = 'GET_DEVICE_MODELS';
export const ADD_NEW_DEVICE_WZ = 'ADD_NEW_DEVICE_WZ';
export const SET_DEVICE_ADDED_WZ = 'SET_DEVICE_ADDED_WZ';
export const EDIT_DEVICE_WZ = 'EDIT_DEVICE_WZ';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
