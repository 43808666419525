import React, {useEffect, useRef, useState} from 'react';

const SearchBar = ({
  onSearch,
  disabled,
  placeholder,
  filterTrigger,
  className = '',
  value: parentValue,
  onChange,
}) => {
  const [value, setValue] = useState(parentValue ?? '');

  useEffect(() => {
    if (parentValue && parentValue !== value) {
      setValue(parentValue);
    }
  }, [parentValue]);

  const searchTimeout = useRef();
  const handleOnChange = e => {
    setValue(e.target.value);
    onChange?.(e.target.value);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      onSearch(e.target.value);
    }, 1500);
  };

  return (
    <div className={className}>
      <div className='border-[1px] border-[##E4E4E4] bg-white flex h-[45px] rounded-lg items-center overflow-hidden focus-within:border-[#354052]'>
        <i className='pi-icon icon-search flex justify-center items-center !text-2xl !w-6 !h-6 mr-1 ml-3 !text-[#354052]' />
        <input
          type='text'
          id='searchAndFilterInput'
          placeholder={placeholder}
          onChange={handleOnChange}
          disabled={disabled}
          value={value}
          className={`!outline-none flex-1 pl-1 h-full ${disabled ? 'bg-white opacity-80' : ''}`}
        />
        {filterTrigger && (
          <button className='mx-2 w-7 h-7' onClick={filterTrigger}>
            <i className='icon-filter text-2xl !w-[24px] !h-[24px] flex justify-center items-center' />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
