import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import ReportMobileHead from './head';
import LocationMap from './locationMap';

import {mobileReportStepChange} from '../../../impact-detection/redux/actions/mobile/reportsActions';

import {ImpactStatuses} from '../../../impact-detection/redux/constants';
import ActivityCharts from 'modules/impact-detection/pages/activity/activityCharts';
import {useHistory} from 'react-router-dom';

const ReportMobilePage = () => {
  const dispatch = useDispatch();

  const {location} = useSelector(state => state.locationLandingPage);
  const permissions = useSelector(state => state.auth.permissions);

  const showStartInspectionButton = !!(
    permissions.ADD_AND_EDIT_REPORTS && ImpactStatuses.PENDING_INSPECTION === location.status
  );

  const startReport = () => dispatch(mobileReportStepChange(1));

  return (
    <div className='rm-main'>
      <ReportMobileHead warningMessage={true} />

      {location && (
        <div className='pi-device-location'>
          <LocationMap currentLocation={location} />
        </div>
      )}

      <ActivityCharts currentEvent={location} />

      {showStartInspectionButton && (
        <div className='rm-start-inspection'>
          <button onClick={startReport}>Start Inspection Report</button>
        </div>
      )}

      {ImpactStatuses.PENDING_INSPECTION !== location.status && (
        <div className='rm-report-added'>
          <span>Report already added!</span>
        </div>
      )}
    </div>
  );
};

export default ReportMobilePage;
