import React from 'react';
import Link from './Link';

const LinkButton = ({
  className = '',
  styleType = '',
  color = '',
  shadow,
  icon = '',
  disabled = false,
  onClick,
  children,
  size = '',
  refAttr,
  href,
}) => {
  let hasShadow = shadow ? 'pi-shadow' : '';
  let hasIcon = icon ? 'pi-with-arrow' : '';
  let iconPosition = icon ? `pi-${icon}-icon` : '';
  let btnSize = !!size ? `pi-size-${size}` : '!h-[41px]';

  return (
    <Link
      onClick={onClick}
      ref={refAttr}
      href={href}
      disabled={disabled}
      className={
        '!no-underline !border-none !outline-none !p-0 !bg-transparent !shadow-none flex justify-center items-center'
      }
    >
      <div
        disabled={disabled}
        className={`pi-btn pi-${styleType} ${btnSize} pi-${color} ${hasShadow} ${hasIcon} ${iconPosition} ${className}`}
      >
        {children}
      </div>
    </Link>
  );
};

export default LinkButton;
