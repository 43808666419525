import React from 'react';

const TextArea = ({
  className = '',
  value = '',
  disabled = false,
  label = '',
  shadow,
  placeholder = '',
  onChange,
  errorMessage,
  onKeyDown,
  onBlur,
  name,
}) => {
  return (
    <div
      className={`pi-text-area ${className} ${shadow ? 'pi-shadow' : ''} ${
        errorMessage !== '' ? 'pi-invalid' : ''
      }`}
    >
      {label && <label className='pi-field-label'>{label}</label>}
      <div className='pi-icon-wrapper'>
        <textarea
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          data-replicated-value={value}
          name={name}
          value={value}
        />
        <i className='pi-icon icon-unfold-more' />
      </div>
      {errorMessage && errorMessage !== '' && (
        <p className='pi-error-message text-xs'>{errorMessage}</p>
      )}
    </div>
  );
};

export default TextArea;

