import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  AUTH_CHECK,
  SAVE_ROUTE,
  ASYNC_STATUS,
  GET_USER_PERMISSIONS,
} from '../constants';

const initialState = {
  data: null,
  message: null,
  loading: true,
  currentUrl: '/maps',
  isAuthenticated: false,
  status: ASYNC_STATUS.REQUEST,
  statusSource: null,
  permissions: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
    case SIGNUP:
    case AUTH_CHECK:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: null,
            loading: true,
            status: ASYNC_STATUS.REQUEST,
            statusSource: action.soruce,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            loading: false,
            data: action.data,
            isAuthenticated: true,
            status: ASYNC_STATUS.SUCCEED,
            statusSource: action.soruce,
            message: 'Succeed to authenticate.',
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            loading: false,
            message: action.message,
            status: ASYNC_STATUS.FAILURE,
            statusSource: action.soruce,
          };
        default:
          return state;
      }
    case LOGOUT:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: null,
            status: ASYNC_STATUS.REQUEST,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            data: null,
            loading: false,
            currentUrl: '/maps',
            message: 'logged out.',
            isAuthenticated: false,
            status: ASYNC_STATUS.SUCCEED,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
            status: ASYNC_STATUS.FAILURE,
          };
        default:
          return state;
      }
    case SAVE_ROUTE:
      return {
        ...state,
        currentUrl: action.url,
      };
    case GET_USER_PERMISSIONS:
      switch (action.status) {
        case ASYNC_STATUS.SUCCEED:
          let permissions = {list: action.data};

          action.data.forEach(item => (permissions[item.key] = item));
          state.permissions = permissions;
          break;
        default:
          return state;
      }
    default:
      return state;
  }
}
