import React, {useRef} from 'react';
import TableCreator from './TableCreator';
import {Spinner} from 'reactstrap';
import {Checkbox} from 'elements';

const Table = ({
  data,
  field,
  loader,
  urlPath,
  urlField,
  expanded,
  className,
  tableType,
  activeRow,
  cursorNext,
  pointerVal,
  pointerKey,
  searchValue,
  tableOptions,
  bulkSelected,
  tableHeadings,
  activeSecondary,
  tableWrapperRef,
  size = 'lg',
  loadMore,
  onSortClick,
  onDeviceClick,
  onBulkSelect,
}) => {
  const timeBeforeCalling = useRef();
  const handleScroll = e => {
    const endReached =
      parseInt(e.target.scrollHeight) - parseInt(e.target.scrollTop) - 10 <=
      Math.floor(parseInt(e.target.clientHeight));

    if (endReached && cursorNext !== '' && !loader && loadMore && !timeBeforeCalling.current) {
      timeBeforeCalling.current = setTimeout(() => {
        clearTimeout(timeBeforeCalling.current);
        timeBeforeCalling.current = undefined;
      }, 500);

      return loadMore();
    }
  };

  return (
    <div className={`pi-table pi-size-${size}`}>
      <div className={`pi-wrapper ${className}`} ref={tableWrapperRef} onScroll={handleScroll}>
        <table className='pi-white-row'>
          <thead>
            <tr>
              {tableHeadings.map((th, index) => (
                <th key={index + '_' + th.title} className={th.classes}>
                  <div
                    className={`pi-th ${th.active ? 'pi-sorted' : ''} ${
                      th.type === 'asc' ? 'pi-asc' : ''
                    }`}
                  >
                    {th.bulkSelect && (
                      <Checkbox name='select_all' onChange={onBulkSelect} checked={bulkSelected} />
                    )}
                    {th.respTitle && <span className='pi-resp-title'>{th.respTitle}</span>}
                    <span className='pi-title'>{th.title}</span>
                    {th.sortAction && (
                      <button type='button' className='pi-sort' onClick={() => onSortClick(th)}>
                        <i className='pi-icon icon-arrow-down' />
                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <TableCreator
              data={data}
              field={field}
              loader={loader}
              urlPath={urlPath}
              urlField={urlField}
              expanded={expanded}
              tableType={tableType}
              activeRow={activeRow}
              pointerVal={pointerVal}
              pointerKey={pointerKey}
              searchValue={searchValue}
              tableOptions={tableOptions}
              clickAction={onDeviceClick}
              activeSecondary={activeSecondary}
            />
          </tbody>
        </table>
      </div>
      {loader && (
        <div className='pi-loader-row'>
          <Spinner color='primary' type='border' size='lg' />
        </div>
      )}
    </div>
  );
};

export default Table;
