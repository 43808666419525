import ReactDOM from 'react-dom';
import icons from '../../modules/workzone/components/Icons';
import React from 'react';

//prettier-ignore
export const MapDefaultSettings = {
  restrictions: {
    strictBounds: true,
    latLngBounds: {north: 85, south: -80, west: -180, east: 180},
  },
  routeColors: {ON: '#4A8F5C', OFF: '#979797'},
  modes: {add: 'add', edit: 'edit', view: 'view'},
  options: {
    styles: [
      {featureType: 'administrative', elementType: 'labels.icon', stylers: [{visibility: 'off'}]},
      {featureType: 'landscape', elementType: 'labels.icon', stylers: [{visibility: 'off'}]},
      {featureType: 'poi',elementType: 'labels.icon',stylers: [{visibility: 'off'}]},
    ],
  },
};

export const addMapControlButton = config => {
  const controlButtonDiv = document.createElement('div');
  ReactDOM.render(
    <button
      type='button'
      className='pi-btn pi-outline pi-white pi-shadow left map-marker-mode-button mode-marker mr-15'
      ref={config.ref}
      onClick={config.onModeChange}
    >
      <img ref={config.iconRef} src={icons.DefaultMode} alt='' />
    </button>,
    controlButtonDiv,
  );
  config.map.controls[config.google.maps.ControlPosition.LEFT_TOP].push(controlButtonDiv);
};

export function createTooltipInfoWindow({google, content, tooltipContainerReference}) {
  //For previous opened tooltip
  if (tooltipContainerReference.current) {
    tooltipContainerReference.current.close();
  }

  let tooltipContainer = document.createElement('div');
  tooltipContainer.className = 'pi-picker-tooltip';
  tooltipContainer.innerText = content;

  const infoWindow = new google.maps.InfoWindow({content: tooltipContainer});
  tooltipContainerReference.current = infoWindow;
  return infoWindow;
}
