import React from 'react';
import {Button} from 'elements';
import {useHistory} from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();
  function onClick() {
    history.push('/');
  }
  return (
    <div style={{margin: 'auto', textAlign: 'center'}}>
      <h1 style={{fontSize: '160px', color: '#626569', fontWeight: '600'}}>404</h1>
      <h3 style={{color: '#38393b', fontSize: '40px'}}>Page Not Found</h3>
      <p style={{color: '#38393b', fontSize: '20px'}}>
        The resource requested could not be found on this server!
      </p>
      <Button onClick={onClick}>Go To Main Page</Button>
    </div>
  );
};

export default NotFound;
