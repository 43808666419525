import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ImageUploading from 'react-images-uploading';
import {mobileReportFormChange} from '../../../../impact-detection/redux/actions/mobile/reportsActions';
// import UploadedPicturePopover from "../../activity/UploadedPicturePopover";
import ImagePreviewModal from '../modals/ImagePreviewModal';
import DeleteImageModal from '../modals/DeleteImageModal';
import ImageIcon from '../../../../../assets/img/svg/image-icon.svg';
import PlusIcon from '../../../../../assets/img/svg/add.svg';
import RemoveIcon from '../../../../../assets/img/svg/close-icon.svg';

const dumArray = Array(5).fill('empty');

const ReportMobileStep6 = () => {
  const dispatch = useDispatch();
  const {form} = useSelector(state => state.reportMobile);

  const [imagePreview, setImagePreview] = useState({
    open: false,
    imageUrl: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState('');

  const changeFiled = value => {
    dispatch(mobileReportFormChange({field: 'images', step: 'step6', value}));
  };

  const showHideDeleteImageModal = index => {
    setDeletedIndex(index);
    setShowModal(!showModal);
  };

  const imagePreviewOpen = e => {
    const imageUrl = e.target.src;
    setImagePreview({
      open: true,
      imageUrl: imageUrl,
    });
  };

  const imagePreviewClose = () => {
    setImagePreview({
      open: false,
      imageUrl: '',
    });
  };

  return (
    <div className='rm-step-content'>
      <h4>Inspection images</h4>
      <ImageUploading
        multiple
        value={form.step6.images.value}
        onChange={changeFiled}
        maxNumber={5}
        dataURLKey='data_url'
      >
        {({imageList, onImageUpload, onImageRemove, errors, dragProps}) => {
          return (
            <>
              <div
                className='rm-image-upload-group imageUploadGroup'
                style={{
                  display: 'grid',
                  gridTemplateColumns: '28% 28% 28%',
                  gridGap: '5%',
                  justifyContent: 'center',
                }}
              >
                <div
                  className='rm-image-uploader imageUploader'
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <img src={PlusIcon} alt='' />
                  <span>
                    Upload <br /> images
                  </span>
                </div>
                {[...imageList, ...dumArray].slice(0, 5).map((item, index) => {
                  if (item === 'empty') {
                    return (
                      <div
                        key={index}
                        className='rm-image-uploader-background imageUploaderBackground'
                      >
                        <img src={ImageIcon} alt='' />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className='imageUploaderBackground rm-image-uploader-background'
                      >
                        <div
                          className='rm-remove-image removeImage'
                          onClick={() => showHideDeleteImageModal(index)}
                          {...dragProps}
                        >
                          <img src={RemoveIcon} alt='' />
                        </div>
                        <img
                          alt={'img'}
                          src={item.data_url}
                          style={{height: '100%', width: '100%', objectFit: 'cover'}}
                          onClick={imagePreviewOpen}
                        />
                        {/*<UploadedPicturePopover*/}
                        {/*    open={imagePreview.open}*/}
                        {/*    onClose={imagePreviewClose}*/}
                        {/*    data={imagePreview.imageUrl}*/}
                        {/*/>*/}
                      </div>
                    );
                  }
                })}
                <ImagePreviewModal
                  open={imagePreview.open}
                  onClose={imagePreviewClose}
                  data={imagePreview.imageUrl}
                />
                <DeleteImageModal
                  showModal={showModal}
                  onImageRemove={onImageRemove}
                  showHideDeleteImageModal={showHideDeleteImageModal}
                  deletedIndex={deletedIndex}
                />
              </div>
              {errors && errors.maxNumber && (
                <div className='invalid-feedback d-block rm-images-error'>
                  Upload max. 5 images.
                </div>
              )}
            </>
          );
        }}
      </ImageUploading>
    </div>
  );
};

export default ReportMobileStep6;
