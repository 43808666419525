import React from 'react';

const Card = ({
    className='',
    title='',
    children,
    cardAction,
    spacing
}) => {
    return (
        <div className={`pi-card ${className}`}>
            {title && <h5 className='pi-card-title'>{title}</h5>}
            <div className='pi-card-body'>
                <div className={`pi-content pi-spacing${spacing?'-' + spacing:''}`}>
                    {children}
                </div>
                {cardAction && <div className='pi-footer'>
                    {cardAction}
                </div>}
            </div>
        </div>
    );
};

export default Card;