import React from 'react';

const DashedTitle = ({
    className='',
    indicator,
    stepNumber,
    title,
    completed,
    position
}) => {
    return (
        <div className={`pi-title-container ${className} ${indicator?'pi-indicator':''}`}>
            {indicator && <div className={`pi-step-indicator ${completed?'pi-completed':''}`}>
                { !completed ?
                    <span className="pi-number">{stepNumber}</span>:
                    <i className="icon-check"/>
                }
            </div>}
            <div className={`pi-title pi-align-${position}`}>
                <h5>{title}</h5>
            </div>
        </div>
    );
};

export default DashedTitle;