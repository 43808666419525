import React from 'react';

const Checkbox = ({
    className,
    label,
    name,
    value,
    onChange,
    color,
    checked,
    disabled
}) => {
    return (
        <label className={`pi-checkbox ${className?className:''} ${color?'pi-'+color:'pi-text'}`}>
            <input type="checkbox" name={name} onChange={onChange} value={value} checked={checked} disabled={disabled}/>
            <i className="pi-check-icon"/>
            <span className="pi-check-text">{label}</span>
        </label>
    );
};

export default Checkbox;