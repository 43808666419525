import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {mobileReportFormChange} from '../../../../impact-detection/redux/actions/mobile/reportsActions';

const ReportMobileStep5 = () => {
  const dispatch = useDispatch();

  const {form} = useSelector(state => state.reportMobile);

  const changeFiled = (field, value) => {
    dispatch(mobileReportFormChange({field, step: 'step5', value}));
  };

  return (
    <div className='rm-step-content'>
      <h4>Do you have any additional notes?</h4>
      <textarea
        value={form.step5.notes.value}
        placeholder='Enter additional notes here'
        onChange={e => changeFiled('notes', e.target.value)}
      />
    </div>
  );
};

export default ReportMobileStep5;
