import React from 'react';
import {Redirect} from 'react-router-dom';

import async from '../components/Async';
import NotFound from '../components/NotFound';
import sharedRoutingConfigs from '../modules/shared/routes';

const Profile = async(() => import('modules/shared/pages/profile'));
const mapGoogle = async(() => import('modules/impact-detection/pages/map'));
const Reports = async(() => import('modules/impact-detection/pages/reports'));
const ImpactTeam = async(() => import('modules/impact-detection/pages/teams'));
const Activity = async(() => import('modules/impact-detection/pages/activity'));
const Analytics = async(() => import('modules/impact-detection/pages/analytics'));
const Devices = async(() => import('modules/impact-detection/pages/devices/Index'));
const Locations = async(() => import('modules/impact-detection/pages/locations/index'));
const UserRoles = async(() => import('modules/impact-detection/pages/account-settings/Index'));
const UserRolesHistory = async(() =>
  import('modules/impact-detection/pages/account-settings/history/RoleHistory'),
);
const WorkspaceSwitcher = async(() =>
  import('modules/shared/pages/workspaceSwitcher/SwitcherIndex'),
);
const AnalyticsDetails = async(() =>
  import('modules/impact-detection/pages/analytics/analyticsDetails'),
);

const reports = {
  path: '/reports',
  name: 'Reports',
  component: Reports,
  children: [{path: '/reports/*', name: 'Not Found', component: NotFound}],
};

const devices = {
  path: '/impact/devices',
  name: 'Devices',
  component: Devices,
  children: [{path: '/impact/devices/*', name: 'Not Found', component: NotFound}],
};

const teamsRoutes = {
  name: 'Team',
  path: '/teams',
  component: ImpactTeam,
  children: [
    {path: '/teams/:teamId?', name: 'Locations', component: ImpactTeam},
    {path: '/teams/:teamId/*', name: 'Not Found', component: NotFound},
  ],
};

const teamsRoutesURL = {
  path: '/teams/:teamId?',
  name: 'Team',
  component: ImpactTeam,
  children: [{path: '/teams/:teamId/*', name: 'Not Found', component: NotFound}],
};

const googleMapsRoutes = {
  //path: '/maps',
  path: ['/maps', '/maps/:location_id'],
  name: 'Map',
  component: mapGoogle,
  containsHome: false,
  children: [
    //{path: '/maps/:location_id', name: 'Assets', component: mapGoogle},
    {path: '/maps/:location_id/*', name: 'Not Found', component: NotFound},
  ],
};

const locationsRoute = {
  path: ['/locations', '/locations/:location_id'],
  name: 'Assets',
  component: Locations,
  children: [
    //{path: '/locations/:location_id', name: 'Locations', component: Locations},
    {path: '/locations/:location_id/*', name: 'Not Found', component: NotFound},
  ],
};

const activityRoute = {
  path: ['/activity', '/activity/:id'],
  exact: false,
  name: 'Activity',
  component: Activity,
  children: [
    //{path: '/activity/:event_id', name: 'Activity', component: Activity},
    {path: '/activity/:event_id/*', name: 'Not Found', component: NotFound},
  ],
};

const workspaceSwitcherRoutes = {
  path: '/workspace-switcher',
  name: 'Switcher',
  component: WorkspaceSwitcher,
  children: null,
};

const profileRoutes = {path: '/profile', name: 'Profile', component: Profile, children: null};

const analytics = {path: '/analytics', name: 'Analytics', component: Analytics, children: null};

const analyticsDetails = {path: '/analytics-single', component: AnalyticsDetails, children: null};

const UserRolesRoute = {
  path: '/account-settings',
  component: UserRoles,
  children: [
    {
      path: '/account-settings/roles-history',
      name: 'Roles change history',
      component: UserRolesHistory,
    },
  ],
};

export const getDashboardRoutes = hiddenRoutes => {
  const routes = [
    reports,
    devices,
    analytics,
    analyticsDetails,
    googleMapsRoutes,
    teamsRoutes,
    teamsRoutesURL,
    locationsRoute,
    activityRoute,
    workspaceSwitcherRoutes,
    profileRoutes,
    ...sharedRoutingConfigs,
  ];

  if (!hiddenRoutes.accountSettings) {
    routes.unshift(UserRolesRoute);
  }

  return routes;
};
