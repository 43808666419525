import {ASYNC_STATUS} from '../constants';
import {PURGE} from 'redux-persist';

export const initialState = {
  message: null,
  status: ASYNC_STATUS.FAILURE,
  data: [],
  currentUser: {},
  selectedIndex: -1,
};

export default function baseReducer(
  state,
  action,
  getAllAction = null,
  getOneAction = null,
  createAction = null,
  updateAction = null,
  deleteAction = null,
  getAction = null,
  getAttenuatorDataAction = null,
  getTicketAndNotesAction = null,
) {
  if (action.type === PURGE) {
    return initialState;
  }
  if (getAllAction && action.type === getAllAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Fetching data...',
        };
      case ASYNC_STATUS.SUCCEED:
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          message: 'Succeed to fetch data.',
          data: action.data,
          allEvents: action.data,
          // ...initialState
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  if (getOneAction && action.type === getOneAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Fetching data...',
        };
      case ASYNC_STATUS.SUCCEED:
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          message: 'Succeed to fetch data.',
          currentUser: action.data[0],
          // ...initialState
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  if (createAction && action.type === createAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Create...',
        };
      case ASYNC_STATUS.SUCCEED:
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          message: 'Succeed to create.',
          data: action.data || state.data,
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  if (updateAction && action.type === updateAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Update...',
        };
      case ASYNC_STATUS.SUCCEED:
        let data = action.data || state.data;

        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          needsRefresh: action.type === 'TICKET_UPDATE',
          message:
            action.type === 'TICKET_UPDATE'
              ? 'Succeed to update the ticket.'
              : 'Succeed to update the device.',
          data,
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  if (deleteAction && action.type === deleteAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Delete data...',
        };
      case ASYNC_STATUS.SUCCEED:
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          data: action.data || state.data,
          message: 'Succeed to delete data.',
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  if (getAction && action.type === getAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Fetching data...',
        };
      case ASYNC_STATUS.SUCCEED:
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          message: 'Succeed to fetch data.',
          data: action.data,
          // ...initialState
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  if (getAttenuatorDataAction && action.type === getAttenuatorDataAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Fetching data...',
        };
      case ASYNC_STATUS.SUCCEED:
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          message: 'Succeed to fetch data.',
          getAttenuatorData: action.data,
          // ...initialState
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  if (getTicketAndNotesAction && action.type === getTicketAndNotesAction) {
    switch (action.status) {
      case ASYNC_STATUS.REQUEST:
        return {
          ...state,
          status: ASYNC_STATUS.REQUEST,
          message: 'Fetching data...',
        };
      case ASYNC_STATUS.SUCCEED:
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          message: 'Succeed to fetch data.',
          ticketsAndLoges: action.data,
          // ...initialState
        };
      case ASYNC_STATUS.FAILURE:
        return {
          ...state,
          status: ASYNC_STATUS.FAILURE,
          message: action.message,
        };
      default:
        return state;
    }
  }
  return state;
}
