import {
  ASYNC_STATUS,
  GET_MOBILE_LOCATION,
  RESET_MOBILE_LOCATION,
  GET_LOCATION_USERS,
} from '../../constants';

const initialState = {
  location: {},
  locationUsers: [],
  status: null,
  message: null,
};

const buildGraphData = data => {
  const x_data = [];
  const y_data = [];
  const z_data = [];

  for (const location of data.accelerometer_data || []) {
    for (const coordinate of location) {
      x_data.push(coordinate.x);
      y_data.push(coordinate.y);
      z_data.push(coordinate.z);
    }
  }

  Object.assign(data, {x_data, y_data, z_data});

  return data;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MOBILE_LOCATION:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            location: buildGraphData(action.data),
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        case ASYNC_STATUS.RESET:
          return {
            status: ASYNC_STATUS.RESET,
            message: '',
            location: {},
          };
        default:
          return state;
      }
    case RESET_MOBILE_LOCATION:
      return {
        ...initialState,
      };
    case GET_LOCATION_USERS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            locationUsers: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        case ASYNC_STATUS.RESET:
          return {
            status: ASYNC_STATUS.RESET,
            message: '',
            locationUsers: {},
          };
        default:
          return state;
      }
    default:
      return state;
  }
}
