import React from 'react';
import {Button} from 'elements';
import SearchBar from './SearchBar';
import SelectedFilters from './SelectedFilters';
import {useSelector} from 'react-redux';

const SearchAndFilter = ({
  children,
  selector,
  onSearch,
  disabled,
  filterState,
  applyFilters,
  clearFilters,
  removeFilter,
  filterTrigger,
  filterBlockTitle,
  searchPlaceholder,
  applyFilterDisable,
  deviceSelectedFilters,
  searchValue,
  setSearchValue,
}) => {
  const globalSelectedFilters = useSelector(state => state.deviceFilter[`${selector}`]);
  const selectedFilters = deviceSelectedFilters || globalSelectedFilters;

  return (
    <div className='pi-search'>
      <SearchBar
        onSearch={onSearch}
        placeholder={searchPlaceholder}
        filterTrigger={filterTrigger}
        disabled={disabled}
        value={searchValue}
        onChange={setSearchValue}
      />
      <SelectedFilters removeFilter={removeFilter} selectedFilters={selectedFilters} />
      {filterState && (
        <div className='pi-filter-block'>
          <div className='pi-block-header'>
            <h4 className='pi-block-heading'>{filterBlockTitle}</h4>
            <button className='pi-filter-close' onClick={filterTrigger}>
              <i className='icon-close' />
            </button>
          </div>
          {children}
          <div className='pi-block-footer'>
            <Button styleType='outline' color='white' onClick={clearFilters}>
              Clear Filters
            </Button>
            <Button
              styleType='filled'
              color='gray'
              onClick={applyFilters}
              disabled={applyFilterDisable}
            >
              Apply Filters
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchAndFilter;
