// Post async action
import {apiRequest} from 'core/api/apiRequest';
import {toastr} from 'react-redux-toastr';
import {ASYNC_STATUS} from '../constants';

const TOAST_TITLE = 'Pi Variables';

// Default async action
export function asyncAction(type, status, params = null) {
  if (params) {
    return {type, status, ...params};
  } else {
    return {type, status};
  }
}

// Get async action
export function getAsyncAction(
  actionType,
  apiEndpoint,
  successMessage = null,
  failureMessage = null,
  number = null,
  cancelable,
) {
  return async dispatch => {
    dispatch(asyncAction(actionType, ASYNC_STATUS.REQUEST));
    const response = await apiRequest(apiEndpoint, 'get', null, false, false, true, cancelable);
    // const response  = {success: false, error: false, data:{}, message: '4'};

    if (response.success) {
      dispatch(
        asyncAction(actionType, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
          number,
        }),
      );
      if (successMessage) toastr.success(TOAST_TITLE, successMessage);
    } else {
      dispatch(
        asyncAction(actionType, ASYNC_STATUS.FAILURE, {
          message: response.message,
        }),
      );
      if (failureMessage) toastr.error(TOAST_TITLE, failureMessage);
    }
  };
}

export function postAsyncAction(
  actionType,
  apiEndpoint,
  params,
  getApiEndpoint = null,
  successMessage = null,
  failureMessage = null,
) {
  return async dispatch => {
    dispatch(asyncAction(actionType, ASYNC_STATUS.REQUEST));
    const response = await apiRequest(apiEndpoint, 'post', params);
    // const response = { success: false, error: false, data: {}, message: "4" };
    if (response.success) {
      let data = null;
      if (getApiEndpoint) {
        const getResponse = {
          success: false,
          error: false,
          data: {},
          message: '4',
        };

        if (getResponse.success) {
          data = getResponse.data;
        }
      }
      dispatch(
        asyncAction(actionType, ASYNC_STATUS.SUCCEED, {
          data,
          message: response.message,
        }),
      );
      if (successMessage) toastr.success(TOAST_TITLE, successMessage);
    } else {
      dispatch(
        asyncAction(actionType, ASYNC_STATUS.FAILURE, {
          message: response.message,
        }),
      );
      if (failureMessage) toastr.error(TOAST_TITLE, failureMessage);
    }
  };
}
