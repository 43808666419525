import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ImagePreviewModal = (props) => {
    const { open, onClose, data } = props;
    return (
        <Modal
            className='modal-content-teams modalMainDimensions imagePreviewModal'
            style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}
            backdrop={true}
            toggle={onClose}
            isOpen={open}
            scrollable={true}
        >
            <ModalHeader toggle={onClose} style={{ borderBottom: 'none' }} />
            <ModalBody style={{ padding: '0px' }}>
                <img alt={'img'} src={data} height={'auto'} width="100%"/>
            </ModalBody>
        </Modal>
    );
};

export default ImagePreviewModal;
