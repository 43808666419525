import React from 'react';
import Select from 'react-select';

const SelectCustomStyles = {
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#354052',
  }),
  container: provided => ({
    ...provided,
    width: '100%',
    height: 41,
    color: '#000000',
    fontFamily: 'Roboto',
    fontSize: 14,
  }),
};

const ReactSelect = ({
  name = '',
  className = '',
  label,
  options,
  value,
  placeholder,
  shadow,
  onChange,
  defaultValue,
  errorMessage,
  isDisabled,
  onBlur,
  maxMenuHeight = 150,
  menuPlacement = 'auto',
  isSearchable,
  onInputChange,
  ...props
}) => {
  return (
    <div
      className={`pi-select ${className} ${shadow ? 'pi-shadow' : ''} ${
        errorMessage !== '' ? 'pi-invalid' : ''
      }`}
    >
      {label && <label className='pi-field-label'>{label}</label>}
      <Select
        {...props}
        blurInputOnSelect={false}
        id='select-input'
        name={name}
        isDisabled={isDisabled}
        className='react-select-container'
        classNamePrefix='react-select'
        styles={SelectCustomStyles}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        options={options}
        defaultValue={defaultValue}
        onBlur={onBlur}
        menuPlacement={menuPlacement}
        maxMenuHeight={maxMenuHeight}
        isSearchable={isSearchable}
        onInputChange={onInputChange}
      />
      {errorMessage !== '' && <p className='pi-error-message'>{errorMessage}</p>}
    </div>
  );
};

export default ReactSelect;
