import {mixed, object, string} from 'yup';
import ERROR_MESSAGES from './messages';
import {
  emailRegExp,
  latitudeRegex,
  longitudeRegex,
  minOneNumberOneUpperCaseLetterAndOneSpecialCharacter,
} from './regexps';

const BaseSchemas = {
  selectField: object().shape({value: mixed(), label: string()}).nullable(),
  latitude: string().matches(latitudeRegex, ERROR_MESSAGES.wrongFormat('latitude')),
  longitude: string().matches(longitudeRegex, ERROR_MESSAGES.wrongFormat('longitude')),
  email: string().matches(emailRegExp, ERROR_MESSAGES.email),
  oldPassword: string().required(ERROR_MESSAGES.required),
  password: string()
    .min(8, ERROR_MESSAGES.min('password'))
    .required(ERROR_MESSAGES.required)
    .matches(minOneNumberOneUpperCaseLetterAndOneSpecialCharacter, ERROR_MESSAGES.password),
};

export default BaseSchemas;
