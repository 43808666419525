import React from 'react';

const Signal = ({
    className='',
    value
}) => {
    let signal = Number(value);
    signal = isNaN(signal) ? 0 : signal;
    signal = Math.round(4 / 100 * signal);

    return (
        <div
            className={`pi-signal-icon ${className}`}
        >
            <i className={`icon-signal pi-active-${signal}`}>
                <span className="path1"/>
                <span className="path2"/>
                <span className="path3"/>
                <span className="path4"/>
            </i>
        </div>
    );
};

export default Signal;