import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Landing from './main';
import ReportMobileStep from './steps';
import {
  getMobileLocation,
  getMobileLocationUsers,
} from '../../../impact-detection/redux/actions/mobile/locationLandingPageActions';
import {getReferenceTypes} from '../../../impact-detection/redux/actions/reportsActions';
import {getUserPermissions} from 'modules/impact-detection/redux/actions/authActions';

const ReportMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlParams = useParams();

  const {currentStep} = useSelector(state => state.reportMobile);
  const {location, locationUsers} = useSelector(state => state.locationLandingPage);
  const {isAuthenticated} = useSelector(state => state.auth);

  const init = () => {
    if (isAuthenticated) {
      dispatch(getMobileLocation(urlParams.impact_id));
      dispatch(getUserPermissions());
    } else {
      history.replace('/auth/sign-in');
    }
  };

  const locationListener = () => {
    if (location.location_id && !locationUsers.length) {
      dispatch(getMobileLocationUsers(location.location_id));
      dispatch(getReferenceTypes());
    }
  };

  useEffect(init, []);

  useEffect(locationListener, [location]);

  return (
    <div className='rm-body'>
      <div className='rm-content'>
        {/*Main page*/}
        {!currentStep && <Landing />}

        {/*Steps*/}
        {!!currentStep && <ReportMobileStep />}
      </div>
    </div>
  );
};

export default ReportMobile;
