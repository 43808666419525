import React from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';

import impact_landing_logo from '../../../../assets/img/logo/impact_landing_logo.png';
import defaultImage from '../../../../assets/img/devices/Nimble_Device_New.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faClock} from '@fortawesome/free-solid-svg-icons';
import {InfoString} from '../../../../elements';
import moment from 'moment';

const ReportMobileHead = ({warningMessage, addReportButton}) => {
  const {location} = useSelector(state => state.locationLandingPage);

  return (
    <>
      {/*Header (Location, logo)*/}
      <div className='rm-top'>
        <div className='rm-top-location'>Asset</div>
        <div className='rm-top-logo'>
          <NavLink to='/maps' target='_blank'>
            <img src={impact_landing_logo} alt='' />
          </NavLink>
        </div>
      </div>
      {/*User information*/}
      <div className='rm-userInfo'>
        <div className='rm-userInfo-top'>
          {/*User Avatar*/}

          <div
            style={{
              backgroundImage: `url("${location.image_url || defaultImage}")`,
            }}
            className='rm-user-avatar'
          />

          {/*Add report button*/}
          {addReportButton && <button className='rm-userInfo-addReport'>Add report</button>}

          {/*User name and id*/}
          <div className='rm-user-name'>{location.name}</div>
        </div>

        {/*Warning message*/}
        {warningMessage && (
          <>
            <div className='rm-warning-message'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              Has detected an impact
            </div>
            <div className='rm-date-time'>
              <InfoString
                icon='event'
                label='Impact Date'
                text={moment.unix(location.event_date).format('MM/DD/YY h:mm A')}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ReportMobileHead;

