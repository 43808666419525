import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Logo from 'assets/img/logo/logo.png';
import {
  ReactSelect,
  Button,
  RadioButton,
  TextField,
  TextArea,
  PhoneNumber,
  FilterTag,
} from 'elements';
import {
  automatedReports,
  reportsPeriod,
  trackMovements,
  notifIcons,
  NotificationsObj,
} from './utils';
import {useFormik} from 'formik';
import {
  useGetCompaniesQuery,
  useLazyGetCompaniesSettingsQuery,
  useLazyGetCompanyDevicesLengthQuery,
} from '../../services';
import {useSubmitCompanySettingsMutation} from 'modules/shared/services';
import {CircularProgress} from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';

import {toastr} from 'react-redux-toastr';
import {SearchWithSelect} from 'components/SearchWithSelect';
import {BsFillPlusCircleFill} from 'react-icons/bs';

import {PiLinkDevicesModal} from './Components/DevicesModal';
import {CreateCompanyModal} from './Components/CreateCompanyModal';

import * as yup from 'yup';

import classes from './piLinkRecords.module.scss';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import BaseSchemas from 'core/services/schemas/BaseSchemas';

const EmailValidation = BaseSchemas.email;
const PhoneValidation = yup.string().phone(undefined, true, 'Phone number is not valid.');

const validationSchema = yup.object().shape({
  message: yup.string().max(40, 'The Notification text can not be over 40 characters.'),
  messagesAndTypes: yup.array().of(
    yup.object().shape({
      message: yup.string().max(40, 'The Alert Message can not be over 40 characters.'),
    }),
  ),
});

export const unitOptions = [
  {
    label: 'km',
    value: 'kilometer',
  },
  {
    label: 'mi',
    value: 'mile',
  },
  {
    label: 'm',
    value: 'meter',
  },
  {
    label: 'ft',
    value: 'feet',
  },
];

const unitsObj = {
  kilometer: {label: 'km', value: 'kilometer'},
  mile: {label: 'mi', value: 'mile'},
  meter: {label: 'm', value: 'meter'},
  feet: {label: 'ft', value: 'feet'},
};
const units = Object.values(unitsObj);

const PiLinkRecords = () => {
  const history = useHistory();
  const userHasAccess = useSelector(store => store.auth?.data?.is_pivar_admin);
  const [submitSettings, result] = useSubmitCompanySettingsMutation();

  const [namespace, setNameSpace] = useState();
  const [search, setSearch] = useState('');
  const [devicesLength, setDevicesLength] = useState();
  const [isDevicesListIsOpen, setIsDevicesListIsOpen] = useState(false);

  const [companyNotFound, setCompanyNotFound] = useState(null);

  const [tempErrors, setTempErrors] = useState({email: '', phone: ''});

  const {values, errors, setFieldValue, setValues, handleSubmit, setFieldError, resetForm} =
    useFormik({
      initialValues: {
        //inputs
        default_message: '',
        isReportAutomated: '',
        email: '',
        phone: undefined,
        geofenceDistance: '',
        geofenceUnit: '',

        emails: [],
        emailFrequency: [],
        message: '',
        icon: '',
        phoneNumbers: [],
        messagesAndTypes: [],
      },
      validationSchema,
      async onSubmit(values) {
        const body = {
          icon: values.icon,
          message: values.message,
          pi_link_alert_numbers: values.phoneNumbers,
          emails_and_frequencies: {},
          report_intervals: values.isReportAutomated === 'No' ? [] : values.emailFrequency,
          messages_and_types: values.messagesAndTypes.map(item => ({
            type: item.type.value,
            message: item.message,
          })),
          namespace: namespace?.value,
          geofence_unit: values.geofenceUnit?.value,
          geofence_distance: values.geofenceDistance,
        };

        if (values.isReportAutomated === 'Yes') {
          values.emails.forEach(email => {
            body.emails_and_frequencies[email] = values.emailFrequency;
          });
        }

        submitSettings(body).then(result => {
          if (result.data) {
            toastr.success('Account settings succussfuly modified.');
            if (values.emails.length === 0) {
              setFieldValue('emails', []);
            }
            setFieldValue('email', '');
          }

          if (result.error) {
            toastr.error('Account settings modification failed.');
          }
        });
      },
    });

  const {data, isLoading} = useGetCompaniesQuery();

  const [trigger] = useLazyGetCompaniesSettingsQuery(namespace?.value, {
    selectFromResult: result => result.isLoading,
  });

  const [triggerDevicesLength, devicesLengthResponse] = useLazyGetCompanyDevicesLengthQuery({
    selectFromResult: result => {
      if (
        result.isFetching ||
        !result.isSuccess ||
        result.isLoading ||
        result.data?.pi_link_count === undefined
      ) {
        return {length: devicesLength};
      }

      return {
        length: result.data.pi_link_count,
      };
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setFieldValue('phone', '+1');
    }, 100);
    setTimeout(() => {
      setFieldValue('phone', '');
    }, 200);
  }, []);

  useEffect(() => {
    setDevicesLength(devicesLengthResponse?.length);
  }, [devicesLengthResponse.length]);

  useEffect(() => {
    if (!userHasAccess) {
      toastr.error('Access denied!', 'You don\'t have permission to access this page. ', {
        timeOut: 5000,
        onHideComplete: () => {
          history.push('/maps');
        },
      });
    }
  }, [userHasAccess]);

  const companies = useMemo(
    () => data?.map(v => ({label: v.company, value: v.namespace})) ?? [],
    [data],
  );

  const handleSelectCompany = useCallback(
    async (selected, reset = true) => {
      if (reset) {
        handleResetForm();
      }
      setNameSpace(selected);
      trigger(selected.value, false).then(result => {
        const newValues = {
          icon: result.data.icon,
          message: result.data.message,
          emails: Object.keys(result.data?.emails_and_frequencies),
          emailFrequency: result.data.report_intervals,
          phoneNumbers: result.data.pi_link_alert_numbers,
          messagesAndTypes: result.data.messages_and_types.map((v, i) => ({
            id: uniqueId('messagesAndTypes'),
            message: v.message,
            type: NotificationsObj[v.haas_location_type],
          })),

          geofenceDistance: result.data.geofence_distance,
          geofenceUnit: unitsObj[result.data.geofence_unit],
          default_message: result.data.default_message,
        };
        newValues.isReportAutomated = newValues.emails.length ? 'Yes' : 'No';

        setValues(newValues);
      });

      triggerDevicesLength(selected.value, false);
    },
    [setFieldValue],
  );
  const handleUpdateDevicesLenght = namespace => {
    triggerDevicesLength(namespace, false);
  };

  const handlePhoneNumberValues = (phone, country, _, formmattedValue) => {
    try {
      setFieldValue('phone', phone.length ? formmattedValue : '');
      PhoneValidation.validateSync(phone.length ? formmattedValue : '');
      handleSetTempError('phone', '');
    } catch (e) {
      handleSetTempError('phone', phone.length ? e.message : '');
    }
  };

  const handleValueChange = event => {
    setFieldValue(event.target.name, event.target.value);
  };
  const handleUnitChange = value => {
    setFieldValue('geofenceUnit', value);
  };

  const handleChangeToggle = useCallback(
    event => {
      const name = event.target.name;
      const value = event.target.value;
      const field = values[name];

      setFieldValue(
        name,
        field.includes(value) ? field.filter(e => e !== value) : [...field, value],
      );
    },
    [values, setFieldValue],
  );

  const handleSetTempError = (key, message) => {
    setTempErrors(state => ({...state, [key]: message}));
  };

  const handleAddEmail = useCallback(async () => {
    try {
      if (!values.email) {
        return;
      }
      if (values.emails.includes(values.email)) {
        setFieldError('email', 'Email address has already been added');
        handleSetTempError('email', 'Email address has already been added');
        return;
      }

      EmailValidation.validateSync(values.email);

      setFieldError('email', null);
      handleSetTempError('email', null);
      setFieldValue('emails', [...values.emails, values.email]);
      setFieldValue('email', '');
    } catch (e) {
      setFieldError('email', e.message);
      handleSetTempError('email', e.message);
    }
  }, [values.email, values.emails, setFieldValue, setFieldError]);

  const handleAddEmailWithEnter = useCallback(
    async event => {
      if (event.key !== 'Enter') {
        return;
      }
      try {
        if (!values.email) {
          return;
        }
        if (values.emails.includes(values.email)) {
          setFieldError('email', 'Email address has already been added');
          handleSetTempError('email', 'Email address has already been added');
          return;
        }

        EmailValidation.validateSync(values.email);

        setFieldError('email', null);
        handleSetTempError('email', null);
        setFieldValue('emails', [...values.emails, values.email]);
      } catch (e) {
        setFieldError('email', e.message);
        handleSetTempError('email', e.message);
      }
    },
    [values.email, values.emails, setFieldValue, setFieldError],
  );

  const handleRemoveEmail = useCallback(
    ({value}) => {
      if (values.emails.length === 1) {
        setFieldValue('emailFrequency', []);
      }
      setFieldValue(
        'emails',
        values.emails.filter(v => v !== value),
      );
    },
    [values.emails, setFieldValue],
  );

  const handleAddPhone = useCallback(() => {
    if (!values.phone) {
      setFieldError('phone', 'Please write a phone number');
      handleSetTempError('phone', 'Please write a phone number');
      return;
    }

    if (values.phoneNumbers.includes(values.phone)) {
      setFieldError('phone', 'Phone has already been added');
      handleSetTempError('phone', 'Phone has already been added');
      return;
    }

    setFieldError('phone', null);
    handleSetTempError('phone', null);
    setFieldValue('phoneNumbers', [...values.phoneNumbers, values.phone]);
    setFieldValue('phone', '');
  }, [values.phone, values.phoneNumbers, setFieldValue, setFieldError]);

  const handleAddPhoneWithEnter = useCallback(
    event => {
      if (event.key !== 'Enter') {
        return;
      }
      if (!values.phone) {
        setFieldError('phone', 'Please write a phone number');
        return;
      }
      if (values.phoneNumbers.includes(values.phone)) {
        setFieldError('phone', 'Phone has already been added');
        return;
      }

      setFieldError('phone', null);
      setFieldValue('phoneNumbers', [...values.phoneNumbers, values.phone]);
    },
    [values.phone, values.phoneNumbers, setFieldValue, setFieldError],
  );

  const handleRemovePhone = useCallback(
    ({value}) => {
      setFieldValue(
        'phoneNumbers',
        values.phoneNumbers.filter(v => v !== value),
      );
    },
    [values.phoneNumbers, setFieldValue],
  );

  const handleAddNewMessageWithType = useCallback(() => {
    setFieldValue('messagesAndTypes', [
      ...values.messagesAndTypes,
      {
        id: uniqueId('messagesAndTypes'),
        type: notifIcons[0],
        message: '',
      },
    ]);
  }, [setFieldValue, values.messagesAndTypes]);

  const handleRemoveMessageWithType = useCallback(
    event => {
      const id = event.currentTarget.dataset.id;

      setFieldValue(
        'messagesAndTypes',
        values.messagesAndTypes.filter(item => item.id !== id),
      );
    },
    [setFieldValue, values.messagesAndTypes],
  );

  const handleChangeMessageTypeValue = event => {
    const index = event.target.dataset.index;
    const arr = [...values.messagesAndTypes];

    arr[index] = {
      ...arr[index],
      message: event.target.value,
    };

    setFieldValue('messagesAndTypes', arr);
  };

  const handleChangeMessageType = index => value => {
    const arr = [...values.messagesAndTypes];

    arr[index] = {
      ...arr[index],
      type: value,
    };
    setFieldValue('messagesAndTypes', arr);
  };

  const handleOpenDeviceDetails = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    setIsDevicesListIsOpen(state => !state);
  }, []);

  const handleCreateCompany = useCallback(value => {
    setCompanyNotFound(value);
    document.activeElement.blur();
  }, []);

  const handleCloseCreateCompanyModal = useCallback(result => {
    if (result.namespace) {
      handleSelectCompany({label: result.namespace, value: result.namespace});
    }
    setCompanyNotFound(null);
  }, []);

  const _reportsItemRender = useMemo(() => {
    return reportsPeriod.map(radio => {
      const value = radio.toUpperCase();
      const isChecked = values.emailFrequency.includes(value);

      return (
        <li key={value}>
          <RadioButton
            className='pi-width-sm pi-active-dark'
            type='checkbox'
            name={'emailFrequency'}
            labelId={`emailFrequency_${radio}`}
            label={radio}
            value={value}
            disabled={!values.emails.length}
            selectedValue={isChecked && value}
            onChange={handleChangeToggle}
          >
            {radio}
          </RadioButton>
        </li>
      );
    });
  }, [handleChangeToggle, values.emailFrequency, values.emails]);

  const handleResetForm = () => {
    resetForm();
    setTempErrors({email: '', phone: ''});
  };

  const handleCancel = useCallback(() => {
    handleResetForm();
    setNameSpace('');
    setSearch('');
    setDevicesLength(undefined);
  }, []);

  return (
    <div className='pr-wrapper overflow-auto'>
      <div className='pr-header'>
        <div className='pr-container'>
          <div className='pr-title'>
            <img src={Logo} alt='Pilit logo' />
            <h2>Companies</h2>
          </div>
          <div className='pr-search flex flex-wrap'>
            <SearchWithSelect
              className='min-w-[300px]'
              handleSelect={handleSelectCompany}
              options={companies}
              isLoading={isLoading}
              placeholder='Search by company name'
              notFoundTitle={'company'}
              onCreate={handleCreateCompany}
              search={search}
              onSearchValueChange={setSearch}
              value={namespace}
              rednerRightAction={
                devicesLength >= 0 ? (
                  <div
                    className={'pr-device-count cursor-pointer'}
                    onClick={handleOpenDeviceDetails}
                  >
                    <i className='icon-devices' />
                    <span>({devicesLength})</span>
                  </div>
                ) : null
              }
            />
            <Button
              className={'pi-width-sm flex items-center justify-evenly gap-2'}
              styleType={'filled'}
              color={'white'}
              onClick={() => handleCreateCompany('')}
            >
              <BsFillPlusCircleFill color='rgba(250, 165, 40, 1)' size={'20px'} />
              ADD COMPANY
            </Button>
          </div>
        </div>
      </div>

      <div className='pr-content'>
        <div className='pr-container'>
          {!namespace ? (
            <h2 className={classes.selectMessageHelper}>Choose or Create Company to Continue</h2>
          ) : (
            <>
              <div className='pr-group-row'>
                <label className='pi-field-label mr-3'>Automated reports</label>
                <ul className='pr-radio-group'>
                  {automatedReports.map(radio => (
                    <li key={radio}>
                      <RadioButton
                        className='pi-width-sm pi-active-dark'
                        type='checkbox'
                        name={'isReportAutomated'}
                        labelId={`automatedReports-${radio}`}
                        label={radio}
                        value={radio}
                        selectedValue={values.isReportAutomated}
                        onChange={handleValueChange}
                      >
                        {radio}
                      </RadioButton>
                    </li>
                  ))}
                </ul>
              </div>

              {values.isReportAutomated === 'Yes' && (
                <div className='pr-group-row'>
                  <TextField
                    label={'What emails to send reports to'}
                    type={'email'}
                    name='email'
                    placeholder={'Enter email'}
                    value={values.email}
                    icon={'circle-plus'}
                    className={'pi-right-icon w-[400px] max-w-[90%]'}
                    onChange={handleValueChange}
                    iconHandler={values.email ? handleAddEmail : undefined}
                    iconClassName={values.email ? 'cursor-pointer' : 'opacity-50'}
                    errorMessage={errors.email || tempErrors.email}
                    onKeyPress={handleAddEmailWithEnter}
                  />

                  <ul
                    className={`pr-selected-tags pi-tags ${
                      errors.email?.length || tempErrors.email ? '!mt-[25px]' : ''
                    }`}
                  >
                    {values.emails.map(item => (
                      <li key={item}>
                        <FilterTag tag={{value: item}} removeFilter={handleRemoveEmail} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {values.isReportAutomated === 'Yes' && (
                <div className='pr-group-row'>
                  <label className='pi-field-label mr-3'>How often do you want reports?</label>
                  <ul className='pr-radio-group'>{_reportsItemRender}</ul>
                  {!values.emails.length ? (
                    <p className='!mt-2 text-gray-500 uppercase text-xs font-medium'>
                      * Add an email to enable the options
                    </p>
                  ) : null}
                </div>
              )}

              <hr className={'pi-dashed'} />

              <div className='pr-group-row flex gap-2'>
                <TextField
                  label='Geofence distance'
                  type='number'
                  placeholder='0.00'
                  name='geofenceDistance'
                  value={values.geofenceDistance}
                  onChange={handleValueChange}
                />
                <ReactSelect
                  label='Unit'
                  options={units}
                  placeholder={'km'}
                  value={values.geofenceUnit}
                  onChange={handleUnitChange}
                  className='min-w-[100px]'
                />
              </div>

              <hr className={'pi-dashed'} />
              <div className='pr-group-row'>
                <TextArea
                  className={errors.message ? '!mb-[25px]' : 'mb-20'}
                  label='Custom Notification'
                  pilink
                  placeholder={values.default_message}
                  value={values.message}
                  onChange={handleValueChange}
                  name='message'
                  errorMessage={errors.message}
                />
                <ul className='fl-notifs fl-no-border'>
                  {trackMovements.map(item => (
                    <li key={item.value}>
                      <RadioButton
                        size='sm'
                        iconSize='md'
                        type='checkbox'
                        name={'icon'}
                        value={item.value}
                        labelId={item.labelId}
                        label={item.label}
                        icon={item.icon}
                        onChange={handleValueChange}
                        selectedValue={values.icon}
                        className={'pi-padding-xs pi-active-dark'}
                      />
                    </li>
                  ))}
                </ul>
              </div>

              <hr className={'pi-dashed'} />

              <div className='pr-group-row'>
                <div className='w-[400px] max-w-[90%]'>
                  <PhoneNumber
                    icon={'circle-plus'}
                    iconHandler={handleAddPhone}
                    className={`pi-right-icon ${
                      errors.phone || tempErrors.phone ? '!mb-[22px]' : undefined
                    }`}
                    label='Subscription list of phone numbers to receive text messages on account activations'
                    value={values.phone}
                    onChange={handlePhoneNumberValues}
                    errorMessage={errors.phone || tempErrors.phone}
                    onKeyPress={handleAddPhoneWithEnter}
                  />
                </div>

                <ul className='pr-selected-tags pi-tags'>
                  {values.phoneNumbers.map(item => (
                    <li key={item}>
                      <FilterTag tag={{value: item}} removeFilter={handleRemovePhone} />
                    </li>
                  ))}
                </ul>
              </div>

              <hr className={'pi-dashed'} />

              <div className='pr-group-row'>
                <label className='pi-field-label mr-3 pi-flex pi-items-center'>
                  <span className={'pr-10'}>Custom Alert Messages</span>
                  <div onClick={handleAddNewMessageWithType} className='cursor-pointer'>
                    <i className='icon-circle-plus pi-font-size-xl pi-color-primary' />
                  </div>
                </label>

                {values.messagesAndTypes.map((item, index) => {
                  return (
                    <div className='pr-custom-createble mt-15' key={item.id}>
                      <ReactSelect
                        placeholder='Notifs'
                        options={notifIcons}
                        value={values.messagesAndTypes[index]?.type}
                        onChange={handleChangeMessageType(index)}
                        isSearchable={false}
                      />
                      <TextField
                        type={'text'}
                        placeholder={'Enter alert message here'}
                        value={values.messagesAndTypes[index]?.message}
                        data-index={index}
                        onChange={handleChangeMessageTypeValue}
                        errorMessage={errors.messagesAndTypes?.[index]?.message}
                        className={errors.messagesAndTypes?.[index]?.message ? '!mb-1' : undefined}
                        relativeErrorMessage
                      />
                      <div
                        onClick={handleRemoveMessageWithType}
                        data-id={item.id}
                        className='h-full flex pt-2'
                      >
                        <i className='icon-circle-remove pi-font-size-24 pi-color-red' />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {namespace && (
          <div className={classes.footer}>
            <div className={classes.actionBar}>
              <Button
                className={'pi-width-sm items-center justify-center flex'}
                styleType={'outline'}
                color={'gray'}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className={`pi-width-sm items-center justify-center flex ${classes.submit}`}
                styleType={'filled'}
                color={'white'}
                onClick={isLoading ? undefined : handleSubmit}
                disabled={!namespace || errors.message || errors.messagesAndTypes}
              >
                {result.isLoading && <CircularProgress size={20} color='white' className='mx-2' />}
                Save
              </Button>
            </div>
          </div>
        )}
      </div>

      <PiLinkDevicesModal
        namespace={namespace?.value}
        onClose={handleOpenDeviceDetails}
        visible={isDevicesListIsOpen}
        updateDevicesLength={handleUpdateDevicesLenght}
      />

      <CreateCompanyModal
        company={companyNotFound}
        visible={companyNotFound !== null}
        onClose={handleCloseCreateCompanyModal}
      />

      {!userHasAccess && (
        <div className='absolute w-full h-full left-0 right-0 bottom-0 top-0 !z-50' />
      )}
    </div>
  );
};

export default PiLinkRecords;
