import {apiRequest} from 'core/api/apiRequest';
const {createAsyncThunk} = require('@reduxjs/toolkit');

export const getImpactDetectionMapItems = createAsyncThunk(
  'impactDetectionMap/fetchMapData',
  async ({params} = {}) => {
    const requestTime = new Date().getTime();
    const response = await apiRequest('api/v0/dashboard/maps', 'get', params);

    // to use binary search for updating the items as fast as possible
    const sortedData = response.data.sort((a, b) => {
      const aId = a.location_id || a.device_id;
      const bId = a.location_id || b.device_id;

      return aId - bId;
    });

    return {
      ...response,
      data: sortedData,
      lastRequestAt: requestTime,
      lastRequestParams: params,
    };
  },
);

export const updateImpactDetectionMapItemsRequest = createAsyncThunk(
  'impactDetectionMap/fetchMapData',
  async (payload, thunkApi) => {
    const {
      lastRequestAt,
      lastRequestParams,
      data: prevMapData,
    } = thunkApi.getState().impactDetectionMap;

    // get the last params used to filter map data
    const data = payload?.params ?? lastRequestParams ?? {};
    const requestTime = new Date().getTime();

    const params = {...data};

    if (data.asset_type) {
      params.asset_type = data?.asset_type?.trim?.();
    }

    if (lastRequestAt) {
      params.after_time = lastRequestAt;
    }

    // send request to get the map data updated after now with the last filtering params
    const response = await apiRequest('api/v0/dashboard/maps', 'get', params);

    //make a list of id to check which items got updated
    const responseItems = response.data.reduce((list, item) => {
      item.location_id && list.push(item.location_id);
      item.device_id && list.push(item.device_id);
      return list;
    }, []);

    // add some specific id to exlude it from the map items
    payload?.idToExclude && responseItems.push(payload.idToExclude);

    // add the updated items
    const items = [...response.data];

    // add the prev item that didn't get updated to the list of items
    prevMapData.forEach(v => {
      if (
        (v?.location_id && responseItems.includes(v.location_id)) ||
        (v?.device_id && responseItems.includes(v.device_id))
      ) {
        // we're adding undefined to force the mapa component to rerender the items
        return items.push(undefined);
      }
      items.push(v);
    });

    return {
      ...response,
      data: items,
      lastRequestAt: requestTime,
      lastRequestParams: params,
    };
  },
);

const findItemIndex = (arr, x, start, end) => {
  if (start > end) return false;
  let mid = Math.floor((start + end) / 2);

  if (arr[mid]?.location_id === x || arr[mid]?.device_id === x) return mid;
  if (arr[mid]?.location_id > x) return findItemIndex(arr, x, start, mid - 1);
  else if (arr[mid]?.location_id === undefined && arr[mid]?.device_id > x)
    return findItemIndex(arr, x, start, mid - 1);
  else return findItemIndex(arr, x, mid + 1, end);
};

export const updateImpactDetectionMapItem = createAsyncThunk(
  'impactDetectionMap/updateItem',
  (payload, thunkApi) => {
    const data = [...thunkApi.getState().impactDetectionMap.data];

    const itemIndex = data.findIndex(
      v =>
        (v.location_id && v.location_id === payload.location_id) ||
        (v.device_id && v.device_id === payload.device_id),
    );

    if (itemIndex >= 0) {
      data[itemIndex] = {...payload};
    }
    return data;
  },
);

export const deleteImpactDetectionMapItem = createAsyncThunk(
  'impactDetectionMap/deleteItem',
  (payload, thunkApi) => {
    const data = [...thunkApi.getState().impactDetectionMap.data];
    const itemIndex = findItemIndex(data, payload.location_id || payload.device_id, 0, data.length);
    if (itemIndex >= 0) {
      data[itemIndex] = undefined;
    }
    return data;
  },
);
