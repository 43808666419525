import React, {useCallback, useEffect, useMemo, useState} from 'react';

//style
import classes from './DevicesModal.module.scss';

//api
import {useGetCompanyDevicesQuery, useRegisterDeviceMutation} from 'modules/shared/services';

//components
import SearchBar from 'components/SearchAndFilter/SearchBar';
import {Button, Table, TextField} from 'elements';
import {TextCell, URLCell} from 'elements/TableCells';

import {Modal} from 'components/Modal';
import {BsFillPlusCircleFill} from 'react-icons/bs';
import {toastr} from 'react-redux-toastr';
import {useFormik} from 'formik';

import {object, string} from 'yup';
import {useHistory} from 'react-router-dom';

import ERROR_MESSAGES from 'core/services/schemas/messages';

const tableRowOptions = history => [
  {
    label: 'name',
    element: URLCell,
    color: 'text',
    getUrl: item => (item.serial_number ? `/pi-link/${item.serial_number}` : undefined),
    singleAction: item => {
      if (item.serial_number) {
        window.open(`/pi-link/${item.serial_number}`, '_blacnk');
      }
    },
  },
  {
    label: 'serial_number',
    element: TextCell,
  },
];

const validationSchema = object().shape({
  serial_number: string().required(ERROR_MESSAGES.required),
});

export const PiLinkDevicesModal = ({namespace, onClose, visible, updateDevicesLength}) => {
  const history = useHistory();
  const [sort, setSort] = useState({
    value: 'name',
    type: 'dsc',
  });
  const [search, setSearch] = useState('');

  const [registerDevice, registerDeviceResult] = useRegisterDeviceMutation();
  const [createDeviceModal, setCreateDeviceModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      serial_number: '',
    },
    validationSchema,
    onSubmit(values) {
      registerDevice({...values, namespace}).then(result => {
        if (result.data) {
          toastr.success('Device registered succussfuly');
          updateDevicesLength(namespace);
          handleCloseCreateDeviceModal(namespace);
          formik.resetForm();
        } else if (result.error) {
          toastr.error(result.error?.data?.message ?? 'Device registeration failed');
        }
      });
    },
  });

  const devices = useGetCompanyDevicesQuery(namespace, {skip: !namespace});

  useEffect(() => {
    if (!visible) {
      setSearch('');
      setSort({
        value: 'name',
        type: 'dsc',
      });
    }
  }, [visible]);

  const tableHeadOptions = useMemo(
    () => [
      {
        type: sort.value === 'name' ? sort.type : 'dsc',
        title: 'Name',
        value: 'name',
        active: sort.value === 'name',
        sorted: false,
        sortAction: true,
      },
      {
        type: sort.value === 'serial_number' ? sort.type : 'dsc',
        sorted: false,
        active: sort.value === 'serial_number',
        sortAction: true,
        title: 'Serial Number',
        value: 'serial_number',
        classes: '!min-w-[50%]',
      },
    ],
    [sort],
  );

  const devicesList = useMemo(() => {
    if (!devices.data?.length) return [];

    const filtered = devices.data.filter(item => {
      const searchLowerCase = search.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchLowerCase) ||
        item.serial_number.toLowerCase().includes(searchLowerCase)
      );
    });

    return filtered.sort((a, b) => {
      const fieldA = (sort.type === 'asc' ? b : a)[sort.value];
      const fieldB = (sort.type === 'asc' ? a : b)[sort.value];

      if (fieldA > fieldB) {
        return 1;
      }

      if (fieldA < fieldB) {
        return -1;
      }

      return 0;
    });
  }, [devices.data, search, sort]);

  const handleSort = sortValue => {
    setSort({...sortValue, type: sortValue.type === 'asc' ? 'dsc' : 'asc'});
  };
  const handleSearch = value => {
    setSearch(value);
  };

  const handleOpenCreateDeviceModal = useCallback(() => {
    setCreateDeviceModal(true);
  }, []);

  const handleCloseCreateDeviceModal = useCallback(() => {
    formik.resetForm();
    setCreateDeviceModal(false);
  }, []);

  return (
    <Modal
      title={'COMPANY DEVICE LIST'}
      onClose={onClose}
      visible={visible}
      className={classes.modal}
      mobileFullScreen
      showCloseIcon
      renderCloseButton={false}
    >
      <div className={classes.header}>
        <SearchBar
          value={search}
          onChange={handleSearch}
          onSearch={() => {}}
          placeholder={'Search'}
          className={classes.search}
        />
        <Button
          className={`pi-width-sm flex items-center justify-evenly gap-2 ${classes.button}`}
          styleType={'filled'}
          color={'white'}
          onClick={handleOpenCreateDeviceModal}
        >
          <BsFillPlusCircleFill color='rgba(250, 165, 40, 1)' size={'20px'} />
          ADD DEVICE
        </Button>
      </div>
      <Table
        size='sm'
        loader={devices.isLoading}
        data={devicesList}
        activeRow={false}
        field='serial_number'
        onSortClick={handleSort}
        tableOptions={tableRowOptions(history)}
        tableHeadings={tableHeadOptions}
        className={classes.table}
      />

      <Modal
        title='ADD DEVICE'
        onSubmit={formik.handleSubmit}
        submitButtonLable='Create'
        closeTitle='Cancel'
        onClose={handleCloseCreateDeviceModal}
        visible={createDeviceModal}
        isSubmitting={registerDeviceResult.isLoading}
        submitDisabled={registerDeviceResult.isLoading || formik.errors.serial_number}
      >
        <TextField
          label='Serial Number *'
          name='serial_number'
          value={formik.values.serial_number}
          onChange={formik.handleChange}
          errorMessage={formik.errors.serial_number}
        />
      </Modal>
    </Modal>
  );
};
