import {GET_WORKZONE_MAP_DEVICES, GET_WORKZONE_MAP_WORKZONES} from '../constants';
import {ASYNC_STATUS} from '../../../impact-detection/redux/constants';

const initialState = {
  workzones: [],
  devices: [],
  message: null,
  getDevicesStatus: ASYNC_STATUS.FAILURE,
  getWorkZonesStatus: ASYNC_STATUS.FAILURE,
};

export default function reducer(state = {...initialState}, action) {
  switch (action.type) {
    case GET_WORKZONE_MAP_DEVICES: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getDevicesStatus: ASYNC_STATUS.REQUEST,
            message: 'Getting map devices...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            devices: action.data,
            getDevicesStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to get map devices.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getDevicesStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }
    case GET_WORKZONE_MAP_WORKZONES: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getWorkZonesStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching map locations list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            getWorkZonesStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the map locations.',
            workzones: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getWorkZonesStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    default:
      return state;
  }
}
