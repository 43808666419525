import React, {useMemo} from 'react';
import {Spinner} from 'reactstrap';
import Graph from '../deviceLineChart';
import {chartColors} from '../activityUtils';
import {buildSensitivityLine} from 'helpers/functions';

function ImpactProfile({currentEvent, magnitudeData, XYZ, sensitivity, minMAxData, isLoading}) {
  const sensitivityData = useMemo(() => {
    if (!sensitivity) {
      return {
        x: '',
        y: '',
        z: '',
      };
    }
    return {
      x: buildSensitivityLine(XYZ.x, sensitivity),
      y: buildSensitivityLine(XYZ.y, sensitivity),
      z: buildSensitivityLine(XYZ.z, sensitivity),
    };
  }, [sensitivity, XYZ]);

  if (currentEvent?.is_fall_event) return null;
  if (isLoading) {
    return (
      <div className='d-flex justify-content-center pi-graph-loader'>
        <Spinner color='primary' type='border' size='lg' />
      </div>
    );
  }

  return (
    <>
      {magnitudeData.length > 0 ? (
        <div className='pi-device-chart'>
          <Graph
            title='Device movement (Magnitude)'
            minY={0}
            color={chartColors.magnitude}
            currentEvent={currentEvent}
            data={magnitudeData}
          />
        </div>
      ) : null}

      {XYZ.x?.length > 0 ? (
        <div className='pi-device-chart'>
          <Graph
            title='Device movement (X)'
            color={chartColors.x}
            minY={minMAxData.min}
            maxY={minMAxData.max}
            currentEvent={currentEvent}
            data={XYZ.x}
            sensitivityData={sensitivityData.x}
          />
        </div>
      ) : null}
      {XYZ.y?.length > 0 ? (
        <div className='pi-device-chart'>
          <Graph
            title='Device movement (Y)'
            color={chartColors.y}
            minY={minMAxData.min}
            maxY={minMAxData.max}
            currentEvent={currentEvent}
            data={XYZ.y}
            sensitivityData={sensitivityData.y}
          />
        </div>
      ) : null}
      {XYZ.z?.length > 0 ? (
        <div className='pi-device-chart'>
          <Graph
            title='Device movement (Z)'
            color={chartColors.z}
            type='Z'
            minY={minMAxData.min}
            maxY={minMAxData.max}
            currentEvent={currentEvent}
            data={XYZ.z}
            sensitivityData={sensitivityData.z}
          />
        </div>
      ) : null}
    </>
  );
}

export default ImpactProfile;
