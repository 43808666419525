import React, {Fragment} from 'react';
import {NoValue} from 'elements';
import IconBtnsCell from '../elements/TableCells/IconBtnsCell';
import IconSingleBtnCell from '../elements/TableCells/IconSingleBtnCell';

const onClickHandler = (cell, item) =>
  (cell.getElement && cell.getElement(item) === IconBtnsCell) ||
  cell.element === IconBtnsCell ||
  cell.element === IconSingleBtnCell
    ? cell.action
    : cell.singleAction;

const buttonsHandler = (cell, item) =>
  (cell.getElement && cell.getElement(item) === IconBtnsCell) || cell.element === IconBtnsCell
    ? cell.actionBtns
    : cell.singleBtn;

const labelHandler = (cell, item) => {
  return cell.labelResolve
    ? cell.labelResolve(item[cell.label]) || <NoValue />
    : cell.secondaryLabel
    ? cell.oneLabel
      ? item[cell.label] || item[cell.secondaryLabel]
      : `${item[cell.label]} ${item[cell.secondaryLabel]}`
    : item[cell.label] || <NoValue />;
};
const tooltipHandler = (cell, item) => {
  return cell.tooltipResolver ? cell.tooltipResolver(item[cell.label]) : cell.hasTooltip;
};

const TableCreator = props => {
  return (
    <>
      {props.data?.length !== 0 ? (
        props.data?.map((item, index) => {
          return (
            <Fragment key={item[props.field] + '_' + index}>
              <tr
                id={item[props.field]}
                onClick={e => props.clickAction && props.clickAction(item[props.field], e)}
                className={`${
                  props.activeRow === item[props.field]
                    ? props.activeSecondary
                      ? ' pi-active'
                      : ' pi-active-primary'
                    : ''
                }`}
              >
                {props.tableOptions.map((cell, indexOp) => {
                  const Element = cell.getElement ? cell.getElement(item) : cell.element;

                  return (
                    <Element
                      {...(cell.elementOptions || {})}
                      data={item}
                      type={cell.type}
                      color={cell.color}
                      extra={cell.extra}
                      refer={cell.refer}
                      path={props.urlPath}
                      url={cell.getUrl ? cell.getUrl(item) : item[props.urlField]}
                      className={cell.className}
                      hasTooltip={tooltipHandler(cell, item)}
                      respHolder={cell.respHolder}
                      searchValue={props.searchValue}
                      label={labelHandler(cell, item)}
                      onClick={onClickHandler(cell, item)}
                      key={`${item[props.field]} ${indexOp}`}
                      actionBtns={buttonsHandler(cell, item)}
                      item={cell.itemResolve && cell.itemResolve(item[cell.label])}
                    >
                      {cell.children}
                      {cell.getChildren && cell.getChildren(item)}
                    </Element>
                  );
                })}
              </tr>
              {props.expanded && props.expanded.init(item)}
            </Fragment>
          );
        })
      ) : (
        <tr>
          {!props.loader && (
            <td className='pi-empty-td' colSpan={props.tableOptions.length}>
              <p className='pi-empty-state'>No results found</p>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default TableCreator;
