import React from 'react';
import {StatisticsItem} from 'elements';

const Statistics = props => {
  const {columns, className, statistics} = props;

  return (
    <div className={`pi-statistics pi-column-${columns} ${className}`}>
      <ul>
        {statistics?.map(item => (
          <li key={item.count}>
            <StatisticsItem
              color={item.color}
              count={item.count}
              title={item.name}
              tooltip={item.tooltip}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Statistics;
