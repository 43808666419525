import React from 'react';
import {FilterTag} from 'elements';

const SelectedFilters = ({selectedFilters, removeFilter, className = ''}) => {
  return (
    <div className={`pi-applied-filters ${className}`}>
      <ul className='pi-tags'>
        {selectedFilters?.map(tag => (
          <li key={tag.label}>
            <FilterTag tag={tag} removeFilter={removeFilter} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedFilters;
