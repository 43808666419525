// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE';
export const SIDEBAR_VISIBILITY_SHOW = 'SIDEBAR_VISIBILITY_SHOW';
export const SIDEBAR_VISIBILITY_HIDE = 'SIDEBAR_VISIBILITY_HIDE';
export const SIDEBAR_STICKY_TOGGLE = 'SIDEBAR_STICKY_TOGGLE';
export const SIDEBAR_STICKY_ENABLE = 'SIDEBAR_STICKY_ENABLE';
export const SIDEBAR_STICKY_DISABLE = 'SIDEBAR_STICKY_DISABLE';

// Layout
export const LAYOUT_BOXED_TOGGLE = 'LAYOUT_BOXED_TOGGLE';
export const LAYOUT_BOXED_ENABLE = 'LAYOUT_BOXED_ENABLE';
export const LAYOUT_BOXED_DISABLE = 'LAYOUT_BOXED_DISABLE';

// Theme
export const THEME_TOGGLE = 'THEME_TOGGLE';

// Constant options
export const WORKSPACES = {
  impactDetection: 'IMPACT_SENSORS',
  workzone: 'WORKZONES',
  flarewaze: 'FLARES',
};

export const UserRoles = [
  {value: 'GLOBAL', label: 'GLOBAL'},
  {value: 'DISPATCHER', label: 'DISPATCHER'},
];
export const NotificationChannels = [
  {value: 'EMAIL', label: 'EMAIL'},
  {value: 'SMS', label: 'SMS'},
  {value: 'PUSH', label: 'Push Notification'},
];

export const TicketStatuses = [
  {value: 'OPEN', label: 'Open'},
  {value: 'IN_PROGRESS', label: 'In Progress'},
  {value: 'COMPLETED', label: 'Completed'},
];

export const TicketTypes = [
  {value: 'MAINTENANCE', label: 'Maintenance'},
  {value: 'INSPECTION', label: 'Inspection'},
];

export const EventStatuses = [
  {value: 'OPEN', label: 'Open'},
  {value: 'IN_PROGRESS', label: 'In Progress'},
  {value: 'CLOSED', label: 'Closed'},
];

export const EventTypes = [
  {value: 'IMPACT', label: 'Impact'},
  {value: 'MAINTENANCE', label: 'Maintenance'},
  {value: 'INSPECTION', label: 'Inspection'},
];

export const ImpactStatuses = {
  INSPECTED: 'INSPECTED',
  ONLINE: 'ONLINE',
  PENDING_INSPECTION: 'PENDING_INSPECTION',
};

export const WzDeviceType = {
  RADAR: {value: 2, name: 'Radar'},
  B_SOLAR_BARRICADE: {value: 1, name: 'B-Solar Barricade'},
  SEQUENTIAL_BARRICADE: {value: 0, name: 'Sequential Barricade'},
};

export const AssetTypes = [{value: 'AIMS', label: 'AIMS'}];

export const DeviceTypes = [
  {value: 1, label: 'Attenuator'},
  {value: 2, label: 'Gateway'},
  {value: 3, label: 'Node'},
];

export const deviceTypesText = {
  TAPER: 'TAPER',
  NODE: 'NODE',
  GATEWAY: 'GATEWAY',
  ATTENUATOR: 'ATTENUATOR',
  //old
  DOT: 'DOT',
  TAPER_GATEWAY: 'TAPER_GATEWAY',
  LAMP: 'LAMP',
};

export const PAGINATION = {
  ACTIVITY_EVENTS: 25,
  DEVICES: 20,
  GROUPS: 20,
  TEAMS_LIST: 20,
  WORKZONES: 25,
  REPORTS: 25,
  LOCATIONS: 25,
};

// Asynchronous action status
export const ASYNC_STATUS = {
  REQUEST: 0,
  SUCCEED: 1,
  FAILURE: 2,
  RESET: 3,
};

// Authentication
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const AUTH_CHECK = 'AUTH_CHECK';
export const SAVE_ROUTE = 'SAVE_ROUTE';

//Authorization
export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';

// User
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USERS_GET_ALL = 'USERS_GET_ALL';
export const USERS_GET_ALL_FOR_TEAM = 'USERS_GET_ALL_FOR_TEAM';
export const GET_USER = 'GET_USER';

// Attenuators
export const ATTENUATORS_GET_ALL = 'ATTENUATORS_GET_ALL';
export const ATTENUATOR_CREATE = 'ATTENUATOR_CREATE';
export const ATTENUATOR_UPDATE = 'ATTENUATOR_UPDATE';
export const ATTENUATOR_EVENT = 'ATTENUATOR_EVENT';
export const ATTENUATOR_UPLOAD = 'ATTENUATOR_UPLOAD';
export const ATTENUATOR_SELECT = 'ATTENUATOR_SELECT';
export const ATTENUATOR_SELECT_EVENT = 'ATTENUATOR_SELECT_EVENT';
export const FILTER_ATTENUATOR_LISTING = 'FILTER_ATTENUATOR_LISTING';
export const NOTE_CREATE = 'NOTE_CREATE';
export const NOTE_UPDATE = 'NOTE_UPDATE';
export const EVENT_UPDATE = 'EVENT_UPDATE';
export const EVENT_CREATE = 'EVENT_CREATE';
export const ADD_INSPECTION_DATE = 'ADD_INSPECTION_DATE';
export const MUTE_DEVICE = 'MUTE_DEVICE';
export const MUTE_CURRENT_DEVICE = 'MUTE_CURRENT_DEVICE';
export const CHANGE_DEVICE_SENSITIVITY = 'CHANGE_DEVICE_SENSITIVITY';
export const UPDATE_BATTERY_NOTIFICATION_LEVEL = 'UPDATE_BATTERY_NOTIFICATION_LEVEL';

// Taper Gateways
export const TAPER_GATEWAYS_GET_ALL = 'TAPER_GATEWAYS_GET_ALL';
export const TAPER_GATEWAY_GET = 'TAPER_GATEWAY_GET';
export const TAPER_GATEWAY_CREATE = 'TAPER_GATEWAY_CREATE';
export const TAPER_GATEWAY_UPDATE = 'TAPER_GATEWAY_UPDATE';
export const UPDATE_TAPER_GATEWAY_BATTERY_NOTIFICATION_LEVEL =
  'UPDATE_TAPER_GATEWAY_BATTERY_NOTIFICATION_LEVEL';
export const MUTE_TAPER_GATEWAY_DEVICE = 'MUTE_TAPER_GATEWAY_DEVICE';
export const UPDATE_TAPER_GATEWAY_DEVICE_LAMP_OPTIONS = 'UPDATE_TAPER_GATEWAY_DEVICE_LAMP_OPTIONS';
export const UPDATE_CONFIRM_STATUS = 'UPDATE_CONFIRM_STATUS';
export const SET_ALARM = 'SET_ALARM';
export const DELETE_ALARM = 'DELETE_ALARM';
export const SEND_REPORT = 'SEND_REPORT';
export const SEND_MAINTENANCE = 'SEND_MAINTENANCE';
export const SEND_DELETE_IMAGES = 'SEN_DELETE_IMAGES';

// Resend
export const RESEND_MAIL = 'RESEND_MAIL';

// Waze Toggle
export const WAZE_TOGGLE = 'WAZE_TOGGLE';

// Events
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_ALL_EVENTS_FOR_REPORTS = 'GET_ALL_EVENTS_FOR_REPORTS';
export const GET_ALL_EVENTS_NEW = 'GET_ALL_EVENTS_NEW';
export const CLEAR_CURSOR = 'CLEAR_CURSOR';
export const CLEAR_REPORTS_CURSOR = 'CLEAR_REPORTS_CURSOR';
export const SET_REPORTS_URL = 'SET_REPORTS_URL';
export const SET_SINGLE_REPORTS_URL = 'SET_SINGLE_REPORTS_URL';
export const GET_ATTENUATOR = 'GET_ATTENUATOR';
export const GET_DEVICE_EVENT = 'GET_DEVICE_EVENT';
export const CLEAR_DEVICE_EVENT = 'CLEAR_DEVICE_EVENT';
export const LOAD_EVENTS = 'LOAD_EVENTS';
export const LOAD_DEVICES = 'LOAD_DEVICES';
export const SEARCH_EVENTS = 'SEARCH_EVENTS';
export const SEARCH_DEVICES = 'SEARCH_DEVICES';
export const SEARCH_GROUPS = 'SEARCH_GROUPS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const CLEAR_DEVICES = 'CLEAR_DEVICES';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_DEVICE_DATA = 'UPDATE_DEVICE_DATA';
export const SET_DEVICES_UPDATED = 'SET_DEVICES_UPDATED';
export const SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT';
export const EMPTY_UPDATED_EVENT_ID = 'EMPTY_UPDATED_EVENT_ID';
export const GET_EVENT_REPORT = 'GET_EVENT_REPORT';
export const CLEAR_EVENT_REPORT = 'CLEAR_EVENT_REPORT';
export const SET_MAINTENANCE_STEP_ACTIVE = 'SET_MAINTENANCE_STEP_ACTIVE';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS';
export const DOWNLOAD_SINGLE_REPORT = 'DOWNLOAD_SINGLE_REPORT';
export const SET_REPORT_URL = 'SET_REPORT_URL';

// Tickets
export const TICKET_UPDATE = 'TICKET_UPDATE';
export const GET_TICKETS_BY_TYPE = 'GET_TICKETS_BY_TYPE';

// Devices
export const CLEAR_GROUP_USER_LIST = 'CLEAR_GROUP_USER_LIST';
export const DEVICES_GET_ALL = 'DEVICES_GET_ALL';
export const DEVICES_GET_ALL_NEW = 'DEVICES_GET_ALL_NEW';
export const DEVICES_GET_ALL_NEW_FOR_EVENTS = 'DEVICES_GET_ALL_NEW_FOR_EVENTS';
export const DEVICES_GET_ALL_FOR_THE_MAP = 'DEVICES_GET_ALL_FOR_THE_MAP';
export const FILTER_DEVICE_LISTING = 'FILTER_DEVICE_LISTING';
export const GET_DEVICE = 'GET_DEVICE';
export const CLEAR_DEVICE = 'CLEAR_DEVICE';
export const GET_DEVICE_FOR_EVENTS = 'GET_DEVICE_FOR_EVENTS';
export const GET_REPORTS = 'GET_REPORTS';
export const UPDATE_ACCELEROMETER_SENSITIVITY = 'UPDATE_ACCELEROMETER_SENSITIVITY';
export const GET_USER_BY_GROUPS = 'GET_USER_BY_GROUPS';
export const GET_DEVICES_MODELS = 'GET_DEVICES_MODELS';
export const GET_LOCATIONS_MANUFACTURERS = 'GET_LOCATIONS_MANUFACTURERS';

// Workzones
export const GET_WORKZONES = 'GET_WORKZONES';
export const LOAD_WORKZONES = 'LOAD_WORKZONES';
export const SEARCH_WORKZONES = 'SEARCH_WORKZONES';
export const CLEAR_WORKZONES = 'CLEAR_WORKZONES';
export const UPDATE_WORKZONE = 'UPDATE_WORKZONE';
export const SET_ACTIVE_WORKZONE = 'SET_ACTIVE_WORKZONE';
export const SET_LAMP_EDITED = 'SET_LAMP_EDITED';
export const SET_LAMP_EDIT_ERROR = 'SET_LAMP_EDIT_ERROR';

// Teams
export const GET_USER_GROUPS = 'GET_USER_GROUPS';
export const GET_USER_GROUPS_NEW = 'GET_USER_GROUPS_NEW';
export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAMS_FOR_REDESIGN = 'GET_TEAMS_FOR_REDESIGN';
export const GET_TEAMS_NEW = 'GET_TEAMS_NEW';
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const SEARCH_TEAMS = 'SEARCH_TEAMS';
export const CLEAR_TEAMS = 'CLEAR_TEAMS';
export const CLEAR_TEAMS_REDESIGN = 'CLEAR_TEAMS_REDESIGN';
export const GET_USER_GROUP = 'GET_USER_GROUP';
export const CREATE_USER_GROUP = 'CREATE_USER_GROUP';
export const EDIT_USER_GROUP_NAME = 'EDIT_USER_GROUP_NAME';
export const SET_USER_GROUP_CREATED = 'SET_USER_GROUP_CREATED';
export const SET_ACTIVE_TEAM = 'SET_ACTIVE_TEAM';
export const SET_ACTIVE_TEAM_REDESIGN = 'SET_ACTIVE_TEAM_REDESIGN';
export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
export const SET_ACTIVE_SUBGROUPS = 'SET_ACTIVE_SUBGROUPS';
export const ADD_USER_GROUP_DATA = 'ADD_USER_GROUP_DATA';
export const SET_USER_GROUP_DATA_ADDED = 'SET_USER_GROUP_DATA_ADDED';
export const DELETE_USER_GROUP_DATA = 'DELETE_USER_GROUP_DATA';
export const GET_TEAMS_DEVICES = 'GET_TEAMS_DEVICES';
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const DISABLE_ADD_BUTTON = 'DISABLE_ADD_BUTTON';
export const CLEAR_USER_GROUPS = 'CLEAR_USER_GROUPS';
export const GET_ONE_TEAM = 'GET_ONE_TEAM';

// Reports
export const GET_EVENT_STATISTICS = 'GET_EVENT_STATISTICS';
export const RESET_REPORTS = 'RESET_REPORTS';
export const GET_REFERENCE_TYPES = 'GET_REFERENCE_TYPES';
export const UPDATE_REPORT_IN_LIST = 'UPDATE_REPORT_IN_LIST';

//Locations
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS';
export const GET_ALL_REPORTS = 'GET_ALL_REPORTS';
export const CLEAR_EVENTS_REDESIGN = 'CLEAR_EVENTS_REDESIGN';
export const GET_ALL_EVENTS_REDESIGN = 'GET_ALL_EVENTS_REDESIGN';
export const GET_LOCATIONS_STATISTICS = 'GET_LOCATIONS_STATISTICS';
export const GET_ONE_LOCATION = 'GET_ONE_LOCATION';
export const CLEAR_ONE_LOCATION = 'CLEAR_ONE_LOCATION';
export const GET_ONE_EVENT = 'GET_ONE_EVENT';
export const CLEAR_ONE_EVENT = 'CLEAR_ONE_EVENT';
export const GET_CURRENT_EVENT_REPORTS = 'GET_CURRENT_EVENT_REPORTS';
export const GET_ONE_LOCATION_HISTORY = 'GET_ONE_LOCATION_HISTORY';
export const GET_TYPES_FOR_HISTORY_FILTER = 'GET_TYPES_FOR_HISTORY_FILTER';
export const GET_USERS_FOR_HISTORY_FILTER = 'GET_USERS_FOR_HISTORY_FILTER';
export const GET_ONE_LOCATION_STATISTIC = 'GET_ONE_LOCATION_STATISTIC';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const GET_TEAM_DATA = 'GET_TEAM_DATA';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_JOB_TITLE = 'SET_JOB_TITLE';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_NOTIFICATION_TYPES = 'SET_NOTIFICATION_TYPES';
export const GET_USER_GROUPS_LOCATIONS = 'GET_USER_GROUPS_LOCATIONS';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const ADD_NEW_USER_TO_GROUP = 'ADD_NEW_USER_TO_GROUP';
export const DELETE_MEMBER_FROM_SUBGROUP = 'DELETE_MEMBER_FROM_SUBGROUP';
export const GET_DEVICES_MODELS_LOCATIONS = 'GET_DEVICES_MODELS_LOCATIONS';
export const LOCATIONS_MANUFACTURERS = 'LOCATIONS_MANUFACTURERS';
export const SELECTED_TEAMS_OPTIONS = 'SELECTED_TEAMS_OPTIONS';
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const SET_TEAM_TABLE_DATA = 'SET_TEAM_TABLE_DATA';
export const CHECK_EMAIL_EXISTS = 'CHECK_EMAIL_EXISTS';
export const DELETE_LOCATION_IMAGE = 'DELETE_LOCATION_IMAGE';

// filter
export const SELECT_DEVICE_FILTERS = 'SELECT_DEVICE_FILTERS';
export const SELECT_HISTORY_FILTERS = 'SELECT_HISTORY_FILTERS';
export const UPDATE_ONE_LOCATION_HISTORY = 'UPDATE_ONE_LOCATION_HISTORY';
export const GET_LOCATION_USERS = 'GET_LOCATION_USERS';

// Mobile
export const GET_MOBILE_LOCATION = 'GET_MOBILE_LOCATION';
export const RESET_MOBILE_LOCATION = 'RESET_MOBILE_LOCATION';
export const MOBILE_REPORT_FORM_RESET = 'MOBILE_REPORT_FORM_RESET';
export const MOBILE_REPORT_ADD_REPORT_FORM = 'MOBILE_REPORT_ADD_REPORT_FORM';
export const MOBILE_REPORT_FORM_ARRAY_CHANGE = 'MOBILE_REPORT_FORM_ARRAY_CHANGE';
export const MOBILE_REPORT_REMOVE_REPORT_FORM = 'MOBILE_REPORT_REMOVE_REPORT_FORM';
export const MOBILE_REPORT_ADD_COST_FORM = 'MOBILE_REPORT_ADD_COST_FORM';
export const MOBILE_REPORT_REMOVE_COST_FORM = 'MOBILE_REPORT_REMOVE_COST_FORM';
export const MOBILE_REPORT_SUBMIT = 'MOBILE_REPORT_SUBMIT';

export const MOBILE_REPORT_STEP_CHANGE = 'MOBILE_REPORT_STEP_CHANGE';
export const MOBILE_REPORT_FORM_CHANGE = 'MOBILE_REPORT_FORM_CHANGE';

export const MOBILE_REPORT_IMAGES_UPLOAD = 'MOBILE_REPORT_IMAGES_UPLOAD';

//Activity signin
export const GET_USERS_LIST_ACTIVITY = 'GET_USERS_LIST_ACTIVITY';
export const ADD_NEW_REPORT = 'ADD_NEW_REPORT';
export const SET_REPORT_ADDED = 'SET_REPORT_ADDED';
export const GET_ACTIVITY_REFERENCE_TYPES = 'GET_ACTIVITY_REFERENCE_TYPES';
export const CLEAR_CURRENT_EVENT_REPORTS = 'CLEAR_CURRENT_EVENT_REPORTS';
export const SET_CURRENT_EVENT_GRAPH_DATA = 'SET_CURRENT_EVENT_GRAPH_DATA';

//Teams signin
export const GET_TEAM = 'GET_TEAM';
export const GET_TEAM_GROUPS = 'GET_TEAM_GROUPS';
export const GET_TEAM_LIST = 'GET_TEAM_LIST';
export const GET_TEAM_LIST_OLD = 'GET_TEAM_LIST_OLD';
export const GET_GROUP = 'GET_GROUP';
export const GET_SUBGROUP = 'GET_SUBGROUP';
export const EDIT_GROUP = 'EDIT_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_TEAM = 'ADD_TEAM';
export const ADD_NEW_MEMBER = 'ADD_NEW_MEMBER';
export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION';
export const GET_USERS_LIST_TEAM = 'GET_USERS_LIST_TEAM';
export const GET_LOCATIONS_LIST_TEAM = 'GET_LOCATIONS_LIST_TEAM';
export const GET_ASSET_TYPES = 'GET_ASSET_TYPES';
export const GET_MODELS = 'GET_MODELS';
export const GET_MANUFACTURERS = 'GET_MANUFACTURERS';
export const INVITATION_MAIL = 'INVITATION_MAIL';
export const SET_HEADING = 'SET_HEADING';

export const GET_CURRENT_EVENT_GRAPH_DATA = 'GET_CURRENT_EVENT_GRAPH_DATA';
