import React from 'react';
import {Modal} from 'reactstrap';
import {Button} from 'elements';
import {ASYNC_STATUS} from 'modules/impact-detection/redux/constants';

const RemoveModal = ({
  text,
  title,
  status,
  isOpened,
  disabled,
  closeHandler,
  confirmAction,
  note,
}) => {
  return (
    <Modal
      className='pi-modal pi-wz-modal'
      isOpen={isOpened}
      onClose={closeHandler}
      style={{maxWidth: '500px'}}
    >
      <div className='pi-modal-header'>
        <h4>{title}</h4>
        <button className='pi-close' onClick={closeHandler}>
          <i className='icon-close' />
        </button>
      </div>
      <div className='pi-modal-body'>
        <p>{text}</p>
        {note && <p className='pi-text-note mt-10'>{note}</p>}
      </div>
      <div className='pi-modal-footer'>
        <div className='pi-block-footer'>
          <Button
            styleType='outline'
            color='white'
            className='pi-width-sm'
            onClick={closeHandler}
          >
            No
          </Button>
          <Button
            styleType='filled'
            color='gray'
            className='pi-width-sm'
            onClick={confirmAction}
            disabled={status === ASYNC_STATUS.REQUEST || disabled}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveModal;
