import React, {useCallback, useDeferredValue, useMemo, useState, useEffect} from 'react';

import {getYMinMax} from 'helpers/functions';
import {
  useGetGraphDataAccelerometerQuery,
  useGetGraphDataMagnitudeQuery,
} from 'modules/impact-detection/services';
import classes from './activityCharts.module.scss';
import ImpactProfile from './ImpactProfile';
import SummaryTab from './SummaryTab';
import {Spinner} from 'reactstrap';

function ActivityCharts({currentEvent}) {
  const [activeTab, setActiveTab] = useState('');
  const renderTab = useDeferredValue(activeTab);

  const sensitivity = currentEvent?.sensitivity > 0 ? currentEvent?.sensitivity : null;

  const magnitudeQuery = useGetGraphDataMagnitudeQuery(currentEvent?.event_id, {
    skip: !currentEvent?.event_id,
    selectFromResult: ({data, isLoading}) => {
      return {
        data: data?.magnitude_data || [],
        counts: data?.counts ?? undefined,
        isLoading,
      };
    },
  });

  const {XYZ, minMax, isLoading} = useGetGraphDataAccelerometerQuery(currentEvent?.event_id, {
    skip: !currentEvent?.event_id,
    selectFromResult: ({data, isLoading}) => {
      const accelData = data?.accelerometer_data ?? [];

      const XYZ = {
        x: [],
        y: [],
        z: [],
      };
      accelData.forEach(arr => {
        arr.length &&
          arr.forEach(item => {
            XYZ.x.push(item.x);
            XYZ.y.push(item.y);
            XYZ.z.push(item.z);
          });
      });

      const minMax = getYMinMax(Object.values(XYZ).flat(), sensitivity);

      return {XYZ, minMax, isLoading};
    },
  });

  const impactChartsDisabled =
    currentEvent?.is_fall_event ||
    (!magnitudeQuery.data.length && !XYZ.y.length && !XYZ.x.length && !XYZ.z.length);
  const summaryChartIsDisabled =
    !magnitudeQuery.counts ||
    (magnitudeQuery.counts?.x_count === undefined &&
      magnitudeQuery.counts?.z_count === undefined &&
      magnitudeQuery.counts?.y_count === undefined);

  useEffect(() => {
    if (magnitudeQuery.isLoading || isLoading) {
      return;
    }

    if (!summaryChartIsDisabled) {
      setActiveTab('summary');
    } else if (!impactChartsDisabled) {
      setActiveTab('impact_profile');
    }
  }, [magnitudeQuery.isLoading, isLoading, summaryChartIsDisabled, impactChartsDisabled]);

  const handleSetActiveTab = useCallback(event => {
    setActiveTab(event.currentTarget.dataset.name);
  }, []);

  const counts = useMemo(() => {
    if (summaryChartIsDisabled) {
      return {};
    }

    const data = {
      x: magnitudeQuery.counts.x_count,
      y: magnitudeQuery.counts.y_count,
      z: magnitudeQuery.counts.z_count,
    };

    data.magnitude = Math.round(Math.sqrt(data.x ** 2 + data.y ** 2 + data.z ** 2));

    return data;
  }, [magnitudeQuery.counts, summaryChartIsDisabled]);

  if (!magnitudeQuery.isLoading && !isLoading && impactChartsDisabled && summaryChartIsDisabled) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.tabs}>
        <div
          data-name='summary'
          onClick={summaryChartIsDisabled ? undefined : handleSetActiveTab}
          className={activeTab === 'summary' ? classes.activeTab : classes.tab}
          disabled={summaryChartIsDisabled}
        >
          Summary
        </div>
        <div
          data-name='impact_profile'
          onClick={impactChartsDisabled ? undefined : handleSetActiveTab}
          className={activeTab === 'impact_profile' ? classes.activeTab : classes.tab}
          disabled={impactChartsDisabled}
        >
          Impact Profile
        </div>
      </div>

      {!renderTab ? (
        <div className='d-flex justify-content-center h-[300px] items-center'>
          <Spinner color='primary' type='border' size='lg' />
        </div>
      ) : null}

      {renderTab === 'impact_profile' && (
        <ImpactProfile
          magnitudeData={magnitudeQuery.data}
          minMAxData={minMax}
          sensitivity={sensitivity}
          XYZ={XYZ}
          isLoading={magnitudeQuery.isLoading || isLoading}
          currentEvent={currentEvent}
        />
      )}
      {renderTab === 'summary' && <SummaryTab currentEvent={currentEvent} counts={counts} />}
    </div>
  );
}

export default ActivityCharts;
