import async from '../../components/Async';
import NotFound from '../../components/NotFound';

const Profile = async(() => import('./pages/profile'));
const Switcher = async(() => import('./pages/workspaceSwitcher/SwitcherIndex'));

const notFound = {path: '/not-found', component: NotFound};
const profileRoutes = {path: '/profile', name: 'Profile', component: Profile};
const switcher = {path: '/workspace-switcher', name: 'Switcher', component: Switcher};

export default [notFound, profileRoutes, switcher];
