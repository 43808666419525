import {
  ASYNC_STATUS,
  GET_ALL_EVENTS,
  LOAD_EVENTS,
  SEARCH_EVENTS,
  CLEAR_EVENTS,
  UPDATE_EVENT,
  SET_ACTIVE_EVENT,
  GET_EVENT_REPORT,
  CLEAR_EVENT_REPORT,
  GET_ALL_EVENTS_NEW,
  GET_DEVICE_EVENT,
  GET_ALL_EVENTS_FOR_REPORTS,
  SET_MAINTENANCE_STEP_ACTIVE,
  CLEAR_CURSOR,
  EMPTY_UPDATED_EVENT_ID,
  SEND_DELETE_IMAGES,
  CLEAR_DEVICE_EVENT,
} from '../constants';
import moment from 'moment';
import React from 'react';
import {act} from 'react-dom/test-utils';

const initialState = {
  message: null,
  updatedEventId: '',
  status: ASYNC_STATUS.FAILURE,
  data: [],
  getEvent: {},
  dataForEventsNew: [],
  dataForReportsEvents: [],
  loadedEvents: [],
  searchedEvents: [],
  currentPage: 1,
  searchValue: '',
  activeEvent: null,
  activeEventReport: [],
  maintenanceStepActive: false,
  eventUpdated: false,
  cursorNext: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EVENTS_NEW:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
            init: action.init,
            loading: true,
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let data = action.data.results;
          let loadedEvents = data;

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the devices.',
            dataForEventsNew: [...state.dataForEventsNew, ...action.data.results],
            loadedEvents: action.isScroll
              ? [...state.loadedEvents, ...loadedEvents]
              : loadedEvents,
            cursorNext: action.data.cursor,
            currentPage: 1,
            searchValue: '',
            loading: false,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            loading: false,
          };
        }
        default:
          return state;
      }
    case CLEAR_CURSOR: {
      return {
        ...state,
        cursorNext: null,
      };
    }
    case EMPTY_UPDATED_EVENT_ID: {
      return {
        ...state,
        updatedEventId: '',
      };
    }
    case GET_ALL_EVENTS_FOR_REPORTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
            init: action.init,
            loading: true,
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let {searchValue} = state;
          let data = action.data.results;
          let loadedEvents = data;

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the devices.',
            dataForReportsEvents: [...state.dataForReportsEvents, ...action.data.results],
            loadedEvents: action.isScroll
              ? [...state.loadedEvents, ...loadedEvents]
              : loadedEvents,
            cursorNext: action.data.cursor,
            currentPage: 1,
            searchValue: '',
            loading: false,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            loading: false,
          };
        }
        default:
          return state;
      }
    case GET_DEVICE_EVENT:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            getEvent: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case CLEAR_DEVICE_EVENT:
      return {
        ...state,
        getEvent: {},
      };
    case GET_ALL_EVENTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
            loading: true,
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let {searchValue} = state;
          let data = action.data;
          let searchedEvents = data;
          let loadedEvents = data;
          let number = action.number;

          if (searchValue) {
            searchedEvents = searchedEvents.filter(event =>
              event.device.name.toLowerCase().includes(searchValue.toLowerCase()),
            );
          }

          if (number) {
            loadedEvents = searchedEvents.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the devices.',
            data,
            searchedEvents,
            loadedEvents,
            currentPage: 1,
            searchValue: '',
            loading: false,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            false: false,
          };
        }
        default:
          return state;
      }

    case LOAD_EVENTS: {
      let {currentPage, searchedEvents} = state;
      let number = action.number;
      let page = currentPage + 1;
      let loadedEvents = searchedEvents.slice(0, page * number);

      return {
        ...state,
        currentPage: page,
        loadedEvents,
      };
    }

    case CLEAR_EVENTS: {
      let {data} = state;
      let {number} = action.number;
      let loadedEvents = data;
      if (number) {
        loadedEvents = data.slice(0, number);
      }
      return {
        ...state,
        data: data,
        message: null,
        currentPage: 1,
        searchValue: '',
        cursorNext: null,
        activeEvent: null,
        searchedEvents: data,
        activeEventReport: [],
        loadedEvents: loadedEvents,
        status: ASYNC_STATUS.FAILURE,
        maintenanceStepActive: false,
      };
    }

    case SEARCH_EVENTS: {
      let {dataForEventsNew} = state;
      let searchedEvents = dataForEventsNew;
      let searchValue = action.value;
      let number = action.number;

      if (searchValue) {
        searchedEvents = searchedEvents.filter(function (event) {
          return (
            event.device.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            event.id?.toLowerCase().includes(searchValue.toLowerCase()) ||
            event.device.model?.toLowerCase().includes(searchValue.toLowerCase()) ||
            event.status.replace('_', ' ').toLowerCase().includes(searchValue.toLowerCase()) ||
            event.device.team?.toLowerCase().includes(searchValue.toLowerCase()) ||
            moment.unix(event.created).format('MM/DD/YY h:mm A').includes(searchValue)
          );
        });
      }

      let loadedEvents = searchedEvents;
      if (number) {
        loadedEvents = searchedEvents.slice(0, number);
      }

      return {
        ...state,
        searchValue,
        dataForEventsNew,
        currentPage: 1,
        loadedEvents,
        searchedEvents,
      };
    }

    case SET_ACTIVE_EVENT: {
      return {
        ...state,
        activeEvent: action.event,
      };
    }

    case GET_EVENT_REPORT: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching event report ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            activeEventReport: action.data,
            message: 'Succeed to fetch the event report.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case CLEAR_EVENT_REPORT: {
      return {
        ...state,
        activeEventReport: [],
        maintenanceStepActive: false,
      };
    }

    case UPDATE_EVENT: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching event report ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let device_id, event_id, getUpdatedEvent;
          let updatedData = action.data;
          let updateType = action.updateType;
          let {data, searchedEvents, loadedEvents} = state;

          if (updateType && updateType === 'device') {
            device_id = updatedData.device_id;
          } else {
            event_id = updatedData.id;
          }

          let newData = [...data];
          newData = newData.map(event => {
            if (event_id && event.id === event_id) {
              event = updatedData;
            }
            if (device_id && event.device.id === device_id) {
              event = {
                ...event,
                device: {
                  ...event.device,
                  id: updatedData.device_id,
                  model: updatedData.model,
                  name: updatedData.name,
                },
              };
            }
            getUpdatedEvent = event;
            return event;
          });

          let newSearchedEvents = [...searchedEvents];
          newSearchedEvents = newSearchedEvents.map(event => {
            if (event_id && event.id === event_id) {
              event = updatedData;
            }
            if (device_id && event.device.id === device_id) {
              event = {
                ...event,
                device: {
                  ...event.device,
                  id: updatedData.device_id,
                  model: updatedData.model,
                  name: updatedData.name,
                },
              };
            }
            getUpdatedEvent = event;
            return event;
          });

          let newLoadedEvents = [...loadedEvents];
          newLoadedEvents = loadedEvents.map(event => {
            if (event_id && event.id === event_id) {
              event = updatedData;
            }
            if (device_id && event.device.id === device_id) {
              event = {
                ...event,
                device: {
                  ...event.device,
                  id: updatedData.device_id,
                  model: updatedData.model,
                  name: updatedData.name,
                },
              }; //device:{updatedData}
            }
            getUpdatedEvent = event;
            return event;
          });

          return {
            ...state,
            data: newData,
            searchedEvents: newSearchedEvents,
            loadedEvents: newLoadedEvents,
            eventUpdated: true,
            // getEvent: getUpdatedEvent,
            updatedEventId: getUpdatedEvent.id,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case SET_MAINTENANCE_STEP_ACTIVE: {
      return {
        ...state,
        maintenanceStepActive: action.value,
      };
    }

    case SEND_DELETE_IMAGES: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Deleting images ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to delete images',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    default:
      return state;
  }
}
