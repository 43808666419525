import React from 'react';
import ReactCodeInput from 'react-verification-code-input';

const VerificationField = ({className, value, onChange, fields}) => {
  return (
    <ReactCodeInput
      type='text'
      fieldWidth={45}
      fieldHeight={40}
      values={value}
      fields={fields ? fields : 4}
      className={`pi-verification-field ${className && className}`}
      onChange={onChange}
    />
  );
};

export default VerificationField;
