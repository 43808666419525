import {
  ASYNC_STATUS,
  GET_TEAMS_NEW,
  GET_TEAMS_DEVICES,
  GET_TEAMS_FOR_REDESIGN,
} from 'modules/impact-detection/redux/constants';
import {
  W_GET_USER_GROUPS,
  W_CREATE_USER_GROUP,
  W_EDIT_USER_GROUP_NAME,
  W_ADD_USER_GROUP_DATA,
  W_MOVE_USER_GROUP_DATA,
  W_DELETE_USER_GROUP_DATA,
  W_DELETE_USER_GROUP,
  GET_TEAMS_WORKZONES,
  W_GET_USER_GROUP,
  W_LOAD_TEAMS,
  W_SEARCH_TEAMS,
  W_CLEAR_TEAMS,
  W_DISABLE_ADD_BUTTON,
  W_CLEAR_USER_GROUPS,
  W_SET_USER_GROUP_CREATED,
  W_SET_ACTIVE_TEAM,
  W_SET_ACTIVE_GROUP,
  W_SET_ACTIVE_SUBGROUPS,
  W_SET_USER_GROUP_DATA_ADDED,
  W_GET_USER_GROUPS_NEW,
  W_GET_TEAMS,
  W_GET_ONE_TEAM,
} from '../constants';

const initialState = {
  message: null,
  status: ASYNC_STATUS.FAILURE,
  addUserGroupStatus: ASYNC_STATUS.FAILURE,
  userGroups: [],
  teams: [],
  loadedTeams: [],
  searchedTeams: [],
  currentPage: 1,
  searchValue: '',
  userGroupCreated: false,
  activeTeam: null,
  activeGroup: null,
  activeSubGroups: null,
  userGroupDataAdded: false,
  allDevices: [],
  disableAddButton: false,
  getedTeam: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case W_GET_USER_GROUPS: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching user groups list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the user groups.',
            userGroups: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case W_GET_USER_GROUP:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching user group ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let userGroup = action.data[0];
          let type = userGroup?.group_type;
          if (type === 'TEAM') {
            const {teams} = state;
            let newTeams = [...teams];
            newTeams = newTeams.map(team => (team.id === userGroup.id ? userGroup : team));
            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the team.',
              activeTeam: userGroup,
              teams: newTeams,
              loadedTeams: newTeams,
              searchedTeams: newTeams,
              currentPage: 1,
              searchValue: '',
            };
          } else if (type === 'GROUP') {
            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the group.',
              activeGroup: userGroup,
            };
          } else if (type === 'SUBGROUP') {
            const {activeSubGroups} = state;
            let newActiveSubGroups = {...activeSubGroups};
            let subGroup = action.data[0];
            newActiveSubGroups[subGroup.id] = subGroup;

            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the subGroup.',
              activeSubGroups: newActiveSubGroups,
            };
          } else {
            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the user group.',
            };
          }
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }

    case W_CREATE_USER_GROUP: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching user group ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let newUserGroup = action.data;
          let type = newUserGroup.group_type;
          const {teams, activeTeam, activeGroup} = state;

          if (type === 'TEAM') {
            let newTeams = [...teams];
            let newActiveTeam = activeTeam;
            newTeams.unshift(newUserGroup);

            if (!activeTeam) {
              newActiveTeam = newUserGroup;
            }

            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the team.',
              teams: newTeams,
              loadedTeams: newTeams,
              searchedTeams: newTeams,
              currentPage: 1,
              searchValue: '',
              activeTeam: newActiveTeam,
            };
          } else if (type === 'GROUP') {
            let newGroupData = {
              devices_count: newUserGroup.workzones.length,
              id: newUserGroup.id,
              name: newUserGroup.name,
              users_count: newUserGroup.users.length,
            };
            let newActiveTeam = {...activeTeam};
            newActiveTeam.children.push(newGroupData);

            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the group.',
              activeTeam: newActiveTeam,
            };
          } else if (type === 'SUBGROUP') {
            let newSubGroupData = {
              id: newUserGroup.id,
              name: newUserGroup.name,
            };
            let newActiveGroup = {...activeGroup};
            newActiveGroup.children.push(newSubGroupData);

            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the subgroup.',
              activeGroup: newActiveGroup,
            };
          } else {
            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the user group.',
            };
          }
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case W_GET_USER_GROUPS_NEW: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching user groups list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the user groups.',
            userGroups: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case W_EDIT_USER_GROUP_NAME: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Editing user group ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let newUserGroup = action.data;
          let type = newUserGroup.group_type;
          const {teams, activeTeam, activeGroup} = state;

          if (type === 'TEAM') {
            let newTeams = [...teams];
            let newActiveTeam = {...activeTeam};
            newTeams = newTeams.map(team => {
              if (team.id === newUserGroup.id) {
                team.name = newUserGroup.name;
                newActiveTeam.name = newUserGroup.name;
              }
              return team;
            });

            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the team.',
              teams: newTeams,
              loadedTeams: newTeams,
              searchedTeams: newTeams,
              currentPage: 1,
              searchValue: '',
              activeTeam: newActiveTeam,
            };
          } else if (type === 'SUBGROUP') {
            let newActiveGroup = {...activeGroup};
            newActiveGroup.children = newActiveGroup.children.map(subgroup => {
              if (subgroup.id === newUserGroup.id) {
                subgroup.name = newUserGroup.name;
              }
              return subgroup;
            });

            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the subgroup.',
              activeGroup: newActiveGroup,
            };
          } else if (type === 'GROUP') {
            let newActiveGroup = {...activeTeam};
            newActiveGroup.children = newActiveGroup.children.map(group => {
              if (group.id === newUserGroup.id) {
                group.name = newUserGroup.name;
              }
              return group;
            });
            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the group.',
              activeTeam: newActiveGroup,
            };
          } else {
            return {
              ...state,
              status: ASYNC_STATUS.SUCCEED,
              message: 'Succeed to fetch the user group.',
            };
          }
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }

        default:
          return state;
      }
    }

    case W_MOVE_USER_GROUP_DATA: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Editing user group ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch the team.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }

        default:
          return state;
      }
    }

    case W_ADD_USER_GROUP_DATA: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            addUserGroupStatus: ASYNC_STATUS.REQUEST,
            message: 'Adding user group data...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          const {id, data} = action;
          const {activeSubGroups} = state;
          let newActiveSubGroups = {...activeSubGroups};
          newActiveSubGroups[id] = data;

          return {
            ...state,
            addUserGroupStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to add the user group data.',
            activeSubGroups: newActiveSubGroups,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            addUserGroupStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case W_DELETE_USER_GROUP_DATA: {
      const {id, data} = action;
      const {activeSubGroups} = state;
      let newActiveSubGroups = {...activeSubGroups};
      newActiveSubGroups[id] = data;

      return {
        ...state,
        status: ASYNC_STATUS.SUCCEED,
        activeSubGroups: newActiveSubGroups,
      };
    }

    case W_DELETE_USER_GROUP: {
      const {id, group_type} = action;
      const {teams, loadedTeams, searchedTeams, activeTeam} = state;

      if (group_type === 'TEAM') {
        let newTeams = [...teams];
        newTeams = newTeams.filter(team => team.id !== id);

        let newLoadedTeams = [...loadedTeams];
        newLoadedTeams = newLoadedTeams.filter(team => team.id !== id);

        let newSearchedTeams = [...searchedTeams];
        newSearchedTeams = newSearchedTeams.filter(team => team.id !== id);

        let newActiveTeam = activeTeam;
        if (newActiveTeam.id === id) {
          if (newLoadedTeams.length > 0) {
            newActiveTeam = newLoadedTeams[0];
          } else {
            newActiveTeam = null;
          }
        }

        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
          teams: newTeams,
          loadedTeams: newLoadedTeams,
          searchedTeams: newSearchedTeams,
          activeTeam: newActiveTeam,
        };
      } else {
        return {
          ...state,
          status: ASYNC_STATUS.SUCCEED,
        };
      }
    }

    case GET_TEAMS_WORKZONES: {
      return {
        ...state,
        status: ASYNC_STATUS.SUCCEED,
        message: 'Succeed to fetch all the devices.',
        allDevices: action.data,
      };
    }

    case W_LOAD_TEAMS: {
      let {currentPage, searchedTeams} = state;
      let number = action.number;
      let page = currentPage + 1;
      let loadedTeams = searchedTeams.slice(0, page * number);

      return {
        ...state,
        currentPage: page,
        loadedTeams,
      };
    }

    case W_SEARCH_TEAMS: {
      let {teams} = state;
      let searchedTeams = teams;
      let searchValue = action.value;
      let number = action.number;

      if (searchValue) {
        searchedTeams = searchedTeams.filter(team =>
          team.name.toLowerCase().includes(searchValue.toLowerCase()),
        );
      }

      let loadedTeams = searchedTeams;
      if (number) {
        loadedTeams = searchedTeams.slice(0, number);
      }

      return {
        ...state,
        searchValue,
        teams,
        currentPage: 1,
        loadedTeams,
        searchedTeams,
      };
    }

    case W_CLEAR_USER_GROUPS: {
      return {
        ...state,
        userGroups: [],
      };
    }

    case W_CLEAR_TEAMS: {
      return {
        ...state,
        message: null,
        status: ASYNC_STATUS.FAILURE,
        userGroups: [],
        teams: [],
        loadedTeams: [],
        searchedTeams: [],
        currentPage: 1,
        searchValue: '',
        userGroupCreated: false,
        activeTeam: null,
        activeGroup: null,
        activeSubGroups: null,
        userGroupDataAdded: false,
        getedTeam: null,
      };
    }

    case W_SET_USER_GROUP_CREATED: {
      return {
        ...state,
        userGroupCreated: action.value,
      };
    }

    case W_SET_ACTIVE_TEAM: {
      return {
        ...state,
        activeTeam: action.team,
      };
    }

    case W_SET_ACTIVE_GROUP: {
      return {
        ...state,
        activeGroup: action.group,
      };
    }

    case W_SET_ACTIVE_SUBGROUPS: {
      return {
        ...state,
        activeSubGroups: action.subGroups,
      };
    }

    case W_SET_USER_GROUP_DATA_ADDED: {
      return {
        ...state,
        userGroupDataAdded: action.value,
      };
    }

    case W_DISABLE_ADD_BUTTON: {
      return {
        ...state,
        disableAddButton: action.bool,
      };
    }

    case W_GET_TEAMS: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching teams list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let teamsList = action.data;
          let {activeTeam, searchValue} = state;
          let number = action.number;
          let activeTeamUpdated = activeTeam;

          if (activeTeam) {
            activeTeamUpdated = teamsList.filter(team => team.id === activeTeam.id)[0];
          }

          let searchedTeams = teamsList;
          let loadedTeams = teamsList;

          if (searchValue) {
            searchedTeams = searchedTeams.filter(team =>
              team.name.toLowerCase().includes(searchValue.toLowerCase()),
            );
          }

          if (number) {
            loadedTeams = searchedTeams.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the teams.',
            teams: teamsList,
            searchedTeams,
            loadedTeams,
            currentPage: 1,
            searchValue: '',
            activeTeam: activeTeam ? activeTeamUpdated : teamsList[0],
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case W_GET_ONE_TEAM: {
      return {
        ...state,
        getedTeam: action.data,
      };
    }

    case GET_TEAMS_FOR_REDESIGN: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching teams list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let teamsList = action.data;
          let {activeTeam, searchValue} = state;
          let number = action.number;
          let activeTeamUpdated = activeTeam;

          if (activeTeam) {
            activeTeamUpdated = teamsList.filter(team => team.id === activeTeam.id)[0];
          }

          let searchedTeams = teamsList;
          let loadedTeams = teamsList;

          if (searchValue) {
            searchedTeams = searchedTeams.filter(team =>
              team.name.toLowerCase().includes(searchValue.toLowerCase()),
            );
          }

          if (number) {
            loadedTeams = searchedTeams.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the teams.',
            teams: teamsList,
            searchedTeams,
            loadedTeams,
            currentPage: 1,
            searchValue: '',
            activeTeam: activeTeam ? activeTeamUpdated : teamsList[0],
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case GET_TEAMS_NEW: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching teams list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let teamsList = action.data;
          let {activeTeam, searchValue} = state;
          let number = action.number;
          let activeTeamUpdated = activeTeam;

          if (activeTeam) {
            activeTeamUpdated = teamsList.filter(team => team.id === activeTeam.id)[0];
          }

          let searchedTeams = teamsList;
          let loadedTeams = teamsList;

          if (searchValue) {
            searchedTeams = searchedTeams.filter(team =>
              team.name.toLowerCase().includes(searchValue.toLowerCase()),
            );
          }

          if (number) {
            loadedTeams = searchedTeams.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the teams.',
            teams: teamsList,
            searchedTeams,
            loadedTeams,
            currentPage: 1,
            searchValue: '',
            activeTeam: activeTeam ? activeTeamUpdated : teamsList[0],
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case GET_TEAMS_DEVICES: {
      return {
        ...state,
        status: ASYNC_STATUS.SUCCEED,
        message: 'Succeed to fetch all the devices.',
        allDevices: action.data,
      };
    }

    default:
      return state;
  }
}
