import React, {useEffect, useState} from 'react';

function ToggleSwitch({title, onOff, onChange, checked = false, disabled = false}) {
  const [isToggled, setIsToggled] = useState(checked);

  const onToggle = () => {
    onChange(!isToggled);
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    setIsToggled(checked);
  }, [checked]);

  return (
    <div className='toggle-switch'>
      <div className='toggle-switch-title'>
        {title && title}
        {onOff && <span>{isToggled ? 'On' : 'Off'}</span>}
      </div>
      <label className='toggle-switch-label'>
        <input type='checkbox' checked={isToggled} onChange={onToggle} disabled={disabled} />
        <span className='switch' />
      </label>
    </div>
  );
}
export default ToggleSwitch;
