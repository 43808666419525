import React from 'react';

export default function URLActionCell(props) {
  let className = props.className ? props.className : '';
  let color = props.color ? props.color : 'text';
  
  return (
    <>
      <td className={`pi-type-url ${className}`}>
        <div className='pi-td'>
          {props.children && props.children}
          <a
            className={`pi-color-${color}`}
            onClick={e => props.onClick && props.onClick(props.data, e)}
            href={props.url && props.url}
          >
            {props.actionBtns.label}
          </a>
        </div>
      </td>
    </>
  );
}
