import {
  ASYNC_STATUS,
  GET_EVENT_STATISTICS,
  RESET_REPORTS,
  GET_REFERENCE_TYPES,
  SET_REPORT_URL,
} from '../constants';
import {apiRequest} from '../../../../core/api/apiRequest';

import {asyncAction} from './asyncAction';

/**
 * Get event statistics
 *
 */
export function getEventStatistics() {
  return async dispatch => {
    dispatch(asyncAction(GET_EVENT_STATISTICS, ASYNC_STATUS.REQUEST));
    const response = await apiRequest('event_statistics');
    if (response.success) {
      dispatch(
        asyncAction(GET_EVENT_STATISTICS, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
    } else {
      dispatch(
        asyncAction(GET_EVENT_STATISTICS, ASYNC_STATUS.FAILURE, {message: response.message}),
      );
    }
  };
}

export const setReportURL = data => {
  return {
    type: SET_REPORT_URL,
    data,
  };
};
export function resetReportsData(value) {
  return {type: RESET_REPORTS, value};
}

export function getReferenceTypes() {
  return async dispatch => {
    dispatch(asyncAction(GET_REFERENCE_TYPES, ASYNC_STATUS.REQUEST));
    const response = await apiRequest('api/v0/reports/reference_types', 'get', {
      account_ref_types: true,
    });

    if (response.success) {
      dispatch(
        asyncAction(GET_REFERENCE_TYPES, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
    } else {
      dispatch(
        asyncAction(GET_REFERENCE_TYPES, ASYNC_STATUS.FAILURE, {message: response.message}),
      );
    }
  };
}
