import React, {useState, forwardRef, useRef} from 'react';
import DatePicker from 'react-datepicker';

const TimePickerSelect = ({date, label, onChange, errorMessage, className}) => {
  return (
    <div className={`pi-timepicker pi-error-two-lines ${className} ${errorMessage !== '' ? 'pi-invalid' : ''}`}>
      {label && <label className='pi-field-label'>{label}</label>}
      <div className="pi-field-wrapper">
        <DatePicker
          timeCaption='Time'
          dateFormat='h:mm aa'
          showTimeSelect
          selected={date}
          timeIntervals={15}
          showTimeSelectOnly
          onChange={onChange}
          className={className}
        />
        <i className={`pi-icon icon-schedule`} />
        {errorMessage && errorMessage !== '' && (
          <p className='pi-error-message'>{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default TimePickerSelect;