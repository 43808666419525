import {CLEAR_WORKSPACE, CHANGE_WORKSPACE} from './workspace.actionTypes';
import {WORKSPACES} from '../../../../impact-detection/redux/constants';

export function switchDashboard(data) {
  return switchWorkspace(WORKSPACES.impactDetection, data);
}

export function switchWorkZone(data) {
  return switchWorkspace(WORKSPACES.workzone, data);
}

export function switchFlareWaze(data) {
  return switchWorkspace(WORKSPACES.flarewaze, data);
}

export function clearWorkspace() {
  localStorage.removeItem('lastSelectedWorkspace');
  return {
    type: CLEAR_WORKSPACE,
  };
}

export function switchWorkspace(name, data) {
  localStorage.setItem('lastSelectedWorkspace', name);
  return {
    type: CHANGE_WORKSPACE,
    payload: {
      currentWorkspaceName: name,
      ...data,
    },
  };
}
