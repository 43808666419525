import {
  ADD_NEW_REPORT,
  ASYNC_STATUS,
  CLEAR_CURSOR,
  CLEAR_EVENTS_REDESIGN,
  GET_ALL_EVENTS_REDESIGN,
  GET_CURRENT_EVENT_REPORTS,
  GET_ONE_EVENT,
  CLEAR_ONE_EVENT,
  GET_USERS_LIST_ACTIVITY,
  SET_REPORT_ADDED,
  GET_ACTIVITY_REFERENCE_TYPES,
  CLEAR_CURRENT_EVENT_REPORTS,
  SET_CURRENT_EVENT_GRAPH_DATA,
  GET_CURRENT_EVENT_GRAPH_DATA,
} from '../constants';

const initialState = {
  message: null,
  status: ASYNC_STATUS.FAILURE,
  addStatus: ASYNC_STATUS.FAILURE,
  loadedEvents: [],
  currentEventReports: null,
  currentEvent: null,
  currentEventGraphData: null,
  graphDataLoading: false,
  cursorNext: null,
  loader: false,
  usersList: [],
  reference_types: [],
  report_added: false,
  eventReportsLoading: true,
};

const updateEvent = (state, action) => {
  let {loadedEvents} = state;
  let updatedEvent = action.data;
  let updatedEventId = updatedEvent.event_id;

  for (const event of loadedEvents) {
    if (event.event_id === updatedEventId) {
      event.name = updatedEvent.name;
      event.model = updatedEvent.model;

      return loadedEvents;
    }
  }

  return loadedEvents;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EVENTS_REDESIGN:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          let data = action.data;

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the devices.',
            loadedEvents:
              action.isScroll === true
                ? [...state.loadedEvents, ...data.results]
                : [...data.results],
            cursorNext: data.cursor,
            currentPage: 1,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_ONE_EVENT:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            getOneStatus: ASYNC_STATUS.REQUEST,
            getOneMessage: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          const newState = {
            ...state,
            getOneStatus: ASYNC_STATUS.SUCCEED,
            currentEvent: action.data,
            getOneMessage: action.message,
          };

          if (action.updateList) {
            Object.assign(newState, {loadedEvents: updateEvent(state, action)});
          }

          return newState;
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            getOneStatus: ASYNC_STATUS.FAILURE,
            getOneMessage: action.message,
          };
        default:
          return state;
      }
    case CLEAR_ONE_EVENT:
      return {
        ...state,
        currentEvent: null,
      };
    case CLEAR_CURRENT_EVENT_REPORTS:
      return {
        ...state,
        currentEventReports: null,
      };
    case GET_CURRENT_EVENT_REPORTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: 'Fetching data...',
            eventReportsLoading: true,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            currentEventReports: action.data,
            message: action.message,
            eventReportsLoading: false,
          };

        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
            eventReportsLoading: false,
          };
        default:
          return state;
      }
    case CLEAR_EVENTS_REDESIGN: {
      return {
        ...state,
        message: null,
        loadedEvents: [],
        // locationStatistics:[],
      };
    }
    case GET_USERS_LIST_ACTIVITY:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            message: action.message,
            usersList: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
          };
        default:
          return state;
      }
    case CLEAR_CURSOR: {
      return {
        ...state,
        cursorNext: null,
      };
    }
    case ADD_NEW_REPORT:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            addStatus: ASYNC_STATUS.REQUEST,
            message: '',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            addStatus: ASYNC_STATUS.SUCCEED,
            message: action.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            addStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        case ASYNC_STATUS.RESET:
          return {
            ...state,
            addStatus: ASYNC_STATUS.RESET,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_ACTIVITY_REFERENCE_TYPES:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            message: action.message,
            reference_types: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
          };
        default:
          return state;
      }
    case SET_REPORT_ADDED: {
      return {
        ...state,
        report_added: action.bool,
      };
    }

    case SET_CURRENT_EVENT_GRAPH_DATA:
      return {
        ...state,
        currentEventGraphData: action.payload,
      };

    case GET_CURRENT_EVENT_GRAPH_DATA:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            graphDataLoading: true,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            graphDataLoading: false,
            currentEventGraphData: action.payload,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            graphDataLoading: false,
            message: action.message,
          };
        default:
          return state;
      }

    default:
      return state;
  }
}
