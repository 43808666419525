import React from 'react';

const InfoString = ({
  className = '',
  icon,
  label,
  text,
  link,
  action,
  size = 'md',
  isNote = false,
  isRow = false,
}) => {
  function onClickHandler() {
    if (action) {
      action();
    }
  }
  return (
    <>
      {isRow === false ? (
        <div
          className={`pi-info-string ${className} ${isNote ? 'pi-note' : ''} pi-size-${size}`}
        >
          {icon && <i className={`icon-${icon}`} />}
          {label && <strong className='pi-info-label'>{label}</strong>}
          {text && <span className='pi-info-text'>{text}</span>}
        </div>
      ) : (
        <div className={`pi-info-string ${className} ${isNote ? 'pi-note' : ''}`}>
          {icon && (
            <div className='pi-info-string-icon'>
              {icon && <i className={`icon-${icon}`} />}
            </div>
          )}
          <div className='pi-info-string-text'>
            {label && <strong className='pi-info-label'>{label}</strong>}
            {text && <span className='pi-info-text'>{text}</span>}
            {link && (
              <a onClick={onClickHandler} className='pi-info-link'>
                {link}
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InfoString;
