import React from 'react';
import LoaderPart1 from '../assets/img/loader/loader-part1.png';
import LoaderPart2 from '../assets/img/loader/loader-part2.png';

const LogoLoader = ({
    className=''
}) => {
    return (
        <div className={`pi-logo-loader ${className}`}>
            <img className='pi-logo-part1' src={LoaderPart1}/>
            <svg xmlns="http://www.w3.org/2000/svg" id="eG7u8xzrNEY1" viewBox="0 0 217 158" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                <g id="eG7u8xzrNEY2_ts" transform="translate(104.949894,77.340094) scale(0.5,0.5)">
                    <g transform="translate(-104.949894,-74.296457)">
                        <path d="M105,53.8477c11.258,0,20.4,9.1412,20.4,20.4s-9.142,20.4-20.4,20.4c-11.2592,0-20.4004-9.1413-20.4004-20.4s9.1412-20.4,20.4004-20.4Z" fill="#354052"/>
                        <path d="M91.019,89.2378c-8.2804-7.7216-8.7334-20.6933-1.0118-28.9737c0,0,29.2538,27.2799,29.9858,27.9619-7.722,8.2804-20.6936,8.7334-28.974,1.0118Z" fill="#354052"/>
                    </g>
                </g>
                <g id="eG7u8xzrNEY5" transform="translate(.000001 0.000002)" opacity="0">
                    <path
                        d="M40,74.2489c0,17.949,7.275,34.1991,19.038,45.9621l14.157-14.157C65.047,97.9059,60,86.6559,60,74.2489s5.047-23.657,13.195-31.805L59.038,28.2869C47.275,40.0499,40,56.2999,40,74.2489Z"
                        transform="translate(0 3.091141)" fill="#faa528"/>
                    <path
                        d="M169.752,79.9121c1.564-17.8807-4.267-34.7029-14.96-47.4464L139.455,45.335c7.407,8.8271,11.454,20.4742,10.373,32.834s-7.09,23.127-15.917,30.534l12.87,15.337c12.743-10.693,21.407-26.2472,22.971-44.1279Z"
                        transform="translate(0-.91276)" fill="#faa528"/>
                </g>
                <g id="eG7u8xzrNEY8" opacity="0">
                    <path
                        d="M20,74.2462c0-23.472,9.514-44.722,24.896-60.104L30.754,0.000183C11.07,19.6842,0,46.4092,0,74.2462C0,102.083,11.07,128.808,30.754,148.492L44.896,134.35C29.514,118.968,20,97.7182,20,74.2462Z"
                        transform="translate(0 3.094)" fill="#faa528"/>
                    <path
                        d="M189.677,81.654c-2.045,23.383-13.375,43.723-30.039,57.705l12.855,15.321c21.325-17.893,34.682-43.552,37.108-71.2829c2.426-27.7311-6.272-55.3192-24.166-76.64389L170.115,19.6088c13.982,16.6641,21.608,38.6625,19.562,62.0452Z"
                        transform="translate(0-3.376513)" fill="#faa528"/>
                </g>
            </svg>
            <img className='pi-logo-part2' src={LoaderPart2}/>
        </div>
    );
};

export default LogoLoader;