import moment from 'moment';
import {toastr} from 'react-redux-toastr';
import {
  ASYNC_STATUS,
  MOBILE_REPORT_STEP_CHANGE,
  MOBILE_REPORT_FORM_CHANGE,
  MOBILE_REPORT_FORM_RESET,
  MOBILE_REPORT_ADD_REPORT_FORM,
  MOBILE_REPORT_REMOVE_REPORT_FORM,
  MOBILE_REPORT_ADD_COST_FORM,
  MOBILE_REPORT_REMOVE_COST_FORM,
  MOBILE_REPORT_FORM_ARRAY_CHANGE,
  MOBILE_REPORT_SUBMIT,
  MOBILE_REPORT_IMAGES_UPLOAD,
} from '../../constants';
import {apiRequest} from '../../../../../core/api/apiRequest';
import {asyncAction} from '../asyncAction';

const submitReportError = 'Impossible to update the change. Please try again!';

const serializeReportForm = (form, location) => {
  return {
    type: 'INSPECTION',
    location_id: location.location_id,
    event_id: location.event_id,
    author: form.step2.user.value,
    report_date: parseFloat(
      moment(`${form.step1.date.value} ${form.step1.time.value}`, 'MM/DD/YYYY HH:mm A').format(
        'X',
      ),
    ),
    // "associated_costs": form.step7.costs.value.filter(i=>!!(i.cost || i.description)),
    // "overall_cost": sumBy(form.step7.reports.value,i => parseFloat(i.cost || 0)).toFixed(2),
    custom_reports: form.step7.reports.value.filter(
      i => !!(i.title || i.value || i.comment || i.cost),
    ),
    other_agencies: form.step4.other_agencies.value,
    notes: form.step5.notes.value,
    image_available: !!form.step6.images.value.length,
    severity: form.step3.impact_severity.value,
    send_email: form.step8.send_report.value,
  };
};

/**
 * Mobile report
 */
export function mobileReportStepChange(step) {
  return {type: MOBILE_REPORT_STEP_CHANGE, payload: step};
}

export function submitMobileReport(form, location) {
  const params = serializeReportForm(form, location);
  return async dispatch => {
    dispatch(asyncAction(MOBILE_REPORT_SUBMIT, ASYNC_STATUS.REQUEST));
    const response = await apiRequest(`api/v0/reports/create`, 'post', params);
    if (response.success) {
      if (params.image_available) {
        await dispatch(uploadImages(form.step6.images.value, response.data.upload_url));
      }
      dispatch(
        asyncAction(MOBILE_REPORT_SUBMIT, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
    } else {
      dispatch(
        asyncAction(MOBILE_REPORT_SUBMIT, ASYNC_STATUS.FAILURE, {message: response.message}),
      );
      toastr.error(submitReportError);
    }
  };
}

function uploadImages(files, upload_url) {
  return async dispatch => {
    dispatch(asyncAction(MOBILE_REPORT_IMAGES_UPLOAD, ASYNC_STATUS.REQUEST));
    const imagesList = files.map(img => img.file);
    const response = await apiRequest(upload_url, 'post', {file: imagesList}, true, true);
    if (response.success) {
      // toastr.success("Comment", "Images Attached Successfully!");
      dispatch(
        asyncAction(MOBILE_REPORT_IMAGES_UPLOAD, ASYNC_STATUS.SUCCEED, {
          message: response.message,
        }),
      );
    } else {
      toastr.error('Comment', 'Failed to attach images.');
      dispatch(
        asyncAction(MOBILE_REPORT_IMAGES_UPLOAD, ASYNC_STATUS.FAILURE, {
          message: response.message,
        }),
      );
    }
  };
}

export function mobileReportFormChange(data) {
  return {type: MOBILE_REPORT_FORM_CHANGE, payload: data};
}

export function mobileReportFormArrayChange(data) {
  return {type: MOBILE_REPORT_FORM_ARRAY_CHANGE, payload: data};
}

export function mobileReportFormReset() {
  return {type: MOBILE_REPORT_FORM_RESET};
}

export function mobileReportAddNewForm() {
  return {type: MOBILE_REPORT_ADD_REPORT_FORM};
}

export function mobileReportRemoveNewForm() {
  return {type: MOBILE_REPORT_REMOVE_REPORT_FORM};
}

export function mobileReportAddNewCostForm() {
  return {type: MOBILE_REPORT_ADD_COST_FORM};
}

export function mobileReportRemoveNewCostForm() {
  return {type: MOBILE_REPORT_REMOVE_COST_FORM};
}
