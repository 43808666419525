import React from 'react';
import {Button} from 'elements';

const MenuExpandable = ({
  data,
  title,
  configs,
  options,
  children,
  menuOpened,
  triggerElem,
  elementType,
  localCondition,
  handleMenuToggle,
  field = 'id',
  menuRef = null,
  className = '',
  position = 'left',
}) => {
  const id = data ? data[field] : '';

  const menuState = localCondition ? id === menuOpened : triggerElem || menuOpened;

  function onMenuClick(event) {
    handleMenuToggle(id, event);
  }

  const elem = (
    <>
      {triggerElem || (
        <Button
          styleType={configs?.type ? configs?.type : 'outline'}
          color={configs?.color ? configs?.color : 'white'}
          shadow={typeof configs?.shadow === 'undefined' ? true : configs?.shadow}
          className='pi-icon'
          onClick={onMenuClick}
        >
          <i
            className={`icon-${configs?.icon ? configs?.icon : 'more-vert'} pi-color-${
              configs?.iconColor ? configs?.iconColor : 'text'
            }`}
          />
        </Button>
      )}
      <div
        className={`pi-action-menu pi-position-${position} ${menuState ? 'pi-expanded' : ''}`}
      >
        {title && <h5>{title}</h5>}
        <ul>
          {options &&
            options.map((item, index) => (
              <li key={index} className={item.className}>
                <a onClick={e => !item.disabled && item.action(data, e)}>
                  {item.icon && (
                    <i className={`icon-${item.icon} pi-color-${item.iconColor}`} />
                  )}
                  {item.name && <span>{item.name}</span>}
                </a>
              </li>
            ))}
          {children && <li>{children}</li>}
        </ul>
      </div>
    </>
  );

  return (
    <>
      {elementType === 'td' ? (
        <td className={`pi-menu-expandable ${className}`} ref={menuRef}>
          <div className='pi-td'>{elem}</div>
        </td>
      ) : (
        <div className={`pi-menu-expandable ${className}`} ref={menuRef}>
          {elem}
        </div>
      )}
    </>
  );
};

export default MenuExpandable;
