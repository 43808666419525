import {apiSlice} from '../../../services';

export const impactDetectionApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getGraphDataMagnitude: builder.query({
      query: eventId => `api/v0/dashboard/impacts/${eventId}/graph_data/magnitude/`,
    }),
    getGraphDataAccelerometer: builder.query({
      query: eventId => `api/v0/dashboard/impacts/${eventId}/graph_data/accelerometer_data/`,
    }),
    getImpactStatistic: builder.query({
      query: serialNumber => `api/v0/devices/impact_statistics/${serialNumber}`,
    }),
  }),
});

export const {
  useGetGraphDataMagnitudeQuery,
  useGetGraphDataAccelerometerQuery,
  useGetImpactStatisticQuery,
} = impactDetectionApi;
