import React from 'react';
import {Link} from 'react-router-dom';

import {Card, CardBody} from 'reactstrap';

import logo from '../../assets/img/logo/logo.png';
import {toastr} from 'react-redux-toastr';
import {connect} from 'react-redux';
import {ASYNC_STATUS} from '../impact-detection/redux/constants';
import {loginAsyncAction} from '../impact-detection/redux/actions/authActions';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    // if (this.props.auth.status === ASYNC_STATUS.SUCCEED && this.props.auth.data) {
    // 	this.onSigninSucceed()
    // }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentWillReceiveProps(newProps) {
    if (this.props.auth.status === ASYNC_STATUS.REQUEST) {
      if (newProps.auth.status === ASYNC_STATUS.SUCCEED && this._isMounted) {
        this.onSigninSucceed();
      } else if (newProps.auth.status === ASYNC_STATUS.FAILURE) {
        toastr.error('Invalid authentication! Please try again.');
      }
    }
  }
  onSigninSucceed() {
    toastr.success('Authentication', 'Welcome to our service!');
    this.props.history.push(this.props.auth.currentUrl);
  }
  onSignin = (event, values) => {
    this.props.dispatch(loginAsyncAction(values));
  };
  render = () => (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className='m-sm-4'>
            <div className='text-center'>
              <img
                src={logo}
                alt='Chris Wood'
                className='img-fluid'
                width='132'
                height='132'
              />
            </div>
            <p className='lead justify mt-4'>
              Welcome to the Pi-Lit® Dashboard. Thank you for verifying your email. Your
              account is now ready to use! Please select the button below to continue to the
              dashboard.
            </p>
            <div className='text-center mt-4'>
              <Link className='yellow-button' to='/overview'>
                Enter Dashboard
              </Link>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  const {auth} = state;
  return {auth};
}

export default connect(mapStateToProps)(SignIn);
