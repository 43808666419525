import {
  ASYNC_STATUS,
  GET_EVENT_STATISTICS,
  RESET_REPORTS,
  GET_REFERENCE_TYPES,
  DOWNLOAD_REPORT,
  SET_REPORT_URL,
} from '../constants';

const initialState = {
  eventStatistics: [],
  message: '',
  referenceTypes: [],
  reportUrl: null,
  dynamicFields: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_STATISTICS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            eventStatistics: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        case ASYNC_STATUS.RESET:
          return {
            status: ASYNC_STATUS.RESET,
            message: '',
            eventStatistics: [],
          };
        default:
          return state;
      }
    case RESET_REPORTS:
      return {
        ...initialState,
      };
    case GET_REFERENCE_TYPES:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            message: 'Succeed to fetch data.',
            referenceTypes: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
          };
        default:
          return state;
      }
    case DOWNLOAD_REPORT:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: action.message,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            message: action.message,
            reportUrl: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
          };
        default:
          return state;
      }
    case SET_REPORT_URL:
      return {
        ...state,
        reportUrl: action.data,
      };
    default:
      return state;
  }
}
