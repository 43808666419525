import workspaces from '../../../shared/pages/workspaceSwitcher/store/workspace.reducers';
import sidebar from './sidebarReducers';
import layout from './layoutReducer';
import theme from './themeReducer';
import attenuators from './attenuatorReducer';
import taperGateways from './taperGatewayReducer';
import workzones from './workzoneReducer';
import users from './usersReducer';
import auth from './authReducer';
import events from './eventsReducer';
import devices from './devicesReducer';
import teams from './teamsReducer';
import reports from './reportsReducer';
import locations from './locationsReducer';
import activities from './activitiesReducer';
import deviceFilter from './deviceFilterReducer';
import locationLandingPage from './mobile/locationLandingPageReducer';
import reportMobile from './mobile/reportReducer';
import redesignReportsReducer from './redesignReportsReducer';
import teamsRedesign from './teamsRedesignReducer';

const allReducers = {
  auth,
  workspaces,
  sidebar,
  layout,
  theme,
  attenuators,
  taperGateways,
  events,
  workzones,
  users,
  devices,
  teams,
  reports,
  locations,
  activities,
  deviceFilter,
  locationLandingPage,
  redesignReportsReducer,
  reportMobile,
  teamsRedesign,
};

export default allReducers;
