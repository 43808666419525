import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useWindowResize} from 'elements';

// Image import
import LogoWhite from 'assets/img/logo/logo-white.png';
import {getUserAsyncAction} from 'modules/impact-detection/redux/actions/usersActions';

const YEAR = new Date().getFullYear();

const NewSideBar = props => {
  const dispatch = useDispatch();
  const [width] = useWindowResize();
  const {currentUser} = useSelector(state => state.users);
  const {data: user} = useSelector(state => state.auth);

  const [userName, setUserName] = useState('');
  const [smallSidebar, setSmallSidebar] = useState(false);

  const smallSidebarTrigger = () => {
    setSmallSidebar(!smallSidebar);
  };

  const handleSidebarResponsive = () => {
    document.body.classList.toggle('pi-sidebar-state');
  };

  const generateName = () => {
    setUserName(`${currentUser?.firstname || ''} ${currentUser?.lastname || ''}`);
  };

  useEffect(generateName, [currentUser]);

  useEffect(() => {
    if (!currentUser || Object.keys(currentUser).length === 0) {
      dispatch(getUserAsyncAction(encodeURIComponent(props.email)));
    }
  }, []);

  return (
    <div
      className={`pi-sidebar ${(width > 1300 ? smallSidebar : !smallSidebar) ? 'pi-small' : ''}`}
    >
      <div className='pi-logo'>
        <a href='/'>
          <img src={LogoWhite} alt='Logo white' />
        </a>
        <button className='pi-sidebar-trigger' onClick={() => smallSidebarTrigger()}>
          <i className='icon-double-arrow' />
        </button>
        <button className='pi-sidebar-close' onClick={() => handleSidebarResponsive()}>
          <i className='icon-close' />
        </button>
      </div>
      {<props.navigation handleSidebarResponsive={handleSidebarResponsive} />}
      <div className='pi-nav-items pi-account-trigger'>
        <NavLink to='/workspace-switcher' className='pi-sidebar-menu' activeClassName='pi-active'>
          <i className='icon-workspaces' />
          <span>Workspaces</span>
        </NavLink>
      </div>
      <div className='pi-nav-items'>
        <NavLink to='/profile' className='pi-sidebar-menu' activeClassName='pi-active'>
          <i className='icon-account' />
          <span>{userName}</span>
        </NavLink>
      </div>
      {(user.is_pivar_admin || user.is_account_admin) && !props.fromWZ && (
        <div className='pi-nav-items'>
          <NavLink to='/account-settings' className='pi-sidebar-menu' activeClassName='pi-active'>
            <i className='icon-manage-accounts' />
            <span>Account settings</span>
          </NavLink>
        </div>
      )}
      <div className='new-sidebar-copyright'>&#169; {YEAR} - Pi Variables, Inc</div>
    </div>
  );
};

const mapStateToProps = state => {
  const {auth} = state;
  if (auth && auth.data) {
    return {...auth.data};
  } else {
    return {};
  }
};

export default connect(mapStateToProps)(NewSideBar);
