import React from 'react';

const NoValue = ({
    tagName = 'em',
    label = 'n/a'
}) => {
    const CustomTag = tagName;

    return (
        <CustomTag className="pi-no-value">{label}</CustomTag>
    );
};

export default NoValue;