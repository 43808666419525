import React from 'react';
import ReactTooltip from 'react-tooltip';

const StatisticsItem = ({
    className='',
    title='',
    color='',
    count='',
    tooltip
}) => {
    
    return (
        <div className={`pi-statistic-item pi-size-lg pi-${color} ${className}`}>
            <span>{title}</span>
            <strong className="pi-count">{count}</strong>
            {tooltip && <div className="pi-info-tooltip">
                <i
                    data-tip={tooltip}
                    data-html={true}
                    className="icon-help"/>
            </div>}
            <ReactTooltip place="top" type="light" effect="solid" className="pi-tooltip"/>
        </div>
    );
};

export default StatisticsItem;