import React from 'react';

const PopOver = ({
  className = '',
  size = 'sm',
  title,
  description,
  children,
  reference,
  position,
}) => {
  return (
    <div
      className={`pi-popover ${className} ${
        position ? 'pi-align-' + position : ''
      } pi-size-${size}`}
      ref={reference}
    >
      {title && <h5>{title}</h5>}
      {description && <p dangerouslySetInnerHTML={{__html: description}} />}
      {children && <div className='pi-btn-wrapper'>{children}</div>}
    </div>
  );
};

export default PopOver;

