export const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

export const latitudeRegex =
  /^(\+|-)?(?:90((?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])((?:\.[0-9]{1,16})?))$/;

export const longitudeRegex =
  /^(\+|-)?(?:180((?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])((?:\.[0-9]{1,16})?))$/;

export const minOneNumberOneUpperCaseLetterAndOneSpecialCharacter =
  /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])/;
