import React, {useMemo} from 'react';

import {motion, useMotionValue, useTransform} from 'framer-motion';

import classes from './summaryChart.module.scss';

const colorsWithMajorImpact = ['#498f5c', '#498f5c', '#faa528', '#faa528', '#e90f0f', '#e90f0f'];
const colorsWithMinorImpact = ['#498f5c', '#498f5c', '#e90f0f', '#e90f0f', '#faa528', '#faa528'];

const SECTIONS_TITLES = {
  minor: 'Minor Impact',
  major: 'Major Impact',
  no_impact: 'No Impact',
};

const getOutPutRange = ranges => (ranges.length === 3 ? [0, 20, 40] : [0, 20, 40, 60]);
const SummaryChart = ({title, value, minorImpact = 20, majorImpact}) => {
  const rangesConfigs = useMemo(() => {
    const obj = {
      ranges: [],
      colorRanges: colorsWithMajorImpact,
      transformInputRanges: [],
    };

    // if there is a majorImpact
    if (majorImpact) {
      const minorIsLess = minorImpact < majorImpact;
      obj.ranges.push({
        start: 0,
        end: minorIsLess ? minorImpact : majorImpact,
        range: 'no_impact',
      });

      obj.ranges.push({
        start: minorIsLess ? minorImpact : majorImpact,
        end: !minorIsLess ? minorImpact : majorImpact,
        range: 'minor',
      });

      obj.ranges.push({
        start: minorIsLess ? majorImpact : minorImpact,
        end: (minorIsLess ? majorImpact : minorImpact) + 20,
        range: 'major',
      });

      obj.colorRanges = colorsWithMajorImpact;
    } else {
      const calculatedMajor = minorImpact * 2;

      obj.ranges.push({
        start: 0,
        end: minorImpact,
        range: 'no_impact',
      });

      obj.ranges.push({
        start: minorImpact,
        end: calculatedMajor,
        range: 'minor',
      });
    }

    if (obj.ranges[obj.ranges.length - 1].end < value) {
      obj.ranges[obj.ranges.length - 1].end = value + 20;
    }

    obj.transformInputRanges = [
      ...obj.ranges.map(range => range.start),
      obj.ranges[obj.ranges.length - 1].end,
    ];

    return obj;
  }, [majorImpact, minorImpact, value]);

  console.log({title, minorImpact, majorImpact, rangesConfigs, value});

  const y = useMotionValue(value);
  const graphValue = useTransform(
    y,
    rangesConfigs.transformInputRanges,
    getOutPutRange(rangesConfigs.transformInputRanges),
  );
  const height = useTransform(
    graphValue,
    [0, rangesConfigs.transformInputRanges.length === 4 ? 60 : 40],
    [0, 200],
  );
  const color = useTransform(graphValue, [0, 20, 21, 40, 41, 60], rangesConfigs.colorRanges);

  const _renderRanges = useMemo(
    () =>
      rangesConfigs.ranges.reduce(
        (obj, item) => {
          obj.sections.push(
            <div className={classes.section} data-type={item.range} key={item.range} />,
          );
          obj.info.push(
            <li className={classes.rangeInfo} data-type={item.range} key={`title-${item.range}`}>
              {item.start}-{item.end} {SECTIONS_TITLES[item.range]}
            </li>,
          );

          return obj;
        },
        {sections: [], info: []},
      ),
    [rangesConfigs.ranges],
  );

  const _renderLabels = useMemo(
    () => rangesConfigs.transformInputRanges.map(item => <li key={`label-${item}`}>{item}</li>),
    [rangesConfigs.transformInputRanges],
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h4 className={classes.title}>{title}</h4>
        <ul className={classes.rangesInfoContainer}>{_renderRanges.info}</ul>
      </div>

      <div className={classes.body}>
        <div className={classes.bodyBackground}>
          <ul className={classes.rangesLabel}>{_renderLabels}</ul>
          <div className={classes.rangeSecons}>{_renderRanges.sections}</div>
        </div>
        <motion.div className={classes.bar} style={{height, backgroundColor: color}}>
          <span className={classes.bar__value}>{value}</span>
        </motion.div>
      </div>
    </div>
  );
};

export default SummaryChart;
