import React from 'react';
export const automatedReports = ['Yes', 'No'];

export const reportsPeriod = ['Weekly', 'Monthly', 'Yearly'];
export const trackMovements = [
  {
    label: 'Construction Hard Hat',
    name: 'construction',
    labelId: 'construction-hard-hat',
    value: 'construction',
    icon: 'construction-hard-hat',
  },
  {
    label: 'Vehicle stopped',
    name: 'vehicle_stopped',
    labelId: 'vehicle_stopped',
    value: 'maintenance',
    icon: 'vehicle-stopped',
  },
  {
    label: 'Flood',
    name: 'flood',
    labelId: 'flood',
    value: 'flood',
    icon: 'flood',
  },
  {
    label: 'Lane Closure',
    name: 'lane_closure',
    labelId: 'lane_closure',
    value: 'lane_closure',
    icon: 'lane-closure',
  },
  {
    label: 'Hazard',
    name: 'hazard',
    labelId: 'hazard',
    value: 'hazard',
    icon: 'warning-alt',
  },
  {
    label: 'Emergency Resposne',
    name: 'emergency',
    labelId: 'emergency',
    value: 'emergency',
    icon: 'emergency',
  },
];

export const NotificationsObj = {
  construction: {
    value: 'construction',
    label: <i className='icon-construction-hard-hat pi-font-size-24' />,
  },
  maintenance: {
    value: 'maintenance',
    label: <i className='icon-vehicle-stopped pi-font-size-24' />,
  },
  flood: {
    value: 'flood',
    label: <i className='icon-flood pi-font-size-24' />,
  },
  lane_closure: {
    value: 'lane_closure',
    label: <i className='icon-lane-closure pi-font-size-24' />,
  },
  hazard: {
    value: 'hazard',
    label: <i className='icon-warning-alt pi-font-size-24' />,
  },
  emergency: {
    value: 'emergency',
    label: <i className='icon-emergency pi-font-size-24' />,
  },
};

export const notifIcons = Object.values(NotificationsObj);

export const selectedEmailsMock = [
  {
    value: 'lorem@ipsum.com',
    removeFilter: null,
  },
];
