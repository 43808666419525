import React from 'react';
import {LabelGroup} from 'elements';

export default function ViewDriver({driver, changeMode}) {
  function onClick(e) {
    e.preventDefault();
    changeMode();
  }

  return (
    <div className='fl-success-view'>
      <div className='fl-success-message'>
        <div className='fl-success-icon'>
          <i className='icon-check' />
        </div>
        <p>
          All your changes have been <br />
          successfully saved!
        </p>
      </div>
      <div className='fl-details-group'>
        <div className='fl-column'>
          <LabelGroup
            type='inline'
            className='pi-no-p pi-layout-full'
            label='First Name'
            value={driver.firstName}
          />
          <LabelGroup
            type='inline'
            className='pi-no-p pi-layout-full'
            label='Last Name'
            value={driver.lastName}
          />
        </div>
        <div className='fl-column'>
          <LabelGroup
            type='inline'
            className='pi-no-p pi-layout-full'
            label='Phone Number'
            value={driver.phoneNumber}
          />
          <LabelGroup
            type='inline'
            className='pi-no-p pi-layout-full'
            label='Vehicle Name/ID'
            value={driver.vehicleId}
          />
        </div>
      </div>
      <p className='pi-color-text mt-20 px-15'>
        Still need to update the details? <br />
        Click
        <a href='/src/modules/shared/pages' className='pi-underline' onClick={onClick}>
          here
        </a>
        to edit the details
      </p>
    </div>
  );
}
