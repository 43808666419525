import React from 'react';

import {SignInForm} from './SignIn';
import {SignInWithPhone} from './SignInWithPhone';
import useToggle from 'core/hooks/useToggle';
import {useHistory} from 'react-router-dom';

export default function SignIn() {
  const history = useHistory();

  const [isSignInWithPhone, setIsSignInWithPhone] = useToggle(
    history?.location?.state?.type === 'phone', //TODO
  );

  return (
    <>
      {isSignInWithPhone ? (
        <SignInWithPhone setIsSignInWithPhone={setIsSignInWithPhone} />
      ) : (
        <SignInForm setIsSignInWithPhone={setIsSignInWithPhone} />
      )}
    </>
  );
}
