import {cloneDeep} from 'lodash';
import {
  ASYNC_STATUS,
  MOBILE_REPORT_STEP_CHANGE,
  MOBILE_REPORT_FORM_CHANGE,
  MOBILE_REPORT_FORM_RESET,
  MOBILE_REPORT_ADD_REPORT_FORM,
  MOBILE_REPORT_REMOVE_REPORT_FORM,
  MOBILE_REPORT_ADD_COST_FORM,
  MOBILE_REPORT_REMOVE_COST_FORM,
  MOBILE_REPORT_FORM_ARRAY_CHANGE,
  MOBILE_REPORT_SUBMIT,
  MOBILE_REPORT_IMAGES_UPLOAD,
} from '../../constants';

// import {containLetters} from '../../../../../helpers/functions';
import moment from 'moment';

const errors = {
  required: 'This field is required.',
  date: 'Enter date',
  time: 'Enter time',
};

const requiredFields = {
  step1: ['date', 'time'],
  step2: ['user'],
  step3: ['impact_severity'],
};

const validateDate = date => !moment(date, 'MM/DD/YYYY', true).isValid();
const validateTime = date => !moment(date, 'HH:mm A', true).isValid();

export const formatReportDate = date => {
  if (typeof date === 'string') return date;

  const d = moment(date);
  return d.isValid() ? d.format('MM/DD/YYYY') : date;
};

export const formatReportTime = date => {
  if (typeof date === 'string') return date;

  const t = moment(date);
  return t.isValid() ? t.format('HH:mm A') : date;
};

const validateField = (form, payload) => {
  switch (payload.field) {
    case 'date':
      if (validateDate(payload.value)) {
        form.step1.date.error = errors.required;
      } else {
        form.step1.date.error = null;
      }
      return form;
    case 'time':
      if (validateTime(payload.value)) {
        form.step1.time.error = errors.required;
      } else {
        form.step1.time.error = null;
      }
      return form;
    case 'user':
      if (!payload.value) {
        form.step2.user.error = errors.required;
      } else {
        form.step2.user.error = null;
      }
      return form;
    case 'impact_severity':
      if (!payload.value) {
        form.step3.impact_severity.error = errors.required;
      } else {
        form.step3.impact_severity.error = null;
      }
      return form;
    default:
      return form;
  }
};

export const validateNext = state => {
  const {form, currentStep} = state;
  const stepKey = 'step' + currentStep;
  const step = form[stepKey];
  const errorFields = [];

  if (requiredFields[stepKey]) {
    for (const field of requiredFields[stepKey]) {
      if (step[field]['required'] && (step[field]['error'] || !step[field]['value'])) {
        errorFields.push(field);
      }
    }
  }

  form[stepKey].isValid = !errorFields.length;
};

const reportForm = {
  title: '',
  value: undefined,
  // cost: 0,
  comment: '',
};

const costForm = {
  cost: undefined,
  description: undefined,
};

const initialState = {
  form: {
    step1: {
      date: {
        value: formatReportDate(new Date()),
        required: true,
        error: null,
      },
      time: {
        value: formatReportTime(new Date()),
        required: true,
        error: null,
      },
      isValid: true,
    },
    step2: {
      user: {
        value: '',
        required: true,
        error: null,
      },
      isValid: null,
    },
    step3: {
      impact_severity: {
        value: null,
        required: true,
        error: null,
      },
      isValid: null,
    },
    step4: {
      other_agencies: {
        value: '',
        error: null,
      },
      isValid: true,
    },
    step5: {
      notes: {
        value: '',
        error: null,
      },
      isValid: true,
    },
    step6: {
      images: {
        value: [],
        error: null,
      },
      isValid: true,
    },
    step7: {
      reports: {
        value: [cloneDeep(reportForm)],
        error: null,
      },
      isValid: true,
    },
    step8: {
      send_report: {
        value: true,
        error: null,
      },
      isValid: true,
    },
  },
  currentStep: 0,
  reportResponse: {},
  imagesResponse: {},
  maxStep: 9,
  submitStep: 8,

  status: null,
  message: '',
};

export default function reportMobileReducer(state = cloneDeep(initialState), action) {
  switch (action.type) {
    case MOBILE_REPORT_SUBMIT:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            reportResponse: action.payload,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        case ASYNC_STATUS.RESET:
          return {
            status: ASYNC_STATUS.RESET,
            message: '',
            reportResponse: {},
          };
        default:
          return state;
      }
    case MOBILE_REPORT_IMAGES_UPLOAD:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            imagesResponse: {
              status: ASYNC_STATUS.REQUEST,
              message: 'Fetching data...',
            },
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            imagesResponse: action.payload,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            imagesResponse: {
              status: ASYNC_STATUS.FAILURE,
              message: action.message,
            },
          };
        case ASYNC_STATUS.RESET:
          return {
            status: ASYNC_STATUS.RESET,
            message: '',
            reportResponse: {},
          };
        default:
          return state;
      }
    case MOBILE_REPORT_STEP_CHANGE:
      return {
        ...state,
        currentStep: action.payload,
      };
    case MOBILE_REPORT_FORM_CHANGE:
      state.form[action.payload.step][action.payload.field]['value'] = action.payload.value;

      validateField(state.form, action.payload);
      validateNext(state);
      return {...state};
    case MOBILE_REPORT_FORM_ARRAY_CHANGE:
      const {step, field, formIndex, subField} = action.payload;
      state.form[step][field]['value'][formIndex][subField] = action.payload.value;
      return {...state};
    case MOBILE_REPORT_ADD_REPORT_FORM:
      state.form['step7']['reports']['value'].push(cloneDeep(reportForm));
      return {...state};
    case MOBILE_REPORT_REMOVE_REPORT_FORM:
      state.form['step7']['reports']['value'].pop();
      return {...state};
    case MOBILE_REPORT_ADD_COST_FORM:
      state.form['step8']['costs']['value'].push(cloneDeep(costForm));
      return {...state};
    case MOBILE_REPORT_REMOVE_COST_FORM:
      state.form['step8']['costs']['value'].pop();
      return {...state};
    case MOBILE_REPORT_FORM_RESET: {
      return cloneDeep(initialState);
    }
    default:
      return state;
  }
}
