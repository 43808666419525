import {Spinner} from 'reactstrap';
import {toastr} from 'react-redux-toastr';
import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';

import Logo from 'assets/img/logo/impact_landing_logo.png';
import DeviceImg from 'assets/img/devices/pi-link.png';

import ViewDriver from './ViewDriver';
import DriverForm from './form/Driver.form';

import {apiRequest} from 'core/api/apiRequest';
import {devicesUrls} from 'core/api/ApiUrls/flareWaze.urls';
import {normalizeKeysForRequest, normalizeKeysFromResponse} from 'core/helpers';
import {trimStringValuesOfObject} from '../../../../core/helpers/fetch.helper';
import {DashedTitle} from '../../../../elements';

export default function DriverDevice() {
  const params = useParams();

  const [mode, setMode] = useState('edit');
  const [driver, setDriver] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(true);
  const [fromViewMode, setFromViewMode] = useState(false);

  const changeMode = () => {
    setFromViewMode(mode === 'view');
    setMode(mode === 'view' ? 'edit' : 'view');
  };

  async function onSubmit(values) {
    setLoading(true);
    delete values.maintenance;
    values = trimStringValuesOfObject(values);
    const {construction, vehicle, phoneNumber, defaultMessage, ...newValues} = values;
    // newValues.iconType = construction ? 'construction' : 'maintenance';
    newValues.phoneNumber = phoneNumber ? '+' + phoneNumber : '';
    const normalizedDriverInfo = normalizeKeysForRequest(newValues);
    const {success, message} = await apiRequest(
      devicesUrls.driver(params.serialNumber),
      'post',
      normalizedDriverInfo,
    );

    if (success) {
      setMode('view');
      setDriver(values);
    } else {
      toastr.error(message);
    }
    setLoading(false);
  }

  /** Side Effects */

  function getDriver() {
    (async () => {
      if (params.serialNumber) {
        const {data, success, message} = await apiRequest(
          devicesUrls.driver(params.serialNumber),
          'get',
          null,
          false,
          false,
          false,
        );
        if (success) {
          let {phoneNumber, iconType, ...driver} = normalizeKeysFromResponse(data);
          // let isConstructionSelected = iconType === 'construction';
          setDriver({
            ...driver,
            iconType,
            phoneNumber: phoneNumber.slice(1),
            // construction: isConstructionSelected,
            // maintenance: !isConstructionSelected,
          });
        } else {
          toastr.error(message);
          setIsRegistered(false);
        }
        setLoading(false);
      }
    })();
  }

  useEffect(getDriver, [params]);

  return (
    <div className='fl-body h-full overflow-auto'>
      {loading && (
        <div className='pi-loader-row pi-fixed'>
          <Spinner color='primary' type='border' size='lg' className={'mr-10'} />
          Loading...
        </div>
      )}
      <div className='fl-headline'>
        <div className='fl-container'>
          <img className='fl-logo' src={Logo} alt='Pi Lit logo' />
          <div className='fl-device-info'>
            <div className='fl-device-wrapper' style={{backgroundImage: `url('${DeviceImg}')`}} />
            <div className='fl-device-title'>
              <h2>
                {params.serialNumber}
                {!isRegistered && (
                  <span className='pi-error-message pi-color-red'>(Pi-Link Not Registered)</span>
                )}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className='fl-content'>
        <div className='fl-container'>
          {mode === 'view' ? (
            <ViewDriver driver={driver} changeMode={changeMode} setFromViewMode={setFromViewMode} />
          ) : (
            <>
              <DriverForm
                initialValues={driver}
                onSubmit={onSubmit}
                disabled={!isRegistered}
                fromViewMode={fromViewMode}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
