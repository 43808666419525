import React from 'react';
import {Redirect} from 'react-router-dom';

import async from '../../../components/Async';
import NotFound from '../../../components/NotFound';
import HomeIcon from '../../../assets/reactstrap-icons/home';
import sharedRoutingConfigs from '../../shared/routes';
const WorkZonePage = async(() => import('modules/workzone/pages/workZone'));
const WorkZoneMap = async(() => import('modules/workzone/pages/map'));
const Team = async(() => import('modules/workzone/pages/teams'));
const Devices = async(() => import('modules/workzone/pages/devices'));

const workZoneRoutes = {
  path: ['/workzone', '/workzone/:workzone_id'],
  name: 'Work Zones',
  icon: HomeIcon,
  component: WorkZonePage,
  children: [
    {
      path: '/workzone/teams',
      name: 'Work Zone Team',
      icon: HomeIcon,
      header: '',
      containsHome: false,
      component: Team,
    },
    {
      path: '/workzone/teams/:teamId?',
      name: 'Work Zone Team',
      icon: HomeIcon,
      header: '',
      containsHome: false,
      component: Team,
      children: [
        {
          path: '/workzone/teams/:teamId/*',
          name: 'Not Found',
          component: NotFound,
        },
      ],
    },
    {
      path: ['/workzone/map', '/workzone/map/:workzone_id'],
      name: 'Work Zones Map',
      icon: HomeIcon,
      header: '',
      containsHome: false,
      component: WorkZoneMap,
    },
    {
      path: '/workzone/devices',
      name: 'Work Zones Devices',
      icon: HomeIcon,
      header: '',
      containsHome: false,
      component: Devices,
    },
    {
      path: '/workzone/devices/:device_id?',
      name: 'Work Zones Devices',
      icon: HomeIcon,
      header: '',
      containsHome: false,
      component: Devices,
      children: [
        {
          path: '/workzone/devices/:device_id/*',
          name: 'Not Found',
          component: NotFound,
        },
      ],
    },
    //{
    //path: '/workzone/:workzone_id',
    //name: 'Work Zones',
    //icon: HomeIcon,
    //component: WorkZonePage,
    //children: [
    //{
    //path: '/workzone/:workzone_id/*',
    //name: 'Not Found',
    //component: NotFound,
    //},
    //],
    //},
  ],
};

const redirectRouteConfig = {
  path: '/*',
  children: null,
  component: () => {
    return <Redirect to={'/workzone/map'} />;
  },
};

export const dashboard = [workZoneRoutes, ...sharedRoutingConfigs];
