import React, {useCallback, useEffect, useMemo} from 'react';

//style
import classes from './CreateCompanyModal.module.scss';

//api
import {useCreateAndUpdateNamespaceMutation} from 'modules/shared/services';

//components
import {Modal} from 'components/Modal';
import {PhoneNumber, RadioButton, ReactSelect, TextField} from 'elements';
import {useFormik} from 'formik';
import moment from 'moment';

//utils
import * as yup from 'yup';
import 'yup-phone';
import {toastr} from 'react-redux-toastr';

import ERROR_MESSAGES from 'core/services/schemas/messages';

import {emailRegExp} from 'core/services/schemas/regexps';

const types = ['Impact Sensors', 'Workzone'];
const WORKSPACES = {'Impact Sensors': 'IMPACT_SENSORS', Workzone: 'WORKZONES'};

const time = moment.tz.names().map(value => ({label: value, value}));

const validationSchema = yup.object().shape({
  namespace: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .max(100, 'The namespace can not be over 100 characters')
    .test({
      name: 'checkNameSpace',
      test(value, ctx) {
        if (value?.startsWith('_')) {
          return ctx.createError({message: 'Namespace should not start with underscore.'});
        }
        if (!value?.match(/^[0-9A-Za-z._-]{0,100}$/g)) {
          return ctx.createError({
            message:
              'The namespace can only contain alphanumeric characters, periods, underscores, and hyphens.',
          });
        }
        return true;
      },
    }),
  company: yup.string().required(ERROR_MESSAGES.required),
  email: yup.string().required(ERROR_MESSAGES.required).matches(emailRegExp, ERROR_MESSAGES.email),
  phone: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .phone(undefined, true, 'Phone number is invalid.'),
  timezone: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(ERROR_MESSAGES.required),
  types: yup.array().min(1, ERROR_MESSAGES.required),
});

export const CreateCompanyModal = ({company, onClose, visible}) => {
  const [trigger, result] = useCreateAndUpdateNamespaceMutation();
  const {values, setFieldValue, errors, handleSubmit, resetForm, touched, setFieldError} =
    useFormik({
      initialValues: {
        namespace: '',
        company: company ?? '',
        email: '',
        timezone: {
          label: 'GMT',
          value: 'GMT',
        },
        types: [],
        phone: '',
      },
      validationSchema,
      validateOnMount: false,
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
      onSubmit(values) {
        trigger({
          namespace: values.namespace,
          company: values.company,
          timezone: values.timezone.value,
          account_types: values.types.map(v => WORKSPACES[v]),
          phone: values.phone,
          email: values.email,
        }).then(result => {
          if (result.data) {
            toastr.success(`Company ${company} created succussfuly`);
            onClose({namespace: values.namespace, company: values.company});
          } else if (result.error) {
            toastr.error('Creating company failed.');
          }
        });
      },
    });

  const handleValidation = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, {[name]: value});
      setFieldError(name, undefined);
    } catch (e) {
      if (e.path === name) {
        setFieldError(e.path, e.message);
      }
    }
  };

  const handleValueChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setFieldValue(name, value, false);
    handleValidation(name, value);
  };

  const handlePhoneNumberValues = (phone, country, e, formmattedValue) => {
    setFieldValue('phone', phone.length ? formmattedValue : '');
    handleValidation('phone', phone.length ? formmattedValue : '');
  };

  const handleSelectTimezone = v => {
    setFieldValue('timezone', v);
    handleValidation('timezone', v);
  };

  const handleToggleType = useCallback(
    event => {
      const value = event.target.value;
      const newValue = values.types.includes(value)
        ? values.types.filter(v => v !== value)
        : [...values.types, value];
      setFieldValue('types', newValue);
      handleValidation('types', newValue);
    },
    [values.types, setFieldValue],
  );

  useEffect(() => {
    setTimeout(() => {
      setFieldValue('phone', '+1', false);
    }, 100);
    setTimeout(() => {
      setFieldValue('phone', '', false);
    }, 200);

    return () => {
      resetForm();
    };
  }, [visible]);

  const _renderAccountTypes = useMemo(() => {
    return types.map(radio => {
      const selected = values.types.includes(radio);

      return (
        <li key={radio} className={classes.typeItem}>
          <RadioButton
            className='pi-width-sm pi-active-dark'
            type='checkbox'
            name={'AccountType'}
            labelId={`account-type-${radio}`}
            label={radio}
            value={radio}
            selectedValue={selected ? radio : null}
            onChange={handleToggleType}
          >
            {radio}
          </RadioButton>
        </li>
      );
    });
  }, [values.types, handleToggleType]);

  const submitDisabled = useMemo(() => {
    try {
      validationSchema.validateSync(values);

      return Object(errors).length || result.isLoading;
    } catch {
      return true;
    }
  }, [errors, values, result.isLoading]);

  return (
    <Modal
      title={'ADD NEW COMPANY'}
      closeTitle='Cancel'
      onClose={onClose}
      visible={visible}
      contentClassName={classes.container}
      className={classes.modal}
      defaultValue='GMT'
      submitButtonLable={'Create'}
      onSubmit={handleSubmit}
      isSubmitting={result.isLoading}
      mobileFullScreen
      submitDisabled={submitDisabled}
    >
      <TextField
        label='Namespace *'
        value={values.namespace}
        onChange={handleValueChange}
        name={'namespace'}
        errorMessage={errors.namespace}
        placeholder='Enter the namespace'
        relativeErrorMessage
      />
      <TextField
        label='Company *'
        value={values.company}
        onChange={handleValueChange}
        name={'company'}
        errorMessage={errors.company}
        placeholder='Enter the company'
        relativeErrorMessage
      />
      <div className={classes.row}>
        <TextField
          label='POC Email *'
          className={classes.items}
          value={values.email}
          onChange={handleValueChange}
          name={'email'}
          errorMessage={errors.email}
          placeholder='Enter email'
        />
        <PhoneNumber
          label={'POC Phone *'}
          className={classes.items}
          onChange={handlePhoneNumberValues}
          errorMessage={errors.phone}
          value={values.phone}
        />
      </div>
      <ReactSelect
        value={values.timezone}
        label={'Timezone *'}
        options={time}
        onChange={handleSelectTimezone}
        errorMessage={touched.timezone ? errors.timezone : ''}
      />
      <div className={classes.types}>
        <label className={classes.typesTitle}>Account Types *</label>
        <ul className={classes.typesList}>{_renderAccountTypes}</ul>
        {errors.types ? <p className={classes.error}>{errors.types}</p> : null}
      </div>
    </Modal>
  );
};
