import {useFormik} from 'formik';
import {useParams, useHistory} from 'react-router-dom';
import React, {useEffect, useState} from 'react';

import useToggle from 'core/hooks/useToggle';
import {PasswordField, Button} from 'elements';

import AuthLayout from './AuthLayout';
import {resetPasswordSchema} from './auth.schema';
import {apiRequest} from '../../core/api/apiRequest';
import {toastr} from 'react-redux-toastr';
import {normalizeKeysForRequest} from '../../core/helpers';
import {authCheckAsyncAction} from '../impact-detection/redux/actions/authActions';
import {useDispatch} from 'react-redux';

function Form({setStepState}) {
  const history = useHistory();
  const params = useParams();
  const [tokenIsValidated, toggleTokenValidated] = useToggle(false);
  const [focused, setFocused] = useState({password: false, resetPassword: false, field: ''});
  const [validationRules, setValidationRules] = useState({min: false, matches: false});

  async function onSubmit(values) {
    const {success, data} = await apiRequest(
      `user/complete_forgot_password/${params.token}`,
      'post',
      normalizeKeysForRequest(values),
    );
    if (success) {
      toastr.success('Reset your password successfully.');
      setStepState({step: 2, token: data.token});
    } else {
      toastr.error('Something went wrong. Please try again!');
    }
  }

  const {
    dirty,
    errors,
    values,
    touched,
    isValid,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    onSubmit,
    validationSchema: resetPasswordSchema,
    initialValues: {password: '', confirmPassword: ''},
  });

  function onFocus({target}) {
    setFocused(focused => ({...focused, [target.name]: true, field: target.name}));
  }

  /**
   * Side Effects.
   */
  function validateToken() {
    (async () => {
      let response = await apiRequest(`user/complete_forgot_password/${params.token}`);
      if (response.success) {
        toggleTokenValidated();
      } else {
        history.push('/not-found');
      }
    })();
  }

  function getValidationStateOfForm() {
    if (tokenIsValidated) {
      (async () => {
        let yupValidationState = {min: false, matches: false};
        await resetPasswordSchema
          .validate(values, {abortEarly: false})
          .then(a => (yupValidationState = {min: true, matches: true}))
          .catch(err => {
            if (focused.password || focused.confirmPassword) {
              yupValidationState = {min: true, matches: true};
            }
            err.inner.forEach(err => {
              if (focused.field === err.path && (err.type === 'min' || err.type === 'matches')) {
                yupValidationState[err.type] = false;
              }
            });
          });

        setValidationRules(yupValidationState);
      })();
    }
  }

  useEffect(validateToken, []);

  useEffect(getValidationStateOfForm, [values, focused]);

  return (
    <AuthLayout
      title='reset Password'
      className='pi-reset-password'
      headingChild={
        <ul className='pi-validation-rules'>
          <li className={`${validationRules.min ? 'pi-active' : ''}`}>
            <i className='icon-check' />
            <span>min 8 characters long</span>
          </li>
          <li className={`${validationRules.matches ? 'pi-active' : ''}`}>
            <i className='icon-check' />
            <span>min 1 number, 1 upper case and 1 special character</span>
          </li>
        </ul>
      }
    >
      <form onSubmit={handleSubmit}>
        <PasswordField
          shadow
          name='password'
          label='New Password *'
          value={values.password}
          errorMessage={touched.password && errors.password}
          onFocus={onFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <PasswordField
          shadow
          className='mt-25'
          name='confirmPassword'
          label='Confirm New Password *'
          value={values.confirmPassword}
          errorMessage={touched.confirmPassword && errors.confirmPassword}
          onFocus={onFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <div className='pi-text-center mt-50'>
          <Button
            color='gray'
            type='submit'
            styleType='filled'
            className='pi-width-sm'
            disabled={!dirty || !isValid || isSubmitting}
          >
            reset Password
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
}

function SuccessView({token}) {
  const dispatch = useDispatch();

  function onClick() {
    localStorage.setItem('accessToken', token);
    dispatch(authCheckAsyncAction());
  }

  return (
    <AuthLayout className='pi-reset-password'>
      <div className='pi-success-message pi-text-center'>
        <i className='icon-check' />
        <p>The password was reset successfully!</p>
        <p>You can now sign in to your account by clicking on the button below</p>
      </div>
      <div className='pi-text-center mt-30'>
        <Button styleType='filled' color='gray' className='pi-width-sm' onClick={onClick}>
          Sign In
        </Button>
      </div>
    </AuthLayout>
  );
}

export function ResetPassword() {
  const [stepState, setStepState] = useState({step: 1, token: ''});

  return stepState.step === 1 ? (
    <Form setStepState={setStepState} />
  ) : (
    <SuccessView token={stepState.token} />
  );
}
