import React from 'react';
import {useFormik} from 'formik';
import {toastr} from 'react-redux-toastr';

import AuthLayout from './AuthLayout';

import {TextField, Button} from 'elements';
import {apiRequest} from 'core/api/apiRequest';
import {forgotPasswordSchema} from './auth.schema';
import useToggle from '../../core/hooks/useToggle';

function Form({toggleSuccess}) {
  const {
    dirty,
    errors,
    values,
    touched,
    isValid,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    onSubmit,
    initialValues: {email: ''},
    validationSchema: forgotPasswordSchema,
  });

  async function onSubmit() {
    const {success, message} = await apiRequest('user/forgot_password', 'post', {
      email: values.email,
    });
    if (success) {
      toastr.success('We sent reset password link to your email.');
      toggleSuccess();
    } else {
      toastr.error(message);
    }
  }

  return (
    <AuthLayout
      title='Forgot Password'
      className='pi-forgot-password'
      description='Enter your email address to receive the link and reset your password'
    >
      <form onSubmit={handleSubmit}>
        <TextField
          shadow
          name='email'
          label='Email *'
          value={values.email}
          errorMessage={touched.email && errors.email}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <div className='pi-text-center mt-50'>
          <Button
            color='gray'
            type='submit'
            styleType='filled'
            className='pi-width-sm'
            disabled={!dirty || !isValid || isSubmitting}
          >
            Send Link
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
}

function SuccessView() {
  return (
    <AuthLayout className='pi-forgot-password'>
      <div className='pi-success-message pi-text-center'>
        <i className='icon-check' />
        <p>The link was sent to your email address successfully!</p>
      </div>
    </AuthLayout>
  );
}

export default function ForgotPassword() {
  const [success, toggleSuccess] = useToggle(false);

  return success ? <SuccessView /> : <Form toggleSuccess={toggleSuccess} />;
}
