import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import {ChildRoutes} from '../../../routes/ChildRoutes';
import ScrollToTop from '../../../components/ScrollToTop';
import WorkZoneLayout from '../../workzone/layout/wzLayout';

import {PrivateRoute} from '../../../routes/GuardRoute';
import {dashboard as workZoneRoutes} from 'modules/workzone/routes/routes';
import ReportMobile from '../../shared/pages/landingPage';
import DriverDevice from '../../shared/pages/driver-device/Index';
import PiLinkRecords from 'modules/shared/pages/pilink-records';

const Routes = ({initialPage}) => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={initialPage?.from || '/'}
            component={() => <Redirect to={initialPage?.screen} />}
          />
          <Route exact path='/mobile/report-flow/:impact_id?' component={ReportMobile} />
          <Route exact path='/pi-link/:serialNumber' component={DriverDevice} />
          <Route exact path='/pi-link-records' component={PiLinkRecords} />
          <PrivateRoute
            path='/*'
            exact
            component={() => <ChildRoutes layout={WorkZoneLayout} routes={workZoneRoutes} />}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};
export default Routes;
