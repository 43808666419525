import React, {useState, useCallback} from 'react';
import {BsQuestion, BsX} from 'react-icons/bs';

import Classes from './HelpModal.module.scss';

export default function HelpModal({
  children,
  defaultOpen = false,
  buttonClass,
  contentClass,
  style,
}) {
  const [modalOpen, setModalOpen] = useState(defaultOpen);

  const handleToggleModal = useCallback(() => {
    setModalOpen(state => !state);
  }, []);

  return (
    <>
      <div
        className={`absolute top-0 md:!top-[unset] md:bottom-14 md:right-2 w-full md:w-80 bg-white rounded z-40 transition-all duration-500 overflow-y-auto md:overflow-hidden ${
          Classes.HelpModalShadow
        } ${modalOpen ? 'h-screen md:h-min md:max-h-screen' : 'opacity-0 max-h-0'} ${contentClass}`}
        style={style}
      >
        {children}
      </div>
      <div
        className={`z-50 fixed bottom-2 right-2 w-10 h-10 rounded-full overflow-hidden ${
          modalOpen ? 'bg-[#FAA528]' : 'bg-[#354052]'
        } cursor-pointer flex justify-center items-center ${
          Classes.HelpModalShadow
        } ${buttonClass}`}
        onClick={handleToggleModal}
      >
        {modalOpen ? <BsX size={30} color={'#fff'} /> : <BsQuestion size={30} color={'#fff'} />}
      </div>
    </>
  );
}
