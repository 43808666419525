import React from 'react';

const StatusCell = (props) => {
    let className = props.className ? props.className : '';
    return (
        <>
           <td className={`pi-type-text-icon ${className}`}>
                <div className="pi-td">
                    <strong>{props?.label}</strong>
                    <i className={`pi-icon icon-${props.item?.icon} pi-color-${props.item?.color}`} dangerouslySetInnerHTML={{__html:props.item?.children}}/>
                </div>
            </td>
        </>
    )
}

export default StatusCell