import React from 'react';
import SummaryChart from '../summaryChart/summaryChart';
import {useGetImpactStatisticQuery} from 'modules/impact-detection/services';
import {Spinner} from 'reactstrap';

function SummaryTab({currentEvent, counts}) {
  const {data, isLoading} = useGetImpactStatisticQuery(currentEvent?.serial_number, {
    skip: !currentEvent?.serial_number,
    selectFromResult: result => {
      if (!result.isSuccess) {
        return {
          isLoading: result.isLoading,
          data: {},
        };
      }

      const minor = result.data?.['Minor Impact'];
      const major = result.data?.['Major Impact'];

      if (!minor && !major)
        return {
          data: {
            major: {},
            minor: {},
          },
          isLoading: result.isLoading,
        };

      const obj = {
        minor: {},
        major: {},
      };
      if (minor?.x_average && minor?.y_average && minor?.z_average) {
        obj.minor.magnitude = Math.round(
          Math.sqrt(minor.x_average ** 2 + minor.y_average ** 2 + minor.z_average ** 2),
        );
      }

      if (major?.x_average && major?.y_average && major?.z_average) {
        obj.major.magnitude = Math.round(
          Math.sqrt(major.x_average ** 2 + major.y_average ** 2 + major.z_average ** 2),
        );
      }

      Object.keys(major ?? {}).forEach(key => {
        obj.major[key[0]] = Math.round(major[key]);
      });

      Object.keys(minor ?? {}).forEach(key => {
        obj.minor[key[0]] = Math.round(minor[key]);
      });

      return {
        data: obj,
        isLoading: result.isLoading,
      };
    },
  });

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center h-[300px] items-center'>
        <Spinner color='primary' type='border' size='lg' />
      </div>
    );
  }

  return (
    <>
      <SummaryChart
        title='Magnitude'
        value={counts.magnitude}
        minorImpact={data?.minor?.magnitude}
        majorImpact={data?.major?.magnitude}
      />
      <SummaryChart
        title='X-Movement'
        value={counts.x}
        minorImpact={data?.minor?.x}
        majorImpact={data?.major?.x}
      />
      <SummaryChart
        title='Y-Movement'
        value={counts.y}
        minorImpact={data?.minor?.y}
        majorImpact={data?.major?.y}
      />
      <SummaryChart
        title='Z-Movement'
        value={counts.z}
        minorImpact={data?.minor?.z}
        majorImpact={data?.major?.z}
      />
    </>
  );
}

export default SummaryTab;
