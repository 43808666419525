import {
  ASYNC_STATUS,
  TAPER_GATEWAY_CREATE,
  TAPER_GATEWAY_UPDATE,
  UPDATE_TAPER_GATEWAY_BATTERY_NOTIFICATION_LEVEL,
  TAPER_GATEWAYS_GET_ALL,
  MUTE_TAPER_GATEWAY_DEVICE,
  UPDATE_TAPER_GATEWAY_DEVICE_LAMP_OPTIONS,
} from '../constants';

const initialState = {
  message: null,
  needRefresh: true,
  status: ASYNC_STATUS.FAILURE,
  imgUrl: null,
  data: [],
  getTaperGatewayData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TAPER_GATEWAYS_GET_ALL:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: false,
            message: 'Succeed to fetch all the Taper Gateways.',
            data: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case TAPER_GATEWAY_CREATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Adding a new device...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to create a new device.',
            imgUrl: action.imgUrl,
            data: [...state.data, action.data],
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case TAPER_GATEWAY_UPDATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating the device...',
          };
        case ASYNC_STATUS.SUCCEED:
          var data = [...state.data];
          const itemIndex = data.findIndex(x => x.device_id === action.data.device_id);
          if (itemIndex < 0) {
            return {
              ...state,
              status: ASYNC_STATUS.FAILURE,
              message: 'Wrong data updated: ' + action.data.device_id,
            };
          }
          const updatedData = action.data;
          data[itemIndex] = {...data[itemIndex], ...updatedData};

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update the device.',
            imgUrl: action.imgUrl,
            data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case UPDATE_TAPER_GATEWAY_BATTERY_NOTIFICATION_LEVEL ||
      MUTE_TAPER_GATEWAY_DEVICE ||
      UPDATE_TAPER_GATEWAY_DEVICE_LAMP_OPTIONS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating a device...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update a device.',
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    default:
      return state;
  }
}
