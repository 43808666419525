import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  getUserPermissions,
  saveRouteAction,
} from 'modules/impact-detection/redux/actions/authActions';

class PrivateRouteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isSuccess: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getUserPermissions());
  }

  async componentWillMount() {
    this.props.dispatch(saveRouteAction(this.props.location.pathname));
    if (
      this.props.location.pathname.length > 8 &&
      this.props.location.pathname.indexOf('/events/') === 0
    ) {
      localStorage.setItem('eventIdUrl', this.props.location.pathname);
    }
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.isLoggedin) {
      this.props.dispatch(saveRouteAction(this.props.location.pathname));
    }
  }

  render() {
    const {component: Component, isLoggedin, inProgress, ...rest} = this.props;

    if (inProgress) {
      return null;
    }
    return (
      <Route
        {...rest}
        render={props => {
          return isLoggedin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/auth/sign-in',
                state: {from: {location: props.location, match: props.match}},
              }}
            />
          );
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const {status} = state.auth;
  const isLoggedin = status !== 2;
  const inProgress = status === 0;

  return {isLoggedin, inProgress};
}

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
