import wzTeams from './wzTeamReducer';
import wzWorkzones from './workzoneReducer';
import workzoneMap from './workzoneMapReducer';
import workzoneTeamUsers from './workzoneTeamUsers';
import wzDevices from './wzDevicesReducer';

export default {
  wzTeams,
  wzWorkzones,
  workzoneMap,
  workzoneTeamUsers,

  wzDevices,
};
