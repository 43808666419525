import {
  ASYNC_STATUS,
  LOGIN,
  SIGNUP,
  AUTH_CHECK,
  LOGOUT,
  SAVE_ROUTE,
  RESEND_MAIL,
  WAZE_TOGGLE,
  GET_USER_PERMISSIONS,
} from '../constants';
import {apiRequest} from '../../../../core/api/apiRequest';
import {toastr} from 'react-redux-toastr';

import {setItemAsync} from '../../../../helpers/functions';
import {clearWorkspace} from '../../../shared/pages/workspaceSwitcher/store/workspace.actions';
import {asyncAction} from './asyncAction';
import {persistor} from 'store';

/**
 * Confirm sign up with token and password
 *
 */
export function signupAsyncAction(token, {...params}) {
  return async dispatch => {
    dispatch(asyncAction(SIGNUP, ASYNC_STATUS.REQUEST));
    // Verify the token
    let response = await apiRequest(`user/complete_signup/${token}`);
    if (response.success && response.status === 200) {
      response = await apiRequest(`user/complete_signup/${token}`, 'post', params);

      if (response.success) {
        await setItemAsync({key: 'accessToken', value: response.data.token || null});
        await dispatch(
          asyncAction(SIGNUP, ASYNC_STATUS.SUCCEED, {data: params, message: response.message}),
        );
        setTimeout(() => dispatch(authCheckAsyncAction()), 1000);
      }
      return;
    }
    dispatch(
      asyncAction(SIGNUP, ASYNC_STATUS.FAILURE, {
        message: response.message,
        source: 'signupAsyncAction',
      }),
    );
  };
}

/**
 * Confirm login with email and password
 *
 */
export function loginAsyncAction({...params}) {
  return async dispatch => {
    await dispatch(asyncAction(LOGIN, ASYNC_STATUS.REQUEST));
    const {success, data, message} = await apiRequest('user/login', 'post', params);
    if (success) {
      await setItemAsync({key: 'accessToken', value: data.token || null});
      await dispatch(asyncAction(LOGIN, ASYNC_STATUS.SUCCEED, {data, message}));
      // setTimeout(() => dispatch(authCheckAsyncAction()));
      toastr.success('Authentication', 'Welcome to our dashboard!');
    } else {
      dispatch(asyncAction(LOGIN, ASYNC_STATUS.FAILURE, {message}));
      toastr.error('Invalid authentication! Please try again.');
    }
  };
}

export function logoutAsyncAction() {
  return async dispatch => {
    const response = await apiRequest('user/logout', 'get');
    dispatch(asyncAction(LOGOUT, ASYNC_STATUS.REQUEST));
    dispatch(clearWorkspace());
    if (response.success) {
      document.cookie = 'auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

      localStorage.removeItem('accessToken');
      localStorage.removeItem('eventIdUrl');

      dispatch(asyncAction(LOGOUT, ASYNC_STATUS.SUCCEED, {message: response.message}));
      dispatch(authCheckAsyncAction());
    } else {
      dispatch(asyncAction(LOGOUT, ASYNC_STATUS.FAILURE, {message: response.message}));
    }
  };
}

export function saveRouteAction(url) {
  return {
    type: SAVE_ROUTE,
    url,
  };
}

/**
 * Check if autherized or not
 *
 */
export function authCheckAsyncAction() {
  return async dispatch => {
    dispatch(asyncAction(AUTH_CHECK, ASYNC_STATUS.REQUEST));
    const response = await apiRequest('user/authenticated');
    if (response.success) {
      dispatch(
        asyncAction(AUTH_CHECK, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
    } else {
      dispatch(asyncAction(AUTH_CHECK, ASYNC_STATUS.FAILURE, {message: response.message}));
      persistor.purge();
    }
  };
}

/**
 * ResendEmail
 *
 */

export function resendEmailAction(email) {
  let params = {email: email};
  return async dispatch => {
    const response = await apiRequest('user/resend_email_verification_link', 'post', params);
    if (response.success) {
      dispatch(
        asyncAction(RESEND_MAIL, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
      toastr.success('Success', 'Invitation sent successfully!');
    } else {
      dispatch(asyncAction(RESEND_MAIL, ASYNC_STATUS.FAILURE, {message: response.message}));
      toastr.error('Error', response.message);
    }
  };
}

/**
 * Waze toggle
 *
 */

export function wazeAction(is_active, device_id) {
  let params = {
    device_id: device_id,
    is_active: is_active,
  };
  return async dispatch => {
    const response = await apiRequest('common/waze', 'post', params);
    if (response.success) {
      dispatch(
        asyncAction(WAZE_TOGGLE, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
    } else {
      dispatch(asyncAction(WAZE_TOGGLE, ASYNC_STATUS.FAILURE, {message: response.message}));
    }
  };
}

export function getUserPermissions() {
  return async dispatch => {
    const response = await apiRequest('api/v0/users/roles/get-grant-permissions');

    if (response.success) {
      dispatch(asyncAction(GET_USER_PERMISSIONS, ASYNC_STATUS.SUCCEED, {data: response.data}));
    } else {
      dispatch(asyncAction(GET_USER_PERMISSIONS, ASYNC_STATUS.FAILURE, {data: response.data}));
    }
  };
}
