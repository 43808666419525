import {
  CLEAR_WORKZONE_CURSOR,
  GET_ALL_WORKZONE_DEVICES,
  DELETE_WORKZONE_DEVICE,
  SET_REMOVE_DEVICE,
  GET_ASSET_TYPES_WZ,
  GET_DEVICE_MANUFACTURERS,
  GET_DEVICE_MODELS,
  ADD_NEW_DEVICE_WZ,
  EDIT_DEVICE_WZ,
  SET_DEVICE_ADDED_WZ,
  UPDATE_DEVICE,
} from '../constants';
import {ASYNC_STATUS} from '../../../impact-detection/redux/constants';

const initialState = {
  cursorNextWorkZone: '',
  allDevices: [],
  assetTypes: [],
  manufacturers: [],
  models: [],
  currentPage: 1,
  searchValue: '',
  message: null,
  removeDevice: false,
  deviceAdded: false,
  getAllDevicesStatus: ASYNC_STATUS.FAILURE,
  deleteWorkzoneDeviceStatus: ASYNC_STATUS.FAILURE,
  getAssetTypesStatus: ASYNC_STATUS.FAILURE,
  getDeviceManufacturersStatus: ASYNC_STATUS.FAILURE,
  getDeviceModelsStatus: ASYNC_STATUS.FAILURE,
  addNewDeviceWzStatus: ASYNC_STATUS.FAILURE,
  editDeviseWzStatus: ASYNC_STATUS.FAILURE,
};

export default function reducer(state = {...initialState}, action) {
  switch (action.type) {
    case GET_ALL_WORKZONE_DEVICES:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getAllDevicesStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching all devices ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          const {
            data: {results: devices, cursor},
          } = action;

          return {
            ...state,
            currentPage: 1,
            searchValue: '',
            cursorNextWorkZone: cursor,
            getAllDevicesStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the workzone devices.',
            allDevices: action.isScroll === true ? [...state.allDevices, ...devices] : devices,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getAllDevicesStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case DELETE_WORKZONE_DEVICE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            deleteWorkzoneDeviceStatus: ASYNC_STATUS.REQUEST,
            message: 'Deleting workzone device...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            deleteWorkzoneDeviceStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to delete workzone device.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            deleteWorkzoneDeviceStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case GET_ASSET_TYPES_WZ:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getAssetTypesStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching asset types ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            getAssetTypesStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all asset types.',
            assetTypes: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getAssetTypesStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case GET_DEVICE_MANUFACTURERS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getDeviceManufacturersStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching manufacturers ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            getDeviceManufacturersStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all manufacturers.',
            manufacturers: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getDeviceManufacturersStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case GET_DEVICE_MODELS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getDeviceModelsStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching models ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            getDeviceModelsStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all models.',
            models: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getDeviceModelsStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case ADD_NEW_DEVICE_WZ:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            addNewDeviceWzStatus: ASYNC_STATUS.REQUEST,
            message: 'Adding new workzone device...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            addNewDeviceWzStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to add new workzone device.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            addNewDeviceWzStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case EDIT_DEVICE_WZ:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            editDeviceWzStatus: ASYNC_STATUS.REQUEST,
            message: 'Adding new workzone device...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            editDeviceWzStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to add new workzone device.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            addNewDeviceWzStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case SET_DEVICE_ADDED_WZ: {
      return {...state, deviceAdded: action.bool};
    }
    case CLEAR_WORKZONE_CURSOR: {
      return {...state, cursorNextWorkZone: ''};
    }
    case SET_REMOVE_DEVICE: {
      return {...state, removeDevice: action.bool};
    }
    case UPDATE_DEVICE: {
      const devices = [];
      state.allDevices.forEach(device => {
        if (device.id === action.data.id) {
          if (!action.data.removable) {
            device = {...device, ...action.data};
            devices.push(device);
          }
        } else {
          devices.push(device);
        }
      });

      return {...state, allDevices: devices};
    }
    default:
      return state;
  }
}
