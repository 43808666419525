import React from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faWrench} from '@fortawesome/free-solid-svg-icons';

const options = {
  teams: '#FAA528',
  workzones: '#FAA528',
};

const WorkZoneMobileNavigation = props => {
  const {handleMobileMenuIcon, changeSVGColorWhite, changeSVGColorYellow, pathname} = props;

  return (
    <div>
      <NavLink
        onClick={handleMobileMenuIcon}
        exact
        onMouseLeave={() => changeSVGColorYellow('workzone')}
        onMouseOver={() => changeSVGColorWhite('workzone')}
        className='new_sidebar_menu'
        activeClassName='new_sidebar_map_active'
        to='/workzone'
      >
        <span className='sideBar_mapIcon'>
          <FontAwesomeIcon
            color={pathname === '/workzone' ? '#ffffff' : options.workzones}
            icon={faWrench}
          />
        </span>
        Work Zones
      </NavLink>
      <NavLink
        onClick={handleMobileMenuIcon}
        onMouseLeave={() => changeSVGColorYellow('teams')}
        onMouseOver={() => changeSVGColorWhite('teams')}
        className='new_sidebar_menu'
        activeClassName='new_sidebar_map_active'
        to='/workzone/teams'
      >
        <span className='sideBar_mapIcon'>
          <FontAwesomeIcon
            color={pathname === '/workzone/teams' ? '#ffffff' : options.teams}
            icon={faUser}
          />
        </span>
        Teams
      </NavLink>
    </div>
  );
};

export default WorkZoneMobileNavigation;
