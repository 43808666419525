import {
  ASYNC_STATUS,
  AUTH_CHECK,
  LOGIN,
  SIGNUP,
  WORKSPACES,
} from 'modules/impact-detection/redux/constants';
import {CLEAR_WORKSPACE, CHANGE_WORKSPACE} from './workspace.actionTypes';

const workspaces = {
  [WORKSPACES.impactDetection]: {
    name: WORKSPACES.impactDetection,
    routes: {default: '/maps', team: '/teams'},
    reducers: {team: 'teamsReducer'},
    actions: {team: 'teamsActions'},
    states: {team: 'teams'},
  },
  [WORKSPACES.workzone]: {
    name: WORKSPACES.workzone,
    routes: {default: '/workzone', team: '/workzone/teams'},
    reducers: {team: 'wzTeamReducers'},
    actions: {team: 'wzTeamActions'},
    states: {team: 'wzTeams'},
  },
  [WORKSPACES.flarewaze]: {name: WORKSPACES.flarewaze, routes: {default: '/flarewaze'}},
};
export const initialState = {
  workspaces,
  currentWorkspace: {},
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case CHANGE_WORKSPACE:
      return {
        ...state,
        defaultWorkspace: actions.payload.defaultWorkspace || state.defaultWorkspace,
        currentWorkspace: state.workspaces[actions.payload.currentWorkspaceName],
      };
    case CLEAR_WORKSPACE:
      return {...state, currentWorkspace: {}};
    case LOGIN:
    case AUTH_CHECK:
      let newState = state;
      let accountType = actions.data?.default_account_type;

      if (actions.status === ASYNC_STATUS.SUCCEED) {
        let workspace;
        if (
          window.location.pathname.includes('workzone') &&
          accountType.includes(WORKSPACES.workzone)
        ) {
          workspace = WORKSPACES.workzone;
        } else if (actions.type === LOGIN) {
          workspace = accountType;
        } else {
          workspace =
            localStorage.lastSelectedWorkspace || accountType || WORKSPACES.impactDetection;
        }

        // const workspace =
        //   actions.type === LOGIN
        //     ? actions.data.default_account_type
        //     : window.location.pathname.includes('workzone') &&
        //       actions.data.account_types.includes(WORKSPACES.workzone)
        //     ? WORKSPACES.workzone
        //     : localStorage.lastSelectedWorkspace || WORKSPACES.impactDetection;

        newState = {
          ...state,
          defaultWorkspace: actions.data.default_account_type,
          currentWorkspace: state.workspaces[workspace],
        };
      }
      return newState;
    default:
      return state;
  }
}
