import fetch from 'cross-fetch';
import {store} from '../../store';
import {generalError} from 'core/helpers/fetch.helper';
import {timer} from '../../helpers/functions';
import {setNavigateToAfterAuth} from 'modules/shared/redux/slices/globalSettings.slice';

const signals = {};

export const BASE_URL = process.env.REACT_APP_BASE_API_URL
  ? process.env.REACT_APP_BASE_API_URL
  : window.location.origin + '/';

export async function apiRequest(
  relativeUrl,
  method = 'get',
  params = null,
  multiForm = false,
  fullUrl = false,
  toNotFound = true,
  cancelable,
) {
  const controller = new AbortController();
  const {signal} = controller;

  if (cancelable) {
    signals[relativeUrl] = controller;
  }

  let url = fullUrl ? relativeUrl : BASE_URL + relativeUrl;
  if (method === 'get' && params && Object.keys(params).length) {
    url = url + '?';

    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        const value =
          key === 'limit' ? (window.innerHeight >= 1800 ? 50 : params[key]) : params[key];

        if (Object.keys(params).indexOf(key) === Object.keys(params).length - 1) {
          url = `${url}${key}=${value}`;
        } else {
          url = `${url}${key}=${value}&`;
        }
      }
    }
  }

  let accessToken = localStorage.getItem('accessToken');
  const headers = {
    Accept: `application/${multiForm ? 'form-data' : 'json'}`,
  };

  if (accessToken !== null) {
    headers['Authorization'] = 'Bearer ' + accessToken;
  }

  let body = null;
  if (params === null || method === 'get') {
    body = null;
  } else {
    if (multiForm) {
      body = new FormData();
      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          if (key === 'file') {
            for (let i = 0; i < params[key].length; i++) {
              const value = params[key][i];
              body.append(key, value);
            }
          } else {
            const value = params[key];
            body.append(key, value);
          }
        }
      }
    } else {
      body = JSON.stringify(params);
    }
  }

  try {
    const res = await fetch(url, {signal, headers, method, body});

    if (res.status === 404 && toNotFound) {
      window.history.replaceState(null, null, '/not-found');
      window.location.reload(); //made to prevent canceled toaster messages
      await timer(1000);
      return {
        message: '',
        success: false,
        status: res.status,
      };
    }

    if (res.status === 401) {
      localStorage.removeItem('accessToken');
      store.dispatch(
        setNavigateToAfterAuth(window.location.href.replace(window.location.origin, '')),
      );

      if (relativeUrl !== 'user/login' || !window.location.pathname.startsWith('/auth/')) {
        //made for workspaces change, need to change localStorage.clear in future
        localStorage.removeItem('persist:root');
        store.dispatch({type: 'CLEAR_STORE'});
      }

      //Add 401 redirection on workspaces(e.g. when we will have /workzone/ and /impact-detection/ path prefixes on
      // logged in state)
    }

    if (res.status >= 400) {
      let fromServer = await res.json();
      return {
        success: false,
        status: res.status,
        message: fromServer.message,
      };
    }
    return res
      .json()
      .then(data => {
        return {
          data,
          success: true,
          message: 'Succeed',
          status: res.status,
        };
      })
      .catch(e => {
        if (res.status >= 200 && res.status <= 299) {
          return {
            success: true,
            message: 'Succeed',
            status: res.status,
          };
        } else {
          throw e;
        }
      });
  } catch (error) {
    let response = {
      success: false,
      status: error.status,
      message: generalError(error.message),
    };

    //Aborted
    if (error instanceof DOMException && error.name === 'AbortError') {
      await timer(1000);
    }

    return response;
  }
}

export const cancelRequest = key => signals[key]?.abort();
