import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import {PrivateRoute} from './GuardRoute';
import {ChildRoutes} from './ChildRoutes';
import ReportMobile from '../modules/shared/pages/landingPage';
import DriverDevice from '../modules/shared/pages/driver-device/Index';
import PiLinkRecords from '../modules/shared/pages/pilink-records';
import DashboardLayout from '../layouts/Dashboard';
import ScrollToTop from '../components/ScrollToTop';
import {getDashboardRoutes} from './index';

const Routes = ({user, initialPage}) => {
  const hiddenRoutes = {accountSettings: !(user.is_pivar_admin || user.is_account_admin)};

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={initialPage?.from || '/'}
            component={() => <Redirect to={initialPage?.screen} />}
          />
          <Route exact path='/mobile/report-flow/:impact_id' component={ReportMobile} />
          <Route exact path='/pi-link/:serialNumber' component={DriverDevice} />
          <Route exact path='/pi-link-records' component={PiLinkRecords} />

          {/* Impact Detection routes */}
          <PrivateRoute
            exact
            path='/*'
            component={() => (
              <ChildRoutes layout={DashboardLayout} routes={getDashboardRoutes(hiddenRoutes)} />
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
