import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import React, {useEffect, useState} from 'react';

import AuthLayout from '../AuthLayout';
import {Button, VerificationField, PhoneNumber} from 'elements';

import {apiRequest} from 'core/api/apiRequest';
import {normalizeKeysForRequest} from 'core/helpers';
import {preventEventAndStopPropagation} from 'core/helpers/DOM.helper';
import {authCheckAsyncAction} from '../../impact-detection/redux/actions/authActions';

import {AuthUrls} from '../auth.urls';
import {signWithPhoneSchema, signWithPhoneVerificationCode} from '../auth.schema';

export function SignInWithPhone({setIsSignInWithPhone}) {
  const dispatch = useDispatch();
  const [stepperState, setStepperState] = useState({step: 1, phone: ''});

  async function onSubmit(values) {
    if (stepperState.step === 1) {
      const phoneNumber = values.phone ? '+' + values.phone : '';
      const {success, message} = await apiRequest(
        AuthUrls.loginWithPhoneNumber,
        'post',
        normalizeKeysForRequest({phoneNumber}),
      );
      if (success) {
        setStepperState({step: 2, phone: values.phone});
      } else {
        toastr.error(message);
      }
    } else {
      const {success, message, data} = await apiRequest(
        AuthUrls.phoneNumberVerified,
        'post',
        normalizeKeysForRequest({otp: values.code}),
      );
      if (success) {
        toastr.success('Authentication', 'Welcome to our dashboard!');
        localStorage.setItem('accessToken', data.token);
        dispatch(authCheckAsyncAction());
      } else {
        toastr.error(message);
      }
    }
  }

  const {
    dirty,
    status,
    errors,
    values,
    touched,
    isValid,
    setStatus,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    onSubmit,
    initialValues: {[stepperState.step === 1 ? 'phone' : 'code']: ''},
    validationSchema: stepperState.step === 1 ? signWithPhoneSchema : signWithPhoneVerificationCode,
    enableReinitialize: true,
  });

  function onVerificationCodeChange(value) {
    setFieldValue('code', value.toUpperCase());
  }

  async function resendVerificationCode(e) {
    preventEventAndStopPropagation(e);
    setStepperState(({phone}) => ({step: 1, phone}));
  }

  function validatePhone(isValid) {
    setStatus(isValid ? null : {phone: 'The phone number is not valid. '});
  }

  function onPhoneNumberBlur() {
    setFieldTouched('phone', true);
  }

  function onPhoneNumberChange(v) {
    setFieldValue('phone', v);
  }

  /**
   * Side Effects.
   */

  function stepper() {
    if (stepperState.step === 1 && stepperState.phone) {
      setFieldValue('phone', stepperState.phone);
    }
  }

  useEffect(stepper, [stepperState]);

  return (
    <AuthLayout
      title={stepperState.step === 1 ? 'Sign in' : 'Enter the verification Code'}
      className='pi-sign-in'
    >
      <form onSubmit={handleSubmit}>
        {stepperState.step === 1 ? (
          <PhoneNumber
            shadow
            name='phone'
            className='mt-30'
            label='Phone Number'
            value={values.phone}
            errorMessage={touched.phone && (errors.phone || status?.phone)}
            onValidate={validatePhone}
            onBlur={onPhoneNumberBlur}
            onChange={onPhoneNumberChange}
          />
        ) : (
          <div className='mt-50'>
            <VerificationField onChange={onVerificationCodeChange} />
            <div className='pi-text-center mt-15'>
              <a href='' onClick={resendVerificationCode}>
                Resend the code
              </a>
            </div>
          </div>
        )}
        <div className='pi-text-center mt-30'>
          <Button
            color='gray'
            type='submit'
            styleType='filled'
            className='pi-width-sm'
            disabled={
              !dirty || !isValid || isSubmitting || (stepperState.step === 1 && status?.phone)
            }
          >
            Sign in
          </Button>

          <div className='pi-full mt-20'>
            <Button
              color='white'
              styleType='outline'
              className='pi-width-sm'
              onClick={setIsSignInWithPhone}
            >
              Sign in with Email
            </Button>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
}
