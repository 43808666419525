import {
  ADD_TEAM,
  GET_TEAM,
  GET_GROUP,
  ADD_GROUP,
  GET_MODELS,
  EDIT_GROUP,
  ASYNC_STATUS,
  DELETE_GROUP,
  GET_SUBGROUP,
  GET_TEAM_LIST,
  ADD_NEW_MEMBER,
  GET_ASSET_TYPES,
  GET_TEAM_GROUPS,
  ADD_NEW_LOCATION,
  GET_TEAM_LIST_OLD,
  GET_MANUFACTURERS,
  GET_USERS_LIST_TEAM,
  CLEAR_TEAMS_REDESIGN,
  GET_LOCATIONS_LIST_TEAM,
} from '../constants';

const initialState = {
  teamsOld: {
    data: [],
    error: false,
    loading: false,
  },
  teams: {
    data: [],
    error: false,
    loading: false,
  },
  team: {
    data: {},
    error: false,
    loading: false,
    groupLoading: true,
  },
  group: {
    data: {},
    error: false,
    loading: false,
  },
  subgroup: {
    data: {},
    error: false,
    loading: false,
  },
  addingGroup: {
    addingGroupStatus: null,
  },
  usersList: {
    data: [],
    error: false,
    loading: false,
    usersListStatus: null,
  },
  locationsList: {
    data: [],
    error: false,
    loading: false,
  },
  assetTypesList: {
    data: [],
    error: false,
    loading: false,
  },
  modelsList: {
    data: [],
    error: false,
    loading: false,
  },
  manufacturersList: {
    data: [],
    error: false,
    loading: false,
  },
};

export default function reducer(state = initialState, {type, data, status}) {
  switch (type) {
    case GET_TEAM_LIST: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            teams: {
              ...state.teams,
              loading: true,
            },
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let teams = data.teams;
          let team = teams[0];

          if (data.teamId) {
            teams = teams.map(item => {
              if (item.id === data.teamId) {
                team = {...item};
                item.isActive = true;
              }
              return item;
            });
          } else {
            teams = data.teams;
            teams[0].isActive = true;
            team = {...teams[0]};
          }

          return {
            ...state,
            teams: {
              error: false,
              loading: false,
              data: data.teams,
            },
            team: {
              ...state.team,
              data: {
                ...state.team.data,
                ...team,
              },
              error: false,
            },
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            teams: {
              data: [],
              loading: false,
              error: data,
            },
            team: initialState.team,
          };
        }
        default:
          return state;
      }
    }
    case GET_TEAM_LIST_OLD: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            teamsOld: {
              ...state.teamsOld,
              loading: true,
            },
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            teamsOld: {
              error: false,
              loading: false,
              data: data.teams,
            },
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            teamsOld: {
              data: [],
              loading: false,
              error: data,
            },
          };
        }
        default:
          return state;
      }
    }

    case GET_TEAM: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            team: {
              ...initialState.team,
              groupLoading: state.team.groupLoading,
              loading: true,
            },
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let teams = state.teams.data.map(item => {
            if (item.isActive) delete item.isActive;
            if (item.id === data.id) {
              item.isActive = true;
            }
            return item;
          });

          return {
            ...state,
            team: {
              data: {
                ...state.team.data,
                ...data,
              },
              groupLoading: state.team.groupLoading,
              error: false,
              loading: false,
            },
            teams: {
              ...state.teams,
              data: teams,
            },
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            team: {
              data: {},
              loading: false,
              error: data,
            },
          };
        }
        default:
          return state;
      }
    }
    case GET_TEAM_GROUPS: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            team: {
              ...state.team,
              groupLoading: true,
            },
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            team: {
              ...state.team,
              data: {...state.team.data, groups: data},
              error: false,
              groupLoading: false,
            },
          };
        }
        default:
          return state;
      }
    }
    case GET_GROUP: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            group: {
              ...initialState.group,
              loading: true,
            },
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            group: {
              data,
              error: false,
              loading: false,
            },
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            group: {
              data: {},
              loading: false,
              error: data,
            },
          };
        }
        case ASYNC_STATUS.RESET: {
          return {
            ...state,
            group: {...initialState.group},
          };
        }
        default:
          return state;
      }
    }
    case GET_SUBGROUP: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            subgroup: {
              ...initialState.subgroup,
              loading: true,
            },
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            subgroup: {
              data,
              error: false,
              loading: false,
            },
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            subgroup: {
              error: data,
            },
          };
        }
        default:
          return state;
      }
    }
    case ADD_TEAM: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating group name ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            addingGroupStatus: ASYNC_STATUS.SUCCEED,
            message: data.message,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        }

        default:
          return state;
      }
    }
    case ADD_GROUP: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            team: {
              ...state.team,
              groupLoading: true,
            },
            status: ASYNC_STATUS.REQUEST,
            message: 'Adding group name ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            addingGroupStatus: ASYNC_STATUS.SUCCEED,
            team: {
              ...state.team,
              groupLoading: false,
            },
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            team: {
              ...state.team,
              groupLoading: false,
            },
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        }

        default:
          return state;
      }
    }
    case EDIT_GROUP: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          const newTeamData =
            data?.groupType === 'team'
              ? {
                  team: {
                    ...state.team,
                    loading: true,
                  },
                }
              : {};
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating group name ...',
            ...newTeamData,
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let newTeamsState = {};

          if (data.group_type === 'TEAM') {
            newTeamsState = {
              newTeamsState: {
                ...state.teams,
                data: state.teams.data.map(team => {
                  if (data.id === team.id) {
                    team.name = data.name;
                  }
                }),
              },
            };
          }

          return {
            ...state,
            ...newTeamsState,
            addingGroupStatus: ASYNC_STATUS.SUCCEED,
            message: data.message,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        }

        default:
          return state;
      }
    }
    case DELETE_GROUP: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            // message: data.message
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        }

        default:
          return state;
      }
    }
    case GET_USERS_LIST_TEAM: {
      switch (status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            // usersList: {
            //     usersListStatus: ASYNC_STATUS.REQUEST
            // },
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            usersList: {
              data: data.sort((a, b) => {
                let nameA = a.firstname.toLowerCase() + ' ' + a.lastname.toLowerCase();
                let nameB = b.firstname.toLowerCase() + ' ' + b.lastname.toLowerCase();
                return nameA.trim() > nameB.trim() ? 1 : -1;
              }),
              // usersListStatus: ASYNC_STATUS.SUCCEED
            },
            message: data.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        default:
          return state;
      }
    }
    case GET_LOCATIONS_LIST_TEAM: {
      switch (status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            locationsList: {
              data: data.sort((a, b) => {
                let nameA = a.name.toLowerCase();
                let nameB = b.name.toLowerCase();
                return nameB.trim() > nameA.trim() ? 1 : -1;
              }),
            },
            message: data.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        default:
          return state;
      }
    }
    case GET_ASSET_TYPES: {
      switch (status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            assetTypesList: {
              data,
            },
            message: data.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        default:
          return state;
      }
    }
    case GET_MODELS: {
      switch (status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            modelsList: {
              data,
            },
            message: data.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        default:
          return state;
      }
    }
    case GET_MANUFACTURERS: {
      switch (status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            manufacturersList: {
              data,
            },
            message: data.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        default:
          return state;
      }
    }
    case ADD_NEW_MEMBER: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Adding new member ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            message: data.message,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        }

        default:
          return state;
      }
    }
    case ADD_NEW_LOCATION: {
      switch (status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Adding new member ...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            addingGroupStatus: ASYNC_STATUS.SUCCEED,
            message: data.message,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: data.message,
          };
        }

        default:
          return state;
      }
    }
    case CLEAR_TEAMS_REDESIGN:
      return {...initialState};

    default:
      return state;
  }
}
