import {
  CREATE_WORKZONE,
  UPDATE_ONE_WORKZONE,
  DELETE_ONE_WORKZONE,
  GET_ALL_WORKZONES,
  CLEAR_WORKZONE_CURSOR,
  GET_ONE_WORKZONE,
  CLEAR_ONE_WORKZONE,
  UPDATE_WORKZONE_LIST,
  GET_WORKZONE_DEVICES,
  GET_WORKZONE_ONE_DEVICE,
  UPDATE_WORKZONE_ONE_DEVICE,
  UPDATE_WORKZONE_DEVICE_LIST,
  SET_WORKZONE_DEVICE_TABLE,
  CREATE_TAPER_SCHEDULE,
  UPDATE_TAPER_SCHEDULE,
  DELETE_TAPER_SCHEDULE,
  SET_WORKZONE_DEVICE_LAMP_TABLE,
  UPDATE_WORKZONE_DEVICE_LAMP_TABLE,
  UPDATE_LAMP_LIST,
  GET_NOT_CONNECTED_WORZONE_DEVICES,
  CLEAR_ONE_WORKZONE_DEVICE,
  CLEAR_WORKZONE_DEVICE_LIST,
} from '../constants';
import {ASYNC_STATUS} from '../../../impact-detection/redux/constants';

const initialState = {
  loadedWorkzones: [],
  activeWorkzone: null,
  cursorNextWorkZone: '',
  devices: [],
  tableDevices: [],
  tableLamps: [],
  notConnectedDevices: [],
  activeDevice: null,
  currentPage: 1,
  message: null,
  getOneStatus: ASYNC_STATUS.FAILURE,
  getAllStatus: ASYNC_STATUS.FAILURE,
  createStatus: ASYNC_STATUS.FAILURE,
  updateStatus: ASYNC_STATUS.FAILURE,
  deleteStatus: ASYNC_STATUS.FAILURE,
  getDevicesStatus: ASYNC_STATUS.FAILURE,
  getOneDeviceStatus: ASYNC_STATUS.FAILURE,
  updateOneDeviceStatus: ASYNC_STATUS.FAILURE,
  createScheduleStatus: ASYNC_STATUS.FAILURE,
  updateScheduleStatus: ASYNC_STATUS.FAILURE,
  deleteScheduleStatus: ASYNC_STATUS.FAILURE,
  updateLAmpStatus: ASYNC_STATUS.FAILURE,
  deleteLAmpStatus: ASYNC_STATUS.FAILURE,
  getOtherDevicesStatus: ASYNC_STATUS.FAILURE,
};

export default function reducer(state = {...initialState}, action) {
  switch (action.type) {
    case CREATE_WORKZONE: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            createStatus: ASYNC_STATUS.REQUEST,
            message: 'Creating workzone...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            createStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to create the workzone.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            createStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }
    case GET_ALL_WORKZONES: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getAllStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching workzones list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          const {
            data: {results: data, cursor},
          } = action;
          const loadedWorkzones = data;

          return {
            ...state,
            getAllStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the workzones.',
            loadedWorkzones:
              action.isScroll === true
                ? [...state.loadedWorkzones, ...loadedWorkzones]
                : loadedWorkzones,
            cursorNextWorkZone: cursor,
            currentPage: 1,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getAllStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }
    case GET_ONE_WORKZONE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getOneStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching workzone...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          const {data} = action;

          return {
            ...state,
            getOneStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch the workzone.',
            activeWorkzone: data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getOneStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case GET_WORKZONE_DEVICES:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getDevicesStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching workzone devices...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          const {data} = action;

          return {
            ...state,
            getDevicesStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch the workzone.',
            tableDevices: data,
            devices: data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getDevicesStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case GET_WORKZONE_ONE_DEVICE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getOneDeviceStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching workzone device...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          const {data} = action;

          return {
            ...state,
            getOneDeviceStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch the workzone device.',
            activeDevice: data,
            tableLamps: data?.devices || [],
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getOneDeviceStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case UPDATE_WORKZONE_ONE_DEVICE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            updateOneDeviceStatus: ASYNC_STATUS.REQUEST,
            message: 'Updating workzone device...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          const {data} = action;

          return {
            ...state,
            updateOneDeviceStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to update the workzone device.',
            activeDevice: data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            updateOneDeviceStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case UPDATE_WORKZONE_DEVICE_LIST:
      const {data = {}} = action;

      const updated = state.tableDevices;

      const updatedItem = updated.find(d => d.id === data.id);
      Object.assign(updatedItem, {...data});

      return {
        ...state,
        tableDevices: [...updated],
        devices: [...updated],
      };

    case CLEAR_WORKZONE_DEVICE_LIST:
      return {
        ...state,
        tableDevices: [],
        devices: [],
      };
    case SET_WORKZONE_DEVICE_TABLE:
      return {
        ...state,
        tableDevices: [...action.data],
      };
    case SET_WORKZONE_DEVICE_LAMP_TABLE:
      return {
        ...state,
        tableLamps: [...action.data],
      };
    case UPDATE_ONE_WORKZONE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            updateStatus: ASYNC_STATUS.REQUEST,
            message: 'Update workzone...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            activeWorkzone: action.data,
            updateStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to update the workzone.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            updateStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case DELETE_ONE_WORKZONE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            deleteStatus: ASYNC_STATUS.REQUEST,
            message: 'Deleting workzone...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            deleteStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to delete the workzone.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            deleteStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case CLEAR_ONE_WORKZONE:
      return {
        ...state,
        activeWorkzone: null,
      };
    case CLEAR_WORKZONE_CURSOR: {
      return {
        ...state,
        cursorNextWorkZone: '',
      };
    }
    case CLEAR_ONE_WORKZONE_DEVICE: {
      return {
        ...state,
        activeDevice: null,
      };
    }
    case UPDATE_WORKZONE_LIST: {
      const {data = {}, params: {isDelete, clear} = {}} = action;

      if (clear) {
        return {
          ...state,
          loadedWorkzones: [],
        };
      }
      let updated = state.loadedWorkzones;
      if (isDelete) {
        updated = updated.filter(w => w.id !== data.id);
      } else {
        const updatedItem = updated.find(w => w.id === data.id);
        updatedItem && Object.assign(updatedItem, {...data});
      }
      return {
        ...state,
        loadedWorkzones: [...updated],
      };
    }
    case CREATE_TAPER_SCHEDULE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            createScheduleStatus: ASYNC_STATUS.REQUEST,
            message: 'Creating schedule mode...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            createScheduleStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to create the schedule mode.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            createScheduleStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case UPDATE_TAPER_SCHEDULE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            updateScheduleStatus: ASYNC_STATUS.REQUEST,
            message: 'Updating schedule mode...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            updateScheduleStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to update the schedule mode.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            updateScheduleStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case DELETE_TAPER_SCHEDULE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            deleteScheduleStatus: ASYNC_STATUS.REQUEST,
            message: 'Deleting schedule mode...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            deleteScheduleStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to delete the schedule mode.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            deleteScheduleStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case UPDATE_LAMP_LIST: {
      const {data = {}, params: {isDelete} = {}} = action;

      let updated = state.tableLamps;

      if (isDelete) {
        updated = updated.filter(l => l.id !== data.id);
      } else {
        const updatedItem = updated.find(l => l.id === data.id);
        Object.assign(updatedItem, {...data});
      }

      state.devices = [...updated];

      return {
        ...state,
        tableLamps: [...updated],
      };
    }
    case UPDATE_WORKZONE_DEVICE_LAMP_TABLE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            updateLAmpStatus: ASYNC_STATUS.REQUEST,
            message: 'Updating lamp...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            updateLAmpStatus: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to update lamp.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            updateLAmpStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    case GET_NOT_CONNECTED_WORZONE_DEVICES:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            getOtherDevicesStatus: ASYNC_STATUS.REQUEST,
            message: 'Fetching workzone not connected devices...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            getOtherDevicesStatus: ASYNC_STATUS.SUCCEED,
            notConnectedDevices: action.data,
            message: 'Succeed to fetch devices.',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            getOtherDevicesStatus: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    default:
      return state;
  }
}
