import React from 'react';
import ReactTooltip from 'react-tooltip';
import {convertFormatWithTimezone, validateDate} from 'helpers/functions';

const TextCell = props => {
  let className = props.className ? props.className : '';

  let label = props.label;

  if (props.type === 'date') {
    if (validateDate(label)) {
      label = convertFormatWithTimezone(label, 6);
    }
  }

  return (
    <>
      <td className={`pi-type-text ${className}`}>
        <div className='pi-td'>
          <p
            data-tip={props.hasTooltip ? props.hasTooltip || props?.data?.severity : undefined}
            data-html={!!props.hasTooltip}
          >
            {props.respHolder && <span className='pi-cell-holder'>{props.respHolder}</span>}
            {label}
          </p>
          {props.note && props.data[props.note] && (
            <p className='pi-cell-note'>{props.data[props.note]}</p>
          )}
          {props.hasTooltip && (
            <ReactTooltip
              place='top'
              type='light'
              effect='solid'
              className={`pi-tooltip ${props.tooltipClassName}`}
            />
          )}
        </div>
      </td>
    </>
  );
};

export default TextCell;
