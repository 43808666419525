import React from 'react';

//style
import classes from './Modal.module.scss';

//components
import {Button} from 'elements';

//asset
import {BsX} from 'react-icons/bs';
import {CircularProgress} from '@material-ui/core';

export const Modal = ({
  title,
  onClose,
  children,
  submitButtonLable,
  onSubmit,
  className,
  contentClassName,
  visible,
  mobileFullScreen,
  isSubmitting,
  closeTitle = 'Close',
  showCloseIcon,
  renderCloseButton = true,
  closeOnClickedOutSide = true,
  submitDisabled,
}) => {
  if (!visible) {
    return null;
  }
  return (
    <div className={classes.container}>
      <div className={classes.backdrop} onClick={closeOnClickedOutSide ? onClose : undefined} />
      <div
        className={`${classes.content_container} ${
          mobileFullScreen ? classes.mobile_full : ''
        } ${className}`}
      >
        <div className={classes.header}>
          <h2 className={classes.title}>{title}</h2>
          {showCloseIcon && (
            <div className={classes.close}>
              <BsX onClick={onClose} size={30} />
            </div>
          )}
        </div>

        <div className={`${classes.content} ${contentClassName}`}>{children}</div>
        {(renderCloseButton || submitButtonLable) && (
          <div className={classes.actions}>
            {renderCloseButton && (
              <Button
                styleType='outline'
                color='white'
                className={classes.action}
                onClick={onClose}
              >
                {closeTitle}
              </Button>
            )}
            {!!submitButtonLable && (
              <Button
                styleType='filled'
                color='white'
                className={classes.actionSubmit}
                onClick={onSubmit}
                disabled={submitDisabled}
              >
                {isSubmitting ? <CircularProgress color='white' size={'20px'} /> : null}
                {submitButtonLable}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
