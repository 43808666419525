import {
  ASYNC_STATUS,
  GET_MOBILE_LOCATION,
  RESET_MOBILE_LOCATION,
  GET_LOCATION_USERS,
} from '../../constants';
import {apiRequest} from '../../../../../core/api/apiRequest';

import {asyncAction} from '../asyncAction';

/**
 * Get Mobile location
 */
export function getMobileLocation(impact_id) {
  return async dispatch => {
    dispatch(asyncAction(GET_MOBILE_LOCATION, ASYNC_STATUS.REQUEST));
    const response = await apiRequest(`api/v0/mobile/report-flow/impacts/${impact_id}/`);
    if (response.success) {
      dispatch(
        asyncAction(GET_MOBILE_LOCATION, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
    } else {
      dispatch(
        asyncAction(GET_MOBILE_LOCATION, ASYNC_STATUS.FAILURE, {message: response.message}),
      );
    }
  };
}

export function getMobileLocationUsers(location_id) {
  return async dispatch => {
    dispatch(asyncAction(GET_LOCATION_USERS, ASYNC_STATUS.REQUEST));
    const response = await apiRequest(`api/v0/locations/${location_id}/users`);
    if (response.success) {
      dispatch(
        asyncAction(GET_LOCATION_USERS, ASYNC_STATUS.SUCCEED, {
          data: response.data,
          message: response.message,
        }),
      );
    } else {
      dispatch(
        asyncAction(GET_LOCATION_USERS, ASYNC_STATUS.FAILURE, {message: response.message}),
      );
    }
  };
}

export function resetReportsData() {
  return {type: RESET_MOBILE_LOCATION};
}
