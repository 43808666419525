import {cancelRequest} from 'core/api/apiRequest';
import {GET_USER, USER_CREATE, USER_UPDATE, USERS_GET_ALL_FOR_TEAM} from '../constants';

import {getAsyncAction, postAsyncAction} from './asyncAction';

export function getAllUsersForTeamAsyncAction() {
  return getAsyncAction(USERS_GET_ALL_FOR_TEAM, 'api/v0/users');
}

export function getUserAsyncAction(id) {
  const url = `user/list?id=${id}`;
  cancelRequest(url);
  return getAsyncAction(GET_USER, url, null, null, null, true);
}

/**
 * Create
 */
export function createUserAsyncAction(params) {
  return postAsyncAction(
    USER_CREATE,
    'user/create',
    params,
    'user/list',
    'Created a user successfully!',
    'Failed to create a user!',
  );
}

/**
 * Update
 */
export function updateUserAsyncAction(params) {
  return async dispatch => {
    dispatch(
      postAsyncAction(
        USER_UPDATE,
        'user/update',
        params,
        'user/list',
        'Information updated successfully!',
        'Failed to update a user!',
      ),
    );

    setTimeout(function () {
      dispatch(getUserAsyncAction(encodeURIComponent(params.id)));
    }, 500);
  };
}
