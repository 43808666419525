import React, {useState, useEffect} from 'react';
import ApexChart from 'react-apexcharts';
import {Card, CardBody} from 'reactstrap';

const Graph = ({
  mobileReport,
  color,
  type,
  title,
  minY,
  maxY,
  currentEvent,
  data,
  sensitivityData = [],
  extraOptions = {},
  name,
}) => {
  const {xaxis = {}} = extraOptions;
  const [series, setSeries] = useState([{name: '', data: data}]);
  const generatedCategories = Array.from({length: data.length}, (_, i) => i * 10 + ' ms');
  const greyLine = '#878181';

  let options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
        },
      },
      animations: {enabled: false},
    },
    grid: {show: true, yaxis: {lines: {show: false}}},
    dataLabels: {enabled: false},
    stroke: {curve: 'smooth', width: 3},
    colors: color ? [color, greyLine] : [color, greyLine],
    series: [
      {
        name: 'Desktops',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    xaxis: {
      type: 'category',
      categories: generatedCategories,
      labels: {show: true},
      tickAmount: 9,
      axisTicks: {show: false},
      tooltip: {enabled: false},
      ...xaxis,
    },
    yaxis: {
      tickAmount: 4,
      min: minY,
      max: maxY,
      type: 'numeric',
      axisBorder: {show: true, color: '#78909C', offsetX: 0, offsetY: 0},
      labels: {
        style: {fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal'},
        formatter: val => val + ' mg',
      },
    },
    tooltip: {enabled: true},
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'top',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  const init = () => {
    if (currentEvent) {
      const newData = [{name: '', data: data}];

      if (sensitivityData.length) {
        newData.push({
          name: 'Device’s Current Sensitivity',
          data: sensitivityData,
        });
      }
      setSeries(newData);
    } else {
      setSeries([]);
    }
  };

  useEffect(init, [currentEvent]);

  const chart = data && data.length > 0 && series.length > 0 && (
    <ApexChart options={options} series={series} type='line' width='100%' height={220} />
  );

  return (
    <>
      {mobileReport ? (
        <Card className='activity_impact_grpah'>
          <CardBody style={{padding: '10px 20px'}}>
            <div>{chart}</div>
          </CardBody>
        </Card>
      ) : (
        <div>
          {title && (
            <div className='graph_headings'>
              <p>{title}</p>
            </div>
          )}
          {chart}
        </div>
      )}
    </>
  );
};

export default Graph;
