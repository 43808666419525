import React from "react";

const CustomActionsCell = (props) => {
    let className = props.className ? props.className : '';
    return (
        <>
        <td className={`pi-type-custom-actions ${className}`}>
            <div className="pi-td">
                <ul>
                    {props.children && props.children}
                </ul>
            </div>
        </td>
        </>
    )
};

export default CustomActionsCell;