import {Button} from 'elements';
import React from 'react';

const ActionBtnCell = props => {
  let className = props.className ? props.className : '';
  return (
    <>
      <td className={`pi-type-action-btn ${className}`}>
        <div className='pi-td'>
          <Button
            styleType='filled'
            color='white'
            shadow={true}
            icon='left'
            onClick={() => props.onClick(props.data)}
          >
            <i className={`icon-${props.actionBtns?.icon} pi-color-secondary`} />
            <span>{props.actionBtns.label}</span>
          </Button>
        </div>
      </td>
      {/*<td className={`pi-type-action-btn ${className}`}>
                <div className="pi-td">
                    <Button styleType="filled" color="primary" icon="left">
                        <i className={`icon-${props.actionBtns.button1Icon}`}/>
                        <span>{props.label || props.item?.label}</span>
                    </Button>
                </div>
            </td>*/}
    </>
  );
};

export default ActionBtnCell;
