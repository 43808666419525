import React from 'react';
import {Modal} from 'reactstrap';
import {Button} from 'elements';

const ConfirmationModal = ({
  isOpened,
  closeHandler,
  acceptConfirmation,
  title = 'Are you sure you want to exit without saving the changes?',
  note,
}) => {
  return (
    <Modal
      className='pi-modal pi-wz-modal'
      isOpen={isOpened}
      onClose={closeHandler}
      style={{maxWidth: '500px'}}
    >
      <div className='pi-modal-header'>
        <h3>DISCARD CHANGES</h3>
        <button className='pi-close' onClick={closeHandler}>
          <i className='icon-close' />
        </button>
      </div>
      <div className='pi-modal-body'>
        <p>{title}</p>
        {note && <p>{note}</p>}
      </div>
      <div className='pi-modal-footer'>
        <div className='pi-block-footer'>
          <Button
            styleType='outline'
            color='white'
            className='pi-width-sm'
            onClick={() => closeHandler(false)}
          >
            No
          </Button>
          <Button
            styleType='filled'
            color='gray'
            className='pi-width-sm'
            onClick={() => acceptConfirmation(true)}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
