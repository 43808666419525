import {object, ref, string} from 'yup';
import ERROR_MESSAGES from 'core/services/schemas/messages';
import BaseSchemas from 'core/services/schemas/BaseSchemas';
import {minOneNumberOneUpperCaseLetterAndOneSpecialCharacter} from 'core/services/schemas/regexps';

export const forgotPasswordSchema = object().shape({
  email: BaseSchemas.email.required(ERROR_MESSAGES.required),
});

export const resetPasswordSchema = object().shape({
  password: BaseSchemas.password,
  confirmPassword: BaseSchemas.password.oneOf(
    [ref('password'), null],
    ERROR_MESSAGES.match('passwords'),
  ),
});

export const signInSchema = object().shape({
  emailField: BaseSchemas.email.required(ERROR_MESSAGES.required),
  password: BaseSchemas.oldPassword,
});

export const signWithPhoneSchema = object().shape({
  phone: string().required(ERROR_MESSAGES.required),
});

export const signWithPhoneVerificationCode = object().shape({
  code: string().min(4, ERROR_MESSAGES.required).required(ERROR_MESSAGES.required),
});

export const signUpCreatePassword = object().shape({
  password: string()
    .min(8, ERROR_MESSAGES.min('password'))
    .matches(minOneNumberOneUpperCaseLetterAndOneSpecialCharacter, ERROR_MESSAGES.password)
    .required(ERROR_MESSAGES.required),
  confirmPassword: string()
    .oneOf([ref('password')], ERROR_MESSAGES.match('password'))
    .required(ERROR_MESSAGES.match('password')),
});
