import React from 'react';
import Wrapper from '../../../components/Wrapper';
import SideBar from './wzSidebar';
import Header from './wzHeader';
import Main from '../../../components/Main';
import Content from '../../../components/Content';

const WorkZone = ({children}) => {
  return (
    <Wrapper>
      <Header />
      <SideBar />
      <Main>
        <Content>{children}</Content>
      </Main>
    </Wrapper>
  );
};

export default WorkZone;
