import React from 'react';
import { UserAvatar } from 'elements';

const SubGroupCard = ({
    data,
    children,
    action,
    badgeLabel
}) => {
    return (
        <div className={`pi-subgroup-header ${data.className?data.className:''}`}>
            <div className="pi-name">
                <span>SubGroup</span>
                {data.title && <strong>{data.title}</strong>}
            </div>
            <div className="pi-username" onClick={action}>
                {data.icon && <UserAvatar username={data.username} color={data.color} onlyBadge={data.onlyBadge} icon={data.icon} iconColor={data.iconColor}/>}
                {badgeLabel}
            </div>
            {children}
        </div>
    );
};

export default SubGroupCard;