import {
  ASYNC_STATUS,
  DEVICES_GET_ALL,
  GET_DEVICE,
  CLEAR_DEVICE,
  FILTER_DEVICE_LISTING,
  GET_TICKETS_BY_TYPE,
  LOAD_DEVICES,
  CLEAR_DEVICES,
  SEARCH_DEVICES,
  GET_REPORTS,
  CLEAR_GROUPS,
  UPDATE_ACCELEROMETER_SENSITIVITY,
  GET_USER_BY_GROUPS,
  CLEAR_GROUP_USER_LIST,
  DEVICES_GET_ALL_FOR_THE_MAP,
  UPDATE_DEVICE,
  SET_DEVICES_UPDATED,
  DEVICES_GET_ALL_NEW,
  UPDATE_DEVICE_DATA,
  DEVICES_GET_ALL_NEW_FOR_EVENTS,
  GET_DEVICE_FOR_EVENTS,
  GET_LOCATIONS_STATISTICS,
  GET_DEVICES_MODELS,
  GET_LOCATIONS_MANUFACTURERS,
} from '../constants';
import moment from 'moment';

const initialState = {
  message: null,
  needRefresh: true,
  status: ASYNC_STATUS.FAILURE,
  imgUrl: null,
  data: [],
  dataForMap: [],
  dataForDevicesNew: [],
  dataForDevicesNewForEvents: [],
  loadedDevices: [],
  searchedDevices: [],
  loadedGroups: [],
  searchedGroups: [],
  currentPage: 1,
  searchValue: '',
  getDeviceData: {},
  getDeviceDataForEvents: {},
  getReportsData: {},
  selectedIndex: -1,
  selectedDeviceId: null,
  selectedEventId: null,
  filteredFields: [],
  deviceTickets: [],
  groupUserList: [],
  devicesUpdated: false,
  sensitivityResponseData: {},
  locationStatistics: [],
  devicesModels: [],
  devicesForTheMapLoading: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DEVICES_GET_ALL_FOR_THE_MAP:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
            devicesForTheMapLoading: true,
          };
        case ASYNC_STATUS.SUCCEED:
          // if (JSON.stringify(action.data) === JSON.stringify(state.dataForMap)) {
          //     return {
          //         ...state,
          //         status: ASYNC_STATUS.SUCCEED,
          //         needRefresh: false,
          //         message: 'Succeed to fetch all the devices.',
          //     }
          // } else {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: false,
            message: 'Succeed to fetch all the devices.',
            dataForMap: action.data,
            devicesForTheMapLoading: false,
          };
        // }
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            devicesForTheMapLoading: false,
          };
        default:
          return state;
      }
    case DEVICES_GET_ALL_NEW:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
            loading: true,
          };
        case ASYNC_STATUS.SUCCEED:
          let {searchValue} = state;
          let data = action.data;
          let searchedDevices = data;
          let loadedDevices = data;
          let number = action.number;

          if (searchValue) {
            searchedDevices = searchedDevices.filter(device =>
              device.device.name.toLowerCase().includes(searchValue),
            );
          }

          if (number) {
            loadedDevices = searchedDevices.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: false,
            message: 'Succeed to fetch all the devices.',
            dataForDevicesNew: action.data,
            searchedDevices,
            loadedDevices,
            currentPage: 1,
            searchValue: '',
            loading: false,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            loading: false,
          };
        default:
          return state;
      }
    case DEVICES_GET_ALL_NEW_FOR_EVENTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: false,
            message: 'Succeed to fetch all the devices.',
            dataForDevicesNewForEvents: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case DEVICES_GET_ALL:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
            loading: true,
          };
        case ASYNC_STATUS.SUCCEED:
          let {searchValue} = state;
          let data = Array.isArray(action.data) ? action.data : [];
          let searchedDevices = data;
          let loadedDevices = Array.isArray(data) ? data : [];
          let number = action.number;

          if (searchValue) {
            searchedDevices = searchedDevices.filter(device =>
              device.device.name.toLowerCase().includes(searchValue),
            );
          }

          if (number) {
            loadedDevices = searchedDevices.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: false,
            message: 'Succeed to fetch all the devices.',
            data: Array.isArray(action.data) ? action.data : [],
            searchedDevices,
            loadedDevices,
            currentPage: 1,
            searchValue: '',
            loading: false,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            loading: false,
          };
        default:
          return state;
      }

    case LOAD_DEVICES: {
      let {currentPage, searchedDevices} = state;
      let number = action.number;
      let page = currentPage + 1;
      let loadedDevices = searchedDevices.slice(0, page * number);

      return {
        ...state,
        currentPage: page,
        loadedDevices,
      };
    }

    case CLEAR_DEVICES: {
      let {dataForDevicesNew} = state;
      let {number} = action.number;
      let loadedDevices = dataForDevicesNew;
      if (number) {
        loadedDevices = dataForDevicesNew.slice(0, number);
      }
      return {
        ...state,
        message: null,
        status: ASYNC_STATUS.FAILURE,
        dataForDevicesNew,
        loadedDevices: loadedDevices,
        searchedDevices: dataForDevicesNew,
        currentPage: 1,
        searchValue: '',
        devicesUpdated: false,
      };
    }

    case SEARCH_DEVICES: {
      let {dataForDevicesNew} = state;
      let searchedDevices = dataForDevicesNew;
      let searchValue = action.value;
      let number = action.number;

      if (searchValue) {
        searchedDevices = searchedDevices.filter(function (device) {
          return (
            device.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            device.model?.toLowerCase().includes(searchValue.toLowerCase()) ||
            device.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
            device.team?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            moment.unix(device.created).format('MM/DD/YY h:mm A').includes(searchValue)
          );
        });
      }
      let loadedDevices = searchedDevices;
      if (number) {
        loadedDevices = searchedDevices.slice(0, number);
      }

      return {
        ...state,
        searchValue,
        dataForDevicesNew,
        currentPage: 1,
        loadedDevices,
        searchedDevices,
      };
    }

    case UPDATE_ACCELEROMETER_SENSITIVITY: {
      let updatedDeviceAccel = action.data;
      let updatedDeviceSerialNumber = action.serial_number;
      let {data, searchedDevices, loadedDevices, sensitivityResponseData} = state;
      sensitivityResponseData = action.data;

      let newData = [...data];
      newData = newData.map(device => {
        if (device.serial_number === updatedDeviceSerialNumber) {
          device.accel_sensitivity = updatedDeviceAccel;
        }
        return device;
      });

      let newSearchedDevices = [...searchedDevices];
      newSearchedDevices = newSearchedDevices.map(device => {
        if (device.serial_number === updatedDeviceSerialNumber) {
          device.accel_sensitivity = updatedDeviceAccel;
        }
        return device;
      });

      let newLoadedDevices = [...loadedDevices];
      newLoadedDevices = newLoadedDevices.map(device => {
        if (device.serial_number === updatedDeviceSerialNumber) {
          device.accel_sensitivity = updatedDeviceAccel;
        }
        return device;
      });

      return {
        ...state,
        data: newData,
        status: ASYNC_STATUS.SUCCEED,
        searchedDevices: newSearchedDevices,
        loadedDevices: newLoadedDevices,
        sensitivityResponseData: action.data,
      };
    }

    case GET_DEVICE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            getDeviceData: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case CLEAR_DEVICE:
      return {
        ...state,
        getDeviceData: {},
      };
    case GET_DEVICE_FOR_EVENTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            getDeviceDataForEvents: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case UPDATE_DEVICE: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          let updatedDevice = action.data;
          let updatedDeviceId = updatedDevice.device_id;
          let updatedDeviceIdForMapCase = updatedDevice.id;
          let {data, dataForMap, searchedDevices, loadedDevices} = state;

          let newData = [...data];
          let newDataForMap = [...dataForMap];
          newData = newData.map(device => {
            if (device.device_id === updatedDeviceId) {
              device = updatedDevice;
            }
            return device;
          });
          newDataForMap = newDataForMap.map(deviceForMap => {
            if (
              deviceForMap.device_id === updatedDeviceId ||
              deviceForMap.device_id === updatedDeviceIdForMapCase
            ) {
              deviceForMap.recent_event_status = updatedDevice.recent_event_status;
            }
            if (deviceForMap.device_id === updatedDeviceId) {
              deviceForMap = updatedDevice;
            }
            return deviceForMap;
          });

          let newSearchedDevices = [...searchedDevices];
          newSearchedDevices = newSearchedDevices.map(device => {
            if (device.device_id === updatedDeviceId) {
              device = updatedDevice;
            }
            return device;
          });

          let newLoadedDevices = [...loadedDevices];
          newLoadedDevices = newLoadedDevices.map(device => {
            if (device.device_id === updatedDeviceId) {
              device = updatedDevice;
            }
            return device;
          });

          return {
            ...state,
            data: newData,
            searchedEvents: newSearchedDevices,
            loadedDevices: newLoadedDevices,
            devicesUpdated: true,
            getDeviceData: updatedDevice,
            dataForMap: newDataForMap,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    }

    case UPDATE_DEVICE_DATA: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          let updatedDevice = action.data;
          let updatedDeviceId = updatedDevice.device_id;
          let {data, searchedDevices, loadedDevices} = state;

          let newData = [...data];
          newData = newData.map(device => {
            if (device.device_id === updatedDeviceId) {
              device = updatedDevice;
            }
            return device;
          });

          let newSearchedDevices = [...searchedDevices];
          newSearchedDevices = newSearchedDevices.map(device => {
            if (device.device_id === updatedDeviceId) {
              device = updatedDevice;
            }
            return device;
          });

          let newLoadedDevices = [...loadedDevices];
          newLoadedDevices = newLoadedDevices.map(device => {
            if (device.device_id === updatedDeviceId) {
              device = updatedDevice;
            }
            return device;
          });

          return {
            ...state,
            data: newData,
            searchedEvents: newSearchedDevices,
            // loadedEvents: newLoadedDevices,
            loadedDevices: newLoadedDevices,
            devicesUpdated: true,
            getDeviceData: updatedDevice,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    }
    case GET_REPORTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          let data = action.data;
          let searchedGroups = data;
          let loadedGroups = data;
          let {searchValue} = state;
          let number = action.number;

          if (searchValue) {
            searchedGroups = searchedGroups.filter(group =>
              group?.device?.name.toLowerCase().includes(searchValue),
            );
          }

          if (number) {
            loadedGroups = searchedGroups.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            getReportsData: action.data,
            searchedGroups,
            loadedGroups,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case CLEAR_GROUPS: {
      return {
        ...state,
        message: null,
        status: ASYNC_STATUS.FAILURE,
        data: [],
        loadedGroups: [],
        searchedGroups: [],
        currentPage: 1,
        searchValue: '',
      };
    }
    case FILTER_DEVICE_LISTING:
      return {
        ...state,
        filteredFields: action.filteredFields,
      };
    case GET_USER_BY_GROUPS:
      return {
        ...state,
        groupUserList: action.data,
      };
    case CLEAR_GROUP_USER_LIST: {
      return {
        ...state,
        groupUserList: [],
      };
    }
    case SET_DEVICES_UPDATED: {
      return {
        ...state,
        setDevicesUpdated: action.value,
      };
    }

    case GET_TICKETS_BY_TYPE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            deviceTickets: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_LOCATIONS_STATISTICS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            locationStatistics: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_DEVICES_MODELS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            devicesModels: action.data.map(action_data => {
              return {value: action_data, label: action_data};
            }),
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_LOCATIONS_MANUFACTURERS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            messasge: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch data.',
            locationsManufacturers: action.data.map(action_data => {
              return {value: action_data, label: action_data};
            }),
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    default:
      return state;
  }
}
