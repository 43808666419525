import {useFormik} from 'formik';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {apiRequest} from 'core/api/apiRequest';

import AuthLayout from '../AuthLayout';
import {signInSchema} from '../auth.schema';
import {TextField, PasswordField, Button} from 'elements';
import {authCheckAsyncAction} from '../../impact-detection/redux/actions/authActions';
import {toastr} from 'react-redux-toastr';

import auth0 from 'auth0-js';
import {client_id, client_domain} from './auth0.config';
import GoogleIcon from '../../../assets/img/svg/google.svg';
import MicrosoftIcon from '../../../assets/img/svg/microsoft.svg';

export function SignInForm({setIsSignInWithPhone}) {
  const webAuth = new auth0.WebAuth({
    domain: client_domain,
    clientID: client_id,
  });

  const dispatch = useDispatch();
  const [incorrectEmailPassword, setIncorrectEmailPassword] = useState(false);

  async function onSubmit(values) {
    const body = {email: values.emailField, password: values.password};
    const {success, data} = await apiRequest('user/login', 'post', body);
    if (success) {
      toastr.success('Authentication', 'Welcome to our dashboard!');
      localStorage.setItem('accessToken', data.token);
      dispatch(authCheckAsyncAction());
    } else {
      setIncorrectEmailPassword(true);
    }
  }

  const {
    dirty,
    errors,
    values,
    touched,
    isValid,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    onSubmit,
    validationSchema: signInSchema,
    initialValues: {emailField: '', password: ''},
  });

  function onChange(event) {
    handleChange(event);
    if (incorrectEmailPassword) {
      setIncorrectEmailPassword(false);
    }
  }

  async function signInWithConnections(connection_type) {
    webAuth.authorize(
      {
        responseType: 'code',
        redirectUri: `${window.location.origin}/auth/callback`,
        connection: connection_type,
      },
      function (err, authResult) {},
    );
  }

  return (
    <AuthLayout title='Sign In' className='pi-sign-in'>
      {incorrectEmailPassword && (
        <div className='pi-error-message pi-text-center'>
          <i className='icon-error-circle' />
          <p>The Email address and/or password is incorrect. Please try again.</p>
        </div>
      )}
      <form onSubmit={handleSubmit} autoComplete='off'>
        <TextField
          shadow
          label='Email *'
          name='emailField'
          value={values.emailField}
          errorMessage={touched.emailField && errors.emailField}
          onBlur={handleBlur}
          onChange={onChange}
        />
        <PasswordField
          shadow
          name='password'
          className='mt-25'
          label='Password *'
          autoComplete='new-password'
          value={values.password}
          errorMessage={touched.password && errors.password}
          onBlur={handleBlur}
          onChange={onChange}
        />
        <div className='pi-text-right mt-10'>
          <Link to='/auth/forgot-password' className='pi-color-primary pi-font-size-md'>
            Forgot Password?
          </Link>
        </div>
        <div className='pi-text-center mt-30'>
          <Button
            type='submit'
            color='gray'
            styleType='filled'
            className='pi-width-lg'
            disabled={
              (!dirty && !values.emailField) || !isValid || isSubmitting || incorrectEmailPassword
            }
          >
            Sign in
          </Button>
          <div className='pi-full mt-20'>
            <Button
              color='white'
              styleType='outline'
              className='pi-width-lg'
              onClick={setIsSignInWithPhone}
            >
              Sign in with phone number
            </Button>
          </div>
          <div className='pi-full social-wrapper mt-20'>
            <p className='other-options'>or</p>
            <Button
              color='white'
              styleType='outline'
              className='pi-width-lg social-btn'
              onClick={() => signInWithConnections('google-oauth2')}
            >
              <img src={GoogleIcon} alt='Google icon' />
              <span>Sign in with Google</span>
            </Button>
            <Button
              color='white'
              styleType='outline'
              className='pi-width-lg social-btn'
              onClick={() => signInWithConnections('windowslive')}
            >
              <img src={MicrosoftIcon} alt='Microsoft icon' />
              <span>Sign in with Microsoft</span>
            </Button>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
}
