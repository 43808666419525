import React from 'react';
import NewSideBar from '../components/newSideBar';
import Wrapper from '../components/Wrapper';
// import Sidebar from '../components/Sidebar';
import Main from '../components/Main';
import DashboardNavigation from 'modules/impact-detection/layout/Navigation';
import DashboardMobileNavigation from '../modules/impact-detection/layout/NavigationMobile';
import HeaderNavBar from '../components/HeaderNavBar';
import Content from '../components/Content';
// import CreateUpdateDevice from 'components/CreateUpdateDevice';
// import CreateUpdateEvent from 'components/CreateUpdateEvent';
import {UserRoles} from 'modules/impact-detection/redux/constants';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class Dashboard extends React.Component {
  state = {
    pathName: '',
    device_type_option: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pathName = prevProps.history.location.pathname;
    if (pathName && pathName !== this.state.pathName) {
      this.setState({pathName: pathName});
    }
  }

  handleCreateNewDevice = (device_type, device_type_option) => {
    if (device_type === 'asset') {
      this.setState({device_type_option: device_type_option});
      this.refs.createAssetModal.toggle();
    }
    if (device_type === 'workzone') {
      this.refs.createWorkzoneModal.toggle();
    }
  };

  handleCreateNewEvent = () => {
    this.refs.createEventModal.toggle();
  };

  render() {
    const {children} = this.props;
    const {pathName, device_type_option} = this.state;

    return (
      <React.Fragment>
        <Wrapper>
          <HeaderNavBar mobileNavigation={DashboardMobileNavigation} pathname={pathName} />
          <NewSideBar pathname={pathName} navigation={DashboardNavigation} />
          <Main>
            <Content>{children}</Content>
            {/*            <CreateUpdateDevice
              ref='createAssetModal'
              {...this.props}
              type={device_type_option}
            />
            <CreateUpdateEvent ref='createEventModal' {...this.props} />*/}
          </Main>
        </Wrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const attenuators = state.attenuators.data;
  const dispatcherUsers = state.users.data?.filter(
    x =>
      x.user_roles && x.user_roles.findIndex(userRole => userRole === UserRoles[1].value) >= 0,
  );
  const ticketCreatorId = state.auth.data ? state.auth.data.email : null;
  const assetTypes = state.assetTypes.data;

  return {attenuators, dispatcherUsers, ticketCreatorId, assetTypes};
}

export default connect(mapStateToProps)(
  withRouter(
    connect(store => ({
      auth: store.auth,
      sidebar: store.sidebar,
      layout: store.layout,
    }))(Dashboard),
  ),
);
