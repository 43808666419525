import React from "react";


const IconSingleBtnCell = (props) => {
    let className = props.className ? props.className : '';
    return (
        <>
            <td className={`pi-type-icon-btns ${className}`}>
                <div className="pi-td">
                    <ul>
                        <li style={{width: '100%'}}>
                            <button className={`pi-icon-btn pi-color-${props?.actionBtns?.button1Color}`} onClick={() => props.onClick.firstAction(props.data)}>
                                <i className={`icon-${props?.actionBtns?.button1Icon}`}/>
                            </button>
                        </li>
                    </ul>
                </div>
            </td>
        </>
    )
}

export default IconSingleBtnCell;