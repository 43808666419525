import React, {useState, useCallback, useEffect} from 'react';
import {HiOutlineChevronDown, HiOutlineChevronUp} from 'react-icons/hi';

export default function Drawer({
  children,
  title,
  visible = false,
  onStateChange,
  childDrawer,
  hideToggle,
}) {
  const [open, setOpen] = useState(visible);

  const handleToggle = useCallback(() => {
    if (hideToggle) {
      return;
    }
    setOpen(state => {
      if (onStateChange) {
        onStateChange(!state);
      }
      return !state;
    });
  }, [hideToggle]);

  useEffect(() => {
    if (open !== visible) {
      setOpen(visible);
    }
  }, [visible]);

  return (
    <div className={`flex flex-col ${childDrawer ? 'px-2' : 'px-4'} pb-2 pt-3 justify-center`}>
      <div
        className='flex cursor-pointer h-8 justify-center border-b-[1px] border-[#E4E4E499]'
        onClick={handleToggle}
      >
        {typeof title === 'string' ? (
          <p className='flex-1 text-[#4B5258B2] text-xs p-0 font-normal'>{title}</p>
        ) : (
          title
        )}

        {open ? <HiOutlineChevronUp size={18} /> : <HiOutlineChevronDown size={18} />}
      </div>
      <div
        className={`w-full ${
          open ? 'max-h-screen opacity-1 duration-1000 delay-200' : 'max-h-0 opacity-0 duration-500'
        } transition-all overflow-hidden`}
      >
        <div className='flex flex-col w-full overflow-visible min-h-min mt-2'>{children}</div>
      </div>
    </div>
  );
}
