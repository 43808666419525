import React from 'react';
import Link from 'components/Link';

const LabelGroup = ({
  className = '',
  label,
  value,
  type = 'block',
  children,
  icon,
  iconColor,
  size = 'md',
  layout = '',
  clickable,
  onClick,
  labelType,
  labelChildren,
  valueClassName,
  href,
}) => {
  return (
    <div
      className={`pi-detail-item pi-type-${type} pi-size-${size} ${
        layout && 'pi-layout-full'
      } ${className}`}
    >
      {label && (
        <p>
          <span>{label}</span>
          {labelChildren && labelChildren}
        </p>
      )}
      {labelType !== 'Status' && label !== 'Status' && children ? (
        children
      ) : (
        <strong className={valueClassName}>
          {clickable && value !== 'n/a' ? (
            <Link href={href} onClick={onClick}>
              {value}
            </Link>
          ) : (
            value
          )}
          {icon && (
            <i
              className={`pi-icon icon-${icon} pi-color-${iconColor}`}
              dangerouslySetInnerHTML={{__html: children}}
            />
          )}
        </strong>
      )}
    </div>
  );
};

export default LabelGroup;
