import {FILTER_ATTENUATOR_LISTING, FILTER_DEVICE_LISTING} from '../constants';

export function filter(valuesArray) {
  return {
    type: FILTER_ATTENUATOR_LISTING,
    filteredFields: valuesArray,
  };
}

export function filterDevices(valuesArray) {
  return {
    type: FILTER_DEVICE_LISTING,
    filteredFields: valuesArray,
  };
}
