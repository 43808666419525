import React from 'react';
import UserAvatar from "./UserAvatar";

const TeamCard = ({
    className='',
    color,
    teamName,
    membersCount,
    action,
    active,
    searched=false
}) => {
    return (
        <div 
            className={`pi-team-card ${className} ${active?'pi-active':''}`} 
            style={{background: searched? "#faa52838" : "unset"}}
            onClick={action}>
            <div className="pi-card-info">
                <UserAvatar username={teamName} color={color} onlyBadge={true}/>
                <div className="pi-info-content">
                    <strong className="pi-info-name">{teamName}</strong>
                </div>
            </div>
        </div>
    );
};

export default TeamCard;