const {createSlice} = require('@reduxjs/toolkit');

const initialState = {
  navigateToAfterAuth: undefined,
  prevNavigateToAfterAuth: undefined,
};

const globalSettings = createSlice({
  initialState,
  name: 'globalSettings',
  reducers: {
    setNavigateToAfterAuth(state, action) {
      state.prevNavigateToAfterAuth = state.navigateToAfterAuth;
      state.navigateToAfterAuth = action.payload;
    },
  },
});

export const {setNavigateToAfterAuth} = globalSettings.actions;

export default globalSettings.reducer;
