import React from 'react';
import {Creatable} from 'react-select';

const CustomStyles = {
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none',
    color: '#FAA528',
  }),
  container: provided => ({
    ...provided,
    height: 40,
    border: '1px solid #E4E4E4',
    color: '#4B5258',
    fontFamily: 'Roboto',
    fontSize: 14,
    borderRadius: '20px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.05)',
  }),
};

const CustomCreatable = ({
  className = '',
  label,
  options,
  value,
  placeholder,
  shadow,
  name,
  defaultValue,
  onChange,
  disabled,
  onBlur,
  errorMessage,
  menuPlacement,
  maxMenuHeight=150
}) => {
  return (
    <div
      className={`pi-select ${className} ${shadow ? 'pi-shadow' : ''} ${
        errorMessage ? 'pi-invalid' : ''
      }`}
    >
      {label && <label className='pi-field-label'>{label}</label>}
      <Creatable
        blurInputOnSelect={false}
        className='react-select-container'
        classNamePrefix='react-select'
        isSearchable
        isClearable
        name={name}
        placeholder={placeholder}
        styles={CustomStyles}
        defaultValue={defaultValue}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        value={value}
        onBlur={onBlur}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
      />
      {errorMessage && errorMessage !== '' && (
        <p className='pi-error-message'>{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomCreatable;
