import React, {useEffect, useState} from 'react';

const IconBtnsCell = props => {
  const [expandAction, setExpandAction] = useState(true);

  const expandListener = () => {
    if (
      !Object.keys(props.extra?.ids || {})?.includes(props?.data[props.extra?.expandedProp])
    ) {
      setExpandAction(true);
    }

    if (
      Object.keys(props.extra?.ids || {})?.includes(props?.data[props.extra?.expandedProp])
    ) {
      setExpandAction(false);
    }
  };

  useEffect(expandListener, [props.extra?.ids]);

  const handleExpandAction = () => {
    setExpandAction(!expandAction);
  };

  return (
    <>
      <td
        className={`pi-type-icon-btns ${props.className ? props.className : ''} ${
          !expandAction ? 'pi-hide-action' : ''
        }`}
      >
        <div className='pi-td'>
          <ul>
            <li className={`${!expandAction ? 'fade_in_left' : ''}`}>
              <button
                className={`pi-icon-btn pi-color-${props?.actionBtns.button1Color}`}
                onClick={e => props.onClick?.firstAction(props.data, e)}
              >
                <i className={`icon-${props?.actionBtns.button1Icon}`} />
              </button>
            </li>
            <li className={`${!expandAction ? 'pi-active' : ''}`}>
              <button
                className={`pi-icon-btn pi-color-${props?.actionBtns.button2Color}`}
                onClick={e => {
                  props.onClick && props.onClick.secondAction(props.data, e);
                  props.onClick.exapandable && handleExpandAction();
                }}
              >
                <i className={`icon-${props?.actionBtns.button2Icon}`} />
              </button>
            </li>
          </ul>
        </div>
      </td>
    </>
  );
};

export default IconBtnsCell;
