import React from 'react';
import {connect} from 'react-redux';
import {
  Collapse,
  Nav,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {logoutAsyncAction} from '../modules/impact-detection/redux/actions/authActions';
import {filterDevices} from '../modules/impact-detection/redux/actions/filterActions';
import 'react-input-range/lib/css/index.css';

class HeaderNavBar extends React.Component {
  state = {
    modal: false,
    deviceOptionsModal: false,
    statusOptions: [],
    manufacturerOptions: [],
    assetTypeOptions: [],
    deviceTypeOptions: [],
    dateOptions: [],
    status: null,
    manufacturer: null,
    asset_type: null,
    device_type: null,
    battery_level: null,
    date: null,
    tag: null,
    map: '#FAA528',
    activity: '#FAA528',
    generic_devices: '#FAA528',
    teams: '#FAA528',
    reports: '#FAA528',
    performance: '#FAA528',
    batteryRange: {
      min: 0,
      max: 100,
    },
    notificationDropdownOpen: false,
    isMenuOpen: false,
    openProfile: false,
    currentUser: {},
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.usersData !== this.props.usersData) {
      let currentUser = this.props.usersData.find(x => x.email === this.props.data?.email);
      this.setState({currentUser});
    }
  }

  capitalizeFirstLetter = string => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  };

  handleFilterChange = async (values, type) => {
    await this.setState({[type]: values});
    let filteredValues = {
      recent_event_type: this.state.status,
      manufacturer: this.state.manufacturer,
      asset_type: this.state.asset_type,
      device_type: this.state.device_type,
      date: this.state.date,
      battery_level: this.state.battery_level,
      tag: this.state.tag,
    };
    this.props.dispatch(filterDevices(filteredValues));
  };

  handleMobileMenuIcon = () => {
    this.setState({menuCloseIcon: !this.state.menuCloseIcon}, () => {
      if (this.state.menuCloseIcon) {
        document.getElementById('mySidepanel').style.width = '250px';
      } else {
        document.getElementById('mySidepanel').style.width = '0px';
      }
    });
  };

  changeSVGColorWhite = value => {
    this.setState({[value]: '#ffffff'});
  };

  changeSVGColorYellow = value => {
    this.setState({[value]: '#FAA528'});
  };

  render() {
    const {dispatch, pathname} = this.props;
    const {firstname, lastname} = this.state.currentUser || {};
    const MobileNavigation = this.props.mobileNavigation;

    let name = firstname ? firstname : '';
    name = lastname ? name + ' ' + lastname : name;

    const onSignOut = () => {
      dispatch(logoutAsyncAction());
    };

    const onProfilePopup = () => {
      this.setState({openProfile: true});
    };

    const updateProfile = () => {};

    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: 1000,
          boxShadow: window.innerWidth > 636 ? '0 1px 20px 0 rgba(0,0,0,0.3)' : 'none',
        }}
      >
        <div id='mySidepanel' className='sidepanel'>
          <div>
            <NavbarToggler onClick={this.toggleMenu} />
            <Collapse isOpen={this.state.isMenuOpen} navbar>
              <Nav className='content-left mr-auto' navbar>
                {/* <Link to="/events" className={`nav-link ${pathname === '/events' ? 'active' : ''}`}>Activity</Link>
                    <Link to="/device/list" className={`nav-link ${pathname === '/device/list' || pathname === '/devices_map' || pathname.indexOf('/device/view/') >= 0 ? 'active' : ''}`}>Inventory</Link>
                    <Link to={`/tabbed-settings`} className={`nav-link ${pathname.indexOf('settings') >= 0 ? 'active' : ''}`}>Settings</Link> */}
              </Nav>
              <Nav navbar className='content-right profile-logo'>
                {/*<img src="https://www.gsr-technology.co.uk/wp-content/uploads/2015/10/partner-logo-placeholder.jpg" className="user-image mr-2 hide-mobile" alt=""/>*/}
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {/* <Circle size={36} className="align-middle mr-2" /> */}
                    <span className='text-dark'>{name}</span>
                    <span onClick={() => updateProfile} className='down_arrow_profile'>
                      <FontAwesomeIcon style={{color: '#FAA528'}} icon={faAngleDown} />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right className='drop-down-profile'>
                    <DropdownItem onClick={onProfilePopup}>Profile</DropdownItem>
                    <DropdownItem divider />
                    {/*<DropdownItem>Help</DropdownItem>*/}
                    {/*<DropdownItem divider />*/}
                    <DropdownItem onClick={onSignOut}>Sign out</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </div>
          {<MobileNavigation pathname={pathname} parent={this} />}
          <div className='new_sidebar_copyright_mobile'>&#169; 2021-Pi Variable, Inc</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {data} = state.auth;
  const usersData = state.users.data;
  const {devices, assetTypes, events} = state;
  return {data, usersData, devices, assetTypes, events};
}

export default connect(mapStateToProps)(HeaderNavBar);
