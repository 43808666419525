import React from 'react';

const ProfileField = ({
    icon,
    label,
    value,
    action,
    disabled,
    errorMsg,
    errorTitle,
    showBtn
}) => {
    return (
      <div className="pi-profile-field-wrapper">
        <div className={`pi-profile-field ${errorMsg || errorTitle ? 'pi-invalid': ''}`}>
          <div className="pi-field-key">
            {icon && <i className={`icon-${icon}`} />}
            {label && <span>{label}:</span>}
          </div>
          <div className="pi-field-value">
            {value && <strong>{value}</strong>}
          </div>
          {showBtn && 
          <div className="pi-field-action">
            <button className="pi-edit-trigger" onClick={action} disabled={disabled}>
              <i className="icon-edit"/>
            </button>
          </div>}
        </div>
        {errorMsg && errorTitle && <div className="pi-field-error">
          <i className="icon-info"/>
          <div className="pi-error-content">
            {errorTitle && <h4>{errorTitle}</h4>}
            {errorMsg && <p>{errorMsg}</p>}
          </div>
        </div>}
      </div>
    );
};

export default ProfileField;