import {apiSlice} from 'services';

export const SharedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCompanies: builder.query({
      query: () => '/api/v0/accounts',
      providesTags: () => [{type: 'Companies'}],
    }),
    getCompaniesSettings: builder.query({
      query: namespace => `/api/v0/accounts/pi_link_settings?namespace=${namespace}`,
      providesTags: (result, error, namespace) => [{type: 'Settings', namespace}],
    }),
    getCompanyDevicesLength: builder.query({
      query: namespace => `/api/v0/flares/statistics?namespace=${namespace}`,
      providesTags: () => [{type: 'DeviceLength'}],
    }),
    getCompanyDevices: builder.query({
      query: namespace => `/api/v0/flares/flare_gateways?namespace=${namespace}`,

      providesTags: () => [{type: 'Devices'}],
    }),
    submitCompanySettings: builder.mutation({
      query: ({namespace, ...body}) => ({
        url: `/api/v0/accounts/pi_link_settings?namespace=${namespace}`,
        method: 'POST',
        body,
      }),

      invalidatesTags: (result, error, body) => [{type: 'Settings', namespace: body.namespace}],
    }),

    createAndUpdateNamespace: builder.mutation({
      query: body => ({
        url: '/api/v0/accounts/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{type: 'Companies'}],
    }),

    registerDevice: builder.mutation({
      query: body => ({
        url: '/api/v0/flares/flare_gateways/register',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{type: 'Devices'}, {type: 'DeviceLength'}],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompaniesQuery,
  useGetCompaniesSettingsQuery,
  useSubmitCompanySettingsMutation,
  useLazyGetCompaniesQuery,
  useLazyGetCompaniesSettingsQuery,
  useLazyGetCompanyDevicesLengthQuery,
  useGetCompanyDevicesQuery,

  useCreateAndUpdateNamespaceMutation,

  useRegisterDeviceMutation,
} = SharedApiSlice;
