import Link from 'components/Link';
import React from 'react';

const URLCell = props => {
  let className = props.className ? props.className : '';

  const highlightLabel = (text, highlight) => {
    if (typeof text === 'string' && highlight && highlight.length !== '') {
      const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
      return (
        <strong>
          {parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <mark key={i} style={{backgroundColor: '#ff980069'}}>
                {part.trim()}
              </mark>
            ) : (
              part.trim()
            ),
          )}
        </strong>
      );
    } else {
      return text;
    }
  };

  const handleClick = e => {
    if (props.onClick) {
      props.onClick(props.data, e);
    }
  };

  return (
    <>
      <td className={`pi-type-url ${className}`}>
        <div className='pi-td'>
          {props.children && props.children}
          <Link className={`pi-color-${props.color}`} onClick={handleClick} href={props.url}>
            {highlightLabel(props.label, props.searchValue)}
          </Link>
        </div>
      </td>
    </>
  );
};

export default URLCell;
