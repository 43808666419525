import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  createTransform,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

//reducers
import WorkZoneReducers from '../modules/workzone/redux/reducers/wzReducers';
import ImpactDetectionReducers from '../modules/impact-detection/redux/reducers';
import {ImpactDetectionSliceReducers} from 'modules/impact-detection/redux/slices';
import {SharedReducers} from '../modules/shared/redux';
import {reducer as toastr} from 'react-redux-toastr';

//services
import {apiSlice, rtkQueryAuthrizationMiddleWare} from '../services';

const blackListTransform = createTransform((inboundState, key) => {
  if (key === 'tickets') {
    const {ticketsAndLoges, ...otherState} = inboundState;
    otherState.ticketsAndLoges = [];
    otherState.needsRefresh = false;
    return otherState;
  }
  return inboundState;
});

const persistConfig = {
  key: 'root',
  storage,
  transforms: [blackListTransform], //truncates only the ticketsAndLoges from tickets in state
  blacklist: [
    'auth',
    'teams',
    'toastr',
    'locations',
    'activities',
    'workspaces',
    'wzWorkzones',
    'deviceFilter',
    'reportMobile',
    'teamsRedesign',
    'impactDetectionMap',
    'globalSettings',
    apiSlice.reducerPath,
  ],
};

const combinedReducers = combineReducers({
  ...WorkZoneReducers,
  ...ImpactDetectionReducers,
  ...ImpactDetectionSliceReducers,
  ...SharedReducers,
  toastr,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }).concat(apiSlice.middleware, rtkQueryAuthrizationMiddleWare),
});

export const persistor = persistStore(store);
