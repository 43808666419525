import {
  ASYNC_STATUS,
  ATTENUATORS_GET_ALL,
  ATTENUATOR_CREATE,
  ATTENUATOR_UPDATE,
  ATTENUATOR_UPLOAD,
  ATTENUATOR_SELECT,
  NOTE_CREATE,
  NOTE_UPDATE,
  EVENT_UPDATE,
  ADD_INSPECTION_DATE,
  ATTENUATOR_SELECT_EVENT,
  EVENT_CREATE,
  MUTE_DEVICE,
  CHANGE_DEVICE_SENSITIVITY,
  UPDATE_BATTERY_NOTIFICATION_LEVEL,
  FILTER_ATTENUATOR_LISTING,
} from '../constants';

const initialState = {
  message: null,
  needRefresh: true,
  status: ASYNC_STATUS.FAILURE,
  imgUrl: null,
  data: [],
  selectedIndex: -1,
  selectedDeviceId: null,
  selectedEventId: null,
  filteredFields: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ATTENUATORS_GET_ALL:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching device list...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: false,
            message: 'Succeed to fetch all the attenuators.',
            data: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case ATTENUATOR_CREATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Adding a new device...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to create a new device.',
            imgUrl: action.imgUrl,
            data: [...state.data, action.data],
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case ATTENUATOR_UPDATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating the device...',
          };
        case ASYNC_STATUS.SUCCEED:
          var data = [...state.data];
          const itemIndex = data.findIndex(x => x.device_id === action.data.device_id);
          if (itemIndex < 0) {
            return {
              ...state,
              status: ASYNC_STATUS.FAILURE,
              message: 'Wrong data updated: ' + action.data.device_id,
            };
          }
          const updatedData = action.data;
          data[itemIndex] = {...data[itemIndex], ...updatedData};

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update the device.',
            imgUrl: action.imgUrl,
            data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case ATTENUATOR_UPLOAD:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Uploading device image...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to upload device image.',
            imgUrl: null,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case ATTENUATOR_SELECT:
      var selectedIndex = -1;
      if (action.deviceId) {
        selectedIndex = state.data.findIndex(x => x.device_id === action.deviceId);
      }
      return {
        ...state,
        selectedIndex,
      };
    case ATTENUATOR_SELECT_EVENT:
      return {
        ...state,
        selectedDeviceId: action.deviceId,
        selectedEventId: action.eventId,
      };
    case NOTE_CREATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Adding a new note...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to create a new note.',
            imgUrl: action.imgUrl,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case NOTE_UPDATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating a note...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update a note.',
            imgUrl: action.imgUrl,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case EVENT_CREATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Creating an event...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to create an event.',
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case EVENT_UPDATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating an event...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update an event.',
            imgUrl: action.imgUrl,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
            imgUrl: null,
          };
        default:
          return state;
      }
    case MUTE_DEVICE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating a device...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update a device.',
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case CHANGE_DEVICE_SENSITIVITY:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating a device...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update a device.',
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case UPDATE_BATTERY_NOTIFICATION_LEVEL:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Updating a device...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            needRefresh: true,
            message: 'Succeed to update a device.',
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case ADD_INSPECTION_DATE:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Add inspection date...',
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            data: action.data,
            needRefresh: true,
            message: action.message,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case FILTER_ATTENUATOR_LISTING:
      return {
        ...state,
        filteredFields: action.filteredFields,
      };
    default:
      return state;
  }
}
