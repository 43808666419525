import MapMarkerGrey from '../../../assets/img/svg/map-marker-grey.svg';
import MapMarkerLight from '../../../assets/img/svg/map-marker-light.svg';

import AddIconGrey from '../../../assets/img/svg/ic-zoom-to-add.svg';
import AddIconLLight from '../../../assets/img/svg/ic-zoom-to-add-l.svg';

import ResetIcon from '../../../assets/img/svg/ic-reset.svg';

import StartWorkZone from '../../../assets/img/svg/StartWorkZone.svg';
import EndWorkzone from '../../../assets/img/svg/EndWorkzone.svg';

import StartWorkZoneOff from '../../../assets/img/svg/StartWorkzoneOff.svg';
import EndWorkZoneOff from '../../../assets/img/svg/EndWorkzoneOff.svg';

import SateliteMode from '../../../assets/img/map/i-SateliteMode.svg';
import DefaultMode from '../../../assets/img/map/ic-defaultMode.svg';

import PinTaperOnState from '../../../assets/img/map/Pin-Taper-On State.svg';
import PinTaperOffState from '../../../assets/img/map/Pin-Taper-Off-State.svg';
import PinTaperOffStateBg from '../../../assets/img/map/pins/Pin-Taper-Off-State.svg';
import PinTaperOnStateBg from '../../../assets/img/map/pins/Pin-Taper-On State.svg';
import PinTaperOffStateUnchecked from '../../../assets/img/map/pins/Pin-Taper-Off-State-Unchecked.svg';
import PinTaperOffStateChecked from '../../../assets/img/map/pins/Pin-Taper-On State-Checked.svg';
import PinTaperOnStateCheckedOn from '../../../assets/img/map/pins/Pin-Taper-On State-Checked-1.svg';
import PinTaperOnStateUnchecked from '../../../assets/img/map/pins/Pin-Taper-On State-Unchecked.svg';

import PinNodeOffState from '../../../assets/img/map/Pin-Node-Off-State.svg';
import PinNodeOnState from '../../../assets/img/map/Pin-Node-On State.svg';
import PinNodeOffStateBg from '../../../assets/img/map/pins/Pin-Node-Off-State.svg';
import PinNodeOnStateBg from '../../../assets/img/map/pins/Pin-Node-On State.svg';
import PinNodeOffStateUnChecked from '../../../assets/img/map/pins/Pin-Node-Off-State-UnChecked.svg';
import PinNodeOffStateChecked from '../../../assets/img/map/pins/Pin-Node-On State-Checked.svg';
import PinNodeOnStateUnChecked from '../../../assets/img/map/pins/Pin-Node-On State-UnChecked.svg';
import PinNodeOnStateChecked from '../../../assets/img/map/pins/Pin-Node-On State-Checked-1.svg';

import PinGateAwayOffState from '../../../assets/img/map/Pin-Gateaway-Off-State.svg';
import PinGateAwayOnState from '../../../assets/img/map/Pin-Gateaway-On-State.svg';
import PinGateawayOffStateBg from '../../../assets/img/map/pins/Pin-Gateaway-Off-State.svg';
import PinGateawayOnStateBg from '../../../assets/img/map/pins/Pin-Node-On State-1.svg';
import PinGateawayOffStateUnChecked from '../../../assets/img/map/pins/Pin-Gateaway-Off-State-UnChecked.svg';
import PinGateawayOnStateChecked from '../../../assets/img/map/pins/Pin-Node-On State-Checked-3.svg';
import PinGateawayOffStateChecked from '../../../assets/img/map/pins/Pin-Node-On State-Checked-2.svg';
import PinGateawayOnStateUnChecked from '../../../assets/img/map/pins/Pin-Gateaway-On State-UnChecked.svg';

import LightOperatingIcon from '../../../assets/img/map/light-marker.svg';
import LightOfflineIcon from '../../../assets/img/map/light-marker-offline.svg';

import MissingAssetHasDevice from '../../../assets/img/map/pins/assets/marker_missing_asset_has_device.svg';
import MissingAssetNoDevice from '../../../assets/img/map/pins/assets/marker_missing_asset_no_device.svg';
import OfflineAssetHasDevice from '../../../assets/img/map/pins/assets/marker_offline_asset_has_device.svg';
import OnlineAssetHasDevice from '../../../assets/img/map/pins/assets/marker_online_asset_has_device.svg';
import PendingInspectionAssetHasDevice from '../../../assets/img/map/pins/assets/marker_pending_inspection_asset_has_device.svg';
import UnassignedAsset from '../../../assets/img/map/pins/assets/marker_unassigned_asset.svg';
import AssetMuted from '../../../assets/img/map/pins/assets/marker_asset_muted_has_device.svg';
import AssetMutedMissing from '../../../assets/img/map/pins/assets/marker_asset_missing_muted.svg';

import AttenOffline from '../../../assets/img/map/pins/devices/pin_gta_att_offline.svg';
import AttenOnline from '../../../assets/img/map/pins/devices/pin_gta_att_online.svg';
import AttenPending from '../../../assets/img/map/pins/devices/pin_gta_att_pending_inspection.svg';
import AttenUnassigned from '../../../assets/img/map/pins/devices/pin_gta_att_unassigned.svg';
import NodeOffline from '../../../assets/img/map/pins/devices/pin_node_offline.svg';
import NodeOnline from '../../../assets/img/map/pins/devices/pin_node_online.svg';
import NodePending from '../../../assets/img/map/pins/devices/pin_node_pending_inspection_no.svg';
import NodeUnassigned from '../../../assets/img/map/pins/devices/pin_node_unassigned.svg';

import AttenMissingDevice from '../../../assets/img/map/pins/missing/pin_att_missing_device.svg';
import AttenMissingDeviceNoAsset from '../../../assets/img/map/pins/missing/pin_att_missing_device_no_asset_on.svg';
import NodeMissingDevice from '../../../assets/img/map/pins/missing/pin_node_missing_device.svg';
import NodeMissingDeviceNoAsset from '../../../assets/img/map/pins/missing/pin_node_missing_device_no_asset_on.svg';

import UnassignedAssetAssignMap from '../../../assets/img/map/pins/assignMap/unassigned-asset-assign-map.svg';
import UnassignedAssetAssignMapChecked from '../../../assets/img/map/pins/assignMap/unassigned-asset-assign-map-checked.svg';
import MissingAssetNoDeviceAssignMap from '../../../assets/img/map/pins/assignMap/missing-asset-no-device-map.svg';
import MissingAssetNoDeviceAssignMapChecked from '../../../assets/img/map/pins/assignMap/missing-asset-no-device-map-checked.svg';

import AttMissingDeviceNoAssetMuted from '../../../assets/img/map/pins/muted/Att-Missing-Device-No-Asset-Muted.svg';
import NodeMissingDeviceNoAssetMuted from '../../../assets/img/map/pins/muted/Node-Missing-Device-No-Asset-Muted.svg';
import PinNodeMuted from '../../../assets/img/map/pins/muted/Pin-Node-Muted.svg';
import PinAttMuted from '../../../assets/img/map/pins/muted/Pin-Att-Muted.svg';

import GuideOfflineSvg from 'assets/img/map/guides/guide-offline.svg';
import GuideOnlineSvg from 'assets/img/map/guides/guide-online.svg';
import GuidePendingInspectionSvg from 'assets/img/map/guides/guide-pending-inspection.svg';
import GuideMissingHasDeviceSvg from 'assets/img/map/guides/guide-missing-has-device.svg';
import GuideMissingNoDeviceSvg from 'assets/img/map/guides/guide-missing-no-device.svg';
import GuideMutedSvg from 'assets/img/map/guides/guide-muted.svg';
import GuideMutedMissingSvg from 'assets/img/map/guides/guide-muted-missing.svg';
import GuideNoDeviceNoAssetSvg from 'assets/img/map/guides/guide-no-device-no-asset.svg';

export default {
  MapMarkerGrey,
  MapMarkerLight,
  AddIconGrey,
  AddIconLLight,
  ResetIcon,
  StartWorkZone,
  EndWorkzone,
  StartWorkZoneOff,
  EndWorkZoneOff,
  SateliteMode,
  DefaultMode,
  //taper
  PinTaperOnState,
  PinTaperOffState,
  PinTaperOffStateBg,
  PinTaperOnStateBg,
  PinTaperOffStateUnchecked,
  PinTaperOffStateChecked,
  PinTaperOnStateCheckedOn,
  PinTaperOnStateUnchecked,
  //node
  PinNodeOffState,
  PinNodeOnState,
  PinNodeOffStateBg,
  PinNodeOnStateBg,
  PinNodeOffStateUnChecked,
  PinNodeOnStateUnChecked,
  PinNodeOnStateChecked,
  PinNodeOffStateChecked,
  //gateAway
  PinGateAwayOffState,
  PinGateAwayOnState,
  PinGateawayOffStateBg,
  PinGateawayOnStateBg,
  PinGateawayOffStateUnChecked,
  PinGateawayOffStateChecked,
  PinGateawayOnStateChecked,
  PinGateawayOnStateUnChecked,
  //light
  LightOperatingIcon,
  LightOfflineIcon,
  //assets
  UnassignedAsset, //asset removed deleted dont have serial number +
  MissingAssetNoDevice, //+
  MissingAssetHasDevice, //+
  OfflineAssetHasDevice, //+
  OnlineAssetHasDevice, //+
  PendingInspectionAssetHasDevice, //+
  //devices
  AttenOffline, //+
  AttenOnline, //+
  AttenPending, //+
  AttenUnassigned, //+
  NodeOffline, //+
  NodeOnline, //+
  NodePending, //+
  NodeUnassigned, //+
  //missing
  AttenMissingDevice, //!location_id  +
  AttenMissingDeviceNoAsset, // +
  NodeMissingDevice, //+
  NodeMissingDeviceNoAsset, //+

  //assignMap
  UnassignedAssetAssignMap,
  UnassignedAssetAssignMapChecked,
  MissingAssetNoDeviceAssignMap,
  MissingAssetNoDeviceAssignMapChecked,

  //muted
  PinAttMuted,
  PinNodeMuted,
  AttMissingDeviceNoAssetMuted,
  NodeMissingDeviceNoAssetMuted,
  AssetMuted,
  AssetMutedMissing,

  //Guide
  GuideMutedSvg,
  GuideOnlineSvg,
  GuideOfflineSvg,
  GuideMutedMissingSvg,
  GuideNoDeviceNoAssetSvg,
  GuidePendingInspectionSvg,
  GuideMissingHasDeviceSvg,
  GuideMissingNoDeviceSvg,
};
