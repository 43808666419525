import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const DeleteImageModal = (props) => {
    const { showModal, showHideDeleteImageModal, onImageRemove, text } = props;

    const onDeleteImage = () => {
        onImageRemove(props.deletedIndex);
        showHideDeleteImageModal();
    };

    return (
        <Modal
            className='modal-content-teams modalMainDimensions'
            style={{ height: "100%", width: "90%", margin: "auto", background: 'transparent', boxShadow: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            backdrop={true}
            toggle={showHideDeleteImageModal}
            isOpen={showModal}
        >
            <ModalHeader toggle={showHideDeleteImageModal} style={{ borderBottom: 'none' }} />
            <ModalBody style={{ padding: '0 20px 20px', paddingTop: '0px' }}>
                <div className='titleModel' style={{ marginBottom: '15px' }}>Remove Image</div>
                <div style={{ marginBottom: '30px' }}>
                    {text || 'Are you sure you want to remove the image?'}
                </div>
                <div className='d-flex justify-content-between' style={{width: '100%'}}>
                    <button onClick={showHideDeleteImageModal} className='grp-create'
                            style={{maxWidth: '100%', width: '45%', background: 'transparent', color: '#000', border: '1px solid rgba(41, 49, 63, 0.3)'}}>
                        Cancel
                    </button>
                    <button onClick={onDeleteImage}
                            className='grp-create'
                            style={{maxWidth: '100%', width: '45%', background: '#2C7BE5'}}>
                        Delete
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DeleteImageModal;
