import {
  ASYNC_STATUS,
  GET_ALL_REPORTS,
  CLEAR_REPORTS_CURSOR,
  CLEAR_REPORTS,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORTS,
  SET_REPORT_URL,
  DOWNLOAD_SINGLE_REPORT,
  SET_REPORTS_URL,
  SET_SINGLE_REPORTS_URL,
  UPDATE_REPORT_IN_LIST,
} from '../constants';

const initialState = {
  message: null,
  status: ASYNC_STATUS.FAILURE,
  loadedReports: [],
  reportUrl: null,
  cursorNextReport: null,
  loader: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REPORTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching data...',
          };
        case ASYNC_STATUS.SUCCEED:
          let data = action.data;

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the devices.',
            loadedReports:
              action.isScroll === true
                ? [...state.loadedReports, ...data.results]
                : [...data.results],
            cursorNextReport: data.cursor,
            currentPage: 1,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        default:
          return state;
      }
    case CLEAR_REPORTS: {
      return {
        ...state,
        message: null,
        loadedReports: [],
      };
    }
    case CLEAR_REPORTS_CURSOR: {
      return {
        ...state,
        cursorNextReport: null,
      };
    }
    case UPDATE_REPORT_IN_LIST: {
      const {data, inspection_report = {}} = action.data;
      const updatedItem = state.loadedReports.find(i => i.impact_id === data.impact_id);

      if (updatedItem) {
        Object.assign(updatedItem, {...data});
        updatedItem.inspection_report &&
          Object.assign(updatedItem.inspection_report, {...inspection_report});
      }

      return {
        ...state,
      };
    }
    case DOWNLOAD_REPORTS:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: action.message,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            message: action.message,
            reportUrl: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
          };
        default:
          return state;
      }
    case DOWNLOAD_SINGLE_REPORT:
      switch (action.status) {
        case ASYNC_STATUS.REQUEST:
          return {
            ...state,
            message: action.message,
          };
        case ASYNC_STATUS.SUCCEED:
          return {
            ...state,
            message: action.message,
            reportUrl: action.data,
          };
        case ASYNC_STATUS.FAILURE:
          return {
            ...state,
            message: action.message,
          };
        default:
          return state;
      }
    case SET_REPORTS_URL:
      return {
        ...state,
        reportUrl: action.data,
      };
    default:
      return state;
  }
}
