import {object, string} from 'yup';
import ErrorMessage from 'core/services/schemas/messages';

export function getInitialValues(data = {}) {
  return {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    vehicleId: '',
    maintenance: false,
    construction: false,
    sendSms: false,
    defaultGeofenceDistance: '',
    defaultGeofenceUnit: '',
    trackMovements: false,
    update_stationary_events: data?.updateStationaryEvents || false,
    iconType: '',
    ...data,
    geofenceDistance: data?.geofenceDistance || data?.defaultGeofenceDistance || '',
    alertMessage: data?.alertMessage || data?.defaultMessage || '',
    geofenceUnit: data?.geofenceUnit || data?.defaultGeofenceUnit || '',
  };
}

export const validationSchemaStationary = object().shape({
  firstName: string().trim().required(ErrorMessage.required),
  lastName: string().trim().required(ErrorMessage.required),
  phoneNumber: string().required(ErrorMessage.required),
  vehicleId: string().trim().required(ErrorMessage.required),
  alertMessage: string().trim().max(40, 'Maximum 40 characters.'),
  // maintenance: string().trim().required(ErrorMessage.required),
  geofenceDistance: string().trim().nullable().required(ErrorMessage.required),
});

export const validationSchemaMoving = object().shape({
  firstName: string().trim().required(ErrorMessage.required),
  lastName: string().trim().required(ErrorMessage.required),
  phoneNumber: string().required(ErrorMessage.required),
  vehicleId: string().trim().required(ErrorMessage.required),
  alertMessage: string().trim().max(40, 'Maximum 40 characters.'),
  // maintenance: string().trim().required(ErrorMessage.required),
});

export const unitOptions = [
  {
    label: 'km',
    value: 'kilometer',
  },
  {
    label: 'mi',
    value: 'mile',
  },
  {
    label: 'm',
    value: 'meter',
  },
  {
    label: 'ft',
    value: 'feet',
  },
];

export const checkOnlyNumber = e => {
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
};
