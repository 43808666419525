import React from 'react';

const TextField = ({
  id = '',
  name,
  hint,
  icon,
  step,
  shadow,
  elemRef,
  errorMessage,
  value = '',
  label = '',
  type = 'text',
  className = '',
  placeholder = '',
  disabled = false,
  onBlur,
  onFocus,
  onChange,
  onClick,
  onKeyDown,
  onKeyUp,
  iconHandler,
  autoComplete,
  inputmode,
  inputClassName,
  iconClassName,
  relativeErrorMessage,
  ...props
}) => {
  return (
    <div
      className={`pi-text-field ${className} ${shadow ? 'pi-shadow' : ''} ${
        errorMessage !== '' ? 'pi-invalid' : ''
      }`}
    >
      {label && (
        <label className='pi-field-label' id={id}>
          {label}
        </label>
      )}
      <div className='pi-field-wrapper' icon={icon}>
        <input
          id={id}
          autoComplete={autoComplete}
          min={0}
          type={type}
          step={step}
          name={name}
          ref={elemRef}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          inputMode={inputmode}
          className={inputClassName}
          {...props}
        />
        {icon && <i className={`pi-icon icon-${icon} ${iconClassName}`} onClick={iconHandler} />}
      </div>
      {errorMessage && errorMessage !== '' && (
        <p className={`pi-error-message ${relativeErrorMessage ? '!relative !bottom-0' : ''}`}>
          {errorMessage}
        </p>
      )}
      {hint && <p className='pi-hint'>{hint}</p>}
    </div>
  );
};

export default TextField;
