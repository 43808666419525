import {formatDate, formatDateWithOffset} from 'helpers/functions';
import moment from 'moment';
import {formatHistoryDate} from '../locations/historyLogs/HistoryLogsUtils';

export const colors = ['primary', 'green', 'gray', 'secondary', 'red', 'purple'];

export const statusButtons = [
  // {
  //     label: 'Pending Maintenance',
  //     icon: 'schedule',
  //     iconColor: 'secondary',
  //     name: 'status',
  //     labelId: 'pending',
  //     value: 'PENDING_MAINTENANCE'
  // },
  {
    label: 'Pending Inspection',
    icon: 'warning',
    iconColor: 'red',
    name: 'status',
    labelId: 'inspection',
    value: 'PENDING_INSPECTION',
  },
  {
    label: 'Closed',
    icon: 'task-black',
    iconColor: 'green',
    name: 'status',
    labelId: 'closed',
    value: 'CLOSED',
  },
];

export const attachmentButtons = [
  {
    label: 'Yes',
    name: 'yes',
    labelId: 'yes',
    value: 'YES',
  },
  {
    label: 'No',
    name: 'no',
    labelId: 'no',
    value: 'NO',
  },
];

export const filterButtons = [
  {
    label: 'All',
    name: 'all',
    labelId: 'all',
    value: 'all',
    checked: false,
  },
  {
    label: 'Today',
    name: 'today',
    labelId: 'today',
    value: 'today',
    checked: false,
  },
  {
    label: 'This week',
    name: 'week',
    labelId: 'wek',
    value: 'week',
    checked: true,
  },
  {
    label: 'This month',
    name: 'month',
    labelId: 'month',
    value: 'month',
    checked: false,
  },
  {
    label: 'This year',
    name: 'year',
    labelId: 'year',
    value: 'year',
    checked: false,
  },
];

export const reportSeverityButtons = [
  {
    label: 'All',
    name: 'allSeverity',
    labelId: 'allSeverity',
    value: 'ALL',
    checked: true,
  },
  {
    label: 'Impacts only',
    name: 'impacts',
    labelId: 'impacts',
    value: 'IMPACTS_ONLY',
    checked: false,
  },
];

export const reportTooltips = [
  '<p><h5>NOTE:</h5>Display all Reports, or only show Reports with confirmed impacts.</p>',
];

export const severityOptions = [
  {value: '', label: 'All'},
  {value: '0 - No impact observed', label: '0 - No Impact observed'},
  {
    value: '1 - Minor impact observed, no repair required',
    label: '1 - Minor Impact observed, no repair required',
  },
  {
    value: '2 - Minor impact observed, repair required',
    label: '2 - Minor Impact observed, repair required',
  },
  {
    value: '3 - Major impact observed, repair/replacement required',
    label: '3 - Major Impact observed, repair/replacement required',
  },
];

export const tooltips = [
  '<p>Filter by all reports or only reports with confirmed impacts.</p> <h5>NOTE:</h5><p>Impacts with no reports will not appear.</p>',
];

export const getDefaultTableHeading = hide => {
  const options = [
    {title: 'Date', value: 'impact_date', sortAction: true, active: true, type: 'desc'},
    {title: 'Name', value: 'name', sortAction: true, active: false},
    {title: 'Status', value: 'status', sortAction: true, active: false},
    {title: 'Severity', value: 'severity', sortAction: true, active: false},
    {title: 'Model', value: 'model', sortAction: true, active: false, classes: 'pi-visibility'},
    {title: 'Team', value: 'team', sortAction: true, active: false, classes: 'pi-visibility'},
  ];

  if (!hide.actions) {
    options.push({title: '', value: 'actions', sortAction: false, active: false});
  }

  return options;
};

export const getStatusIcon = status => {
  switch (status) {
    case 'PENDING_MAINTENANCE':
      return {
        icon: 'schedule',
        color: 'secondary',
      };
    case 'PENDING_INSPECTION':
      return {
        icon: 'warning',
        color: 'red',
      };
    case 'CLOSED':
      return {
        icon: 'task-black',
        color: 'green',
      };
    default:
  }
};

export const statusFormater = status => {
  return (
    status &&
    status
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join(' ')
  );
};

export const statusReformat = status => {
  return status && status.toUpperCase().split(' ').join('_');
};

export const severityFormatter = severity => {
  let value = '';
  if (severity === '0 - No impact observed') {
    value = 'No Impact';
  }
  if (severity === '1 - Minor impact observed, no repair required') {
    value = 'Minor Impact no repair';
  }
  if (severity === '2 - Minor impact observed, repair required') {
    value = 'Minor Impact with repair';
  }
  if (severity === '3 - Major impact observed, repair/replacement required') {
    value = 'Major Impact';
  }
  return value;
};

export const timeFormater = time => {
  return moment.unix(time).format('MM/DD/YY h:mm A');
};

export const reportActionsBtns = {
  button1Color: 'text',
  button1Icon: 'file-download',
  button2Color: 'text',
  button2Icon: 'double-arrow',
};

export const reportSingleBtn = {
  label: 'Add report',
  icon: 'circle-plus',
};

export const alphabeticSort = (data, type, field) => {
  if (type === 'asc') {
    return data.sort((a, b) => {
      if (field === 'groups' || field === 'subgroups') {
        return a[field][0].name.toUpperCase() > b[field][0].name.toUpperCase()
          ? -1
          : a[field][0].name.toUpperCase() < b[field][0].name.toUpperCase()
          ? 1
          : 0;
      } else {
        return a[field].toUpperCase() > b[field].toUpperCase()
          ? -1
          : a[field].toUpperCase() < b[field].toUpperCase()
          ? 1
          : 0;
      }
    });
  } else {
    return data.sort((a, b) => {
      if (field === 'groups' || field === 'subgroups') {
        return a[field][0].name.toUpperCase() < b[field][0].name.toUpperCase()
          ? -1
          : a[field][0].name.toUpperCase() > b[field][0].name.toUpperCase()
          ? 1
          : 0;
      } else {
        return a[field].toUpperCase() < b[field].toUpperCase()
          ? -1
          : a[field].toUpperCase() > b[field].toUpperCase()
          ? 1
          : 0;
      }
    });
  }
};

export const searchFunction = (data, searchTerm) => {
  return data.filter(item => {
    const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      item.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.groups[0].name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subgroups[0].name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
};

export const emailValidation = email => {
  let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return email !== ''
    ? email.match(mailFormat)
      ? ''
      : 'Incorrect email format.'
    : 'This field is required.';
};

export const numberFieldValidation = e =>
  ['e', 'E', '.', '+', '-'].includes(e.key) && e.preventDefault();

export const buildReportExpandedHistory = parentData => {
  const data = parentData?.inspection_report || parentData;
  let body = [];
  let images = [];

  let customReports = [];
  let costs = [];
  let total = 0;

  const generalInformation = [
    {
      icon: 'engineering',
      label: 'Performed by',
      text:
        data.author && data.author.first_name
          ? `${data.author.first_name} ${data?.author.last_name}`
          : '',
    },
    {
      icon: 'event',
      label: 'Impact Date',
      text: formatHistoryDate(parentData.impact_date || parentData.event_date),
    },
    {
      icon: 'calendar',
      label: 'Report Date',
      text: formatHistoryDate(data.report_date),
    },
    {
      icon: 'speed',
      label: 'Severity',
      text: parentData.severity,
    },
    {
      icon: 'speed',
      label: 'Other agencies (optional)',
      text: data.other_agencies,
    },
  ];

  const generalInformationNote = [
    {
      icon: 'notes',
      label: 'Notes',
      isNote: true,
      text: data.notes,
    },
  ];

  customReports = data.custom_reports?.flatMap(item => {
    const sectionData = [
      {label: 'Title', text: item.title},
      {label: 'Reference', text: item.value},
      {label: 'Comments', text: item.comment, isNote: true},
      {show: item.title || item.value || item.comment ? true : false},
    ];
    item.show = item.title || item.value || item.comment ? true : false;
    return sectionData;
  });
  let image_data = data?.images || data?.image_urls;

  images =
    image_data &&
    image_data.map((item, index) => {
      const imagesData = {src: item, key: index};
      return imagesData;
    });

  // costs = data.costs?.length && data?.costs?.map(item => {
  //     total += Number(item.cost);
  //     return {label: item.description, text: `$${item.cost}`}
  // });
  // if(costs) {
  //     costs.push({label: 'Total', text: `$${total}`, isTotal: true});
  // }

  const showState = {
    // costs: false,
    images: false,
    customReports: false,
    generalInformation: false,
  };

  // showState.costs = !!total;
  showState.images = !!images?.length;
  customReports?.forEach(report => {
    if (report.show) {
      showState.customReports = true;
    }
  });
  showState.generalInformation = images?.length;
  showState.generalInformationNote = data.notes?.length;
  generalInformation.forEach(info => {
    if (info.text) {
      showState.generalInformation = true;
    }
  });

  body = [
    {
      sectionTitle: 'Inspection General information',
      show: showState.generalInformation,
      infoStrings: generalInformation,
      isRow: true,
    },
    {
      show: showState.generalInformationNote,
      infoStrings: generalInformationNote,
      isRow: false,
      noteSection: true,
    },
    // {
    //     sectionTitle: 'Inspection Cost Information',
    //     infoStrings: costs,
    //     show: showState.costs,
    //     isRow: false
    // },
    {
      sectionTitle: 'Inspection Images',
      imageGallery: images,
      show: showState.images,
    },
    {
      sectionTitle: 'Inspection Additional Information',
      infoStrings: customReports,
      show: showState.customReports,
      isRow: false,
    },
  ];

  return {
    body,
    images,
  };
};

function getRanges() {
  const dates = {
    today: convertTimeRange('today'),
    week: convertTimeRange('week'),
    month: convertTimeRange('month'),
    year: convertTimeRange('year'),
  };

  const ranges = {
    today: {
      start: formatDateWithOffset(dates.today.start).dateFormatted,
      end: formatDateWithOffset(dates.today.end).dateFormatted,
    },
    week: {
      start: formatDateWithOffset(dates.week.start).dateFormatted,
      end: formatDateWithOffset(dates.week.end).dateFormatted,
    },
    month: {
      start: formatDateWithOffset(dates.month.start).dateFormatted,
      end: formatDateWithOffset(dates.month.end).dateFormatted,
    },
    year: {
      start: formatDateWithOffset(dates.year.start).dateFormatted,
      end: formatDateWithOffset(dates.year.end).dateFormatted,
    },
  };

  return ranges;
}

export const convertDateToRange = (startDate, endDate) => {
  const ranges = getRanges();
  if (ranges.today.start === startDate && ranges.today.end === endDate) {
    return 'today';
  } else if (ranges.week.start === startDate && ranges.week.end === endDate) {
    return 'week';
  } else if (ranges.month.start === startDate && ranges.month.end === endDate) {
    return 'month';
  } else if (ranges.year.start === startDate && ranges.year.end === endDate) {
    return 'year';
  } else {
    return '';
  }
};

export const convertTimeRange = time => {
  if (time === 'today') {
    let thisDay = new Date();
    let times = {
      start: thisDay,
      end: thisDay,
    };
    return times;
  } else if (time === 'month') {
    let date = new Date();
    let times = {
      start: new Date(date.getFullYear(), date.getMonth(), 1),
      end: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    };
    return times;
  } else if (time === 'week') {
    let curr_date = new Date();
    let week = [];

    for (let i = 1; i <= 7; i++) {
      let first = curr_date.getDate() - curr_date.getDay() + i;
      let day = new Date(curr_date.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }

    let times = {
      start: new Date(week[0]),
      end: new Date(week[6]),
    };
    return times;
  } else if (time === 'year') {
    let year = new Date().getFullYear();
    let times = {
      start: new Date(`01/01/${year}`),
      end: new Date(`12/31/${year}`),
    };
    return times;
  } else {
    let times = {
      start: null,
      end: null,
    };
    return times;
  }
};

export const convertTimeDate = time => {
  if (time !== null && !isNaN(time)) {
    let startDateFormatted = moment(time).format('YYYY-MM-DD');
    let simpleStartDate = new Date(startDateFormatted).getTime();
    return simpleStartDate / 1000;
  } else {
    return '';
  }
};

export const vallidateDate = date => isNaN(date);
