import React from 'react';

const TextIconCell = props => {
  let className = props.className ? props.className : '';

  function onClick() {
    props.onClick(props.data);
  }

  return (
    <>
      <td className={`pi-type-text-icon ${className}`}>
        <div className='pi-td'>
          <strong>{props.label}</strong>
          <i
            className={`pi-icon icon-${props.data.icon} pi-color-${props.data.color}`}
            onClick={onClick}
          />
        </div>
      </td>
    </>
  );
};

export default TextIconCell;
