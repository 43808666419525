import React from 'react';
import {createRoot} from 'react-dom/client';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@mui/material/styles';

import theme from './theme';

import './index.css';
import App from './App';
import {store, persistor} from './store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </LocalizationProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>,
);
