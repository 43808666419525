import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ASYNC_STATUS} from '../../../../impact-detection/redux/constants';

import ReportMobileHead from '../head';
import ReportMobileStep1 from './step1';
import ReportMobileStep2 from './step2';
import ReportMobileStep3 from './step3';
import ReportMobileStep4 from './step4';
import ReportMobileStep5 from './step5';
import ReportMobileStep6 from './step6';
import ReportMobileStep7 from './step7';
import ReportMobileStep8 from './step8';
import ReportMobileStep9 from './step9';
import ReportMobileStep10 from './step9';

import {
  mobileReportStepChange,
  mobileReportFormReset,
  submitMobileReport,
} from '../../../../impact-detection/redux/actions/mobile/reportsActions';
import {getMobileLocation} from '../../../../impact-detection/redux/actions/mobile/locationLandingPageActions';
import {useParams} from 'react-router-dom';

const steps = [
  <ReportMobileStep1 />,
  <ReportMobileStep2 />,
  <ReportMobileStep3 />,
  <ReportMobileStep4 />,
  <ReportMobileStep5 />,
  <ReportMobileStep6 />,
  <ReportMobileStep7 />,
  // <ReportMobileStep8/>,
  <ReportMobileStep8 />,
  <ReportMobileStep9 />,
];

const ReportMobileStep = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const {currentStep, maxStep, submitStep, form, status} = useSelector(
    state => state.reportMobile,
  );
  const {location} = useSelector(state => state.locationLandingPage);

  const next = () => {
    if (form['step' + currentStep].isValid) {
      if (form['step' + currentStep].impact_severity?.value === 'No Impact') {
        dispatch(mobileReportStepChange(currentStep + 2));
      } else {
        dispatch(mobileReportStepChange(currentStep + 1));
      }
    }
  };

  const back = () => {
    if (currentStep === 5 && form['step' + 3].impact_severity?.value === 'No Impact') {
      dispatch(mobileReportStepChange(currentStep - 2));
    } else {
      dispatch(mobileReportStepChange(currentStep - 1));
    }
  };

  const goLandingPage = async () => {
    await dispatch(getMobileLocation(urlParams.impact_id));
    dispatch(mobileReportFormReset());
    dispatch(mobileReportStepChange(0));
  };

  const listenSubmit = () => {
    if (status === ASYNC_STATUS.SUCCEED) next();
  };

  const submit = async () => {
    if (status === ASYNC_STATUS.REQUEST) return;
    await dispatch(submitMobileReport(form, location));
  };

  useEffect(listenSubmit, [status]);

  return (
    <div className='rm-steps'>
      <ReportMobileHead addReportButton={true} />

      <div className='rm-step'>
        {/*Current step*/}
        {currentStep && steps[currentStep - 1]}

        {/*Step buttons (back, next)*/}
        {maxStep !== currentStep && (
          <div className='rm-step-buttons'>
            <button
              className={`${status === ASYNC_STATUS.REQUEST ? 'disabled' : ''} rm-back-button`}
              onClick={back}
            >
              Back
            </button>
            {submitStep !== currentStep && (
              <button
                className={`${
                  !form['step' + currentStep].isValid ? 'disabled' : ''
                } rm-next-button`}
                onClick={next}
              >
                Next
              </button>
            )}
            {submitStep === currentStep && (
              <button
                className={`${
                  status === ASYNC_STATUS.REQUEST ? 'disabled' : ''
                } rm-next-button`}
                onClick={submit}
              >
                Submit
              </button>
            )}
          </div>
        )}

        {/*Button for Step 8*/}
        {maxStep === currentStep && (
          <button onClick={goLandingPage} className='rm-mainPage-button'>
            Go to main page
          </button>
        )}
      </div>
    </div>
  );
};

export default ReportMobileStep;
