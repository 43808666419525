import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RemoveIcon from '../../../../../assets/img/svg/remove.svg';
import AddIcon from '../../../../../assets/img/svg/add.svg';
import {
  mobileReportFormArrayChange,
  mobileReportAddNewForm,
  mobileReportRemoveNewForm,
} from '../../../../impact-detection/redux/actions/mobile/reportsActions';
import {FormGroup, Input} from 'reactstrap';
import {Creatable} from 'react-select';
import {validateAmount} from '../../../../../helpers/functions';
import DollarIcon from '../../../../../assets/img/svg/dollar.svg';

const modifyReferenceTypes = types =>
  types.map(item => {
    return {value: item.replace(/ /g, '_'), label: item};
  });

const ReportMobileStep7 = () => {
  const dispatch = useDispatch();
  const {form} = useSelector(state => state.reportMobile);
  const {referenceTypes} = useSelector(state => state.reports);

  const referenceTypesOptions = modifyReferenceTypes(referenceTypes);

  const changeFiled = (subField, e, formIndex) => {
    const value = e?.value || (e?.target ? e.target?.value : '');

    dispatch(
      mobileReportFormArrayChange({
        formIndex,
        value,
        subField,
        field: 'reports',
        step: 'step7',
      }),
    );
  };

  return (
    <div className='rm-step-content'>
      <h4>
        Please provide case number, work order number, service request, police report, etc.
      </h4>
      <div className='rm-cost-content'>
        {form.step7.reports.value.map((report, i) => {
          return (
            <div key={i} className='rm-cost-section'>
              <FormGroup>
                <Creatable
                  isSearchable
                  isClearable
                  menuPortalTarget={window.innerWidth > 636 ? document.body : null}
                  name='title'
                  placeholder='Title'
                  bsSize='lg'
                  className='rm-select'
                  options={referenceTypesOptions}
                  value={report.title ? {value: report.title, label: report.title} : null}
                  onChange={e => changeFiled('title', e, i)}
                />
              </FormGroup>
              <Input
                type='text'
                placeholder='Value'
                value={report.value}
                onChange={e => {
                  const value = validateAmount(e.target.value, report.value);
                  changeFiled('value', {value}, i);
                }}
              />

              {/*<div className="cost-field">*/}
              {/*    <img src={DollarIcon} alt=""/>*/}
              {/*    <Input*/}
              {/*        type='text'*/}
              {/*        placeholder='Cost'*/}
              {/*        value={report.cost}*/}
              {/*        onChange={(e )=> {*/}
              {/*            const value = validateAmount(e.target.value, report.cost);*/}
              {/*            changeFiled('cost',  {value}, i)*/}
              {/*        }}*/}
              {/*    />*/}
              {/*</div>*/}

              <Input
                type='textarea'
                placeholder='Enter description here'
                value={report.comment}
                onChange={e => changeFiled('comment', e, i)}
              />
            </div>
          );
        })}
      </div>

      {/*Total Price*/}
      {/*<div className="rm-total">*/}
      {/*    Total <span>${sumBy(form.step7.reports.value,i => parseFloat(i.cost || 0) || 0).toFixed(2)}</span>*/}

      {/*</div>*/}

      {/*Remove/Add new buttons*/}
      <div className='rm-remove-add-buttons'>
        {form.step7.reports.value.length > 1 && (
          <button onClick={() => dispatch(mobileReportRemoveNewForm())}>
            <img src={RemoveIcon} alt='Remove' />
            Remove
          </button>
        )}
        <button
          className={`${form.step7.reports.value.length > 1 ? '' : 'only-remove-btn'}`}
          onClick={() => dispatch(mobileReportAddNewForm())}
        >
          <img src={AddIcon} alt='Add' />
          ADD NEW {form.step7.reports.value.length > 1 ? '' : 'REPORT'}
        </button>
      </div>
    </div>
  );
};

export default ReportMobileStep7;
