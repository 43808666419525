import {
  GET_WORKZONE_TEAM_USERS,
  SET_WORKZONE_TEAM_TABLE_DATA,
  CLEAR_WORKZONE_TEAM_USERS,
} from '../constants';
import {ASYNC_STATUS} from '../../../impact-detection/redux/constants';
import {cloneDeep} from 'lodash';

const initialState = {
  teamData: [],
  teamTableData: [],
  message: null,
  updateLAmpStatus: ASYNC_STATUS.FAILURE,
  deleteLAmpStatus: ASYNC_STATUS.FAILURE,
};

export default function reducer(state = {...initialState}, action) {
  switch (action.type) {
    case GET_WORKZONE_TEAM_USERS: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching team data list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the team users.',
            teamData: action.data,
            teamTableData: action.data,
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }
    case CLEAR_WORKZONE_TEAM_USERS: {
      return {
        ...state,
        teamData: [],
        teamTableData: [],
      };
    }
    case SET_WORKZONE_TEAM_TABLE_DATA:
      return {
        ...state,
        teamTableData: action.data,
      };
    default:
      return state;
  }
}
