import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {mobileReportFormChange} from '../../../../impact-detection/redux/actions/mobile/reportsActions';

const severityOptions = [
  {
    value: 'No Impact',
    label: 'No Impact',
  },
  {
    value: 'Minor Impact',
    label: 'Minor Impact',
  },
  {
    value: 'Major Impact',
    label: 'Major Impact',
  },
];

const ReportMobileStep3 = () => {
  const dispatch = useDispatch();
  const {form} = useSelector(state => state.reportMobile);

  const changeFiled = (field, value) => {
    dispatch(mobileReportFormChange({field, step: 'step3', value}));
  };

  return (
    <div className='rm-step-content'>
      <h4>*Rate the impact severity</h4>
      <div className='rm-radio-buttons'>
        {severityOptions.map(opt => (
          <label key={opt.value}>
            <input
              checked={form.step3.impact_severity.value === opt.value}
              type='radio'
              value={opt.value}
              onChange={e => changeFiled('impact_severity', e.target.value)}
            />
            <span>{opt.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default ReportMobileStep3;
