export function normalizeKeysForRequest({...obj}, selectable = false) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (selectable && obj[key]?.value && obj[key]?.label) {
      obj[key] = obj[key].value;
    }
    let newKey = key
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .split(' ')
      .reduce((prev, cr, index) => prev + (index ? '_' : '') + cr.toLowerCase(), '');
    newObj[newKey] = obj[key];
  });

  return newObj;
}

export function normalizeKeysFromResponse(obj) {
  if (typeof obj !== 'object') {
    return Error('Converted value type needs to be an object.');
  }
  const newObj = {};
  Object.keys(obj).forEach(key => {
    let newKey = key
      .split('_')
      .reduce(
        (prev, cr, index) => prev + (index ? cr[0].toUpperCase() + cr.slice(1) : cr),
        '',
      );
    newObj[newKey] = obj[key];
  });

  return newObj;
}

export const generalError = message => {
  return message && typeof message === 'string'
    ? message
    : 'Impossible to update the change. Please try again!';
};

export function trimStringValuesOfObject(obj) {
  if (typeof obj !== 'object') {
    throw Error();
  }
  let newObj = {};
  let currentValue;
  Object.keys(obj).forEach(key => {
    currentValue = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
    newObj[key] = currentValue;
  });

  return newObj;
}
