import React, {useState} from 'react';
import PhoneInput from 'react-phone-input-2';

const PhoneNumber = ({
  hint,
  value,
  label,
  shadow,
  disabled,
  className,
  errorMessage,
  onBlur,
  onFocus,
  onClick,
  onChange,
  onKeyDown,
  onValidate,
  icon,
  iconHandler,
  onKeyPress,
}) => {
  let [isValidPattern, setIsValidPattern] = useState(null);

  function isValid(value) {
    let isValid = value.length >= 7;
    if (isValidPattern !== isValid) {
      setIsValidPattern(isValid);
      onValidate && onValidate(isValid);
    }

    return true;
  }

  return (
    <div
      className={`pi-phone-number ${className ? className : ''} ${shadow ? 'pi-shadow' : ''} ${
        errorMessage !== '' ? 'pi-invalid' : ''
      }`}
    >
      {label && <label className='pi-field-label'>{label}</label>}
      <div className='pi-field-wrapper !overflow-visible'>
        <PhoneInput
          country='us'
          countryCodeEditable
          className='pi-phone-number-field !border-0'
          inputClass='!border-0'
          buttonClass='!border-0'
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          isValid={isValid}
          onFocus={onFocus}
          onClick={onClick}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
        />

        {icon && (
          <i
            className={`pi-icon icon-${icon} ${
              value && !errorMessage ? 'cursor-pointer' : 'opacity-50'
            }`}
            onClick={value && !errorMessage ? iconHandler : undefined}
          />
        )}
      </div>
      {errorMessage && errorMessage !== '' && <p className='pi-error-message'>{errorMessage}</p>}
      {hint && <p className='pi-hint'>{hint}</p>}
    </div>
  );
};

export default PhoneNumber;
