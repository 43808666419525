import React, {useEffect} from 'react';
import {Collapse} from 'reactstrap';

const ReactCollapse = ({
  className = '',
  cardId,
  headerContent,
  bodyContent,
  merged = false,
  closeCollapse,
  handleAction,
  setCloseCollapse,
  headerChildren,
  isOpen,
  setIsOpen,
}) => {
  const collapseListener = () => {
    if (closeCollapse) {
      setCloseCollapse(false);
      setIsOpen('');
    }
  };

  useEffect(collapseListener, [closeCollapse]);

  const handleToggle = key => {
    if (isOpen === key) {
      setIsOpen('');
    } else {
      setIsOpen(key);
      if (handleAction) {
        handleAction(cardId);
      }
    }
  };

  return (
    <div className={`pi-collapse ${className} ${merged ? 'pi-merged' : ''}`} key={cardId}>
      <div className='pi-collapse-header'>
        <div onClick={() => handleToggle(cardId)}> {headerContent}</div>
        <div className='pi-header-action'>{headerChildren}</div>
      </div>

      <Collapse isOpen={isOpen === cardId} className='!visible'>
        <div className='pi-collapse-body'>{bodyContent}</div>
      </Collapse>
    </div>
  );
};

export default ReactCollapse;
