import React from 'react';

const Battery = ({className = '', percentage, withValue, data, elementType}) => {
  percentage = data?.battery_level || percentage || 0;
  const elem = (
    <div className={`pi-battery-icon ${className}`}>
      <div className='pi-battery-bar' style={{width: percentage + '%'}} />
    </div>
  );

  const battery_elem = withValue ? (
    <div style={{display: 'flex'}}>
      {elem}
      {percentage}%
    </div>
  ) : (
    elem
  );

  return (
    <>
      {elementType && elementType === 'td' ? (
        <td>
          <div className='pi-td'>{battery_elem}</div>
        </td>
      ) : (
        battery_elem
      )}
    </>
  );
};

export default Battery;
