import React from 'react';
import {Spinner} from 'reactstrap';

const RadioButton = ({
  type,
  name,
  label,
  value,
  image,
  labelId,
  disabled,
  children,
  hasLoader,
  selectedValue,
  icon = '',
  size = 'sm',
  className = '',
  iconColor = '',
  onChange,
  iconSize
}) => {
  return (
    <div
      className={`pi-radio-btn ${className} ${hasLoader ? 'pi-loading' : ''} pi-size-${size}`}
    >
      <input
        type={type}
        name={name}
        id={labelId}
        value={value}
        disabled={disabled}
        checked={selectedValue === value}
        onChange={onChange}
      />
      <label className={disabled ? 'pi-disabled' : ''} htmlFor={labelId}>
        {icon && (
          <i
            className={`icon-${icon} pi-color-${iconColor} pi-size-${iconSize}`}
            dangerouslySetInnerHTML={{__html: children}}
          />
        )}
        {image && <img src={image} />}
        {label && <strong>{label}</strong>}

        {hasLoader && <Spinner color='primary' size='sm' className='pi-loader' />}
      </label>
    </div>
  );
};

export default RadioButton;
