import React from 'react';

const InfoTag = ({
    className,
    label,
    color='gray',
    size='md'
}) => {
    return (
        <div className={`pi-info-tag pi-${color} pi-size-${size} ${className}`}>
            <span>{label}</span>
        </div>
    );
};

export default InfoTag;