import {createSlice} from '@reduxjs/toolkit';
import {
  getImpactDetectionMapItems,
  updateImpactDetectionMapItem,
  deleteImpactDetectionMapItem,
  updateImpactDetectionMapItemsRequest,
} from './asyncActions';

const initialState = {
  data: [],
  loading: false,
  error: false,
  message: null,

  lastRequestAt: null,
  lastRequestParams: {},

  addLocationModal: {
    open: false,
    mode: 'addLocationForm',
    formState: {
      values: {},
      errors: {},
      touched: {},
      confirmButton: 'next',
      hasGoBackOption: false,
      hide: {showOnMap: true},
      title: 'add new asset',
    },
  },
  assignToModal: {
    open: false,
    state: null,
  },

  event: false,
};

const mapSlice = createSlice({
  initialState,
  name: 'impact-detection-map',
  reducers: {
    // locations actions
    addItemToMap(state, action) {
      state.data.push(action.payload);
    },

    // modal actions
    updateAddLocationModal(state, action) {
      state.addLocationModal = {...state.addLocationModal, ...action.payload};
    },
    resetAddLocaitonModal(state) {
      state.addLocationModal = initialState.addLocationModal;
    },
    updateAssingToModalc(state, action) {
      state.assignToModal = {...state.assignToModal, ...action.payload};
    },

    setEvent(state, action) {
      state.event = action.payload;
    },
    clearMap() {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder.addCase(getImpactDetectionMapItems.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
      state.error = false;
      state.event = true;
      state.lastRequestAt = action.payload.lastRequestAt;
      state.lastRequestParams = action.payload.lastRequestParams ?? {};
      return state;
    });

    builder.addCase(getImpactDetectionMapItems.pending, state => {
      state.loading = true;
      state.error = false;
      return state;
    });
    builder.addCase(getImpactDetectionMapItems.rejected, state => {
      state.loading = false;
      state.error = true;
      return state;
    });

    // update locally
    builder.addCase(updateImpactDetectionMapItem.fulfilled, (state, action) => {
      state.data = action.payload;
      state.event = true;
    });
    builder.addCase(deleteImpactDetectionMapItem.fulfilled, (state, action) => {
      state.data = action.payload;
      state.event = true;
    });
  },
});

export const ImpactDetectionMapActions = mapSlice.actions;

// async actions
export const ImpactDetectionMapAsyncActions = {
  getImpactDetectionMapItems,
  updateImpactDetectionMapItem,
  deleteImpactDetectionMapItem,
  updateImpactDetectionMapItemsRequest,
};

export default mapSlice.reducer;
