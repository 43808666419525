import React from 'react';

const UserAvatar = ({
    className='',
    username,
    onlyBadge=false,
    color='gray',
    icon,
    iconColor='white',
    searchMode=false,
    component
}) => {
    let abbreviation = username.match(/\b(\w)/g).join('');
    return (
        <div className={`pi-user-avatar pi-${color} ${className}`}>
            {icon ? <span><i className={`icon-${icon} pi-color-${iconColor}`}/></span> : <span>{abbreviation}</span>}
            {!onlyBadge && !searchMode ? <strong>{username}</strong> : component}
        </div>
    );
};

export default UserAvatar;