import {SELECT_DEVICE_FILTERS, SELECT_HISTORY_FILTERS} from '../constants';

const initialState = {
  selectedFilters: [],
  selectedHistoryFilters: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_HISTORY_FILTERS:
      return {...state, selectedHistoryFilters: action.payload};
    case SELECT_DEVICE_FILTERS:
      return {...state, selectedFilters: action.payload};
    default:
      return state;
  }
}
