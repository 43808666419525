import React from 'react';

const Button = ({
  className = '',
  styleType = '',
  color = '',
  shadow = '',
  icon = '',
  disabled = false,
  onClick,
  children,
  size = '',
  type = 'button',
  refAttr,
  ...props
}) => {
  let hasShadow = shadow ? 'pi-shadow' : '';
  let hasIcon = icon ? 'pi-with-arrow' : '';
  let iconPosition = icon ? `pi-${icon}-icon` : '';
  let btnSize = size ? `pi-size-${size}` : '';

  return (
    <button
      {...props}
      onClick={onClick}
      className={`select-none pi-btn pi-${styleType} ${btnSize} pi-${color} ${hasShadow} ${hasIcon} ${iconPosition} ${className}`}
      type={type}
      disabled={disabled}
      ref={refAttr}
    >
      {children}
    </button>
  );
};

export default Button;
