import React from 'react';
import {NavLink} from 'react-router-dom';

const WorkZoneNavigation = ({handleSidebarResponsive}) => {
  return (
    <ul className='pi-nav-items'>
      <li onClick={handleSidebarResponsive}>
        <NavLink
          exact
          to='/workzone/map'
          className='pi-sidebar-menu'
          activeClassName='pi-active'
        >
          <i className='icon-map' />
          <span>Map</span>
        </NavLink>
      </li>
      <li onClick={handleSidebarResponsive}>
        <NavLink exact to='/workzone' className='pi-sidebar-menu' activeClassName='pi-active'>
          <i className='icon-desktop' />
          <span>Work Zones</span>
        </NavLink>
      </li>
      <li onClick={handleSidebarResponsive}>
        <NavLink
          to='/workzone/devices'
          className='pi-sidebar-menu'
          activeClassName='pi-active'
        >
          <i className='icon-devices' />
          <span>Devices</span>
        </NavLink>
      </li>
      <li onClick={handleSidebarResponsive}>
        <NavLink to='/workzone/teams' className='pi-sidebar-menu' activeClassName='pi-active'>
          <i className='icon-groups' />
          <span>Teams</span>
        </NavLink>
      </li>
    </ul>
  );
};

export default WorkZoneNavigation;
