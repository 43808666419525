import {createTheme} from '@mui/material';

const theme = createTheme({
  components: {
    MuiSwitch: {
      root: {
        width: '20px',
      },
      styleOverrides: {
        width: 30,
        switchBase: {
          color: '#354052',
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#64C27D',
          },
        },
        thumb: {
          boxShadow: `0px 0.8170954585075378px 2.4512863159179688px 0px rgba(0, 0, 0, 0.2),
          0px 1.6341909170150757px 0.8170954585075378px 0px rgba(0, 0, 0, 0.12),
          0px 0.8170954585075378px 0.8170954585075378px 0px rgba(0, 0, 0, 0.14)`,
        },
        track: {
          opacity: 0.4,
          backgroundColor: 'rgba(53, 64, 82, 0.38)',
          '.Mui-checked.Mui-checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: 'rgba(100, 194, 125, 0.38)',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#354052',
      light: 'rgba(53, 64, 82, 0.38)',
    },
    success: {
      main: '#64C27D',
      light: 'rgba(100, 194, 125, 0.38)',
    },
  },
});

export default theme;
