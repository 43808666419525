import React from 'react';
import {NotFoundItemModal} from './NotFoundItemModal';
import {BsSearch} from 'react-icons/bs';

import classes from './SearchWithSelect.module.scss';

import Select, {components} from 'react-select';

const SelectCustomStyles = {
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#354052',
  }),
  container: provided => ({
    ...provided,
    width: '100%',
    height: 41,
    color: '#000000',
    fontFamily: 'Roboto',
    fontSize: 14,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: '8px',
    borderColor: state.isFocused ? 'rgba(53, 64, 82, 1)' : 'rgba(255, 255, 255, 1)',
    boxShadow: state.isFocused ? 'none' : baseStyles.boxShadow,
    height: '100%',
  }),
  menu: styles => ({
    ...styles,
    borderRadius: '8px',
  }),
  noOptionsMessage: styles => ({
    ...styles,
    padding: 0,
  }),
};

export const SearchWithSelect = ({
  options,
  isLoading,
  handleSelect,
  rednerRightAction,
  onCreate,
  placeholder,
  className,
  notFoundTitle,
  search,
  onSearchValueChange,
  value,
}) => {
  return (
    <div className={`${classes.container} ${className}`}>
      <Select
        styles={SelectCustomStyles}
        placeholder={placeholder}
        options={options}
        onChange={handleSelect}
        isLoading={isLoading}
        noOptionsMessage={({inputValue}) => {
          return <NotFoundItemModal title={notFoundTitle} value={inputValue} onCreate={onCreate} />;
        }}
        className={classes.selectBox}
        components={{Control}}
        inputValue={search}
        onInputChange={onSearchValueChange}
        value={value}
      />
      {rednerRightAction}
    </div>
  );
};

function Control({children, renderRight, ...props}) {
  return (
    <components.Control {...props}>
      <BsSearch color='#aeb3bb' size={20} className={classes.icon} />
      {children}
    </components.Control>
  );
}
