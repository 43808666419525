import React from "react";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";

const Auth = ({ children }) => (
  <React.Fragment>
    <Main className="pi-auth-wrapper auth-container">
        {children}
    </Main>
  </React.Fragment>
);

export default Auth;
