import {createAsyncThunk} from '@reduxjs/toolkit';
import {apiRequest} from 'core/api/apiRequest';

export const getAllAssetTypesAsyncAction = createAsyncThunk('assetType/getAll', async () => {
  const response = await apiRequest('asset_types');

  if (response.success) {
    return {
      data: response.data,
      message: response.message,
    };
  }

  throw Error(response.message);
});
