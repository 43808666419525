import {
  ASYNC_STATUS,
  GET_WORKZONES,
  LOAD_WORKZONES,
  SEARCH_WORKZONES,
  CLEAR_WORKZONES,
  UPDATE_WORKZONE,
  SET_ACTIVE_WORKZONE,
  SET_LAMP_EDITED,
  SET_LAMP_EDIT_ERROR,
} from '../constants';

const initialState = {
  message: null,
  status: ASYNC_STATUS.FAILURE,
  workzones: [],
  loadedWorkzones: [],
  searchedWorkzones: [],
  currentPage: 1,
  searchValue: '',
  activeWorkzone: null,
  lampEdited: false,
  lampEditError: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_WORKZONES: {
      switch (action.status) {
        case ASYNC_STATUS.REQUEST: {
          return {
            ...state,
            status: ASYNC_STATUS.REQUEST,
            message: 'Fetching workzones list...',
          };
        }
        case ASYNC_STATUS.SUCCEED: {
          let {searchValue} = state;
          let {data, number} = action;
          let searchedWorkzones = data;
          let loadedWorkzones = data;

          if (searchValue) {
            searchedWorkzones = searchedWorkzones.filter(workzone =>
              workzone.name.toLowerCase().includes(searchValue),
            );
          }

          if (number) {
            loadedWorkzones = searchedWorkzones.slice(0, number);
          }

          return {
            ...state,
            status: ASYNC_STATUS.SUCCEED,
            message: 'Succeed to fetch all the workzones.',
            workzones: data,
            searchedWorkzones,
            loadedWorkzones,
            currentPage: 1,
            searchValue: '',
          };
        }
        case ASYNC_STATUS.FAILURE: {
          return {
            ...state,
            status: ASYNC_STATUS.FAILURE,
            message: action.message,
          };
        }
        default:
          return state;
      }
    }

    case LOAD_WORKZONES: {
      let {currentPage, searchedWorkzones} = state;
      let number = action.number;
      let page = currentPage + 1;
      let loadedWorkzones = searchedWorkzones.slice(0, page * number);

      return {
        ...state,
        currentPage: page,
        loadedWorkzones,
      };
    }

    case SEARCH_WORKZONES: {
      let {workzones} = state;
      let searchedWorkzones = workzones;
      let searchValue = action.value;
      let number = action.number;

      if (searchValue) {
        searchedWorkzones = searchedWorkzones.filter(workzone =>
          workzone.name.toLowerCase().includes(searchValue.toLowerCase()),
        );
      }

      let loadedWorkzones = searchedWorkzones;
      if (number) {
        loadedWorkzones = searchedWorkzones.slice(0, number);
      }

      return {
        ...state,
        searchValue,
        workzones,
        currentPage: 1,
        loadedWorkzones,
        searchedWorkzones,
      };
    }

    case CLEAR_WORKZONES: {
      let {workzones} = state;
      let {number} = action.number;
      let loadedWorkzones = workzones;

      if (number) {
        loadedWorkzones = workzones.slice(0, number);
      }

      return {
        ...state,
        message: null,
        status: ASYNC_STATUS.FAILURE,
        workzones,
        loadedWorkzones: loadedWorkzones,
        searchedWorkzones: workzones,
        currentPage: 1,
        searchValue: '',
        activeWorkzone: null,
        lampEdited: false,
        lampEditError: false,
      };
    }

    case UPDATE_WORKZONE: {
      let updatedWorkzone = action.data[0];
      let updatedWorkzoneId = updatedWorkzone.id;
      let {workzones, searchedWorkzones, loadedWorkzones} = state;

      let newWorkzones = [...workzones];
      newWorkzones = newWorkzones.map(workzone => {
        if (workzone.id === updatedWorkzoneId) {
          workzone = updatedWorkzone;
        }
        return workzone;
      });

      let newSearchedWorkzones = [...searchedWorkzones];
      newSearchedWorkzones = newSearchedWorkzones.map(workzone => {
        if (workzone.id === updatedWorkzoneId) {
          workzone = updatedWorkzone;
        }
        return workzone;
      });

      let newLoadedWorkzones = [...loadedWorkzones];
      newLoadedWorkzones = newLoadedWorkzones.map(workzone => {
        if (workzone.id === updatedWorkzoneId) {
          workzone = updatedWorkzone;
        }
        return workzone;
      });

      return {
        ...state,
        workzones: newWorkzones,
        searchedWorkzones: newSearchedWorkzones,
        loadedWorkzones: newLoadedWorkzones,
        activeWorkzone: updatedWorkzone,
      };
    }

    case SET_ACTIVE_WORKZONE: {
      return {
        ...state,
        activeWorkzone: action.workzone,
      };
    }

    case SET_LAMP_EDITED: {
      return {
        ...state,
        lampEdited: action.value,
      };
    }

    case SET_LAMP_EDIT_ERROR: {
      return {
        ...state,
        lampEditError: action.value,
      };
    }

    default:
      return state;
  }
}
