import React from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMap} from '@fortawesome/free-regular-svg-icons';
import {faChartLine, faDesktop, faUser, faWrench} from '@fortawesome/free-solid-svg-icons';

const DashboardMobileNavigation = parent => {
  const groupedProps = {...parent?.props, ...this?.state};
  const {
    handleMobileMenuIcon,
    changeSVGColorWhite,
    changeSVGColorYellow,
    pathname,
    teams,
    map,
    activity,
    reports,
    generic_devices,
  } = groupedProps;

  return (
    <div>
      <NavLink
        onClick={handleMobileMenuIcon}
        onMouseLeave={() => {
          changeSVGColorYellow('map');
        }}
        onMouseOver={() => changeSVGColorWhite('map')}
        className='new_sidebar_menu'
        activeClassName='new_sidebar_map_active'
        to='/maps'
      >
        <span className='sideBar_mapIcon'>
          <FontAwesomeIcon color={pathname === '/maps' ? '#ffffff' : map} icon={faMap} />
        </span>{' '}
        Map
      </NavLink>
      <NavLink
        onClick={handleMobileMenuIcon}
        onMouseLeave={() => changeSVGColorYellow('activity')}
        onMouseOver={() => changeSVGColorWhite('activity')}
        className='new_sidebar_menu'
        activeClassName='new_sidebar_map_active'
        to='/activity'
      >
        <span className='sideBar_mapIcon'>
          <FontAwesomeIcon
            color={pathname === '/activity' ? '#ffffff' : activity}
            icon={faDesktop}
          />
        </span>
        Activity
      </NavLink>
      <NavLink
        onClick={handleMobileMenuIcon}
        onMouseLeave={() => changeSVGColorYellow('generic_devices')}
        onMouseOver={() => changeSVGColorWhite('generic_devices')}
        className='new_sidebar_menu'
        activeClassName='new_sidebar_map_active'
        to='/generic_devices'
      >
        <span className='sideBar_mapIcon'>
          <FontAwesomeIcon
            color={pathname === '/generic_devices' ? '#ffffff' : generic_devices}
            icon={faWrench}
          />
        </span>
        Devices
      </NavLink>
      <NavLink
        onClick={handleMobileMenuIcon}
        onMouseLeave={() => changeSVGColorYellow('teams')}
        onMouseOver={() => changeSVGColorWhite('teams')}
        className='new_sidebar_menu'
        activeClassName='new_sidebar_map_active'
        to='/teams'
      >
        <span className='sideBar_mapIcon'>
          <FontAwesomeIcon color={pathname === '/teams' ? '#ffffff' : teams} icon={faUser} />
        </span>
        Teams
      </NavLink>
      <NavLink
        onClick={handleMobileMenuIcon}
        onMouseLeave={() => changeSVGColorYellow('reports')}
        onMouseOver={() => changeSVGColorWhite('reports')}
        className='new_sidebar_menu'
        activeClassName='new_sidebar_map_active'
        to='/reports'
      >
        <span className='sideBar_mapIcon'>
          <FontAwesomeIcon
            color={pathname === '/reports' ? '#ffffff' : reports}
            icon={faChartLine}
          />
        </span>
        Reports
      </NavLink>
    </div>
  );
};

export default DashboardMobileNavigation;
