import React from 'react';

const PageTitle = ({
    className='',
    title='',
    children,
    backAction
}) => {
    const handleSidebarResponsive = () => {
        document.body.classList.toggle('pi-sidebar-state');
    };

    return (
        <div className={`pi-page-title ${className}`}>
            <button className="pi-sidebar-trigger" onClick={handleSidebarResponsive}>
                <i className="icon-notes"/>
            </button>
            {title &&
            <h1 className={backAction ? 'pi-has-btn':''}>
                {backAction && <button className='pi-back-btn pi-displayed' onClick={backAction}>
                    <i className='icon-arrow-down' />
                </button>}
                {title}
            </h1>}
            {children}
        </div>
    );
};

export default PageTitle;