export default {
  format: 'Wrong format.',
  time: 'Incorrect time format.',
  email: 'Incorrect email format.',
  required: 'This field is required.',
  password: 'The password does not match with the criteria.',
  match: field => `The ${field} do not match.`,
  wrongFormat: type => `Wrong ${type} format.`,
  min: field => `The ${field} must be min 8 characters long.`,
};
