import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import {mobileReportFormChange} from '../../../../impact-detection/redux/actions/mobile/reportsActions';

const ReportMobileStep8 = () => {
  const dispatch = useDispatch();

  const {form} = useSelector(state => state.reportMobile);

  const changeFiled = field => {
    dispatch(
      mobileReportFormChange({field, step: 'step8', value: !form.step8.send_report.value}),
    );
  };

  return (
    <div className='rm-step-content'>
      <h4>Do you want send report via email?</h4>
      <div className='rm-checkbox'>
        <label htmlFor='send_report'>
          <input
            type='checkbox'
            checked={form.step8.send_report.value == true}
            name='send_report'
            value={!!form.step8.send_report.value}
            id='send_report'
            onChange={e => changeFiled('send_report')}
          />
          <span>
            <FontAwesomeIcon icon={faCheck} />
          </span>
          Send report via email
        </label>
      </div>
    </div>
  );
};

export default ReportMobileStep8;
