import React from 'react';

export default function Link({disabled, children, onClick, removeUnderline, className, ...props}) {
  const handleClick = event => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (!event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      onClick && onClick(event);
      return;
    }
    event.stopPropagation();
  };

  return (
    <a
      {...props}
      onClick={handleClick}
      className={removeUnderline ? `!no-underline${className ? ' ' + className : ''}` : className}
    >
      {children}
    </a>
  );
}
