export function onClickAway(onClickOut) {
  let currentRef = null;

  function callback(clickEvent) {
    if (!currentRef || currentRef.contains(clickEvent.target)) return;
    document.removeEventListener('click', callback, true);
    onClickOut(clickEvent);
  }

  function onRefObtained(ref) {
    if (ref === currentRef) return;
    currentRef = ref;
    if (ref) {
      document.addEventListener('click', callback, true);
    } else {
      document.removeEventListener('click', callback, true);
    }
  }

  return onRefObtained;
}

export function preventEventAndStopPropagation(event) {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
