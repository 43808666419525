import React, {useState} from 'react';
import {TextField} from 'elements';

export default function PasswordField(props) {
  const [passwordType, setPasswordType] = useState('password');

  const togglePassword = () => {
    if (passwordType === 'password') {
      return setPasswordType('text');
    }
    setPasswordType('password');
  };

  return (
    <TextField
      {...props}
      className={`pi-password-field pi-right-icon ${props.className && props.className}`}
      icon={passwordType === 'password' ? 'visibility-on' : 'visibility-off'}
      type={passwordType}
      iconHandler={togglePassword}
    />
  );
}
