import React, {useState, useCallback, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import Green from '../../../../assets/svg/green.png';
import Red from '../../../../assets/svg/red.png';
import Yellow from '../../../../assets/svg/yellow.png';
import MapMarkerGrey from '../../../../assets/img/svg/map-marker-grey.svg';
import {MapDefaultSettings} from '../../../../core/helpers/map.helper';

const LocationMapConnect = props => {
  const [deviceZoom, setDeviceZoom] = useState(false);
  const deviceZoomRef = useRef(deviceZoom);
  const actionBtn = useRef();
  const {google} = props;

  const chooseMarker = status => {
    if (status === 'PENDING_INSPECTION') {
      return Red;
    } else if (status === 'PENDING_MAINTENANCE') {
      return Yellow;
    } else {
      return Green;
    }
  };

  const loadTraffic = (mapProps, map) => {
    const {google} = mapProps;
    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(map);
    handleOnLoad(google, map);
    map.setRestriction(MapDefaultSettings.restrictions);
  };

  const setActive = () => {
    !deviceZoomRef.current
      ? actionBtn.current && actionBtn.current.classList.add('pi-active')
      : actionBtn.current && actionBtn.current.classList.remove('pi-active');
  };

  const toggle = useCallback(() => {
    setDeviceZoom(!deviceZoomRef.current);
    setActive();
  }, [deviceZoom, setDeviceZoom]);

  useEffect(() => {
    deviceZoomRef.current = deviceZoom;
  }, [deviceZoom]);

  const handleOnLoad = (google, map) => {
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(
      <button
        className={`pi-btn pi-outline pi-white pi-shadow left map-marker-mode-button mr-15`}
        onClick={() => toggle()}
        ref={actionBtn}
      >
        <img src={MapMarkerGrey} alt='' />
        <span className='pi-font-size-sm'>Device</span>
      </button>,
      controlButtonDiv,
    );
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv);
  };

  return (
    <div className='pi-device-map'>
      <Map
        google={props?.google}
        mapType='Map'
        fullscreenControl={false}
        zoomControl={false}
        scaleControl={false}
        streetView={false}
        streetViewControl={false}
        mapTypeControl={true}
        zoom={!deviceZoom ? 10 : 18}
        onReady={loadTraffic}
        initialCenter={{
          lat: props?.currentLocation?.latitude || 0,
          lng: props?.currentLocation?.longitude || 0,
        }}
        center={{
          lat: props?.currentLocation?.latitude || 0,
          lng: props?.currentLocation?.longitude || 0,
        }}
      >
        {props?.currentLocation?.latitude && props?.currentLocation?.longitude && (
          <Marker
            name={props?.currentLocation?.name}
            device_id={props?.currentLocation?.device_id}
            position={{
              lat: props?.currentLocation?.latitude,
              lng: props?.currentLocation?.longitude,
            }}
            icon={{
              url: chooseMarker(props?.currentLocation?.status),
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(31, 36),
            }}
          />
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(LocationMapConnect);
